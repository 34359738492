<template>
  <div class="tz_box">

    <!-- <Tabs value="name1"> -->
    <!-- <Tab-pane :label="typeName" name="name1"> -->
    <div class="cell_box">
      <div class="cell th">
        <div v-for="(it,i) in th" :key="i">{{ it }}</div>
      </div>
      <div v-for="(it,i) in ryList" v-if="ryList.length>0" :key="i" class="cell td">
        <div>{{ params.pageSize * params.pageNo - params.pageSize + i + 1 }}</div>
        <!--        <div class="single">{{ it.name }}</div>-->
        <!--        <div class="single">{{ it.phone }}</div>-->
        <!--        <div class="single">{{ it.idcard }}</div>-->
        <!--        <div class="single">{{ it.company }}</div>-->
        <div class="single">{{ it.courseName }}</div>
        <div class="single">{{ it.enrollAmount }}元</div>
        <div class="single">{{ it.confirm == 1 ? '已确认' : '待确认' }}</div>
        <div class="single">{{ it.studyFinish == 1 ? '已完成' : '未完成' }}</div>
        <!--        <div class="single">{{ it.studyScore }}</div>-->
        <div class="single">{{ it.classHour }}学时</div>
        <div class="single">{{ it.classHourTotal }}学时</div>
        <div class="single">{{ it.enrollTime }}</div>
        <!-- <div class="single" style="cursor: pointer;" @click="goDetail(it)">查看</div> -->
      </div>
      <div v-if="ryList.length==0" class="common_excel_no_more">暂无内容~</div>
    </div>
    <Page v-if="ryList.length!=0" :current="params.pageNo" :page-size="params.pageSize" :total="total"
          class="common_page_box" show-total
          @on-change="changePage"></Page>
    <!-- </Tab-pane> -->
    <!-- </Tabs> -->
    <ksLogin ref="ksbox"></ksLogin>

    <!-- <detailModal ref="detailModal"></detailModal> -->
  </div>
</template>

<script>
import bannerBox from '@/components/banner.vue'
import headerBox from '@/components/header.vue'
import footerBox from '@/components/footer.vue'
import ksLogin from '@/components/ks_login.vue'
import detailModal from './detail.vue'
import {enrollInfo} from '@/api/home/guild'

export default {
  name: 'home',
  components: {
    bannerBox,
    headerBox,
    footerBox,
    ksLogin,
    detailModal
  },
  data() {
    return {
      th: ['序号', '培训名称', '报名金额', '是否确认', '学习进度', '总学时', '已获学时', '报名时间'],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10
      },
      form: {},
      ryList: [],
      userInfo: {},
      typeName: '',
      catid: ''
    }

  },
  created() {
    this.getArticle();
  },
  methods: {

    showInfo(obj) {
      console.log("===========obj=============", obj)
      this.showText = this.formatStatus(obj)
      this.showPop = true
    },
    formatStatus(obj) {
      //console.log("=======formatStatus========", obj, typeof(obj.status))
      if (obj.status == 1) return '审核通过'
      if (obj.status == 2) return '审核不通过' + (obj.otherinfo ? '|' + obj.otherinfo : '')
      return '待审核'
    },
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    getArticle() {
      enrollInfo({
        ...this.params,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: '错误',
            content: res.message,
            onOk: () => {
              this.$Cookies.remove('cyry_user');
              this.$Cookies.remove('cyry_token');
              this.$refs['ksbox'].showLogin = true;
            },
          });
        }
      })
    },
    toAdd() {
      this.$refs['detailModal'].showPop = true;
      this.$refs['detailModal'].id = '';
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getArticle();
    },
    goDetail(it) {
      this.$refs['detailModal'].showPop = true;
      this.$refs['detailModal'].id = it.id;
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div {
        width: 12%;
      }

      > div:first-child {
        width: 5%;
      }

      > div:nth-child(2) {
        width: 30%;
      }

      > div:nth-child(3) {
        width: 10%;
      }

      > div:nth-child(4) {
        width: 10%;
      }

      > div:nth-child(5) {
        width: 10%;
      }

      > div:nth-child(6) {
        width: 10%;
      }

      > div:nth-child(7) {
        width: 15%;
      }

      > div:nth-child(8) {
        width: 20%;
      }

      //
      //> div:nth-child(9) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(10) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(11) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(12) {
      //  width: 8%;
      //}
      //
      //> div:nth-child(13) {
      //  width: 15%;
      //}
    }

    .th {
      background-color: #49B3FF;
    }

    .td {
      // cursor: pointer;
    }

    .td:hover {
      background-color: #eee;
    }

    .td:nth-child(odd) {
      background-color: #D8D8D8;
    }

    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>