<!-- 在线申请会员 -->
<template>
	<div class="box1">
		<div class="sq_box">
			<img src="../../../assets/images/7.png" />
			<div class="sq_con">
				<!-- <div class="tit">在线申请会员</div> -->
				<div>
					<div @click="goApply(1)">个人会员</div>
					<div @click="goApply(2)">企业会员</div>
				</div>
			</div>
		</div>
		<!-- <div class="content">
			<div v-html="info.content"></div>
			<div class="fj_box">
				<div>相关附件:</div>
				<div>
					<div class="one_file">
						<div class="doc" @click="download">1：{{fjInfo.name}}.doc</div>
						<div class="iconfont icon-xiazai" @click="download"></div>
					</div>
					<div class="one_file">
						<div class="doc" @click="download2">2：{{fjInfo2.name}}.doc</div>
						<div class="iconfont icon-xiazai" @click="download2"></div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		components: {

		},
		data() {
			return {
				info: {},
			}

		},
		mounted() {
			// this.getSingleArticle();
		},
		methods: {
			getSingleArticle() {
				getSingleArticle({
					catid: 201,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
			goApply(e) {
				// this.$Message.warning('此功能暂未开放！');
				// return
				if (e == 2) {
					this.$router.push('apply')
				} else {
					// this.$router.push('personal')
					this.$Message.warning('个人会员窗口功能暂未开放！');
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box1 {
		.sq_box {
			position: relative;

			img {
				width: 100%;
				object-fit: cover;
				height: 180px;
				border-radius: 15px;
				vertical-align: top;
			}

			.sq_con {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				color: #fff;
				font-size: 35px;

				>div {
					display: flex;
					justify-content: space-around;
					margin: 5px;
					white-space: nowrap;
					width: 100%;

					div {
						// margin: 0 100px;
						font-family: STHupo;
					}

					>div {
						cursor: pointer;
					}

					>div:hover {
						// color: #999;
					}
				}

				.tit {
					font-family: STHupo;
				}
			}
		}

		.sq_box:hover {
			img {
				box-shadow: 8px 8px 10px 0 rgba(153, 153, 153, 0.5);
			}

			div {
				// transform: scale(1.08);
			}
		}

		.content {
			font-size: 14px;
			margin-top: 40px;
			color: #011243;

			.title {
				font-size: 25px;
				text-align: center;
				margin-bottom: 20px;
			}

		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.sq_box {
			position: relative;
			margin-top:40px;

			img {
				height: 120px!important;
				border-radius: 12px!important;
			}
			.sq_con{
				font-size: 26px!important;
			}
		}
	}
</style>
