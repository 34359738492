<template>
	<div class="notice_box">
		<div class="iconfont icon-notice"></div>
		<div class="marquee" :style="{ backgroundColor: bgColor, height: height + 'px' }">
			<span @click="goHH" class="marquee__title" ref="marqueeTitle"
				:style="{ color, fontSize: fontSize + 'px', animationDuration: speed + 's', '--speed': speed }"
				v-html="title"></span>
		</div>
	</div>
</template>

<script>
	/**
	 * NoticeBar 公告栏跑马灯
	 * @description 从右往左的跑马灯
	 * @property {String} content 显示的内容，支持html
	 * @property {String} color 文字颜色，默认#EFF0DB
	 * @property {Number} fontSize 文字大小，默认40
	 * @property {Number} speed 滚动速度，单位s（播放一次所用时间，默认根据内容宽度计算时间）
	 * @property {String} bgColor 背景颜色，默认#CC2529
	 * @property {Number} height 背景高度，默认78
	 */
	export default {
		name: 'NoticeBar',
		props: {
			content: {
				type: String,
				default: ''
			},
			noticeId:{
				type: String,
				default: ''
			},
			color: {
				type: String,
				default: '#333'
			},
			fontSize: {
				type: Number,
				default: 15
			},
			speed: {
				type: Number,
				default: 20
			},
			bgColor: {
				type: String,
				default: ''
			},
			height: {
				type: Number,
				default: 78
			}
		},
		data() {
			return {
				DEFAULT_SPEED: 500, // 默认速度，每秒跑的距离，单位：px/s
				title: ''
			};
		},
		watch: {
			content(value) {
				this.title = value;
				this.calcSpeed();
			}
		},
		created() {
			if (this.content) {
				this.title = this.content;
				this.calcSpeed();
			}
		},
		methods: {
			calcSpeed() {
				if (this.title !== '' && this.speed === 0) {
					this.$nextTick(() => {
						let width = this.$refs.marqueeTitle.clientWidth;
						this.speed = Number(width / this.DEFAULT_SPEED).toFixed(2);
					});
				}
			},
			goHH(){
				this.$router.push({
					name: 'news_detail',
					query:{
						id: this.noticeId
					}
				});
			},
		}
	};
</script>

<style scoped="scoped">
	.notice_box {
		display: flex;
		align-items: center;
		width: 95%;
		/* margin: 0 100px; */
	}

	.marquee {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		word-break: break-all;
		white-space: nowrap;
		overflow: hidden;
	}

	.icon-notice {
		font-size: 25px;
		padding-right: 10px;
	}

	.marquee__title {
		letter-spacing: 3px;
		cursor: default;
		display: inline-block;
		/* padding-left: 100%; */
		width: 1100px;
		cursor: pointer;
		animation: marqueeMove calc(var(--speed) * 0s) linear infinite;
	}

	.marquee:hover .marquee__title {
		animation-play-state: paused;
	}

	@keyframes marqueeMove {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-100%);
		}
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.notice_box{
			width: 90%;
		}
	}
</style>
