<template>
  <div class="detail">
    <headerBox :current="111"></headerBox>
    <bannerBox ref="bannerChild" :bgNum="bgNum"></bannerBox>
    <div class="content_box">
      <div class="title">{{ newInfo.title }}</div>
      <div class="line_box line_pc">
        <div class="time">发布时间：{{ newInfo.date }}</div>
        <div class="ly">来源：{{ newInfo.source || '暂无' }}</div>
        <div class="share">
          <Tooltip content="Top Center text" placement="top">
            <img src="@/assets/images/wx.png"/>
            <img slot="content" :src="newInfo.qrcode" class="qrcode"/>
          </Tooltip>
          <img src="@/assets/images/wb.png" @click="goWb"/>
        </div>
      </div>
      <div class="line_box line_sj">
        <div>
          <div class="ly">来源：{{ newInfo.source || '暂无' }}</div>
          <div class="time">发布时间：{{ newInfo.date }}</div>
        </div>
        <div class="share">
          <Tooltip content="Top Center text" placement="top">
            <img src="@/assets/images/wx.png"/>
            <img slot="content" :src="newInfo.qrcode" class="qrcode"/>
          </Tooltip>
          <img src="@/assets/images/wb.png" @click="goWb"/>
        </div>
      </div>

      <div style="margin: auto;display: flex;justify-content: center;">
        <div class="content" style="width: 900px;" v-html="newInfo.content"></div>
      </div>
    </div>
    <footerBox></footerBox>
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>

<script>
import bannerBox from '@/components/banner.vue'
import headerBox from '@/components/header.vue'
import footerBox from '@/components/footer.vue'
import {
  getDetatilArticle
} from '@/api/home/index'

export default {
  name: 'home',
  components: {
    bannerBox,
    headerBox,
    footerBox,
  },
  data() {
    return {
      newInfo: {},
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 6
      },
      catid: '',
      bgNum: 0
    }

  },
  mounted() {
    this.getDetatilArticle();
    if (this.$route.params.locationList && this.$route.params.locationList != 'undefined') {
      let locationList = JSON.parse(decodeURIComponent(this.$route.params.locationList));
      this.$refs["bannerChild"].locationList = locationList;
    }
    if (this.$route.params.bgNum) {
      this.bgNum = this.$route.params.bgNum;
    }
  },
  methods: {
    /* ====================数据请求部分========================== */
    getDetatilArticle() {
      getDetatilArticle({
        id: this.$route.query.id,
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          let newInfo = res.result;
          this.newInfo = newInfo
          this.total = res.result.total;
          let height = document.documentElement.clientHeight - 50;
          this.newInfo.content = this.newInfo.content.replace(/\<table/gi,
              `<table style="width:100%;" `);
          if (window.innerWidth > 800) { //屏幕宽度
            this.newInfo.content = this.newInfo.content.replace(/\<iframe/gi,
                `<iframe style="height:${height}px" `);
            // this.newInfo.content = this.newInfo.content.replace(/\<div/gi, `<div style="max-width:100%" ` );
            // this.newInfo.content = this.newInfo.content.replace(/\<img/gi, `<img style="max-width:100%;margin:auto" ` );
          } else { //手机端
            this.newInfo.content = this.newInfo.content.replace(/\<iframe/gi,
                `<iframe style="height:${height}px" `);
            this.newInfo.content = this.newInfo.content.replace(/\<div style="/gi,
                `<div style="max-width:${window.innerWidth - 40}px;overflow: hidden; `);
            this.newInfo.content = this.newInfo.content.replace(/\<img/gi,
                `<img style="max-width:${window.innerWidth - 40}px;height:auto" width:"100%" height="100%" `);
            this.newInfo.content = this.newInfo.content.replace(/\<table/gi,
                `<table style="max-width:${window.innerWidth - 40}px" `);
            this.newInfo.content = this.newInfo.content.replace(/\<td/gi,
                `<td style="max-width:${window.innerWidth - 40}px" `);
            this.newInfo.content = this.newInfo.content.replace(/\<hr/gi,
                `<hr style="width:${window.innerWidth - 40}px;background:#F00;height:7px;" `);
          }
        } else {
          this.infoList = [];
        }
      })
    },
    /* ====================页面交互部分========================== */
    goWb() {
      window.open(this.newInfo.weibo, '_blank')
    },
  }
}
</script>

<style lang="scss">
.detail {
  a, p, div {
    word-break: break-all;
  }

  .content_box {
    font-size: 15px;
    margin: 80px 200px;
    min-width: 1100px;

    .title {
      font-size: 22px;
      text-align: center;
      word-break: break-all;
    }

    .ly {
      font-size: 13px;
    }

    .line_box {
      padding: 50px 0 5px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .time {
        font-size: 13px;
      }

      .share {
        display: flex;

        img {
          height: 25px;
          margin: 0 10px;
          cursor: pointer;
        }

        .qrcode {
          height: 100px;
        }
      }
    }

  }

  // 大屏幕
  @media only screen and (min-width: 800px) {
    .line_pc {
      display: flex !important;
    }

    .line_sj {
      display: none !important;
    }
  }

  /* 手机 */
  @media only screen and (max-width: 800px) {
    .content_box {
      font-size: 15px;
      margin: 0 !important;
      min-width: 100% !important;
      width: 100%;
      padding: 30px 20px 80px;
      overflow: hidden;

      .title {
        font-size: 20px !important;
      }

      .line_box {
        padding: 30px 0 10px !important;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .time {
          font-size: 13px;
        }

        .share {
          display: flex;

          img {
            height: 25px;
            margin: 0 10px;
            cursor: pointer;
          }

          .qrcode {
            height: 100px;
          }
        }
      }
    }

    .line_pc {
      display: none !important;
    }

    .line_sj {
      display: flex !important;
    }
  }

  table {
    border: 1px solid #bbb;
    border-collapse: collapse;

    td {
      border: 1px solid #bbb;
      padding: 6px;
      // border-bottom: 1px solid #bbb;
      // border-right: 1px solid #bbb;
    }
  }
}

</style>
