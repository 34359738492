<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<!-- <div class="common_search_box">
				<Input placeholder="请输入相关关键词">
				</Input>
				<div class="btn_box">
					<div>搜索</div>
					<div>重置</div>
				</div>
			</div> -->
			<div class="pdf">
				<!-- <img src="../../assets/images/4.png"/> -->
				<div class="list_box">
					<div class="single" v-for="(it,i) in pptList" :key="i" @click="lookPdf(it)">{{it.name}}</div>
				</div>
				<!-- <div class="common_more" style="float: right;margin-top: 10px;">
					<span>更多</span>
					<span class="iconfont icon-gengduo"></span>
				</div> -->
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'

	import {
		getResourcese
	} from '@/api/home/guild'
	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				pptList: [],
			}
		},
		mounted() {
			this.getResourcesePPT();
			this.$refs["bannerChild"].locationList = ['教育培训', decodeURIComponent(this.$route.query.name)];
		},
		methods: {
			getResourcesePPT() {
				getResourcese({
					type: 7,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.pptList = res.result;
					}
				})
			},
			lookPdf(it){
				window.open(it.resource)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		padding: 80px 100px;
		max-width: 1400px;
		min-width: 1100px;
		margin: auto;

		.box3 {
			margin-top: 20px;

			.swiper-container {
				position: relative;
				width: 100vm;

				.swiper-slide {
					width: 100%;
					color: #000;
				}

				img {
					object-fit: fill;
					height: 300px;
					width: 100%;
				}

				.swiper_text {
					padding: 0px 20px 40px;

					.title {
						font-size: 20px;
						margin: 10px 0;
					}
				}
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #8971F1;
			}

		}
	}
	.pdf {
		margin: 20px 0 30px;
		img {
			width: 100%;
			height: 180px;
		}
		
		.tit {
			color: #3E41F0;
			font-family: Source Han Sans CN;
			font-size: 20px;
			white-space: nowrap;
			margin-bottom: 20px;
		}
		
		.list_box {
			div {
				border-bottom: 1px solid #eee;
				padding-bottom: 10px;
				margin-top: 10px;
				font-size: 14px;
				cursor: pointer;
				padding: 10px 0;
			}
			>div:hover{
				background-color: #eee;
			}
		}
	}
</style>
