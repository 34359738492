<template>
	<div>
		<Card :bordered="false">
			<div class="user_box">
				<div>
					<div class="avater">
						<img :src="xh_userInfo.user.resource" />
					</div>
					<div class="user_info">
						<div>欢迎您!</div>
						<div style="font-weight: bold;margin: 5px 0;">{{xh_userInfo.user.dwmc}}</div>
						<div style="font-size: 12px;">会员类型：{{xh_userInfo.user.typeName}}</div>
					</div>
				</div>
				<div>会员到期时间：{{xh_userInfo.yxjzrq}}</div>
				<div>
					<div class="xgmm" @click="updatePwd">修改密码</div>
				</div>
			</div>
		</Card>
		<div style="display: flex;width: 100%;">
			<Card :bordered="false" style="width: 50%;">
				<div slot="title" style="display: flex;">
					<p>通知</p>
					<div @click="goTz" class="common_more">
						<span>更多</span>
						<span class="iconfont icon-gengduo"></span>
					</div>
				</div>
				<div class="box1_height">
					<div v-if="newList.length>0">
						<div class="box1_content" v-for="(tt,ii) in newList" :key="ii">
							<div class="tag_line"></div>
							<div class="text single">{{tt.title}}</div>
							<div>{{tt.date}}</div>
						</div>
					</div>
					<div class="common_no_more" style="height: 248px" v-if="newList.length==0">暂无内容~
					</div>
				</div>
			</Card>
			<Card :bordered="false" style="width: 50%;">
				<div slot="title" style="display: flex;">
					<p>人员信息</p>
					<div @click="goRyxx" class="common_more">
						<span>更多</span>
						<span class="iconfont icon-gengduo"></span>
					</div>
				</div>
				<div v-if="ryList.length>0" class="ry_box">
					<div class="cell th">
						<div v-for="(it,i) in th" :key="i">{{it}}</div>
					</div>
					<div class="cell td" v-for="(it,i) in ryList" :key="i">
						<div>{{i+1}}</div>
						<div class="single">{{it.name}}</div>
						<div class="single">{{it.certno}}</div>
						<div>{{it.shrq}}</div>
						<div>{{it.yxqz}}</div>
					</div>
				</div>
				<div v-if="ryList.length==0" class="common_excel_no_more">暂无内容~</div>
			</Card>
		</div>
		<div style="display: flex;width: 100%;">
			<Card :bordered="false" style="width: 50%;">
				<div slot="title" style="display: flex;">
					<p>会议</p>
					<div @click="goHy" class="common_more">
						<span>更多</span>
						<span class="iconfont icon-gengduo"></span>
					</div>
				</div>
				<div v-if="hyList.length>0" class="ry_box">
					<div class="cell cellHY th">
						<div>序号</div>
						<div>会议主题</div>
						<div>会议内容</div>
					</div>
					<div class="cell cellHY td" v-for="(it,i) in hyList" :key="i">
						<div>{{i+1}}</div>
						<div class="single">{{it.title}}</div>
						<div class="single">{{it.content}}</div>
					</div>
				</div>
				<div v-if="hyList.length==0" class="common_excel_no_more">暂无内容~</div>
			</Card>
			<Card :bordered="false" style="width:48%;">
				<p slot="title">培训</p>
				<div class="common_excel_no_more">暂无内容~</div>
			</Card>
		</div>
		<upPwd ref="pwdbox"></upPwd>
	</div>
</template>
<script>
	import upPwd from '@/components/upPwd.vue'
	import {
		getV9News
	} from '@/api/home/guild'
	import {
		getArticleDes,
		getCyry,
		getMeeting,
		getJkxx,
	} from '@/api/home/index'
	export default {
		components: {
			upPwd
		},
		data() {
			return {
				contentHeight: '',
				menuList: [{
						name: '首页',
						icon: 'icon-shouye',
						url: '/'
					},
					{
						name: '通知',
						icon: 'icon-tongzhi',
						url: 'news',
						params: {
							name: encodeURIComponent('通知公告')
						}
					},
					{
						name: '培训',
						icon: 'icon-datixitongguanli',
						option: [{
								name: '在线报名'
							},
							{
								name: '课程中心'
							},
							{
								name: '培训通知'
							},
						]
					},
					{
						name: '会员信息',
						icon: 'icon-huiyuan',
						option: [{
								name: '会费管理',
								url: 'tax'
							},
							{
								name: '会员证书',
								url: 'member',
								params: {
									name: encodeURIComponent('会员证书')
								}
							},
						]
					},
					{
						name: '会议',
						icon: 'icon-huiyi',
						option: [{
							name: '会议报名',
							url: 'meeting'
						}, ]
					},
					{
						name: '单位信息',
						icon: 'icon-danwei',
						option: [{
								name: '基本信息',
								url: ''
							},
							{
								name: '人员信息',
								url: 'people'
							},
						]
					}
				],
				xh_userInfo: {
					user: {
						username: ''
					}
				},
				tabList1: [{
						label: '全部',
						value: 0,
					},
					{
						label: '会长单位',
						value: 1
					},
					{
						label: '副会长单位',
						value: 2
					},
					{
						label: '监事单位',
						value: 3
					},
					{
						label: '常务理事单位',
						value: 4
					},
					{
						label: '副秘书长',
						value: 5
					},
					{
						label: '理事单位',
						value: 6
					},
					{
						label: '会员单位',
						value: 7
					},
					{
						label: '监事长',
						value: 30
					}
				],
				newList: [],
				ryList: [],
				th: ['序号', '姓名', '证书编号', '审核日期', '有效期至'],
				hyList: [],
				menuObj: {
					name: '首页',
				}
			}
		},
		mounted() {
			if (this.$Cookies.get('xh_userInfo')) {
				this.xh_userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				this.xh_userInfo.user.typeName = this.tabList1.find(v => v.value == this.xh_userInfo.user.type).label
			}
			// const height = window.innerHeight;
			this.contentHeight = `calc(${100}vh - 64px - 0px)`;

			this.getArticleDes();
			this.getCyry();
			this.getMeeting();
		},
		methods: {
			/* ====================数据请求部分========================== */
			getArticleDes() {
				getArticleDes({
					order: 'desc',
					pageNo: 1,
					pageSize: 5,
					catid: 10
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.newList = res.result.records;
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				}).catch(err => {
					if (err.response.status == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			getCyry() {
				getCyry({
					pageNo: 1,
					pageSize: 5
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						this.ryList = info;
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			getMeeting() {
				getMeeting({
					pageNo: 1,
					pageSize: 5,
					vipid: this.xh_userInfo.user.uuid
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						this.hyList = info;
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			/* ====================页面交互部分========================== */
			goHome() {
				this.$router.push('/')
			},
			goTz() {
				this.$emit('changeMenu','通知')
			},
			goRyxx(){
				this.$emit('changeMenu','人员信息')
			},
			goHy(){
				this.$emit('changeMenu','会议报名')
			},
			updatePwd() {
				this.$refs['pwdbox'].showLogin = true;
			},
			goLogout() {
				let token = this.$Cookies.get('xh_userInfo') ? JSON.parse(this.$Cookies.get('xh_userInfo')).token : '';
				loginout({
					token
				}).then(res => {
					if (res.code == 200 || res.code == 0 || res.code == 401) {
						this.$Modal.confirm({
							title: '提示',
							content: '确定退出登录吗？',
							onOk: () => {
								this.$Message.success('退出成功！');
								this.$Cookies.remove('xh_userInfo');
								location.reload()
							},
						});
					}
				})
			},
			updatePwd() {
				this.$refs['pwdbox'].showLogin = true;
			},
		}

	}
</script>
<style scoped lang="scss">
	/deep/.ivu-menu-horizontal {
		height: 64px;
		line-height: 64px;
	}

	/deep/.ivu-layout-header {
		background-color: #4381E6;

		.ivu-menu-dark {
			background-color: #4381E6;
		}
	}

	/deep/.ivu-card {
		margin: 8px;
	}

	.layout {
		border: 1px solid #d7dde4;
		border-radius: 4px;
		background: #f5f7f9;
		min-width: 1400px;
		width: 100%;
		height: 100%;
		position: fixed;
		overflow: hidden;
		left: 0;
		top: 0;
	}

	.layout-logo {
		border-radius: 3px;
		float: left;
		position: relative;
		top: 0;
		left: 20px;
		display: flex;
		align-items: center;
		height: 100%;

		img {
			height: 50px;
			cursor: pointer;
			vertical-align: top;
		}
	}

	/deep/ .ivu-tooltip-inner {
		background-color: #fff;
		color: #011243;
	}

	/deep/ .ivu-tooltip-arrow {
		border-bottom-color: #fff
	}

	.layout-nav {
		width: 420px;
		margin: 0 auto;
		margin-right: 20px;
		color: #fff;
		font-size: 15px;
	}

	.userPop {
		font-size: 15px;
		text-align: center;

		>div {
			padding: 8px 0;
			cursor: pointer;
			color: #666;
		}

		>div:hover {
			// color: #2D85E2;
		}

		.outBtn {
			border-top: 1px solid #eee;
		}
	}

	.user {
		display: flex;
		align-items: center;
		margin-left: 30px;
		font-size: 15px;
		cursor: pointer;
		white-space: nowrap;

		.icon-chazhaoyonghu {
			margin-right: 10px;
		}
	}

	.layout-footer-center {
		text-align: center;
	}

	.user_box {
		display: flex;
		align-items: center;
		font-size: 16px;

		>div {
			display: flex;
			align-items: center;
			width: 33.33%;
			height: 100%;

			.avater {
				height: 80px;

				img {
					height: 100%;
					vertical-align: top;
				}
			}

			.user_info {
				margin-left: 20px;
			}

			.xgmm {
				font-size: 16px;
				background-color: #4381E6;
				color: #fff;
				padding: 8px 20px;
				border-radius: 20px;
				cursor: pointer;
			}
		}
	}

	/deep/.ivu-col {
		height: 100%;
	}

	/deep/.ivu-menu {
		font-size: 16px;
	}

	/deep/.ivu-menu-item {
		font-size: 16px;
	}

	.box1_height {
		// margin-top: -15px;

		.box1_content {
			font-size: 14px;
			border-bottom: 1px solid #eee;
			display: flex;
			align-items: center;
			white-space: nowrap;
			padding: 15px 5px;
			// margin-top: 20px;
			// cursor: pointer;

			.text {
				margin: 0 20px;
				font-size: 15px;
			}

			.tag_line {
				width: 4px;
				height: 19px;
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
			}
		}

		.box1_content:hover {
			background-color: #eee;
		}
	}

	.ry_box {
		margin-top: 10px;

		.cell {
			display: flex;
			width: 100%;
			white-space: nowrap;
			font-size: 14px;
			padding: 10px 0;
			text-align: center;
			border-left: 1px solid #eee;
			border-right: 1px solid #eee;

			>div:first-child {
				width: 10%;
			}

			>div:nth-child(2) {
				width: 20%;
			}

			>div:nth-child(3) {
				width: 20%;
			}

			>div:nth-child(4) {
				width: 20%;
			}
			>div:nth-child(5) {
				width: 20%;
			}
		}

		.cellHY {
			>div:first-child {
				width: 10%;
			}

			>div:nth-child(2) {
				width: 30%;
			}

			>div:nth-child(3) {
				width: 60%;
			}
		}

		.th {
			background-color: #49B3FF;
			color: #fff;
		}

		.td {
			// cursor: pointer;
		}

		.td:hover {
			background-color: #eee;
		}

		.td:nth-child(odd) {
			background-color: #D8D8D8;
		}

		.td:nth-child(even) {
			border-bottom: 1px solid #eee;
		}
	}
</style>
