<!-- 中标公示 -->
<template>
	<div class="box1_gg">
		<div class="search_box">
			<div class="search_top">
				<div>发布时间：</div>
				<div>
					<RadioGroup v-model="time" @on-change="getSort">
						<Radio v-for="(it,i) in timeRadio" :key="i" :label="it.label">
							<Icon type="social-apple"></Icon>
							<span>{{it.label}}</span>
						</Radio>
					</RadioGroup>
				</div>
				<div>
					<DatePicker :value="value2" @on-change="getLong" :editable="false" format="yyyy-MM-dd" type="daterange"
						placement="bottom-end" placeholder="选择时间"></DatePicker>
				</div>
			</div>
			<div class="dq_box">
				<div>交易场所：</div>
				<div class="qd_list" v-if="qdList.length>0">
					<div class="qd_item" v-for="(it,i) in qdList" :key="i" @click="chooseCs(it)"
						:class="[form.ssdq==it.commoninfo?'isYes':'isNo']">{{it.commoninfo}}</div>
				</div>
			</div>
			<div class="search_tom">
				<!-- <RadioGroup v-model="form.sfyx" @on-change="toSearch">
					<Radio v-for="(it,i) in stateRadio" :key="i" :label="it.value">
						<Icon type="social-apple"></Icon>
						<span>{{it.label}}</span>
					</Radio>
				</RadioGroup> -->
				<div>
					关键词：
					<Input v-model="form.keywords" @on-enter="getArticle" placeholder="请输入相关关键词"
						style="width: 200px"></Input>
				</div>
				<div class="btn_box">
					<img @click="toSearch" src="@/assets/images/engineering/btn1.png" />
					<img @click="toReset" src="@/assets/images/engineering/btn2.png" />
				</div>
			</div>
		</div>
		<div class="cell th">
			<div class="name single">中标公示名称</div>
			<div class="ssdq single">交易场所</div>
			<div class="hy single">所属行业</div>
			<div class="time single">公告发布时间</div>
			<!-- <div class="time single">距离开标时间</div> -->
			<div class="addr single">来源渠道</div>
		</div>
		<div class="td_box" v-if="infoList.length>0">
			<div class="cell td" v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
				<div class="name single">{{it.title}}</div>
				<div class="ssdq single">{{it.ssdq}}</div>
				<div class="hy single">{{it.hy}}</div>
				<div class="time single">{{it.fbsj}}</div>
				<!-- <div class="time single">{{it.jlkbsj}}</div> -->
				<div class="addr single">{{it.lyqd}}</div>
			</div>
		</div>
		<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
		<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total"
			:current="params.pageNo" :page-size="params.pageSize" show-total></Page>
	</div>
</template>

<script>
	import {
		getArticle,
		getZbdq
	} from '@/api/home/index'
	import {
		getOtherDate
	} from '@/utils/util'
	export default {
		components: {

		},
		props: {
			locationList: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 10,
				},
				infoList: [],
				qdList: [{
						commoninfo: '全部'
					},
					{
						commoninfo: '省本级'
					},
					{
						commoninfo: '贵阳市'
					},
					{
						commoninfo: '遵义市'
					},
					{
						commoninfo: '六盘水市'
					},
					{
						commoninfo: '安顺市'
					},
					{
						commoninfo: '毕节市'
					},
					{
						commoninfo: '铜仁市'
					},
					{
						commoninfo: '黔东南州'
					},
					{
						commoninfo: '黔南州'
					},
					{
						commoninfo: '黔西南州'
					}
				],
				time: '全部',
				timeRadio: [{
						label: '三天内',
						value: ''
					},
					{
						label: '一周内',
						value: ''
					},
					{
						label: '全部',
						value: ''
					}
				],
				form: {
					ks: '',
					js: '',
					sfyx: 0,
					keywords: '',
					ssdq: '全部'
				},
				stateRadio: [{
						label: '有效期内',
						value: 1
					},
					{
						label: '已失效',
						value: 2
					},
					{
						label: '全部',
						value: 0
					}
				],
				value2: ['', '']
			}

		},
		mounted() {
			this.getArticle();
			// this.getZbdq();
		},
		methods: {
			getArticle() {
				let param = {
					catid: 303,
					...this.params,
					...this.form
				}
				param.ssdq = param.ssdq =='全部'?'':param.ssdq;
				if(param.ssdq =='省本级'){
					param.ssdq = '贵州';
				}
				getArticle(param).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			getZbdq() {
				getZbdq({
					catid: 303,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.qdList = res.result;
					} else {
						this.qdList = [];
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			toReset() {
				this.form = {
					ks: '',
					js: '',
					sfyx: 0,
					keywords: '',
					ssdq: '全部'
				};
				this.value2 = ['', ''];
				this.params.pageNo = 1;
				this.time = '全部';
				this.getArticle()
			},
			toSearch() {
				if (this.time == '三天内') {
					this.form.ks = getOtherDate(-3);
					this.form.js = getOtherDate(0);
				}
				if (this.time == '一周内') {
					this.form.ks = getOtherDate(-7);
					this.form.js = getOtherDate(0);
				}
				if (this.time == '全部') {
					this.form.ks = '';
					this.form.js = '';
				}
				this.getArticle();
			},
			getLong(e) {
				this.time = '';
				this.form.ks = e[0];
				this.form.js = e[1];
				this.getArticle();
			},
			getSort(e) {
				this.value2 = ['', ''];
				this.toSearch();
			},
			chooseCs(it) {
				this.form.ssdq = it.commoninfo;
				this.getArticle();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box1_gg {
		font-family: YouYuan;
		font-weight: bold;
	}

	.search_box {
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
		color: #0E2C68;
		font-size: 16px;

		>div {
			padding: 20px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.ivu-radio-wrapper {
			font-size: 16px;
			margin: 0;
		}

		/deep/.ivu-radio-inner {
			border-radius: 0;
		}

		/deep/.ivu-radio-checked {
			.ivu-radio-inner {
				background-color: #2D8CF0;
			}

			.ivu-radio-inner::after {
				display: none;
			}
		}

		.search_top {
			>div:first-child {
				width: 20%;
			}

			>div:nth-child(2) {
				width: 40%;
				text-align: right;
			}

			>div:last-child {
				width: 40%;
				text-align: right;
				padding-right: 20px;
			}

			/deep/.ivu-input {
				font-size: 15px;
				border: 1.5px solid;
				border-image: linear-gradient(to right, #02B3FF, #2C65FF) 4;
			}

			.ivu-date-picker {
				font-size: 13px;
				width: 220px !important;
			}

			.ivu-radio-group {
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: right;

			}
		}

		/deep/.ivu-input {
			font-size: 15px;
			border: 1.5px solid;
			border-image: linear-gradient(to right, #02B3FF, #2C65FF) 4;
		}

		.dq_box {
			border-top: 1px solid #E9E9E9;

			.qd_list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 90%;

				.qd_item {
					padding: 2px 10px;
					margin: 5px;
					border-radius: 5px;
					cursor: pointer;
					font-size: 15px;
				}
				.isNo:hover{
					color: #2D8CF0;
				}

				.isYes {
					background-color: #2D8CF0;
					color: #fff;
				}
			}
		}

		.search_tom {
			background-color: #E4F3FF;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			justify-content: space-between;
			display: flex;

			.btn_box {
				margin-right: 20px;

				img {
					height: 28px;
					cursor: pointer;
					vertical-align: top;
				}

				img:last-child {
					margin-left: 40px;
				}
			}
		}
	}

	.td_box {
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
	}

	.cell {
		display: flex;
		font-size: 14px;
	}

	.th {
		font-weight: bold;
		margin-bottom: 15px;
		margin-top: 50px;
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
		text-align: center;
		color: #072563;
		padding: 15px 0;
		font-size: 16px;

		>div {
			border-right: 1.5px solid #42BAFD;
			padding: 5px 0;
		}

		>div:last-child {
			border: none;
		}
	}

	.td {
		cursor: pointer;
		font-size: 15px;
		text-align: center;
		height: 50px;
		line-height: 50px;
		border-bottom: 1.5px solid #42BAFD;
		font-family: SimHei;
		font-weight: normal;

		>div:nth-child(even) {
			background-color: #E9E9E9;
		}

		>div {
			border-right: 1.5px solid #42BAFD;
			padding: 0 5px;
		}

		>div:last-child {
			border: none;
		}
	}

	.td:last-child {
		border: none;
	}

	.td:hover {
		color: #072563;
	}

	.name {
		width: 40%;
	}

	.hy {
		width: 20%;
	}

	.time {
		width: 20%;
	}

	.addr {
		width: 28%;
	}

	.ssdq {
		width: 15%;
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.qd_list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-start;
			width: 100%;
		}
		.search_box {
			border-radius: 8px;
			font-size: 14px;
		
			>div {
				padding: 10px;
			}
		
			.ivu-radio-wrapper {
				font-size: 14px;
			}
		
			.search_top {
				>div:first-child {
					width: 15%;
				}
				
				>div:nth-child(2) {
					width: 40%;
				}
				
				>div:last-child {
					width:45%;
					padding-right: 0;
				}
			}
			.search_tom {
				.btn_box {
					margin-right: 0;
			
					img {
						height: 25px;
					}
			
					img:last-child {
						margin-left: 30px;
					}
				}
			}
		}
	}
	/deep/.ivu-input {
		font-size: 12px!important;
	}
	.th {
		margin-bottom: 15px;
		margin-top: 40px;
		border-radius: 8px;
		padding: 5px 0;
		font-size: 16px;
	}
	.td {
		font-size: 14px;
		height: 40px;
		line-height: 40px;
	}
	.name {
		width: 30%;
	}
	
	.hy {
		width: 15%;
	}
	
	.time {
		width: 22%;
	}
	
	.addr {
		width: 20%;
	}
	
	.ssdq {
		width: 15%;
	}
</style>
