<template>
	<div class="footer_box">
		<div class="footer_content">
			<div>网站主办：贵州省建设工程招标投标协会</div>
			<div>
				<div>联系电话：0851-86587786</div>
				<div>Emall：ztbxh@163.com</div>
			</div>
			<div>地址：贵州省贵阳市云岩区延安西路66号汇金国际广场商务楼16层06号</div>
			<div>
				<div>版权所有© 贵州省建设工程招标投标协会</div>
				<div class="isLink" @click="goBah">黔ICP备13002703号-1</div>
			</div>
			<div class="link_box">
				<div v-for="(it,i) in list" :key="i" @click="goLink(it)">{{it.name}}</div>
			</div>
		</div>
		<img src="../assets/images/5.png" />
	</div>
</template>
<script>
	import {
		getPartner
	} from '@/api/home/index'
	export default {
		name: 'Header',
		props: {
			isBlue: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				list: [{
						name: '关于我们',
						url: 'guild',
						urlName: '协会简介'
					},
					{
						name: '网站声明',
						url: 'agreement',
						urlName: '网站声明',
						catid: 217,
					},
					{
						name: '网站隐私保护条款',
						url: 'agreement',
						urlName: '网站隐私保护条款',
						catid: 210,
					},
					{
						name: '考试系统隐私保护条款',
						url: 'agreement',
						urlName: '考试系统隐私保护条款',
						catid: 215
					},
					{
						name: '联系我们',
						url: 'guild',
						urlName: '联系我们',
					},
					// {
					// 	name: '帮助中心',
					// 	url: 'guild',
					// 	urlName: '联系我们',
					// }
				]
			}
		},
		mounted() {
			// this.getPartner();
		},
		methods: {
			getPartner() {
				getPartner({}).then(res => {
					if (res.code == 200 || res.code == 0) {
						// this.keyList = JSON.parse();
						console.log(res.result)
					}
				})
			},
			goLink(it) {
				let query = {};
				if (it.urlName) {
					query.name = encodeURIComponent(it.urlName)
				}
				if (it.catid) {
					query.catid = it.catid;
				}
				this.$router.push({
					name: it.url,
					query: query
				})
				location.reload()
			},
			toCall() {
				window.open(
					window.location.href = 'tel://0851-7588458', '_blank')
			},
			goBah() {
				window.open('https://beian.miit.gov.cn/#/Integrated/index')
			},
		}
	}
</script>
<style lang="scss" scoped>
	.footer_box {
		background-color: #1F2C45;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		// padding: 30px 0;
		color: #aaa;
		height: 210px;

		.footer_content {
			div {
				display: flex;
				margin: 5px 0;
				font-size: 14px;
				margin-right: 40px;
			}

			.link_box {
				>div {
					cursor: pointer;
				}

				>div:hover {
					color: #3E41F0;
				}
			}

			.phone {
				cursor: pointer;
			}

			.isLink {
				cursor: pointer;
			}

			.isLink:hover {
				color: #3E41F0;
			}
		}

		img {
			width: 120px;
			position: absolute;
			top: -40px;
			right: 100px;
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.footer_box {
			height: 300px;
			padding: 20px;
			img {
				width: 100px;
				position: absolute;
				top: -40px;
				right: 30px;
			}
			.footer_content{
				margin-top: 40px;
				div {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					margin: 5px 0;
					font-size: 12px;
					margin-right: 20px!important;
				}
			}
		}
	}
</style>
