<template>
  <div class="basicInfo-view">
    <div class="basicInfo-head">
      <el-card>
        <div slot="header" class="clearfix">
          <span>基本信息</span>
          <!--          <el-button size="small" style="float: right;" type="primary" @click="goStudy">学习系统</el-button>-->

        </div>
        <div>
          <el-descriptions>
            <el-descriptions-item label="姓名">{{ cyry_user.name }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ cyry_user.phone }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{ cyry_user.sfz }}</el-descriptions-item>
            <el-descriptions-item label="公司">{{ cyry_user.dw }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </div>
    <div class="basicInfo-center">
      <div class="basicInfo-item">
        <el-card :body-style="{padding:'10px'}" style="height: 100%">
          <div slot="header" class="clearfix">
            <span>报名信息</span>
          </div>
          <div>
            <el-table v-loading="loading" :cell-style="{padding: 0}" :data="enrollsData"
                      :header-cell-style="{lineHeight: '35px',height: '35px',padding: 0}"
                      height="300"
                      style="width: 100%;">
              <el-table-column align="center" label="姓名" prop="name" show-overflow-tooltip
                               width="80px"></el-table-column>
              <el-table-column align="center" label="电话" prop="phone" show-overflow-tooltip
                               width="120px"></el-table-column>
              <el-table-column align="center" label="身份证号" prop="idCard" show-overflow-tooltip
                               width="120px"></el-table-column>
              <el-table-column align="center" label="公司" prop="company" show-overflow-tooltip
                               width="120px"></el-table-column>
              <el-table-column align="center" label="作废状态" prop="zfrq" show-overflow-tooltip width="80px">
                <template #default="scope">
                  {{ scope.row.zfrq ? '作废' : '正常' }}
                </template>
              </el-table-column>
              <el-table-column align="center" fixed="right" label="操作" width="80px">
                <template #default="scope">
                  <el-button type="text" @click="enrollsClick(scope.row)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
      <div class="basicInfo-item">
        <el-card :body-style="{padding:'10px'}" style="height: 100%">
          <div slot="header" class="clearfix">
            <span>考试记录</span>
          </div>
          <div>
            <el-table v-loading="loading" :cell-style="{padding: '8px'}" :data="scoresData"
                      :header-cell-style="{lineHeight: '35px',height: '35px',padding: 0}"
                      height="300"
                      style="width: calc(100%- 20px);">
              <el-table-column align="center" label="姓名" prop="name" show-overflow-tooltip>
                <template #default="scope">
                  {{ cyry_user.name }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="手机号" prop="phone" show-overflow-tooltip width="150px">
                <template #default="scope">
                  {{ cyry_user.phone }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="考试结果" prop="score" show-overflow-tooltip width="120px">
                <template #default="scope">
                  {{ scope.row.type == 3 ? "免考" : scope.row.score }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="考试时间" prop="kssj" show-overflow-tooltip width="150px">
              </el-table-column>
              <!--              <el-table-column align="center" label="证书情况" prop="iscancel" w>-->
              <!--                <template #default="scope">-->
              <!--                  {{ scope.row.iscancel == '0' ? '正常' : scope.row.iscancel == '1' ? '注销' : '' }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <!--              <el-table-column align="center" label="证书图片" prop="resourceUrl">-->
              <!--                <template #default="scope">-->
              <!--                  <el-image-->
              <!--                      :preview-src-list="[$previewImg + scope.row.resourceUrl + `?token=${$Cookies.get('cyry_token')}`]"-->
              <!--                      :src="$previewImg + scope.row.resourceUrl + `?token=${$Cookies.get('cyry_token')}`"-->
              <!--                      style="width: 80px; height: 80px" @preview="handlePreview"></el-image>-->
              <!--                </template>-->
              <!--              </el-table-column>-->

            </el-table>
          </div>
        </el-card>
      </div>
      <div class="basicInfo-item">
        <el-card :body-style="{padding:'10px'}" style="height: 100%">
          <div slot="header" class="clearfix">
            <span>证书记录</span>
          </div>
          <div>
            <el-table v-loading="loading" :cell-style="{padding: 0}" :data="certificatesData"
                      :header-cell-style="{lineHeight: '35px',height: '35px',padding: 0}"
                      height="300"
                      style="width: 100%;">
              <el-table-column align="center" label="姓名" prop="certName" show-overflow-tooltip
                               width="80px"></el-table-column>
              <el-table-column align="center" label="性别" prop="certSex" show-overflow-tooltip
                               width="60px"></el-table-column>
              <el-table-column align="center" label="公司" prop="certCompany" show-overflow-tooltip
                               width="150px"></el-table-column>
              <el-table-column align="center" label="生效日期" prop="yxqq" show-overflow-tooltip
                               width="120px"></el-table-column>
              <el-table-column align="center" label="失效日期" prop="yxqz" show-overflow-tooltip
                               width="110px"></el-table-column>
              <el-table-column align="center" fixed="right" label="操作" width="80px">
                <template #default="scope">
                  <el-button type="text" @click="certificatesClick(scope.row)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
      <div class="basicInfo-item">
        <el-card :body-style="{padding:'10px'}" style="height: 100%">
          <div slot="header" class="clearfix">
            <span>培训记录</span>
          </div>
          <div>
            <el-table v-loading="loading" :cell-style="{padding: 0}" :data="courseStudyTotalsData"
                      :header-cell-style="{lineHeight: '35px',height: '35px',padding: 0}"
                      height="300"
                      style="width: 100%;">
              <!--              <el-table-column align="center" label="课程ID" prop="courseId"></el-table-column>-->
              <el-table-column align="center" label="培训课程" prop="courseName" show-overflow-tooltip
                               width="300px"></el-table-column>
              <el-table-column align="center" label="学习时间" prop="updateTime"
                               show-overflow-tooltip></el-table-column>
              <!--              <el-table-column align="center" label="学习进度" prop="studyStep"></el-table-column>-->
              <el-table-column align="center" label="学习状态" prop="studyFinish" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.studyFinish == '1' ? '已完成' : scope.row.studyFinish == '0' ? '学习中' : '' }}
                </template>
              </el-table-column>
              <!--              <el-table-column align="center" label="已获学时" prop="classHour">-->
              <!--                <template #default="scope">-->
              <!--                  {{ scope.row.classHour ? scope.row.classHour + '学时' : '' }}-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column align="center" label="操作" width="80px">
                <template #default="scope">
                  <el-button type="text" @click="courseStudyTotalsClick(scope.row)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>

    </div>
    <EnrollsDetails :details="enrollsDetails" @handleClose="detailsClose"></EnrollsDetails>
    <CertificatesDetails :details="certificatesDetails" @handleClose="detailsClose"></CertificatesDetails>
    <CourseStudyTotalsDetails :details="courseStudyTotalsDetails"
                              @handleClose="detailsClose"></CourseStudyTotalsDetails>
  </div>
</template>

<script>
import EnrollsDetails from './EnrollsDetails.vue'
import CertificatesDetails from './CertificatesDetails.vue'
import CourseStudyTotalsDetails from './CourseStudyTotalsDetails.vue'
import {queryInfoByCyry, toKsxt} from "@/api/home/basicInfo";

export default {
  name: "basicInfo",
  components: {
    EnrollsDetails,
    CertificatesDetails,
    CourseStudyTotalsDetails
  },
  data() {
    return {
      cyry_user: {},
      enrollsDetails: {
        dialogVisible: false,
        title: '报名信息详情',
        data: {}
      },
      certificatesDetails: {
        dialogVisible: false,
        title: '报名信息详情',
        data: {}
      },
      courseStudyTotalsDetails: {
        dialogVisible: false,
        title: '培训记录详情',
        data: {}
      },
      enrollsData: [], // 报名信息
      scoresData: [], // 考试记录
      certificatesData: [], //证书记录
      courseStudyTotalsData: [], // 培训记录
      loading: false,
    }
  },
  mounted() {
    if (this.$Cookies.get('cyry_user')) {
      this.cyry_user = JSON.parse(this.$Cookies.get('cyry_user'))
      this.getInfoByCyry()
    }
  },
  methods: {
    // 报名信息 详情
    enrollsClick(row) {
      this.enrollsDetails.dialogVisible = true
      this.enrollsDetails.data = row
      this.enrollsDetails.title = '报名信息详情'
      console.log('row', row)
    },
    // 证书记录 详情
    certificatesClick(row) {
      this.certificatesDetails.dialogVisible = true
      this.certificatesDetails.title = '证书信息详情'
      this.certificatesDetails.data = row
      console.log('row', row)

    },
    // 证书记录 详情
    courseStudyTotalsClick(row) {
      this.courseStudyTotalsDetails.dialogVisible = true
      this.courseStudyTotalsDetails.title = '培训记录详情'
      this.courseStudyTotalsDetails.data = row
      console.log('row', row)
    },

    detailsClose() {
      this.certificatesDetails.dialogVisible = false
      this.certificatesDetails.data = {}
      this.enrollsDetails.dialogVisible = false
      this.enrollsDetails.data = {}
      this.courseStudyTotalsDetails.dialogVisible = false
      this.courseStudyTotalsDetails.data = {}
      // this.getInfoByCyry()
    },
    getInfoByCyry() {
      this.loading = true
      queryInfoByCyry({
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 0) {
          this.enrollsData = res.result.enrolls || []
          this.scoresData = res.result.scores || []
          this.certificatesData = res.result.certificates || []
          this.courseStudyTotalsData = res.result.courseStudyTotals || []
        }
        this.loading = false
        console.log('res', res)
      })
    },
    goStudy() {
      toKsxt({token: this.$Cookies.get('cyry_token')}).then(res => {
        if (res) {
          if (res.code == 200) {
            let token = res.result.token || ''
            let url =
                `${this.$ksHost}/#/home?token=${token}`;
            window.open(url, '_blank')
          }
        } else {
          this.$message(res.message);
        }
      }).catch(err => {
        this.$message(err);
      })

    },
    handlePreview(e) {
      console.log('Preview triggered', e);
    }
  }
}
</script>

<style lang="scss" scoped>
.basicInfo-view {
  display: flex;
  flex-wrap: wrap;

  .basicInfo-head {
    width: 100%;
    margin-bottom: 10px;
  }

  .basicInfo-center {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .basicInfo-item {
      width: calc(50% - 5px);
      margin-bottom: 10px;
      height: 400px;
    }
  }


}
</style>