<template>
  <div class="course">
    <el-card :body-style="{padding:'10px'}" style="height: 100%;width: 100%;min-height: 500px">
      <div slot="header" class="clearfix">
        <span>课程中心</span>
      </div>
      <div>
        <el-table v-loading="loading" :data="enrollsData" style="width: 100%;">
          <el-table-column align="center" label="课程名称" prop="name" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" label="课程学时" prop="classHour" show-overflow-tooltip width="100px">
            <template #default="scope">
              {{ scope.row.classHour ? scope.row.classHour + '学时' : '' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="课程简介" prop="description" show-overflow-tooltip
                           width="150px"></el-table-column>
          <el-table-column align="center" label="课程价格" prop="classPrice" show-overflow-tooltip width="100px">
            <template #default="scope">
              {{ scope.row.charge == '0' ? '免费' : scope.row.classPrice }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="学习方式" prop="study" show-overflow-tooltip width="100px">
            <template #default="scope">
              {{ scope.row.study == '0' ? '线下' : scope.row.study == '1' ? '线上' : '' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="报名状态" prop="isEnroll" show-overflow-tooltip width="100px">
            <template #default="scope">
              {{
                scope.row.isEnroll == '0' ? '待确认' : scope.row.isEnroll == '1' ? '已报名' : '未报名'
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="学习状态" prop="studyFinish" show-overflow-tooltip width="120px">
            <template #default="scope">
              {{ scope.row.studyFinish == '1' ? '已完成' : scope.row.studyFinish == '0' ? '未学习' : '未学习' }}
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="80px">
            <template #default="scope">
              <div v-if="scope.row.study == '1'">
                <el-button v-if="scope.row.charge == '0'" type="text" @click="goDetail(scope.row)">立即学习
                </el-button>
                <el-button v-if="scope.row.charge!=0 && scope.row.isEnroll == '1'" type="text" @click="goXX(scope.row)">
                  立即学习
                </el-button>
                <el-button v-if="scope.row.charge!=0 && scope.row.isEnroll==null" type="text" @click="toBm(scope.row)">
                  立即报名
                </el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
            :page-size="10"
            :page-sizes="[10, 50, 100, 200]"
            :total="total"
            layout="total, sizes, prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>

import {courseEnroll, cyryCourseList} from "@/api/home/guild";

export default {
  components: {},
  data() {
    return {
      cyry_user: {},
      enrollsData: [], // 报名信息
      loading: false,
      total: 0,
      queryType: 'web',
      params: {
        pageNo: 1,
        pageSize: 10
      },
    }
  },
  mounted() {
    if (this.$Cookies.get('cyry_user')) {
      this.cyry_user = JSON.parse(this.$Cookies.get('cyry_user'))
      this.getInfoByCyry()
    }
  },
  methods: {
    goDetail(row) {
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '提示',
          content: '未登录，请登录从业人员信息后开始学习。',
          okText: '去登录',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      if (row.isEnroll == 0) {
        this.$Modal.info({
          title: '提示',
          content: '已报名缴费未确认,请在缴费确认后参加学习。',
        });
        return
      }
      // }
      let url = 'subjectDetail';
      this.$router.push({
        name: url,
        query: {
          id: row.id,
          name: encodeURIComponent('公益讲座')
        }
      });
    },
    // 点击学习 按钮
    goXX(row) {
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '提示',
          content: '未登录，请登录从业人员信息后开始学习。',
          okText: '去登录',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      if (row.isEnroll == 0) {
        this.$Modal.info({
          title: '提示',
          content: '已报名缴费未确认,请在缴费确认后参加学习。',
        });
        return
      }
      let url = 'subjectDetail';
      this.$router.push({
        name: url,
        query: {
          id: row.id,
          name: encodeURIComponent('公益讲座')
        }
      });
    },
    // 报名学习 按钮
    toBm(it) {
      let that = this;
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '温馨提示',
          okText: '登录',
          content: '未登录，请您登录从业人员信息后报名学习',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      that.$Modal.confirm({
        title: '温馨提示',
        width: 600,
        okText: '报名',
        content: `<div style="font-size:16px">
					尊敬的${that.cyry_user.name}用户，该课程为收费课程，请您先缴费后学习！
					缴费金额：<span style="color:red">${that.ishy ? '会员价' : '非会员价'}${this.ishy ? it.classPricemember : it.classPrice}元</span>，
					缴费完成后系统将为您开通学习入口，祝您取得佳绩~
					</div>
					<br>
					帐号：52001613600052521882<br>
					收款单位名称：贵州省建设工程招标投标协会<br>
					开 户 行：中国建设银行股份有限公司贵阳朝阳支行<br>
					<br>
					如您已缴费，系统仍未开通，请拨打0851-86587786咨询。
					`,
        onOk: () => {
          setTimeout(() => {
            courseEnroll({
              id: it.id,
              token: that.$Cookies.get('cyry_token')
            }).then(res => {
              if (res.code == 200 || res.code == 0) {
                that.$Modal.info({
                  title: '提示',
                  content: res.message,
                  onOk: () => {
                    that.getInfoByCyry();
                  },
                });
              }
            })
          }, 500)
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageNo = 1
      this.params.pageSize = val
      this.getInfoByCyry()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNo = val
      this.getInfoByCyry()
    },
    getInfoByCyry() {
      this.loading = true
      cyryCourseList({
        ...this.params,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200) {
          this.enrollsData = res.result.records || []
          this.total = res.result.total
        }
        this.loading = false
        console.log('res', res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.course {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>