import request from '@/utils/request'
import Cookies from 'js-cookie'

// 查询证书获取验证码
export function getStuCode(params) {
	return request({
		url: '/jeecg-boot/web/code',
		method: 'POST',
		// params: params,
		data: JSON.stringify(params)
	})
}

// 证书登录
export function getLogin(params) {
	return request({
		url: '/jeecg-boot/web/stuLogin',
		method: 'POST',
		// params: params,
		data: JSON.stringify(params)
	})
}
// 修改密码
export function changePwd(params) {
	return request({
		url: '/jeecg-boot/web/changePwd',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}

// 退出登录
export function loginout(params) {
	return request({
		url: '/jeecg-boot/index/loginout',
		method: 'GET',
		params: params,
	})
}

// 会员
export function getHyLogin(params) {
	return request({
		url: '/jeecg-boot/index/login',
		method: 'POST',
		// params: params,
		data: JSON.stringify(params)
	})
}

// 图形验证码获取
export function getRandomImage(params) {
	return request({
		url: `/jeecg-boot/sys/randomImage/${new Date()}`,
		method: 'GET',
		params: params,
	})
}
