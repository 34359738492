<template>
	<div v-if="bannerList.length>0" class="recommendPage" ref="bannerSwiper">
		<swiper :options="swiperOption" ref="swiperBox1">
			<swiperSlide v-for="(it,i) in bannerList" :key="i">
				<img :src="it.resource">
			</swiperSlide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<!-- 中间菜单 -->
		<div class="menuLink" v-if="locationList.length>0" :class="[bgNum==0?'bg1':'bg2']">
			<div class="name">当前位置</div>
			<div class="menu_text">
				<div @click="goHome" class="home_text">首页</div>
				<div v-for="(it,i) in locationList" :key="i">
					<span @click="toClick(it,i)"
						:style="{'cursor':it=='从业人员'||(locationList.length <= i + 1?!zdList.find((v => v.name == locationList[i - 1])):!zdList.find(v => v.name == it)&&!zdList.find((v => v.name == locationList[i - 1])))?'':'pointer'}">{{it}}</span>
					<span v-if="locationList.length>i+1" class="iconfont icon-gengduo"></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import {
		getBanner
	} from '@/api/home/index'
	export default {
		name: 'banner',
		components: {
			swiper,
			swiperSlide,
		},
		props: {
			bgNum: {
				type: Number,
				default: 0 //0蓝色 1红色
			},
		},
		data() {
			return {
				locationList: [],
				bannerList: [],
				swiperOption: {
					loop: true,
					speed: 1000,
					autoplay: false,
					allowTouchMove: false,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true, //允许分页点击跳转
					},
					on: {
						click: function() {
							// const index = this.clickedSlide.dataset.swiperSlideIndex
							// this.goBannerUrl(index)
						}
					}
				},
				zdList: [{
						name: '首页',
						url: '/'
					},
					{
						name: '行业动态',
						url: 'news',
						subName: '行业要闻'
					},
					{
						name: '党建专栏',
						url: 'party',
						subName: '支部简介'
					},
					{
						name: '工程交易',
						url: 'engineering',
						subName: '招标公告'
					},
					{
						name: '从业人员',
						url: 'people'
					},
					{
						name: '关于协会',
						url: 'guild',
						subName: '协会简介'
					},
					{
						name: '会员中心',
						url: 'member',
						subName: '入会须知'
					},
					{
						name: '政策法规',
						url: 'policy',
					},
					{
						name: '教育培训',
						url: 'subject',
						subName: '公益讲座'
					},
					{
						name: '学习培训',
						url: 'subject',
						subName: '公益讲座'
					}
				]
			}
		},
		mounted() {
			this.getBanner();
		},
		methods: {
			getBanner() {
				getBanner({}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.bannerList = res.result;
					}
				})
			},
			goBack() {
				this.$router.go(-1);
			},
			goHome() {
				this.$router.push('/')
			},
			toClick(it, i) {
				if (this.locationList.length <= i + 1) { //最后一个
					let item = this.zdList.find((v => v.name == this.locationList[i - 1]));
					if (item) {
						if (item.name == '从业人员') {
							location.reload()
							return
						}
						if (item) {
							let query = {
								name: encodeURIComponent(it)
							};
							this.$router.push({
								name: item.url,
								query: query
							})
						}
						location.reload()
					}
				} else {

					if (it == '从业人员') {
						return
					}
					let item = this.zdList.find((v => v.name == it));
					if (item) {
						let query = {};
						if (item.subName) {
							query.name = encodeURIComponent(item.subName)
						}
						this.$router.push({
							name: item.url,
							query: query
						})
						location.reload()
					} else {
						let subItem = this.zdList.find((v => v.name == this.locationList[i - 1]));
						if (subItem) {
							let query = {
								name: encodeURIComponent(it)
							};
							this.$router.push({
								name: subItem.url,
								query: query
							})
						}
						location.reload()
					}
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.recommendPage {
		position: relative;

		.swiper-container {
			position: relative;
			width: 100vm;

			.swiper-slide {
				width: 100%;
				color: #000;
				text-align: center;
			}

			img {
				object-fit: cover;
				height: 550px;
				width: 100%;
				vertical-align: top;
			}


		}

		/deep/ .swiper-pagination-bullet-active {
			background-color: #fff;
			width: 24px;
			border-radius: 15px;
		}
	}

	.menuLink {
		z-index: 99;
		position: absolute;
		bottom: 0;
		width: 80%;
		left: 0;
		padding: 10px 80px;
		text-align: left;
		color: #fff;
		font-size: 14px;
		display: flex;
		align-items: center;
		white-space: nowrap;

		>div {
			display: flex;
			align-content: center;
		}

		.icon-fanhui {
			padding: 0 20px;
			font-size: 30px;
			cursor: pointer;
		}

		.name {
			font-size: 16px;
			margin-right: 20px;
			color: #eee;
		}

		.menu_text {
			font-size: 15px;

			// cursor: pointer;
			>div {
				display: flex;
				align-items: center;
			}

			.iconfont {
				padding: 0 5px;
			}

			.home_text {
				margin-right: 10px;
				cursor: pointer;
			}
		}
	}

	.bg1 {
		background: linear-gradient(270deg, rgba(45, 132, 226, 0.00) 0%, #2D85E2 45%, #5B43DF 100%);
	}

	.bg2 {
		background: linear-gradient(270deg, rgba(226, 223, 45, 0.00) 0%, #FFC300 45%, #FF0000 97%);
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.recommendPage {
			img {
				object-fit: cover;
				height: 220px !important;
				width: 100%;
				vertical-align: top;
			}

			.menuLink {
				width: 80%;
				padding: 8px 30px;
				overflow: hidden;
			}
		}
	}
</style>