<!-- 支部简介 -->
<template>
	<div class="item1_box">
		<!-- <div class="title">中共贵州省建设工程招标投标协会党支部简介</div> -->
		<!-- <div class="time">{{info.date}}</div> -->
		<div class="content" v-html="info.content"></div>
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/home/party'
	export default {
		components: {
			
		},
		data() {
			return {
				info:{}
			}

		},
		mounted() {
			this.getSingleArticle();
		},
		methods: {
			getSingleArticle(){
				getSingleArticle({
					catid: 205,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.item1_box {
		.title {
			font-size: 22px;
			text-align: center;
		}
		
		.time {
			font-size: 12px;
			padding: 50px 0 20px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			width: 100%;
		}
	}
</style>
