<template>
	<div>
		<Modal v-model="showPop" width="60%" class="model_box" scrollable :mask-closable="false">
			<div class="pop_content" :style="{height:height}">
				<Spin size="large" fix v-if="isLoading"></Spin>
				<div v-else>
					<!-- <div class="zxBtn">
						<Button type="primary" icon="ios-trash-outline" @click="toZx">申请注销证书</Button>
					</div> -->
					<div class="box">
						<div class="cell_box">
							<div>
								<div>姓名：</div>
								<div>{{ryInfo.name}}</div>
							</div>
							<div>
								<div>证书编号：</div>
								<div>{{ryInfo.certno}}</div>
							</div>
							<div>
								<div>身份证号：</div>
								<div>{{ryInfo.sfz}}</div>
							</div>
							<div>
								<div>从业单位：</div>
								<div>{{ryInfo.dw}}</div>
							</div>
						</div>
						<div class="zs_box">
							<img :src="ryInfo.resource" alt="">
						</div>
					</div>
				</div>
			</div>
		</Modal>
		<zxPop ref="zxbox"></zxPop>
		<vipLogin ref="vipbox"></vipLogin>
	</div>
</template>

<script>
	import zxPop from './zx_pop.vue'
	import vipLogin from '@/components/vip_login.vue'
	import {
		changePwd,
	} from '@/api/login'
	import {
		getCyry
	} from '@/api/home/index'
	export default {
		components: {
			zxPop,
			vipLogin
		},
		data() {
			return {
				showPop: false,
				form: {},
				loading: false,
				id: '',
				newInfo: {},
				height: '',
				isLoading: false,
				ryInfo: {},
				certno: ''
			}
		},
		mounted() {
			this.height = (window.innerHeight - 200) + 'px';
		},
		watch: {
			certno(val) {
				if (val) {
					this.getCyry(val)
				}
			}
		},
		methods: {
			getCyry() {
				this.isLoading = true
				getCyry({
					certno: this.certno
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						if (res.result.records.length > 0) {
							this.ryInfo = res.result.records[0];
						}
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
					this.isLoading = false
				})
			},
			toZx() {
				this.$refs['zxbox'].showLogin = true;
				this.$refs['zxbox'].form.certno = this.ryInfo.certno;
			},
			goWb() {
				window.open(this.newInfo.weibo, '_blank')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

	}

	/deep/ .ivu-btn-text {
		display: none;
	}


	.pop_content {
		font-size: 15px;
		padding: 50px;
		overflow-y: scroll;
		overflow-x: hidden;

		.title {
			font-size: 22px;
			text-align: center;
		}
	}

	.zxBtn {
		display: flex;
		justify-content: flex-end;
		text-align: right;
		margin-top: -30px;
	}

	.box {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		margin-top: 50px;

		.cell_box {
			margin-top: 20px;

			>div {
				display: flex;
				align-items: center;
				margin: 20px;
				font-size: 15px;

				>div:first-child {
					width: 100px;
					/*高度需要添加，不然文字下面会多出一些空隙*/
					text-align: justify;
					height: 20px;

					&::after {
						content: "";
						display: inline-block;
						width: 100%;
					}
				}

				>div:last-child {
					background-color: #D8D8D8;
					width: 220px;
					height: 40px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					padding: 0 10px;
				}
			}
		}

		.zs_box {
			display: flex;
			margin-left: 30px;
			width: 300px;
			height: 100%;

			img {
				vertical-align: top;
				height: 100%;
				width: 100%;
			}
		}
	}
</style>
