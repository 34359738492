<template>
  <div class="container">
    <headerBox :current="4"></headerBox>
    <bannerBox ref="bannerChild"></bannerBox>
    <div class="pep_box" style="overflow-x: scroll">
      <div class="table_box">
        <ryTable></ryTable>
      </div>
    </div>
    <footerBox></footerBox>
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import vipLogin from "@/components/vip_login.vue";
import ryTable from "./components/table.vue";
import { getCyry } from "@/api/home/index";
import { handleIdCard } from "@/utils/util";

export default {
  name: "home",
  components: {
    bannerBox,
    headerBox,
    footerBox,
    vipLogin,
    ryTable,
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs["bannerChild"].locationList = ["从业人员", "证书查询"];
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.table_box {
  min-width: 1200px;
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .table_box {
    min-width: 850px;
  }
}
</style>
