<template>
	<div class="container">
		<headerBox :current="0"></headerBox>
		<bannerBox></bannerBox>
		<div class="common_content">
			<div class="notice_content" v-if="showNotice">
				<noticeBar :content="noticeContent" :noticeId="noticeId"></noticeBar>
				<span @click="showNotice=false" class="iconfont icon-close"></span>
			</div>
			<box1 :style="{'margin-top':showNotice?'':'50px'}"></box1>
			<box2 class="margin_top"></box2>
			<box3 class="margin_top"></box3>
			<boxD class="margin_top"></boxD>
			<!-- <box5 class="margin_top"></box5> -->
		</div>
		<box6></box6>
		<bayWindow></bayWindow>
		<!-- <bayWindow2></bayWindow2> -->
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import bannerBox from '@/components/banner.vue'
	import leftMenu from '@/components/left_menu.vue'
	import noticeBar from '@/components/notice.vue'
	import bayWindow from '@/components/bay_window.vue'
	import bayWindow2 from '@/components/bay_window2.vue'
	import box1 from './components/box1.vue'
	import box2 from './components/box2.vue'
	import box3 from './components/box3.vue'
	import boxD from './components/box_d.vue'
	import box5 from './components/box5.vue'
	import box6 from './components/box6.vue'
	import {
		getArticleDes
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			headerBox,
			footerBox,
			bannerBox,
			leftMenu,
			noticeBar,
			box1,
			box2,
			box3,
			boxD,
			box5,
			box6,
			bayWindow,
			bayWindow2
		},
		data() {
			return {
				noticeContent: '',
				showNotice:true,
				noticeId:''
			}

		},
		mounted() {
			this.getArticleDes();
		},
		methods: {
			/* ====================数据请求部分========================== */
			getArticleDes() {
				getArticleDes({
					catid:10,
					pageNo: 1,
					pageSize: 1
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let newList = res.result.records[0];
						// let arr = [];
						// newList.map(v=>{
						// 	arr.push(v.title)
						// })
						// console.log(newList)
						this.noticeContent = newList.title;
						this.noticeId = newList.id.toString();
					}
				})
			},
			/* ====================页面交互部分========================== */

		}
	}
</script>

<style lang="scss" scoped>
	.notice_content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.icon-close{
			font-size: 20px;
			cursor: pointer;
			padding-left: 20px;
		}
		.icon-close:hover{
			color: #6D8BE7;
		}
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.icon-close{
			padding-left: 15px;
		}
	}
</style>
