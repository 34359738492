<template>
	<div class="box6">
		<div class="common_content box" style="background-color: transparent;">
			<div class="common_box_tit">
				<div>政策法规</div>
				<div class="bom_line"></div>
			</div>
			<div class="flex_zcfg">
				<div class="zcfg">
					<div class="search_box">
						<div class="tab">
							<div v-for="(it,i) in tabList" :key="i" @click="getTab(it)">
								<div>{{it.name1}}</div>
								<div>{{it.name2}}</div>
							</div>
						</div>
						<!-- <div class="input_box">
							<span class="iconfont icon-sousuo"></span>
							<Input v-model="keywords" placeholder="输入关键字" @on-enter="goUrl"></Input>
							<div class="btn" @click="goUrl">查询</div>
						</div> -->
					</div>
					<!-- <div class="rmss">
						<div class="tit">热门搜索</div>
						<div class="gjc">
							<div v-for="(it,i) in keyList" :key="i" @click="chooseKey(it)">{{it.keywords}}</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="common_box_tit yqlj_title">
				<div>友情链接</div>
				<div class="bom_line"></div>
			</div>
			<div class="yqlj_select">
				<Select class="item" v-for="(it,i) in typeList" :key="i" transfer :placeholder="it" @on-change="getYqlj" clearable style="width:300px;">
					<OptionGroup :label="it">
						<Option v-for="item in selectList[it]" :value="item.url" :key="item.name">{{ item.name }}</Option>
					</OptionGroup>
				</Select>
			</div>
			<!-- <div class="yqlj">
				<div v-for="(it,i) in yqList" :key="i" @click="goLink(it.url)">{{it.name}}</div>
			</div> -->
		</div>
		<vipLogin ref="vipbox"></vipLogin>
	</div>
</template>
<script>
	import {
		getLawKw,
		getPartner
	} from '@/api/home/index'
	import vipLogin from '@/components/vip_login.vue'
	export default {
		components: {
			vipLogin
		},
		data() {
			return {
				current: 100,
				// tabList: [{
				// 		name: '政策查询',
				// 		type: 1
				// 	},
				// 	{
				// 		name: '法律查找',
				// 		type: 2
				// 	}
				// ],
				tabList:[
					{
						name: '全部',
						name1: '全部',
						type: 0
					},
					{
						name: '国家法律',
						name1: '国家法律',
						type: 19
					},
					{
						name: '国家行政法规',
						name1: '国家行政法规',
						type: 20
					},
					{
						name: '国务院规范性',
						name1: '国务院规范性',
						type: 97
					},
					{
						name: '住建部规章及规范性文件',
						name1: '住建部规章',
						name2: '及规范性文件',
						type: 136
					},
					{
						name: '其他部门规章及规范性文件',
						name1: '其他部门规章',
						name2: '及规范性文件',
						type: 155
					},
					// {
					// 	name: '贵州省有关规范性文件',
					// 	name1: '贵州省有关',
					// 	name2:'规范性文件',
					// 	type: 156
					// }
				],
				keyList: [],
				yqList: [],
				keywords: '',
				typeList:[],
				selectList:[]
			}
		},
		mounted() {
			// this.getLawKw();
			this.getPartner();
		},
		methods: {
			getLawKw() {
				getLawKw({
					type: this.tabList[this.current].type
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.keyList = res.result;
					}
				})
			},
			getPartner() {
				getPartner({}).then(res => {
					if (res.code == 200 || res.code == 0) {
						// this.yqList = res.result;
						const aa = res.result.reduce((m, o) => ({
							...m,
							[o.ext1]: [...(m[o.ext1] || []), o]
						}), {});
						let bb = res.result.map(v=>{
							return v.ext1
						})
						this.selectList = aa;
						this.typeList = Array.from(new Set(bb))
					}
				})
			},
			getTab(it) {
				this.goUrl(it)
			},
			goUrl(it) {
				 console.log("===========xh_userInfo=============", this.$Cookies.get('xh_userInfo'))
				if(this.$Cookies.get('xh_userInfo')){
					this.$router.push({
						name: 'policy',
						query: {
							type:it.type
						}
					})
				}else{
					this.$refs['vipbox'].showLogin = true;
					this.$refs['vipbox'].isZCFG = true;
					this.$refs['vipbox'].zcfgQuery = {
						type: it.type,
					};
				}
			},
			goLink(url) {
				window.open(url, '_blank')
			},
			getYqlj(url){
				if(url){
					setTimeout(()=>{
						window.open(url, '_blank')
					},1200)
				}
			},
			chooseKey(it) {
				this.keywords = it.keywords;
			},
		}
	}
</script>
<style lang="scss" scoped>
	.box6 {
		position: relative;
		background-image: url('@/assets/images/7.png');
		background-repeat: no-repeat;
		background-position: 50% 50%;
		height: 700px;
		z-index: 0;
		padding: 70px 0;

		.bg {
			width: 100%;
		}

		.common_box_tit {
			color: #fff !important;

			.bom_line {
				background: #fff !important;
			}
		}
		
		.flex_zcfg{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.zcfg {
			// width: 600px;
			margin: 50px auto 0;

			.search_box {
				display: flex;
				justify-content: center;
				flex-direction: column;

				.tab {
					display: flex;
					// flex-wrap: wrap;
					justify-content: space-between;
					font-size: 17px;
					width: 1100px;

					>div {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						background-color: #6C6CE4;
						color: #fff;
						padding: 20px 35px;
						cursor: pointer;
						margin: 10px;
						border-radius: 5px;
						white-space: nowrap;
					}

					.selected {
						background-color: #fff;
						border-bottom: 1px solid #eee;
						color: #6C6CE4;
						font-weight: bold;
					}
					>div:hover{
						background-color: #fff;
						color: #6C6CE4;
						font-weight: bold;
					}

					>div:first-child {
						// border-top-left-radius: 12px;
						// border-bottom-left-radius: 12px;
					}

					>div:last-child {
						// border-top-right-radius: 12px;
						// border-bottom-right-radius: 12px;
					}
				}

				.input_box {
					background-color: #fff;
					padding: 15px 15px;
					border-radius: 15px;
					border-top-left-radius: 0;
					display: flex;
					align-items: center;

					.btn {
						white-space: nowrap;
						padding: 0 10px;
						cursor: pointer;
						font-size: 16px;
					}

					.btn:hover {
						color: #3E41F0;
					}

					.iconfont {
						color: #3E41F0;
						font-weight: bold;
						margin-right: 10px;
					}
				}
			}

			.rmss {
				color: #fff;
				display: flex;
				align-items: flex-start;
				margin-top: 30px;

				.tit {
					font-size: 14px;
					padding-right: 30px;
					margin-right: 30px;
					border-right: 1px solid #eee;
					white-space: nowrap;
				}

				.gjc {
					display: flex;
					flex-wrap: wrap;
					font-size: 14px;

					>div {
						margin: 0 10px 10px;
						cursor: pointer;
					}

					>div:hover {
						color: #6C6CE4;
					}
				}
			}
		}
		.yqlj_title{
			margin-top: 95px;
		}
		.yqlj_select{
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			.item{
				margin: 20px 30px;
			}
			/deep/.ivu-select-single .ivu-select-selection{
				height: 40px!important;
				.ivu-select-placeholder, .ivu-select-selected-value{
					height: 40px!important;
					line-height: 40px!important;
					font-size: 14px;
					color: #333;
				}
				.ivu-select-placeholder,{
					// font-size: 15px;
					// color:#666
				}
			}
		}
		.yqlj {
			color: #fff;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			font-size: 14px;

			>div {
				margin: 15px 10px;
				width: 260px;
				cursor: pointer;
			}

			>div:hover {
				color: #6C6CE4;
			}
		}

	}
	/deep/.ivu-select-item{
		font-size: 14px!important;
	}
	/deep/.ivu-select-group-title{
		// background-color: #2B4BFA!important;
		// color: #fff;
		font-size: 14px;
		// text-align: center;
		// font-weight: bold;
	}
	/deep/.ivu-select-dropdown-transfer{
		background-color: red!important;
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.box6{
			height: auto!important;
			.zcfg{
				margin-top: 40px!important;
				.tab{
					font-size: 15px!important;
					width: 100%!important;
					flex-wrap: wrap;
					justify-content: center!important;
					>div {
						padding: 15px!important;
						margin: 10px!important;
						width: 38%;
					}
				}
			}
			.yqlj_title{
				margin-top: 60px!important;
			}
		}
	}
</style>
