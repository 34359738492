<template>
  <div>
    <headerBox :current="3"></headerBox>
    <bannerBox ref="bannerChild"></bannerBox>
    <div v-if="isPC" class="en_pc">
      <div class="content_box">
        <left-tabs ref="tabsChild" :bgColor='1' @change="getMenu" :menu="menuList"></left-tabs>
        <div class="right_box">
          <item1 v-if="current==0" :locationList="locationList"></item1>
          <cq-xg v-if="current==1" @toGG="toGG" :locationList="locationList"></cq-xg>
          <item3 v-if="current==2" @toGG="toGG" :locationList="locationList"></item3>
          <!--          <item2 v-if="current==2" :locationList="locationList"></item2>-->
          <!--          <item4 v-if="current==2" :locationList="locationList"></item4>-->
          <zbhxr v-if="current==3" :locationList="locationList"></zbhxr>
          <zsgg v-if="current==4" :locationList="locationList"></zsgg>
        </div>
      </div>
    </div>
    <div v-if="!isPC" class="en_sj">
      <div class="tab_box">
        <div class="tab_item" v-for="(it,i) in menuList" :key="i" @click="chooseTab(i)"
             :class="{'selected':current==i}">
          <div>{{ it.title }}</div>
          <div v-show="current==i" class="line"></div>
        </div>
      </div>
      <div style="overflow-x: scroll;">
        <div style="min-width: 570px;padding: 20px 10px 50px;">
          <!-- <van-tabs v-model="current" :swipe-threshold="2" color="#2D85E2" @click="getMenu">
            <van-tab v-for="(it,i) in menuList" :title="it.title"> -->
          <div style="margin-top: 10px;">
            <!--            <item1 v-if="current==0"></item1>-->
            <!--            <item3 v-if="current==1" :locationList="locationList"></item3>-->
            <!--            <item2 v-if="current==2" :locationList="locationList"></item2>-->
            <!--            <item4 v-if="current==3" :locationList="locationList"></item4>-->
            <item1 v-if="current==0" :locationList="locationList"></item1>
            <cq-xg v-if="current==1" @toGG="toGG" :locationList="locationList"></cq-xg>
            <item3 v-if="current==2" @toGG="toGG" :locationList="locationList"></item3>
            <!--          <item2 v-if="current==2" :locationList="locationList"></item2>-->
            <!--          <item4 v-if="current==2" :locationList="locationList"></item4>-->
            <zbhxr v-if="current==3" :locationList="locationList"></zbhxr>
            <zsgg v-if="current==4" :locationList="locationList"></zsgg>
          </div>
          <!-- </van-tab>
          </van-tabs> -->
        </div>
      </div>
    </div>
    <footerBox></footerBox>
    <Back-top :height="100" :bottom="120">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>

<script>
import bannerBox from '@/components/banner.vue'
import headerBox from '@/components/header.vue'
import footerBox from '@/components/footer.vue'
import leftTabs from '@/components/left_tabs.vue'
import item1 from './components/item1.vue'
import item2 from './components/item2.vue'
import item3 from './components/item3.vue'
import item4 from './components/item4.vue'
import CqXg from "@/views/engineering/components/CqXg.vue";
import Zbhxr from "@/views/engineering/components/Zbhxr.vue";
import Zsgg from "@/views/engineering/components/Zsgg.vue";
import {
  getArticle
} from '@/api/home/index'

export default {
  name: 'home',
  components: {
    bannerBox,
    headerBox,
    footerBox,
    leftTabs,
    item1,
    item2,
    item3,
    item4,
    CqXg,
    Zbhxr,
    Zsgg
  },
  data() {
    return {
      locationList: [],
      current: 0,
      menuList: [{
        title: '招标公告',
      },
        {
          title: '澄清与修改公告'
        },

        {
          title: '中标结果公示',
        },
        {
          title: '中标候选人公示'
        },

        {
          title: '资审公告',
        },
        // {
        // 	title: '违法违规信息',
        // },
      ],
      isPC: !window.matchMedia("(max-width: 800px)").matches
    }

  },
  mounted() {
    let index = this.menuList.findIndex(v => v.title == decodeURIComponent(this.$route.query.name))
    this.setLocation(index);
  },
  methods: {
    getMenu() {
      this.setLocation();
    },
    chooseTab(i) {
      this.current = i;
      this.setLocation(i);
    },
    setLocation(index) {
      let current = index || index == 0 ? index : this.$refs["tabsChild"].current;
      this.current = current;
      let locationList = ['工程交易', this.menuList[current].title];
      this.locationList = locationList;
      this.$refs["tabsChild"].current = current;
      this.$refs["bannerChild"].locationList = locationList;
    },
    toGG() {
      this.$refs["tabsChild"].current = 0
      this.setLocation();
    }
  }
}
</script>

<style lang="scss" scoped>
.content_box {
  margin: 80px 100px;
  display: flex;
  min-width: 1200px;

  .right_box {
    min-width: 75%;
  }
}

// 大屏幕
@media only screen and (min-width: 800px) {
  .en_pc {
    display: flex;
  }

  .en_sj {
    display: none;
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .en_pc {
    display: none !important;
  }

  .en_sj {
    display: flex !important;
    flex-direction: column;

    .tab_box {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin-top: 20px;
      font-size: 17px;
      color: #646566;

      .selected {
        color: #323233;
        position: relative;
        font-weight: 500;
      }

      .tab_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .line {
        width: 40px;
        height: 3px;
        border-radius: 3px;
        background-color: rgb(45, 133, 226);
      }
    }
  }
}
</style>
