<template>
  <div class="tz_box">
    <div class="downLoadBtn" @click="download">
      <span>下载培训记录</span>
      <span class="iconfont icon-xiazai"></span>
    </div>
    <div class="cell_box">
      <div class="cell th">
        <div v-for="(it,i) in th" :key="i">{{ it }}</div>
      </div>
      <div v-for="(it,i) in ryList" v-if="ryList.length>0" :key="i" class="cell td">
        <div>{{ it.courseId }}</div>
        <div class="single">{{ it.courseName }}</div>
        <div class="single">{{ it.updateTime }}</div>
        <div class="single">{{ it.studyStep }}</div>
        <div class="single">{{ it.studyFinish == 1 ? '是' : '否' }}</div>
        <div class="single">{{ it.classHour }}学时</div>
        <div class="detailBtn" @click="lookDetail(it)">查看详情</div>
      </div>
      <div v-if="ryList.length==0" class="common_excel_no_more">暂无内容~</div>
    </div>
    <Page v-if="ryList.length!=0" :current="params.pageNo" :page-size="params.pageSize" :total="total"
          class="common_page_box" show-total @on-change="changePage"></Page>
    <!-- </Tab-pane> -->
    <!-- </Tabs> -->
    <ksLogin ref="ksbox"></ksLogin>

    <detailModal ref="detailModal"></detailModal>
  </div>
</template>

<script>
import bannerBox from '@/components/banner.vue'
import headerBox from '@/components/header.vue'
import footerBox from '@/components/footer.vue'
import ksLogin from '@/components/ks_login.vue'
import detailModal from './detail.vue'
import {recordInfo, studyInfo} from '@/api/home/guild'
import {tosfm} from '@/utils/util'

export default {
  name: 'home',
  components: {
    bannerBox,
    headerBox,
    footerBox,
    ksLogin,
    detailModal
  },
  data() {
    return {
      th: ['课程ID', '培训课程', '学习时间', '学习进度', '是否学习完成', '已获学时', '操作'],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10
      },
      ryList: [],
    }

  },
  created() {
    this.getArticle();
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getArticle();
    },
    download() {
      // window.open(this.$host + '/jeecg-boot/web/course/total/export?token=' + this.$Cookies.get('cyry_token'))

      // studyDownload({
      // 	token: this.$Cookies.get('cyry_token')
      // }).then(res => {

      // })
      // const link = document.createElement('a')
      //
      // link.style.display = 'none'  //去除a标签的样式
      let url = this.$host + '/jeecg-boot/web/course/total/export?token=' + this.$Cookies.get('cyry_token')
      // document.body.appendChild(link)
      // //模拟点击事件
      // link.download = '学习记录' + '.pdf'
      // link.click()
      //
      // //移除创建的a标签
      // window.URL.revokeObjectURL(link.href)
      // document.body.removeChild(link)
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = '培训记录.pdf'
        a.click()
        // document.body.removeChild(a)
        // 然后移除
      }
      x.send()
    },
    getArticle() {
      studyInfo({
        ...this.params,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: '错误',
            content: res.message,
            onOk: () => {
              this.$Cookies.remove('cyry_user');
              this.$Cookies.remove('cyry_token');
              this.$refs['ksbox'].showLogin = true;
            },
          });
        }
      })
    },
    lookDetail(it) {
      recordInfo({
        courseId: it.courseId,
        pageNo: 1,
        pageSize: 10,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          if (info.length > 0) {
            info.map(v => {
              v.xxsj =
                  `${tosfm(v.studyTimes)[0] == '00' ? '00:' : tosfm(v.studyTimes)[0] + ':'}${tosfm(v.studyTimes)[1] == '00' ? '00:' : tosfm(v.studyTimes)[1] + ':'}${tosfm(v.studyTimes)[2] == '00' ? '00' : tosfm(v.studyTimes)[2] + ''}`
            })
          }
          this.$refs['detailModal'].showPop = true;
          this.$refs['detailModal'].newInfo = info;
          this.$refs['detailModal'].total = res.result.total;
          this.$refs['detailModal'].courseId = it.courseId;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: '错误',
            content: res.message,
            onOk: () => {
              this.$Cookies.remove('cyry_user');
              this.$Cookies.remove('cyry_token');
              this.$refs['ksbox'].showLogin = true;
            },
          });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.tz_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .downLoadBtn {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
  }

  .downLoadBtn:hover {
    color: #2D85E2;
  }

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div {
        // width: 12%;
      }

      > div:first-child {
        width: 10%;
      }

      > div:nth-child(2) {
        width: 40%;
      }

      > div:nth-child(3) {
        width: 10%;
      }

      > div:nth-child(4) {
        width: 10%;
      }

      > div:nth-child(5) {
        width: 10%;
      }

      > div:nth-child(6) {
        width: 10%;
      }

      > div:nth-child(7) {
        width: 10%;
      }
    }

    .th {
      background-color: #49B3FF;
    }

    .td {
      // cursor: pointer;
    }

    .td:hover {
      background-color: #eee;
    }

    .td:nth-child(odd) {
      background-color: #D8D8D8;
    }

    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }

    .detailBtn {
      cursor: pointer;
    }

    .detailBtn:hover {
      color: #2D85E2;
    }
  }
}
</style>