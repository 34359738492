<template>
  <div class="container">
    <headerBox :current="5"></headerBox>
    <bannerBox ref="bannerChild"></bannerBox>
    <div style="overflow-x: scroll">
      <div class="content_box">
        <!-- <div class="zxBtn">
          <Button type="primary" icon="ios-trash-outline" @click="toZx">申请注销证书</Button>
        </div> -->
        <Tabs value="name1">
          <Tab-pane label="从业人员库-招标代理从业人员资料" name="name1">
            <div class="box">
              <div class="cell_box">
                <div>
                  <div>姓名：</div>
                  <div>{{ ryInfo.name }}</div>
                </div>
                <div>
                  <div>证书编号：</div>
                  <div>{{ ryInfo.certno }}</div>
                </div>
                <div>
                  <div>身份证号：</div>
                  <div>{{ ryInfo.sfz }}</div>
                </div>
                <div>
                  <div>从业单位：</div>
                  <div>{{ ryInfo.dw }}</div>
                </div>
              </div>
              <div>
                <div class="zs_box">
                  <viewer :images="[{ url: ryInfo.resource }]">
                    <img ref="toast" :src="ryInfo.resource" />
                  </viewer>
                </div>
                <!--                <div class="xz_btn">-->
                <!--                  <a target="_blank" :href="ryInfo.resource" download="download.png">查看证书</a>-->
                <!--                </div>-->
                <!-- <div class="xz_btn" @click="xzImg(ryInfo.resource)">
                  <span>附件下载</span>
                  <span class="iconfont icon-xiazai"></span>
                </div> -->
              </div>
            </div>
          </Tab-pane>
        </Tabs>
      </div>
    </div>
    <footerBox></footerBox>
    <zxPop ref="zxbox"></zxPop>
    <vipLogin ref="vipbox"></vipLogin>
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import zxPop from "./components/zx_pop.vue";
import vipLogin from "@/components/vip_login.vue";
import html2canvas from "html2canvas";

import { getCyry } from "@/api/home/index";

export default {
  name: "home",
  components: {
    bannerBox,
    headerBox,
    footerBox,
    zxPop,
    vipLogin,
  },
  data() {
    return {
      ryInfo: {},
      params: {},
    };
  },
  mounted() {
    if (this.$route.query.certno) {
      this.params.certno = this.$route.query.certno;
    }
    if (this.$route.query.sfz) {
      this.params.sfz = this.$route.query.sfz;
    }
    if (this.$route.query.token) {
      this.params.token = this.$route.query.token;
    }
    this.$refs["bannerChild"].locationList = [
      "从业人员",
      "证书查询",
      "从业人员资料",
    ];
    this.getCyry();
  },
  methods: {
    showImg() {
      window.open(this.ryInfo.resource, "blank");
    },
    getCyry() {
      getCyry(this.params).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.ryInfo = res.result.records[0];
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
          this.$refs["vipbox"].hasLoad = true;
        }
      });
    },
    toZx() {
      this.$refs["zxbox"].showLogin = true;
      this.$refs["zxbox"].form.certno = this.ryInfo.certno;
    },
    xzImg(img) {
      let e = this.$refs.toast;
      html2canvas(e, {
        allowTaint: true,
        useCORS: true, // 设置跨域
        tainttest: true, // 检测每张图片都已经加载完成
        backgroundColor: null, // 背景色, 转换图片可能会有白色底色，不要的话就null
      }).then((canvas) => {
        const link = document.createElement("a"); // 创建一个超链接对象实例
        link.download = "download.png"; // 设置要下载的图片的名称
        link.href = canvas.toDataURL(); // 将图片的URL设置到超链接的href中
        link.click(); // 触发超链接的点击事件
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.content_box {
  margin: 80px auto;
  width: 90%;
  max-width: 1400px;

  .zxBtn {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin-top: -30px;
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .cell_box {
      margin-top: 20px;

      > div {
        display: flex;
        align-items: center;
        margin: 20px;
        font-size: 17px;

        > div:first-child {
          width: 100px;
          /*高度需要添加，不然文字下面会多出一些空隙*/
          text-align: justify;
          height: 20px;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
          }
        }

        > div:last-child {
          background-color: #d8d8d8;
          width: 320px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding: 10px;
        }
      }
    }

    .zs_box {
      display: flex;
      margin-left: 30px;
      width: 300px;
      height: 100%;

      img {
        vertical-align: top;
        height: 100%;
        width: 100%;
      }
    }

    .xz_btn {
      text-align: center;
      margin-top: 10px;
      cursor: pointer;
    }

    .xz_btn:hover {
      color: #2d85e2;
    }
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .cell_box {
    margin-top: 20px;

    > div {
      margin: 20px;
      font-size: 14px !important;

      > div:first-child {
        width: 90px !important;
      }

      > div:last-child {
        background-color: #d8d8d8;
        width: 230px !important;
      }
    }
  }

  .zs_box {
    margin-left: 0 !important;
  }

  .zxBtn {
    margin-top: 0px !important;
  }
}
</style>
