<template>
	<div class="box5">
		<div class="common_box_tit">
			<div>培训学习</div>
			<div class="bom_line"></div>
		</div>
		<div class="pxxy">
			<div v-for="(it,i) in itemList" :key="i" @click="goUrl(i)">
				<div class="iconfont" :class="it.icon"></div>
				<div>{{it.name}}</div>
			</div>
		</div>
	</div>
</template>
<script>
	let that;
	export default {
		props: {
			isBlue: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				itemList:[
					{name:'全部资源',icon:'icon-ziyuan-xianxing'},
					{name:'视频资料',icon:'icon-shipintianchong'},
					{name:'课程ppt',icon:'icon-_kecheng'},
					{name:'相关问答',icon:'icon-datixitongguanli'}
				]
			}
		},
		mounted() {
			that = this;

		},
		methods: {
			goUrl(i){
				if(i==0){
					this.$router.push('subject')
				}
				if(i==1){
					this.$router.push({
						name:'video',
						query:{
							name:encodeURIComponent('视频文件')
						}
					})
				}
				if(i==2){
					this.$router.push({
						name:'ppt',
						query:{
							name:encodeURIComponent('课程ppt')
						}
					})
				}
				if(i==3){
					this.$router.push({
						name:'QandA',
						query:{
							name:encodeURIComponent('相关问答')
						}
					})
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.box5{
		.pxxy{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;
			>div{
				z-index: 999;
				width: 175px;
				height: 245px;
				border-radius: 8px;
				background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #1F2C45;
				box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				font-size: 15px;
				transition: all 0.22s ease-in;
				-webkit-transition: all 0.22s ease-in;
				-moz-transition: all 0.22s ease-in;
				-o-transition: all 0.22s ease-in;
				cursor: pointer;
				.iconfont{
					color: #4D6BCB;
					font-size: 45px;
				}
			}
			>div:hover{
				transform: scale(1.05);
				color: #fff;
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);
				.iconfont{
					color: #fff;
				}
			}
		}
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		
	}
</style>
