<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<!-- <div class="common_search_box">
				<Input placeholder="请输入相关关键词">
				</Input>
				<div class="btn_box">
					<div>搜索</div>
					<div>重置</div>
				</div>
			</div> -->
			<div class="pxxy">
				<div v-for="(it,i) in pptList" :key="i" @click="lookPpt(it)">
					<div class="iconfont icon-_kecheng"></div>
					<div>{{it.name}}</div>
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'

	import {
		getResourcese
	} from '@/api/home/guild'
	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				pptList: [],
			}
		},
		mounted() {
			this.getResourcesePPT();
			this.$refs["bannerChild"].locationList = ['教育培训', decodeURIComponent(this.$route.query.name)];
		},
		methods: {
			getResourcesePPT() {
				getResourcese({
					type: 6,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.pptList = res.result;
					}
				})
			},
			lookPpt(it){
				window.open('http://view.officeapps.live.com/op/view.aspx?src='+it.resource)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.common_search_box {
		display: flex;

		/deep/ .ivu-input {
			border-radius: 0 !important;
			height: 40px;
			width: 100%;
			margin-bottom: 10px;
		}

		.btn_box {
			display: flex;
			height: 100%;
			height: 40px;

			>div {
				padding: 0 20px;
				white-space: nowrap;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
			}

			>div:first-child {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				height: 100%;
			}

			>div:last-child {
				background-color: #767676;
			}

		}
	}

	.content_box {
		padding: 80px 100px;
		max-width: 1400px;
		min-width: 1100px;
		margin: auto;

		.box3 {
			margin-top: 20px;

			.swiper-container {
				position: relative;
				width: 100vm;

				.swiper-slide {
					width: 100%;
					color: #000;
				}

				img {
					object-fit: fill;
					height: 300px;
					width: 100%;
				}

				.swiper_text {
					padding: 0px 20px 40px;

					.title {
						font-size: 20px;
						margin: 10px 0;
					}
				}
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #8971F1;
			}

		}
	}
	.pxxy {
		display: flex;
		// justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 30px;
	
		>div {
			width: 175px;
			height: 245px;
			margin: 10px;
			border-radius: 8px;
			background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #1F2C45;
			box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-size: 14px;
			transition: all 0.22s ease-in;
			-webkit-transition: all 0.22s ease-in;
			-moz-transition: all 0.22s ease-in;
			-o-transition: all 0.22s ease-in;
			cursor: pointer;
	
			.iconfont {
				color: #4D6BCB;
				font-size: 45px;
			}
		}
	
		>div:hover {
			transform: scale(1.05);
			color: #fff;
			background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
			box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);
	
			.iconfont {
				color: #fff;
			}
		}
	}
</style>
