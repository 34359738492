
<script>
import { getClientSize, getScrollBarWidth } from '@/api/home/guild'


export default {
  name: 'bayWindow',
  props: {
    random: {
      type: Boolean,
      default: false
    },
    info:{
      type:Object
    }
  },
  data() {
    return {
      innerWidth: 0,
      innerHeight: 0,
      rectangle: [200, 100],
      clineX: 0,
      clineY: 0,
      removeAnimation :true,
      posX: 1,
      posY: 1,
      enableAnimation: true
    }
  },
  watch: {},
  computed: {
    rectangleStyle() {
      return {
        width: `${this.rectangle[0]}px`,
        height: `${this.rectangle[1]}px`,
        top: `${this.clineY}px`,
        left: `${this.clineX}px`,
        borderRadius:'10px'
      }
    },
  },
  mounted() {
    this.getClientSize()
    this.rectangleAnimation()
    if (this.random) {
      this.setRandom();
    }
    window.addEventListener('resize', () => {
      this.getClientSize()
    })
  },
  beforeDestroy() {
  },
  methods: {
    goTz(info){
      this.$parent.goTz(info)
    },
    getClientSize() {
      const bw = getScrollBarWidth();
      const { width, height } = getClientSize()
      this.innerWidth = width - this.rectangle[0] - bw;
      this.innerHeight = height - this.rectangle[1]
    },
    rectangleAnimation() {
      if (this.enableAnimation) {
        this.setRectanglePosition()
      }
      if(this.removeAnimation){
        requestAnimationFrame(this.rectangleAnimation)
      }
    },
    setRectanglePosition() {
      if (this.clineY >= this.innerHeight) {
        this.posY = -1;
      }
      if (this.clineY <= 0){
        this.posY = 1;
      }
      if (this.clineX >= this.innerWidth) {
        this.posX = -1;
      }
      if (this.clineX <= 0){
        this.posX = 1;
      }
      this.clineY += this.posY;
      this.clineX += this.posX;
    },
    eventAnimateStart() {
      this.enableAnimation = true;
    },
    eventAnimateStop() {
      this.enableAnimation = false;
    },
    removeStop(id){
      if(id === this.info.id){
        this.removeAnimation = false
      }

    },
    setRandom() {
      this.clineX = Math.random() * this.innerWidth;
      this.clineY = Math.random() * this.innerHeight;
    }
  }
}
</script>

<template>
  <div class="bay"  v-show="removeAnimation" :style="rectangleStyle" @mouseenter="eventAnimateStop" @mouseout="eventAnimateStart" @click="goTz(info)">
    <img :src="info.imgUrl" alt="">
<!--    <p>{{info.title}}</p>-->
    <i class="iconfont icon-guanbi" style="color: #d81e06;" @mouseenter="eventAnimateStop" @mouseout="eventAnimateStart" @click="removeStop(info.id)"/>
  </div>
</template>

<style scoped lang="scss">
.bay{
  position: fixed;
  z-index: 99999;
  background: #ffffff;
  border: 1px solid #ccc;

  img{
    width: 220px;
    height: 100px;
    border-radius: 10px
  }
  p{
    position: absolute;
    bottom: 0;
    left:0;
    height: 30px;
    line-height: 30px;
    background: #000;
    width: 100%;
    opacity: 0.5;
    box-sizing: border-box;
    color: #ffffff;
    padding:0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i{
    position: absolute;
    top: 4px;
    right:4px
  }

}
</style>