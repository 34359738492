<template>
	<div>
		<Modal v-model="showPop" width="1020" class="model_box" title="新增企业风采"  scrollable :mask-closable="false">
			<div class="pop_content" :style="{height:height}">
				<div class="form_content">
					<Form ref="uform" :model="formValidate" :rules="ruleValidate" :label-width="80">
							<div style="width: 70%; float:left;">
								<FormItem label="标题" prop="title">
									<Input v-model="formValidate.title" placeholder="请输入标题" :disabled="disabled"></Input>
								</FormItem>
								<FormItem label="链接">
									<Input v-model="formValidate.url" placeholder="请输入链接" :disabled="disabled"></Input>
								</FormItem>
							</div>

							<div style="width: 25%; float:right;">
								<FormItem label="展示图" prop="thumb">
									<div class="upload_img" @click="toUpload('thumb')">
										<div v-if="formValidate['thumb']" class="demo-upload-list">
											<template>
												<img :src="$previewImg+formValidate['thumb']">
												<div class="demo-upload-list-cover">
													<Icon type="ios-eye-outline" @click.native="handleView('thumb')"></Icon>
													<Icon type="ios-trash-outline" @click.native="handleRemove('thumb')"></Icon>
												</div>
											</template>
										</div>
										<Upload
											v-else
											ref="upload"
											:disabled="disabled"
											:show-upload-list="false"
											:on-success="handleSuccess"
											:format="imgOption.format" 
											:max-size="imgOption.maxSize" 
											:on-format-error="handleFormatError"
											:on-exceeded-size="handleMaxSize" 
											type="drag"
											:action="$imgCommonHost" :data="imgOption.data">
											<div class="img_box">+</div>
											</Upload>
									</div>
								</FormItem>
							</div>
							
						</Form>
						<div style="margin-left: 20px;">
							<Editor ref="editorRef" id="tinymce" v-model="editorValue" :init="editorInit" :disabled="disabled" key="editorKey" >
							</Editor>
						</div>

						<div class="div_btn" v-if="!disabled">
							<Button @click="submit" class="btn" type="primary" long>提交</Button>	
						</div>
				</div>

				<Modal title="" v-model="visible">
					<img :src="imgUrl" v-if="visible" style="width: 100%">
					<div slot="footer"></div>
				</Modal>

			</div>
		</Modal>
	</div>
</template>

<script>
	import tinymce from 'tinymce/tinymce'
	import Editor from '@tinymce/tinymce-vue'
	import "tinymce/themes/silver";
	import "tinymce/icons/default/icons";
	import "tinymce/plugins/advlist";
	import "tinymce/plugins/anchor";
	import "tinymce/plugins/autolink";
	import "tinymce/plugins/autosave";
	import "tinymce/plugins/code";
	import "tinymce/plugins/codesample";
	import "tinymce/plugins/directionality";
	import "tinymce/plugins/fullscreen";
	import "tinymce/plugins/hr";
	import "tinymce/plugins/insertdatetime";
	import "tinymce/plugins/link";
	import "tinymce/plugins/lists";
	import "tinymce/plugins/media";
	import "tinymce/plugins/nonbreaking";
	import "tinymce/plugins/noneditable";
	import "tinymce/plugins/pagebreak";
	import "tinymce/plugins/paste";
	import "tinymce/plugins/preview";
	import "tinymce/plugins/print";
	import "tinymce/plugins/save";
	import "tinymce/plugins/searchreplace";
	import "tinymce/plugins/spellchecker";
	import "tinymce/plugins/tabfocus";
	// import 'tinymce/plugins/table';
	import "tinymce/plugins/template";
	import "tinymce/plugins/textpattern";
	import "tinymce/plugins/visualblocks";
	import "tinymce/plugins/visualchars";
	import "tinymce/plugins/wordcount";
	import "tinymce/plugins/image";
	import "tinymce/plugins/table";
	import "tinymce/plugins/textcolor";
	import "tinymce/plugins/contextmenu";
	
	import axios from 'axios'
	import {
		getDetatilArticle
	} from '@/api/home/index'
	import {
		qyfcApply
	} from '@/api/other'
	export default {
		components: {
			Editor
		},
		data() {
			return {
				showPop: false,
				form: {},
				loading: false,
				id: '',
				newInfo: {},
				height: '',
				isLoading:false,
				formValidate: {
					title:'',
					thumb: '',
					url: ''
				},
				ruleValidate: {
					title: [{
						required: true,
						message: '标题不能为空',
						trigger: 'change'
					}],
					thumb: [{
						required: true,
						message: '请选择展示图',
						trigger: 'change'
					}]
				},
				userInfo: {},
				publicUploadUrl: this.$previewImg,
				imgUrl:'',
				visible:false,
				file_name:'',//当前文件名
				imgOption:{
					maxSize:2048,
					format:['jpg','jpeg','png'],
					data:{type:11}
				}, 
				disabled: false,
				editorValue: '',
				// toolbar: " undo redo  bold italic underline strikethrough formatselect fontselect fontsizeselect  align \
      			// 			indent numlist bullist  lineheight  forecolor backcolor hr blockquote removeformat codesample| \
     			// 			link image  table searchreplace  preview insertdatetime  bdmap charmap emoticons  code",
				 toolbar: 'undo redo |  formatselect | fontsizeselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table preview',
				 plugins: 'lists advlist image imagetools table autolink preview',
				 //富文本框init配置
				editorInit: {
					selector: '#tinymce',
					language_url: '/tinymce/langs/zh_CN.js', // 根据自己文件的位置，填写正确的路径，注意/可以直接访问到public文件
					language: 'zh_CN',
					skin_url: '/tinymce/skins/ui/oxide', // 根据自己文件的位置，填写正确的路径。路径不对会报错
					height: 480,
					width: 900,
					//plugins: 'advlist anchor autolink autosave code codesample colorpicker  contextmenu directionality  fullscreen hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount',
        			// toolbar:'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat | table',
        			plugins: "link lists advlist image code table wordcount media hr table searchreplace fullscreen preview charmap \
       						pagebreak insertdatetime  codesample emoticons", //插件
					toolbar: ['searchreplace bold italic underline strikethrough fontselect fontsizeselect  alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
					font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,\
						times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;\
						Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;\
						Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;\
						Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
					branding: false,
					menubar: true,
					// 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
					// 配置了此方法,即可手动选择图片
					images_upload_base_path: this.$previewImg,
					images_upload_handler: (blobInfo, success, failure) => {
						const formData = new FormData()
						formData.append('file', blobInfo.blob());
						//publicUpload(formData)
						let config = {
							headers: {
								"Content-Type": "multipart/form-data"
							}
						};
						//console.log("==========url=============", this.$imgCommonHost)
						axios.post(this.$imgCommonHost, formData, config)
						.then(res => {
							console.log("========2222========", res)
							if(res.status == 200 && res.data.code == 200) {
								success(this.$previewImg + res.data.result.file.filefullpath)
								this.$Notice.success({
									title: '文件上传成功',
									desc: '文件上传成功!'
								});
								//console.log("========3333========", this.$previewImg + res.data.result.file.filefullpath)
							} else {
								this.$Notice.warning({
									title: '文件上传失败',
									desc: '文件上传失败！'
								});
							}
						})
						.catch(err => {
							console.log(err)
						})
					}
				}
			}
		},
		mounted() {
			//this.height = (window.innerHeight - 100) + 'px';
			//tinymce.init(this.editorInit)
			if (this.$Cookies.get('xh_userInfo')) {
				this.userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
			}
		},
		watch: {
			id(val) {
				if (val) {
					this.getDetatilArticle(val)
				} else {
					this.formValidate.title = '';
					this.formValidate.thumb = '';
					this.formValidate.url = '';
					this.editorValue = ''
					this.disabled = false
				}
			}
		},
		methods: {
			getDetatilArticle(id) {
				this.isLoading = true
				getDetatilArticle({
					id: id,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let newInfo = res.result;
						console.log("========newInfo========", newInfo)
						this.newInfo = newInfo
						this.formValidate = newInfo
						this.editorValue = newInfo.content
						console.log("========formValidate========", this.formValidate)
					}
					this.isLoading = false
				})
			},
			goWb() {
				window.open(this.newInfo.weibo, '_blank')
			},
			submit() {
				this.$refs['uform'].validate((valid) => {
					if (valid) {
						if(this.editorValue == null || this.editorValue == '') {
							this.$Modal.warning({
									title: '提示',
									content: '请输入内容！'
								});
							return false;
						}
						let param = {...this.formValidate, v9NewsDataList: [{content: this.editorValue}] }
						//console.log("========param========", param)
						qyfcApply(param).then(res => {
							if (res.code == 200 || res.code == 0) {
								this.$Modal.success({
									title: '提示',
									content: '提交成功！',
									onOk: () => {
										this.showPop = false
										this.$parent.reflushTable()
									},
								});
							}else{
								this.$Modal.error({
									title: '提示',
									content: res.message,
									onOk: () => {},
								});
							}
						})
					}
				})
			},
			
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '超出文件大小限制',
					desc: '文件  ' + file.name + ' 太大, 不能超过 2M.'
				});
			},
			handleFormatError (file) {
				this.$Notice.warning({
					title: '文件格式不正确',
					desc: '文件的格式 ' + file.name + ' 不正确，请选择jpg或png.'
				});
			},
			handleSuccess (res, file) {
				this.formValidate[this.file_name] = this.$previewImg+res.result.file.filefullpath;
			},
			handleView (name) {
				this.imgUrl = this.$previewImg+this.formValidate[name];
				this.visible = true;
			},
			handleRemove (name) {
				this.formValidate[name] = ''
			},
			toUpload(name){
				this.file_name = name;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

	}

	/deep/ .ivu-btn-text {
		display: none;
	}


	.pop_content {
		font-size: 15px;
		padding: 20px 20px 0 20px;
		overflow-y: scroll;
		overflow-x: scroll;

		.title {
			font-size: 22px;
			text-align: center;
		}

		.ly {
			font-size: 13px;
		}

		.line_box {
			padding: 50px 0 5px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.time {
				font-size: 13px;
			}

			.share {
				display: flex;

				img {
					height: 25px;
					margin: 0 10px;
					cursor: pointer;
				}

				.qrcode {
					height: 100px;
				}
			}
		}

	}

	.btn {
		font-size: 18px;
		letter-spacing: 40px;
		padding-left: 60px;
		background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	}
	/deep/ .ivu-upload-drag{
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.demo-upload-list {
		display: inline-block;
		width: 60px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0,0,0,.2);
		margin-right: 4px;
	}
	.demo-upload-list img{
		width: 100%;
		height: 100%;
	}
	.demo-upload-list-cover{
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,.6);
	}
	.demo-upload-list:hover .demo-upload-list-cover{
		display: block;
	}
	.demo-upload-list-cover i{
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}
	/deep/.ivu-form .ivu-form-item-label{
		font-size: 14px;
		line-height: none;
	}
	/deep/.ivu-input{
		font-size: 14px;
	}

	.upload_img {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 100px;
		margin: 0 10px;
		background-color: #D8D8D8;
		padding: 20px 10px;
		border-radius: 5px;

		.img_box {
			font-size: 50px;
			color: #888888;
			.iconfont {
				font-size: 60px;
				cursor: pointer;
				color: #666666;
			}
		}
	}

	.form_content {
		margin: 30px 20px 10px 20px;
		// margin: 80px 200px;

		.div_btn {
			text-align: center;
			margin-top: 20px;
			.btn {
				width: 200px;
			}
		}

	}
</style>
