<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<!-- <div class="common_search_box">
				<Input placeholder="请输入相关关键词">
				</Input>
				<div class="btn_box">
					<div>搜索</div>
					<div>重置</div>
				</div>
			</div> -->
			<div class="xxwd" v-for="(it,i) in 10" :key="i">
				<div class="yuan"></div>
				<div class="single">
					我公司是按月申报的增值税小规模纳税人，2023年1月份预计销售货物取得收入20万元，请问能够享受什么2023年1月份预计销售货物取得收入20万元，请问能够享受什么2023年1月份预计销售货物取得收入20万元，请问能够享受什么
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'

	import {
		getResourcese
	} from '@/api/home/guild'
	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				pptList: [],
			}
		},
		mounted() {
			// this.getResourcesePPT();
			this.$refs["bannerChild"].locationList = ['教育培训', decodeURIComponent(this.$route.query.name)];
		},
		methods: {
			getResourcesePPT() {
				getResourcese({
					type: 7,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.pptList = res.result;
					}
				})
			},
			lookPdf(it){
				window.open(it.resource)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		padding: 80px 100px;
		max-width: 1400px;
		min-width: 1100px;
		margin: auto;

		.box3 {
			margin-top: 20px;

			.swiper-container {
				position: relative;
				width: 100vm;

				.swiper-slide {
					width: 100%;
					color: #000;
				}

				img {
					object-fit: fill;
					height: 300px;
					width: 100%;
				}

				.swiper_text {
					padding: 0px 20px 40px;

					.title {
						font-size: 20px;
						margin: 10px 0;
					}
				}
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #8971F1;
			}

		}
	}
	.xxwd {
		display: flex;
		align-items: center;
		margin-top: 30px;
		font-size: 14px;
	
		.yuan {
			width: 10px;
			height: 10px;
			border: 1px solid #333;
			border-radius: 50%;
			margin-right: 10px;
		}
	}
</style>
