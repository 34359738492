<template>
  <div class="box3">
    <div class="tab_box">
      <div v-for="(it,i) in tabList" class="common_box_tit a_tab" @click="getTab(i)">
        <div :style="{'font-size': current==i?'28px':'24px','color': current==i?'#3E41F0':'#333'}">{{ it.name }}</div>
        <div v-if="current==i" class="bom_line"></div>
      </div>
    </div>
    <div v-if="current==0">
      <swiper v-if="newList.length>0" class="swiper_box" ref="carouselSwiper" :options="swiperOptions">
        <swiper-slide v-for="(it,i) in newList" :key="i">
          <img :src="it.thumb">
          <div class="swiper_text">
            <div class="title">{{ it.title }}</div>
            <!-- <div v-if="it.description" class="description">{{it.description}}</div> -->
          </div>
        </swiper-slide>
      </swiper>
      <div @click="goList" class="common_more" style="float: right;margin-right: 40px;">
        <span>更多</span>
        <span class="iconfont icon-gengduo"></span>
      </div>
    </div>
    <div v-if="current==1">
      <rewardPunishment></rewardPunishment>
    </div>
  </div>
</template>
<script>
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {
  getArticleDes
} from '@/api/home/index'
import rewardPunishment from './reward_punishment.vue'

let vm;
export default {
  components: {
    swiper,
    swiperSlide,
    rewardPunishment
  },
  props: {
    isBlue: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      current: 0,
      tabList: [{
        name: '会员风采',
        catid: '6',
        url: 'news'
      },
        {
          name: '表彰信息/不良记录',
          catid: '91',
          url: 'news'
        },
      ],
      newList: [],
      newListMore: [],
      swiperOptions: {
        loop: true,
        speed: 1000,
        autoplay: false,
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        slidesPerView: 3, //设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: 0,
        centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左
        slideToClickedSlide: true,//true:点击slide会过渡到这个slide,默认false
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          800: {
            slidesPerView: 1.5,
          },
          600: {
            slidesPerView: 1.5,
          },
        },
        on: {
          click: function (e) {
            vm.handleDetailClick(this.realIndex)
          }
        }
      }
    }
  },
  mounted() {
    vm = this;
    this.getArticleDes();
  },
  methods: {
    getArticleDes() {
      getArticleDes({
        catid: 306,
        pageNo: 1,
        pageSize: 7
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          // this.newListMore = res.result.records;
          // let arr = res.result.records.slice(0, 3);
          // let more = res.result.records.slice(-3);
          // this.newList = arr;
          // this.newListMore = more;
          let arr = res.result.records;
          this.newList = arr;
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        name: 'news_detail',
        query: {
          id
        },
        params: {
          locationList: encodeURIComponent(JSON.stringify(['会员风采']))
        }
      });
    },
    goList() {
      this.$router.push({
        name: 'news',
        query: {
          catid: 306
        }
      });
    },
    getTab(e) {
      this.current = e

    },
    handleDetailClick(index) {
      let id = this.newList[index].id;
      this.$router.push({
        name: 'news_detail',
        query: {
          id
        },
        params: {
          locationList: encodeURIComponent(JSON.stringify(['会员风采']))
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.box3 {
  .tab_box {
    display: flex;
    align-items: center;

    .a_tab {
      margin-right: 30px;
      cursor: pointer;
    }
  }

  .swiper-container {
    position: relative;
    padding: 10px 0;

    img {
      object-fit: cover;
      height: 75%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      vertical-align: top;
      margin-bottom: 20px;
    }

    .swiper_text {
      padding: 0px 20px 40px;

      .title {
        font-size: 16px;
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
      }

      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -o-box-orient: vertical;
        font-size: 14px;
      }
    }
  }

  /deep/ .swiper-pagination-bullet-active {
    background-color: #8971F1;
  }

}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  transition: 300ms;
  transform: scale(0.8);
  border-radius: 10px;
  color: #000;
  height: 320px;
  box-shadow: 0 0 10px #999;
  cursor: pointer;
}

.swiper-slide:hover {
  color: #2D85E2;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .box3 {
    .swiper-slide {
      height: 260px !important;
    }

    img {
      height: 75% !important;
      margin-bottom: 10px !important;
    }
  }
}
</style>
