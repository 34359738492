<template>
	<div>
		<Modal v-model="showLogin" width="500" class="model_box" :mask-closable="false">
			<p slot="header" style="color:#2B4BFA;text-align:center;font-size: 16px;">
				<Icon type="information-circled"></Icon>
			</p>
			<div class="login_box">
				<div class="login_content">
					<div>
						<div class="tit_name">原密码</div>
						<Input class="input" v-model="form.ypwd" placeholder="请输入原密码" key="xm">
						</Input>
					</div>
					<div>
						<div class="tit_name">新密码</div>
						<Input class="input" v-model="form.pwd" type="password" autocomplete="new-password" placeholder="请输入新密码" key="xm">
						</Input>
					</div>
					<div>
						<div class="tit_name">确认密码</div>
						<Input class="input" v-model="form.rePwd" type="password" autocomplete="new-password"
							placeholder="请确认密码" key="mm">
						</Input>
					</div>
					<Button class="btn" type="primary" :loading="loading" long @click="submit">提交</Button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		changePwd,
	} from '@/api/login'
	export default {
		data() {
			return {
				showLogin:false,
				form: {
					ypwd:'',
					pwd:'',
					rePwd:''
				},
				loading: false,
			}
		},
		mounted() {
			
		},
		methods: {
			submit() {
				if (!this.form.ypwd) {
					this.$Message.info('请输入原密码');
					return
				}
				if (!this.form.pwd) {
					this.$Message.info('请输入新密码');
					return
				}
				if (!this.form.rePwd) {
					this.$Message.info('请确认密码');
					return
				}
				if (this.form.rePwd!=this.form.pwd) {
					this.$Message.info('两次输入密码不一致');
					return
				}
				this.loading = true;
				changePwd({
					ypwd:this.form.ypwd,
					pwd:this.form.pwd
				}).then(res => {
					this.loading = false;
					if (res.code == 200 || res.code == 0) {
						this.$Message.success('修改成功！');
						this.showLogin = false;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box{
		/deep/.ivu-modal-body{
			padding: 0;
		}
		/deep/.ivu-modal-footer, /deep/.ivu-modal-header{
			padding: 30px 50px;
			display: none;
		}
		
		.login_box{
			position: relative;
			.bg{
				width: 100%;
				object-fit: fill;
				vertical-align: top;
			}
			.login_content{
				// position: absolute;
				// top: 0;
				padding: 50px 50px;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;
				img{
					height: 50px;
					margin-bottom: -5px;
				}
				.tit_name{
					font-size: 16px;
					margin-bottom: 7px;
				}
			}
		}
		.input {
			width: 100%;
			margin-bottom: 22px;
					
			::v-deep .ivu-input-group-append {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				width: 100px;
				cursor: pointer;
				border: none;
				font-size: 14px;
			}
					
			::v-deep .ivu-input-group-prepend {
				background-color: #D0E8FF;
				width: 160px;
				font-size: 12px;
				text-align-last:justify;
				padding: 0 20px;
				color: #333;
				font-weight: bold;
			}
		}
		
		.check_box {
			/deep/ .ivu-checkbox-inner{
				margin-right: 5px;
			}
		}
		
		.flex_mm{
			margin-bottom: 20px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			.zhmm{
				cursor: pointer;
				font-size: 13px;
				display: flex;
				align-items: center;
				.iconfont{
					margin-right: 3px;
				}
			}
			.zhmm:hover{
				color: #2B4BFA;
			}
		}
		
		.btn {
			height: 40px;
			font-size: 16px;
			letter-spacing: 30px;
			padding-left: 40px;
			background: #2B4BFA;
			box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
			border: none;
		}
	}
	.xy_box {
		height: 400px;
		overflow: scroll;
		// transform: scale(0.9);
	}
	
	/deep/ .ivu-btn-text {
		display: none;
	}
	/deep/.ivu-input{
		height: 35px;
		font-size: 13px;
	}
</style>