import { render, staticRenderFns } from "./listSj.vue?vue&type=template&id=edfa2292&scoped=true"
import script from "./listSj.vue?vue&type=script&lang=js"
export * from "./listSj.vue?vue&type=script&lang=js"
import style0 from "./listSj.vue?vue&type=style&index=0&id=edfa2292&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edfa2292",
  null
  
)

export default component.exports