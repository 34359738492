<!-- 领导机构 -->
<template>
	<div class="box2">
		<!-- <div class="content" v-html="info.content"></div> -->
		<div>
			<div v-for="(it,i) in typeList" :key="i">
				<div class="title_text">{{it.name}}</div>
				<div class="card_box">
					<div v-for="(mm,nn) in infoList[it.type]" :key="nn">
						<img :src="mm.resource" />
						<div>{{mm.name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getSingleArticle,
		getResourcese
	} from '@/api/home/guild'
	export default {
		components: {

		},
		data() {
			return {
				info: {},
				typeList:[
					{name:'会长',type:1},
					{name:'副会长',type:2},
					{name:'监事长',type:3},
					{name:'监事',type:4},
					{name:'秘书长',type:5}
				],
				infoList:[],
			}

		},
		mounted() {
			this.getSingleArticle()
			this.getResourcese()
		},
		methods: {
			getSingleArticle() {
				getSingleArticle({
					catid: 65,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
			getResourcese(){
				getResourcese({
					type: 13,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						// this.info = res.result;
						const aa = res.result.reduce((m, o) => ({
							...m,
							[o.seq]: [...(m[o.seq] || []), o]
						}), {});
						let bb = res.result.map(v=>{
							return v.seq
						})
						this.infoList = aa;
						console.log(aa)
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box2 {
		color: #011243;
		font-size: 14px;
		// box-shadow: 0px 4px 10px 0px #164EB9;
		// min-width: 600px;
	
		.title {
			font-size: 25px;
			text-align: center;
			margin-bottom: 10px;
			margin-top: 20px;
		}
		.title_text{
			text-align: center;
			font-size: 18px;
			margin-top: 25px;
			margin-bottom: 20px;
			font-weight: bold;
		}
		.card_box{
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
			img{
				height: 80px;
			}
			>div{
				text-align: center;
				width: 20%;
				padding: 10px 5px;
				// white-space: nowrap;
			}
		}
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.card_box{
			>div{
				width: 33.33%!important;
			}
		}
	}
</style>
