<template>
	<div class="sj_box">
		<div class="right_box" v-if="infoList.length>0">
			<div v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
				<div style="width: 100%">
					<div class="title single">{{it.title}}</div>
					<div class="time">{{it.date}}</div>
					<div class="article">
						{{it.description}}
					</div>
				</div>
			</div>
			<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total"
				:current="params.pageNo" :page-size="params.pageSize" show-total></Page>
		</div>
		<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
	</div>
</template>

<script>
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		props: {
			params: {
				type: Object,
				default: () => ({
					pageNo: 1,
					pageSize: 20
				})
			},
			menu: {
				type: Array,
				default: []
			},
		},
		data() {
			return {
				infoList: [],
				total: 0,
				// params: {
				// 	pageNo: 1,
				// 	pageSize: 20
				// },
				catid: '',
				current:0,
				keywords:''
			}

		},
		watch: {
			catid(val) {
				let index = this.menu.findIndex(v=>v.catid==val);
				if(index!=-1){
					this.current=index;
				}
				this.getArticle();
			},
			keywords(val){
				this.getArticle();
			}
		},
		methods: {
			init(){
				this.getArticle();
			},
			/* ====================数据请求部分========================== */
			getArticle() {
				getArticle({
					catid: this.catid,
					keywords:this.keywords,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			/* ====================页面交互部分========================== */
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			getSjMenu(e){
				this.current=e;
				this.catid = this.menu[this.current].catid
				this.getArticle()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.sj_box{
		width: 100%;
		padding: 10px;
	}
	.right_box {
		>div {
			border-bottom: 1px solid #eee;
			cursor: pointer;
			transition: all 0.2s ease-in;
			-webkit-transition: all 0.2s ease-in;
			-moz-transition: all 0.2s ease-in;
			-o-transition: all 0.2s ease-in;
			display: flex;
			padding: 10px;

			.title {
				font-size: 15px;
				color: #000000;
				text-align: left;
			}

			.time {
				color: #575B66;
				font-size: 13px;
			}

			.article {
				color: #9E9E9E;
				font-size: 13px;
			}
		}

		>div:hover {
			// background-color: #eee;
			// padding: 15px 10px;
			box-shadow: 5px 5px 20px #9E9E9E;
		}
	}
</style>
