<template>
	<div class="container">
		<headerBox></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div class="box1">
				<!-- <div class="search_box">
					<div class="search_tom">
						<div>
							关键词：
							<Input v-model="params.keywords" @on-enter="getArticle" placeholder="请输入相关关键词"
								style="width: 200px"></Input>
						</div>
						<div class="btn_box">
							<img @click="toSearch" src="@/assets/images/engineering/btn1.png" />
							<img @click="toReset" src="@/assets/images/engineering/btn2.png" />
						</div>
					</div>
				</div> -->
				<div class="cell th">
					<div class="name single">姓名</div>
					<div class="idcard single">身份证号码</div>
					<div class="phone single">手机号码</div>
					<!-- <div class="zw single">职务</div> -->
				</div>
				<div class="td_box" v-if="infoList.length>0">
					<div class="cell td" v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
						<div class="name single">{{it.name}}</div>
						<div class="idcard single">{{it.idCard}}</div>
						<div class="phone single">{{it.phone}}</div>
						<!-- <div class="zw single">{{it.company}}</div> -->
					</div>
				</div>
				<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
				<!-- <Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total"
					:current="params.pageNo" :page-size="params.pageSize" show-total></Page> -->
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getVipList
	} from '@/api/home/index'
	import {
		handleIdCard,
		handlePhone
	} from '@/utils/util'
	export default {
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		props: {
			locationList: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				xh_userInfo:{},
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 200,
					keywords: '',
				},
				infoList: [],
			}
		},
		mounted() {
			this.$refs["bannerChild"].locationList = ['从业人员名单'];
			if (this.$Cookies.get('xh_userInfo')) {
				this.xh_userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
			}
			this.getArticle();
		},
		methods: {
			getArticle() {
				let param = {
					column: 'createTime',
					order: 'desc',
					company: this.xh_userInfo.user.dwmc,
					...this.params
				}
				getVipList(param).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						if(info.length>0){
							info.map(v=>{
								v.idCard = handleIdCard(v.idCard);
								v.phone = handlePhone(v.phone);
							})
						}
						this.infoList = info;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			toReset() {
				this.params.keywords='';
				this.params.pageNo = 1;
				this.getArticle()
			},
			toSearch() {
				this.getArticle();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 100px;
		min-width: 1100px;
		display: flex;
		justify-content: center;
	}
	.box1 {
		font-family: YouYuan;
		font-weight: bold;
		width: 80%;
	}

	.search_box {
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
		color: #0E2C68;
		font-size: 16px;

		>div {
			padding: 20px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.ivu-radio-wrapper {
			font-size: 16px;
			margin: 0;
		}

		/deep/.ivu-radio-inner {
			border-radius: 0;
		}

		/deep/.ivu-radio-checked {
			.ivu-radio-inner {
				background-color: #2D8CF0;
			}

			.ivu-radio-inner::after {
				display: none;
			}
		}

		.search_top {
			>div:first-child {
				width: 20%;
			}

			>div:nth-child(2) {
				width: 40%;
				text-align: right;
			}

			>div:last-child {
				width: 40%;
				text-align: right;
				padding-right: 20px;
			}

			/deep/.ivu-input {
				font-size: 15px;
				border: 1.5px solid;
				border-image: linear-gradient(to right, #02B3FF, #2C65FF) 4;
			}

			.ivu-date-picker {
				font-size: 13px;
				width: 220px !important;
			}

			.ivu-radio-group {
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: right;

			}
		}

		/deep/.ivu-input {
			font-size: 15px;
			border: 1.5px solid;
			border-image: linear-gradient(to right, #02B3FF, #2C65FF) 4;
		}

		.dq_box {
			border-top: 1px solid #E9E9E9;

			.qd_list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 90%;

				.qd_item {
					padding: 2px 10px;
					margin: 5px 0;
					border-radius: 5px;
					cursor: pointer;
					font-size: 15px;
				}

				.isNo:hover {
					color: #2D8CF0;
				}

				.isYes {
					background-color: #2D8CF0;
					color: #fff;
				}
			}
		}

		.search_tom {
			background-color: #E4F3FF;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			justify-content: space-between;

			.ivu-radio-group {
				width: 50%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.btn_box {
				text-align: right;
				margin-right: 20px;

				img {
					height: 28px;
					cursor: pointer;
					vertical-align: top;
				}

				img:last-child {
					margin-left: 40px;
				}
			}
		}
	}

	.td_box {
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
	}

	.cell {
		display: flex;
		font-size: 14px;
	}

	.th {
		font-weight: bold;
		margin-bottom: 15px;
		// margin-top: 50px;
		border: 1.5px solid #42BAFD;
		border-radius: 10px;
		text-align: center;
		color: #072563;
		padding: 15px 0;
		font-size: 16px;

		>div {
			border-right: 1.5px solid #42BAFD;
			padding: 5px 0;
		}

		>div:last-child {
			border: none;
		}
	}

	.td {
		cursor: pointer;
		font-size: 15px;
		text-align: center;
		height: 50px;
		line-height: 50px;
		border-bottom: 1.5px solid #42BAFD;
		font-family: SimHei;
		font-weight: normal;

		>div:nth-child(even) {
			background-color: #E9E9E9;
		}

		>div {
			border-right: 1.5px solid #42BAFD;
			padding: 0 5px;
		}

		>div:last-child {
			border: none;
		}
	}

	.td:last-child {
		border: none;
	}

	.td:hover {
		color: #072563;
	}

	.name {
		width: 33.33%;
	}

	.idcard {
		width: 33.33%;
	}

	.phone {
		width: 33.33%;
	}

	.zw {
		width: 20%;
	}
</style>
