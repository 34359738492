<template>
	<div class="box4">
		<div class="common_box_tit">
			<div>会员名录</div>
			<div class="bom_line"></div>
		</div>
		<div class="hyml">
			<div class="hyml_left">
				<div v-for="(it,i) in tabList" :key="i" @click="getTab(i,it.type)" :class="{'selected':current==i}">
					<span>{{it.name}}</span>
					<span class="iconfont icon-gengduo"></span>
				</div>
			</div>
			<div class="hyml_right" @mouseenter="swiper_enter" @mouseleave="swiper_leave">
				<!-- <div class="iconfont icon-xiangzuo" slot="button-prev"></div> -->
				<swiper v-if="vipList.length>=5&&showSwiper" ref="swiper" :options="options" class="swiper_box">
					<swiper-slide v-for="(item,index) in vipList" :key="index" class="item">
						<img v-if="item.resource" :src="item.resource" alt="" @click="goToVipPage(item.uuid)"/>
						<div class="single" @click="goToVipPage(item.uuid)">{{item.dwmc}}</div>
					</swiper-slide>
					<swiper-slide class="item itemKB">
						
					</swiper-slide>
				</swiper>
				<div v-if="vipList.length<5&&showSwiper" class="swiper_box">
					<div v-for="(item,index) in vipList" :key="index" class="item">
						<img v-if="item.resource" :src="item.resource" alt="" @click="goToVipPage(item.uuid)"/>
						<div class="single" @click="goToVipPage(item.uuid)">{{item.dwmc}}</div>
					</div>
				</div>
				<div @click="goMore" class="common_more" style="float: right;margin-right: 40px;">
					<span>查看全部</span>
					<span class="iconfont icon-gengduo"></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import {
		getVip
	} from '@/api/home/index'
	export default {
		components: {
			swiper,
			swiperSlide
		},
		props: {
			isBlue: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				vipList: [],
				current: 0,
				showSwiper: false,
				tabList: [{
						name: '会长单位',
						type: 2
					},
					// {
					// 	name: '副会长单位',
					// 	type:2
					// },
					{
						name: '监事单位',
						type: 3
					},
					{
						name: '常务理事单位',
						type: 4
					},
					{
						name: '理事单位',
						type: 6
					},
					{
						name: '会员单位',
						type: 7
					}
				],
				options: {
					pagination: {
						el: ".case-swiper-pagination"
						//区分不同Swiper的组件
					},
					//显示分页
					loop: false,
					//开启循环
					loopedSlides: 6,
					//设置平滑
					slidesPerView: 5,
					//设置能够同时显示的数量（可设置 auto）
					speed: 1000,
					//切换速度
					
					breakpoints: {
						800: {
							slidesPerView: 1,
						},
						600: {
							slidesPerView: 1,
						},
					},

					navigation: {
						nextEl: '.icon-xiangzuo',
						prevEl: '.icon-xiangyou',
					},
					//左右箭头按钮(可自定义)

					// autoplay: false,
					//是否开启自动轮播

					autoplay: {
						delay: 2000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 开启自动轮播后，设置自动轮播的延迟时间
					loopAdditionalSlides: 1,
					//复制若干个slide
					// slidesPerGroup: 3,
					// 定义slides的数量多少为一组，即每次切换slides的数量，默认每次切换一张
					on: {
						slideChangeTransitionEnd: function() {
							//获取当前下标，切换结束时，告诉我现在是第几个slide
						}
					}
				}
			}
		},
		computed: {
			// swiper() {
			// 	return this.$refs.swiper.$swiper;
			// }
		},
		mounted() {
			this.getVip();
		},
		methods: {
			getVip() {
				this.showSwiper = false;
				getVip({
					type: this.tabList[this.current].type,
					pageSize:20
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.vipList = res.result.records;
						this.showSwiper = true;
						// this.options.slidesPerView = this.vipList.length<=1?this.vipList.length:5;
						// console.log(this.options.slidesPerView)
					}
				})
			},
			swiper_enter() {
				// this.$refs.swiper.swiper.autoplay.stop()
			},
			//鼠标悬停停止swiper自动轮播
			swiper_leave() {
				// this.$refs.swiper.swiper.autoplay.start()
			},
			getTab(i, type) {
				this.current = i;
				if (i != 0 && i != 1) {
					this.$router.push({
						name: 'vip',
						query: {
							type: type,
						}
					});
				} else {
					this.getVip()
				}
			},
			goMore(){
				this.$router.push({
					name: 'vip',
					query: {
						type: this.tabList[this.current].type
					}
				});
			},
			goToVipPage(uuid) {
				this.$router.push({
					name: 'vipInfo',
					query: {
						id: uuid
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.box4 {
		padding-bottom: 70px;;
		.hyml {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 15px;

			.hyml_left {
				width: 150px;

				>div {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 8px 15px;
					font-size: 15px;
					margin: 5px 0;
					color: #164EB9;
					cursor: pointer;

					span {
						white-space: nowrap;
					}

					.icon-gengduo {
						margin-left: 15px;
					}
				}

				.selected {
					font-size: 15px;
					color: #fff;
					background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				}
			}

			.hyml_right {
				width: 80%;

				.single {
					cursor:pointer;
				}

				.iconfont {
					display: flex;
					align-items: center;
					font-size: 30px;
					color: #999;
					cursor: pointer;
				}

				.iconfont:hover {
					color: #164EB9;
				}

				.swiper_box {
					border-radius: 5px;
					box-shadow: 0 0 5px #eee;
					display: flex;
					margin-bottom: 15px;

					.item {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						font-size: 13px;
						padding: 0 10px;
						margin: 20px 0;
						text-align: center;
						img {
							width: 110px;
							height: 110px;
							object-fit: scale-down;
							// cursor: pointer;
						}

						div {
							margin-top: 10px;
						}
					}
				}
			}

		}
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.box4{
			.hyml_left{
				width: 40%!important;
			}
			.hyml_right{
				width: 55%!important;
				.itemKB{
					display: none!important;
				}
			}
		}
	}
</style>
