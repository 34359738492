<!-- 自律承诺书 -->
<template>
	<div class="item1_box">
		<div class="content" v-html="info.content"></div>
		<!-- <div class="fj_box">
			<div>相关附件:</div>
			<div>
				<div class="one_file">
					<div class="doc" @click="download2">1：{{fjInfo2.name}}.doc</div>
					<div class="iconfont icon-xiazai" @click="download2"></div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/home/party'
	import {
		getLinks
	} from '@/api/home/index'
	export default {
		components: {
			
		},
		data() {
			return {
				info:{},
				fjInfo2:{}
			}

		},
		mounted() {
			this.getSingleArticle();
			this.getLinksCr();
		},
		methods: {
			getSingleArticle(){
				getSingleArticle({
					catid: 219,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
			getLinksCr() {
				getLinks({
					type: 12
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.fjInfo2 = res.result;
					}
				})
			},
			download2() {
				window.open(this.fjInfo2.resource);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.item1_box {
		.title {
			font-size: 22px;
			text-align: center;
		}
		
		.time {
			font-size: 12px;
			padding: 50px 0 20px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			width: 100%;
		}
	}
	.fj_box{
		display: flex;
		align-items: flex-start;
		font-size: 18px;
		white-space: nowrap;
		.doc{
			cursor: pointer;
			color: #164EB9;
			margin: 0 20px;
		}
		.iconfont{
			cursor: pointer;
			color: #164EB9;
			font-size: 24px;
		}
		.one_file{
			display: flex;
			align-items: center;
			margin-top: -5px;
			margin-bottom: 20px;
		}
	}
</style>
