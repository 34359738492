<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div class="right_box">
				<div class="item pc_item">
					<img style="width: 26%;" src="http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.jpg" />
					<div style="width: 74%;">
						<div class="title single">新时代学习大讲堂《贵州省招标投标条例》解读</div>
						<div class="time">
							授课老师：<span style="color: #2179C8;">经志刚</span>
							<span style="margin: 0 10px;">|</span>
							<span>课程时长:1:53:02</span>
						</div>
						<div class="time">详细解读我省地方性法规《贵州省招标投标条例》。</div>
						<div style="display: flex;justify-content: flex-start;width: 80%;">
							<div class="btn" @click="goDetail(it)">学习</div>
						</div>
					</div>
				</div>
				<div class="sj_item">
					<div class="title">新时代学习大讲堂《贵州省招标投标条例》解读</div>
					<div>
						<img style="width: 26%;" src="http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.jpg" />
						<div style="width: 74%;">
							<div class="time">
								授课老师：<span style="color: #2179C8;">经志刚</span>
								<span style="margin: 0 10px;">|</span>
								<span>课程时长:1:53:02</span>
							</div>
							<div class="time">详细解读我省地方性法规《贵州省招标投标条例》。</div>
						</div>
					</div>
					<div style="display: flex;justify-content: center;width: 80%;">
						<div class="btn" @click="goDetail(it)">学习</div>
					</div>
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				infoList: [],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 6
				},
				catid: '',
			}

		},
		mounted() {
			this.getArticle();
			this.$refs["bannerChild"].locationList = ['教育培训', '公益讲座'];
		},
		methods: {
			getArticle(){
				getArticle({
					catid: 309,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail() {
				let url = 'video';
				this.$router.push({
					name: url,
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 200px;

		.right_box {
			.item {
				border-bottom: 1px solid #eee;
				padding: 10px;
				padding-bottom: 30px;
				margin-bottom: 30px;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
				box-shadow: 5px 5px 20px #9E9E9E;
				
				display: flex;
				align-items: center;
				width: 100%;
		
				img {
					width: 250px;
					height: 180px;
					margin-right: 50px;
				}
		
				.title {
					font-size: 20px;
					color: #333;
					text-align: left;
				}
				
				.btn{
					color: #fff;
					background-color: #2179C8;
					border-radius: 3px;
					margin-top:10px;
					width: 80px;
					padding: 5px 0;
					text-align: center;
					font-size:18px;
					cursor: pointer;
				}
		
				.time {
					color: #575B66;
					font-size: 15px;
					padding: 10px 0;
					display: flex;
					align-items: center;
				}
		
				.article {
					color: #9E9E9E;
					font-size: 13px;
				}
		
			}
		
			.item:hover {
				// box-shadow: 5px 5px 20px #9E9E9E;
			}
		}
	}
	
	// 大屏幕
	@media only screen and (min-width: 800px) {
		.sj_item{
			display: none!important;
		}
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.pc_item{
			display: none!important;
		}
		.content_box {
			.right_box {
				.sj_item {
					border-bottom: 1px solid #eee;
					padding: 10px;
					padding-bottom: 12px;
					margin-bottom: 30px;
					transition: all 0.2s ease-in;
					-webkit-transition: all 0.2s ease-in;
					-moz-transition: all 0.2s ease-in;
					box-shadow: 5px 5px 20px #9E9E9E;
					>div{
						display: flex;
						align-items: center;
					}
					img {
						width: 100%;
						height: 60px;
						margin-right: 15px;
					}
			
					.title {
						font-size: 18px!important;
						color: #333;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						display: block;
					}
					
					.btn{
						color: #fff;
						background-color: #2179C8;
						text-align: center;
						cursor: pointer;
						border-radius: 3px;
						margin-top:10px;
						width: 60px;
						padding: 5px 0;
						font-size:15px;
					}
			
					.time {
						font-size: 10px;
						padding: 3px 3px 3px 0;
					}
			
					.article {
						color: #9E9E9E;
						font-size: 13px;
					}
			
				}
			
				.item:hover {
					// box-shadow: 5px 5px 20px #9E9E9E;
				}
			}
		}
	
	}
</style>
