<template>
	<div style="width: 1200px;margin: auto;">
		<div class="gr_box">
			<div class="box2">
				<div class="flex_tit">
					<div class="title">在线申请个人会员</div>
					<!-- <div class="common_search_box">
						<Input v-model="keywords" clearable @on-enter="toSearch" placeholder="请输入验证码查询">
						</Input>
						<div class="btn_box">
							<div @click="toSearch">查询</div>
						</div>
					</div> -->
				</div>
				<Form ref="uform" :model="formValidate" :rules="ruleValidate" :label-width="150">
					<FormItem label="姓名" prop="name">
						<Input v-model="formValidate.name" placeholder="请输入姓名"></Input>
					</FormItem>
					<FormItem label="身份证号" prop="sfz">
						<Input v-model="formValidate.sfz" placeholder="请输入身份证号"></Input>
					</FormItem>
					<FormItem label="手机号码" prop="phone">
						<Input v-model="formValidate.phone" placeholder="请输入手机号码"></Input>
					</FormItem>
					<FormItem label="学历" prop="xl">
						<Input v-model="formValidate.xl" placeholder="请输入学历"></Input>
					</FormItem>
					<FormItem label="从事招标投标相关专业技术管理工作年限" prop="nx">
						<Input v-model="formValidate.nx" placeholder="请输入从事招标投标相关专业技术管理工作年限"></Input>
					</FormItem>
					<FormItem label="单位名称" prop="dwmc">
						<Input v-model="formValidate.dwmc" placeholder="请输入单位名称"></Input>
					</FormItem>
					<FormItem label="职称" prop="zc">
						<Input v-model="formValidate.zc" placeholder="请输入职称"></Input>
					</FormItem>
					<FormItem label="执业资格证" prop="zyzgzbh">
						<Input v-model="formValidate.zyzgzbh" placeholder="请输入执业资格证"></Input>
					</FormItem>
					<FormItem label="注册证书" prop="zczsbh">
						<Input v-model="formValidate.zczsbh" placeholder="请输入注册证书"></Input>
					</FormItem>
					<FormItem label="设置密码" prop="pwd">
						<Input v-model="formValidate.pwd" placeholder="请输入设置密码"></Input>
					</FormItem>
					<FormItem label="确认密码" prop="qdpwd">
						<Input v-model="formValidate.qdpwd" placeholder="请输入确认密码"></Input>
					</FormItem>
					<FormItem label="常用邮箱" prop="email">
						<Input v-model="formValidate.email" placeholder="请输入常用邮箱"></Input>
					</FormItem>
					<FormItem label="QQ号码" prop="qq">
						<Input v-model="formValidate.qq" placeholder="请输入QQ号码"></Input>
					</FormItem>
					<div class="all_img">
						<FormItem v-for="(it,i) in imgZD" :key='i' :prop="it.label" :label-width="0">
							<div class="upload_img" @click="toUpload(it.label)">
								<div v-if="formValidate[it.label]" class="demo-upload-list">
									<template>
										<img :src="$previewImg+formValidate[it.label]">
										<div class="demo-upload-list-cover">
											<Icon type="ios-eye-outline" @click.native="handleView(it.label)"></Icon>
											<Icon type="ios-trash-outline" @click.native="handleRemove(it.label)"></Icon>
										</div>
									</template>
								</div>
								<Upload
									v-else
									ref="upload"
									:show-upload-list="false"
									:on-success="handleSuccess"
									:format="imgOption.format" 
									:max-size="imgOption.maxSize" 
									:on-format-error="handleFormatError"
									:on-exceeded-size="handleMaxSize" 
									type="drag"
									:action="$imgHost" :data="imgOption.data">
									<div class="img_box">+</div>
									</Upload>
									<div class="name">{{it.name}}</div>
							</div>
						</FormItem>
					</div>
				</Form>

				<Button @click="submit" class="btn" type="primary" long>提交</Button>
			</div>
		</div>
		<Modal title="" v-model="visible">
			<img :src="imgUrl" v-if="visible" style="width: 100%">
			<div slot="footer"></div>
		</Modal>
	</div>
</template>

<script>
	import {
		grapplicationSubmit,
		grapplicationUpdate,
		getVipxx,
		infoQuery
	} from '@/api/other'
	export default {
		name: 'home',
		data() {
			const validatePhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else if (!/^1[34578]\d{9}$/.test(value)) {
					callback('手机号格式不正确');
				} else {
					callback();
				}   
			};
			const validateIdCard = (rule, value, callback) => {
				if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
					callback('身份证号格式不正确');
				} else {
					callback();
				}   
			};
			return {
				imgUrl:'',
				visible:false,
				file_name:'',//当前文件名
				imgOption:{
					maxSize:2048,
					format:['jpg','jpeg','png'],
					data:{type:11}
				},
				imgZD:[
					{
						name:'身份证正面',
						label:'sfzzmpath'
					},
					{
						name:'身份证反面',
						label:'sfzfmpath'
					},
					{
						name:'毕业证',
						label:'byzpath'
					},
					{
						name:'职称证',
						label:'zyzgzpath'
					},
					{
						name:'注册证',
						label:'zczpath'
					},
				],
				formValidate: {
					name:'',
					sfz:'',
					phone:'',
					xl:'',
					nx:'',
					dwmc:'',
					zc:'',
					zyzgzbh:'',
					zczsbh:'',
					pwd:'',
					qdpwd:'',
					email:'',
					qq:'',
					sfzzmpath:'',
					sfzfmpath:'',
					byzpath:'',
					zyzgzpath:'',
					zczpath:''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '姓名不能为空',
						trigger: 'change'
					}],
					sfz: [{
						required: true,
						message: '身份证号不能为空',
						trigger: 'change'
					},{
						validator:validateIdCard,
						trigger: 'change'
					}],
					phone: [{
						required: true,
						trigger: 'change',
						validator:validatePhone
					}],
					xl: [{
						required: true,
						message: '学历不能为空',
						trigger: 'change'
					}],
					nx: [{
						required: true,
						message: '从事招标投标相关专业技术管理工作年限不能为空',
						trigger: 'change'
					}],
					dwmc: [{
						required: true,
						message: '单位名称不能为空',
						trigger: 'change'
					}],
					zc: [{
						required: true,
						message: '职称不能为空',
						trigger: 'change'
					}],
					zyzgzbh: [{
						required: true,
						message: '执业资格证编号不能为空',
						trigger: 'change'
					}],
					zczsbh: [{
						required: true,
						message: '注册证书编号不能为空',
						trigger: 'change'
					}],
					pwd: [{
						required: true,
						message: '密码不能为空',
						trigger: 'change'
					}],
					qdpwd: [{
						required: true,
						message: '确认密码不能为空',
						trigger: 'change'
					}],
					email: [{
							required: true,
							message: '常用邮箱不能为空',
							trigger: 'change'
						},
						{
							type: 'email',
							message: '邮箱格式不正确！',
							trigger: ['change', 'blur']
						}
					],
					qq: [{
						required: true,
						message: 'QQ号码不能为空',
						trigger: 'change'
					}],
					sfzzmpath: [{
						required: true,
						message: '请上传身份证正面',
						trigger: 'change'
					}],
					sfzfmpath: [{
						required: true,
						message: '请上传身份证反面',
						trigger: 'change'
					}],
					byzpath: [{
						required: true,
						message: '请上传毕业证',
						trigger: 'change'
					}],
					zyzgzpath: [{
						required: true,
						message: '请上传职称证',
						trigger: 'change'
					}],
					zczpath: [{
						required: true,
						message: '请上传注册证',
						trigger: 'change'
					}],
				},
				typeName:'',
				keywords:'',
			}
		},
		mounted() {
			if (this.$Cookies.get('xh_userInfo')) {
				let userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				let userName = userInfo.user.ext1;
				this.getVipxx(userName)
			}
		},
		methods: {
			getVipxx(username) {
				getVipxx({
					username
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.vipvo;
						info.uuid = res.result.uuid;
						this.formValidate = info;
					}
				})
			},
			submit() {
				this.$refs['uform'].validate((valid) => {
					if (valid) {
						grapplicationUpdate({...this.formValidate,dxyzm:this.keywords}).then(res => {
							if (res.code == 200 || res.code == 0) {
								this.$Modal.success({
									title: '提示',
									content: '修改成功！',
									onOk: () => {
										this.$router.go(-1);
									},
								});
							}else{
								this.$Modal.error({
									title: '提示',
									content: res.message,
									onOk: () => {},
								});
							}
						})
					}
				})
			},
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '超出文件大小限制',
					desc: '文件  ' + file.name + ' 太大, 不能超过 2M.'
				});
			},
			handleFormatError (file) {
				this.$Notice.warning({
					title: '文件格式不正确',
					desc: '文件的格式 ' + file.name + ' 不正确，请选择jpg或png.'
				});
			},
			handleSuccess (res, file) {
				this.formValidate[this.file_name] = res.result.file.filefullpath;
			},
			handleView (name) {
				this.imgUrl = this.$previewImg+this.formValidate[name];
				this.visible = true;
			},
			handleRemove (name) {
				this.formValidate[name] = ''
			},
			toUpload(name){
				this.file_name = name;
			},
			toSearch(){
				if(this.keywords==''){
					this.$Message.warning('请输入验证码查询！');
					return
				}
				this.typeName = this.keywords
				infoQuery({
					type:5,
					dxyzm:this.keywords
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						if(res.result.code==0){
							this.formValidate = res.result.formGr;
						}else{
							this.$Message.error(res.result.message);
						}
					}else{
						this.$Message.error(res.message);
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.gr_box {
		margin: 10px 20px;
		// margin: 80px 200px;

		.box2 {
			color: #011243;
			padding: 50px;
			font-size: 14px;
			box-shadow: 0px 4px 10px 0px #164EB9;
			.flex_tit{
				position: relative;
				.common_search_box{
					position: absolute;
					top: 0;
					right: 0;
					/deep/.ivu-input{
						height: 40px;
					}
					.btn_box {
						height: 40px;
					}
				}
			}
			.title {
				font-size: 25px;
				text-align: center;
				margin-bottom: 50px;
			}

			.cell_box {
				font-size: 14px;

				>div {
					display: flex;
					align-items: center;
					white-space: nowrap;
					margin: 20px;

					>div:first-child {
						width: 190px;
						text-align-last: justify;
						margin-right: 10px;
					}
				}

				/deep/ .ivu-input {
					height: 40px;
				}
			}
		}

	}

	.all_img {
		display: flex;
		flex-wrap: wrap;
		padding: 0 80px;
		>div{
			// width: 33.33%;
			text-align: center;
			display: flex;
			justify-content: center;
		}
		
		.name{
			font-size: 13px;
			font-weight: bold;
			color: #515a6e;
			margin-top: 10px;
		}

		.upload_img {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			width: 180px;
			margin: 0 10px;
			background-color: #D8D8D8;
			padding: 20px 10px;
			border-radius: 5px;

			.img_box {
				font-size: 50px;
				color: #888888;
				.iconfont {
					font-size: 60px;
					cursor: pointer;
					color: #666666;
				}
			}
		}
	}

	.btn {
		height: 48px;
		font-size: 18px;
		letter-spacing: 60px;
		padding-left: 80px;
		margin-top: 50px;
		background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	}
	/deep/ .ivu-upload-drag{
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.demo-upload-list {
		display: inline-block;
		width: 60px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0,0,0,.2);
		margin-right: 4px;
	}
	.demo-upload-list img{
		width: 100%;
		height: 100%;
	}
	.demo-upload-list-cover{
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,.6);
	}
	.demo-upload-list:hover .demo-upload-list-cover{
		display: block;
	}
	.demo-upload-list-cover i{
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}
	/deep/.ivu-form .ivu-form-item-label{
		font-size: 14px;
		line-height: none;
	}
	/deep/.ivu-input{
		font-size: 14px;
	}
</style>
