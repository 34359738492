<template>
  <div class="basicInfo-view">
    <div class="basicInfo-center">
      <div class="basicInfo-item">
        <el-card style="height: 100%">
          <div slot="header" class="clearfix">
            <span>从业人员证书</span>
          </div>
          <div>
            <el-table
              v-loading="loading"
              :cell-style="{ padding: 0 }"
              :data="certificatesData"
              :header-cell-style="{
                lineHeight: '35px',
                height: '35px',
                padding: 0,
              }"
              height="300"
              style="width: 100%"
            >
              <el-table-column
                align="center"
                label="姓名"
                prop="certName"
                show-overflow-tooltip
                width="100px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="证书编号"
                prop="certNo"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="生效日期"
                prop="yxqq"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="失效日期"
                prop="yxqz"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                label="操作"
                width="100px"
              >
                <template #default="scope">
                  <el-button type="text" @click="certificatesClick(scope.row)"
                    >查看证书</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
      <div class="basicInfo-item">
        <el-card style="height: 100%">
          <div slot="header" class="clearfix">
            <span>项目负责人证书</span>
          </div>
          <div>
            <el-table
              v-loading="loading"
              :cell-style="{ padding: '8px' }"
              :data="certificateLeadersData"
              :header-cell-style="{
                lineHeight: '35px',
                height: '35px',
                padding: 0,
              }"
              height="300"
              style="width: calc(100%- 20px)"
            >
              <el-table-column
                align="center"
                label="姓名"
                prop="certName"
                show-overflow-tooltip
                width="100px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="证书编号"
                prop="certNo"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="生效日期"
                prop="yxqq"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                label="失效日期"
                prop="yxqz"
                show-overflow-tooltip
                width="150px"
              ></el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                label="操作"
                width="100px"
              >
                <template #default="scope">
                  <el-button
                    type="text"
                    @click="certificateLeadersClick(scope.row)"
                    >查看证书</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
    </div>
    <CertificatesDetails
      :details="certificatesDetails"
      @handleClose="detailsClose"
    ></CertificatesDetails>
    <CertificateLeadersDetails
      :details="certificateLeadersDetails"
      @handleClose="detailsClose"
    ></CertificateLeadersDetails>
  </div>
</template>

<script>
import CertificatesDetails from "./CertificatesDetails.vue";
import CertificateLeadersDetails from "./CertificateLeadersDetails.vue";
import { queryZsInfoByCyry } from "@/api/home/basicInfo";

export default {
  name: "basicInfo",
  components: {
    CertificatesDetails,
    CertificateLeadersDetails,
  },
  data() {
    return {
      cyry_user: {},
      certificatesDetails: {
        dialogVisible: false,
        title: "从业人员证书详情",
        data: {},
      },
      certificateLeadersDetails: {
        dialogVisible: false,
        title: "项目负责人证书详情",
        data: {},
      },
      certificatesData: [], // 从业人员
      certificateLeadersData: [], // 项目负责人
      loading: false,
    };
  },
  mounted() {
    if (this.$Cookies.get("cyry_user")) {
      this.cyry_user = JSON.parse(this.$Cookies.get("cyry_user"));
      this.getInfoByCyry();
    }
  },
  methods: {
    // 从业人员证书 详情
    certificatesClick(row) {
      this.certificatesDetails.dialogVisible = true;
      this.certificatesDetails.title = "从业人员证书详情";
      this.certificatesDetails.data = row;
      console.log("row", row);
    },
    // 项目负责人证书 详情
    certificateLeadersClick(row) {
      this.certificateLeadersDetails.dialogVisible = true;
      this.certificateLeadersDetails.title = "项目负责人证书详情";
      this.certificateLeadersDetails.data = row;
      console.log("row", row);
    },

    detailsClose() {
      this.certificatesDetails.dialogVisible = false;
      this.certificatesDetails.data = {};
      this.certificateLeadersDetails.dialogVisible = false;
      this.certificateLeadersDetails.data = {};
    },
    getInfoByCyry() {
      this.loading = true;
      let cyryUser = JSON.parse(this.$Cookies.get("cyry_user"));
      queryZsInfoByCyry({
        token: this.$Cookies.get("cyry_token"),
        uuid: cyryUser.studentId,
      }).then((res) => {
        if (res.code == 0) {
          this.certificatesData = res.result.certificates || [];
          this.certificateLeadersData = res.result.certificateLeaders || [];
        }
        this.loading = false;
        console.log("res", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.basicInfo-view {
  display: flex;
  flex-wrap: wrap;

  .basicInfo-head {
    width: 100%;
    margin-bottom: 10px;
  }

  .basicInfo-center {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .basicInfo-item {
      width: calc(50% - 5px);
      margin-bottom: 10px;
      height: 400px;
    }
  }
}
</style>
