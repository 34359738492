<template>
	<div class="search">
		<!-- <Tooltip :always="options1.length>0" trigger="hover"> -->
		<!-- <div slot="content" class="search_con" @scroll="scrollEvent">
				<div v-if="noData" class="noData">无匹配数据~</div>
				<div v-else v-for="(option, index) in options1" :key="index" @click="choose(option.id)" class="single">
					{{option.title}}
				</div>
			</div> -->
		<!-- <span slot="prepend" class="iconfont icon-sousuo"></span>
		<span>搜索</span> -->
		<Input v-model="queryText" @on-enter="searchBtn" placeholder="搜索" />
		<span slot="prepend" @click="searchBtn" class="iconfont icon-sousuo"></span>
		<!-- </Tooltip> -->
	</div>
</template>
<script>
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'Search',
		props: {
			isBlue: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				loading1: false,
				options1: [],
				list: [],
				queryText: '',
				noData: true,
				params: {
					pageNo: 1,
					pageSize: 20,
				}
			}
		},
		mounted() {
			
		},
		methods: {
			scrollEvent(e) {
				if (e.srcElement.scrollTop + e.srcElement.offsetHeight > e.srcElement.scrollHeight) {

				}
			},
			choose(e) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: e,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(['首页', '检索']))
					}
				});
				location.reload()
			},
			searchBtn() {
				this.$router.push({
					name: 'search',
					query: {
						queryText: this.queryText,
					},
				});
				location.reload()
			},
		}
	}
</script>
<style lang="scss" scoped>
	.search {
		background-color: rgba(0, 0, 0, .5);
		border-radius: 20px;
		width: 250px;
		display: flex;
		align-items: center;
		padding: 5px;
		cursor: pointer;

		.icon-sousuo {
			padding: 0 0 0 10px;
		}


		.icon-sousuo {
			margin-right: 10px;
			color: #fff;
		}

		input {
			outline-style: none
		}

		/deep/.ivu-input {
			padding: 0 15px;
			color: #fff;
			font-size: 14px;
			background-color: transparent;
			border: none;
		}

		/deep/.ivu-input:hover {
			border: none;
		}

		/deep/.ivu-input:focus {
			outline: none;
			border: none;
			box-shadow: 0 0 0 2px transparent;
		}

		/deep/ .ivu-select-input {
			padding: 0 15px 0 0;
			color: #fff;
			font-size: 14px;
		}

		/deep/ .ivu-select-selection {
			background-color: transparent !important;
			border-radius: 20px;
			border: none;
			outline-style: none;
			height: 30px;
		}
	}

	.search_con {
		color: #666;
		width: 250px;
		max-height: 300px;
		overflow: scroll;
		background-color: #fff;

		>div {
			padding: 10px;
			font-size: 14px;
			cursor: pointer;
		}

		>div:hover {
			background-color: #eee;
		}

	}

	::v-deep .ivu-tooltip-inner {
		background-color: #fff;
		color: #011243;
		background-color: transparent;
		box-shadow: none;
		padding-left: 0;
	}

	::v-deep .ivu-tooltip-arrow {
		border-bottom-color: transparent !important;
	}

	/deep/.ivu-input-group-prepend {
		background-color: transparent;
		border: none;
		padding: 0;
		padding-left: 10px;
	}
</style>
