import request from "@/utils/request";

// 关于协会、会员中心
export function getSingleArticle(params) {
  return request({
    url: "/jeecg-boot/index/singleArticle",
    method: "GET",
    params: params,
  });
}

// 基础信息 获取报名信息、考试记录、证书记录、培训记录
export function queryInfoByCyry(params) {
  return request({
    url: "/jeecg-boot/web/queryInfoByCyry",
    method: "GET",
    params: params,
  });
}
// 从业人员-个人中心-证书情况 从业人员证书、项目负责人证书
export function queryZsInfoByCyry(params) {
  return request({
    url: "/jeecg-boot/web/queryZsInfoByCyry",
    method: "GET",
    params: params,
  });
}

// 基础信息 从业人员 获取token 跳转到考试系统
export function toKsxt(params) {
  return request({
    url: "/jeecg-boot/web/toKsxt",
    method: "GET",
    params: params,
  });
}

export var getClientSize = function () {
  var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  var height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return { width: width, height: height };
};

let scrollBarWidth;
export var getScrollBarWidth = function () {
  if (scrollBarWidth !== undefined) return scrollBarWidth;
  var outer = document.createElement("div");
  outer.className = "el-scrollbar__wrap";
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.position = "absolute";
  outer.style.top = "-9999px";
  document.body.appendChild(outer);
  var widthNoScroll = outer.offsetWidth;
  outer.style.overflow = "scroll";
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);
  var widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  scrollBarWidth = widthNoScroll - widthWithScroll;
  return scrollBarWidth;
};
