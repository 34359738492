<template>
	<div class="box3">
		<div class="title">意见反馈</div>
		<div class="cell_box">
			<Form ref="uform" :model="formValidate" :rules="ruleValidate" :label-width="90">
				<FormItem label="姓名" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入姓名"></Input>
				</FormItem>
				<FormItem label="单位名称" prop="company">
					<Input v-model="formValidate.company" placeholder="请输入单位名称"></Input>
				</FormItem>
				<FormItem label="联系方式" prop="phone">
					<Input v-model="formValidate.phone" placeholder="请输入联系方式"></Input>
				</FormItem>
				<FormItem label="问题类型" prop="type">
					<Select v-model="formValidate.type">
						<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
					</Select>
				</FormItem>
				<FormItem v-if="formValidate.type==4" label="其他问题类型" prop="othertypedetail">
					<Input v-model="formValidate.othertypedetail" placeholder="请输入其他问题类型"></Input>
				</FormItem>
				<FormItem label="问题描述" prop="description">
					<Input v-model="formValidate.description" type="textarea" :rows="4" placeholder="请输入问题描述"></Input>
				</FormItem>
				<FormItem label="上传资料">
					<div class="upload_img">
						<div v-if="uploadList.length>0" class="demo-upload-list" v-for="(it,i) in uploadList" :key="i">
							<template>
								<img :src="$previewImg+imgArr[i]">
								<div class="demo-upload-list-cover">
									<Icon type="ios-eye-outline" @click.native="handleView(imgArr[i])"></Icon>
									<Icon type="ios-trash-outline" @click.native="handleRemove(it)"></Icon>
								</div>
							</template>
						</div>
						<div>
							<Upload ref="upload" multiple :show-upload-list="false" :on-success="handleSuccess"
								:format="imgOption.format" :max-size="imgOption.maxSize"
								:on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" type="drag"
								:action="$imgHost" :data="imgOption.data">
								<div class="img_box">+</div>
							</Upload>
						</div>
					</div>
				</FormItem>
			</Form>
			<Modal title="" v-model="visible">
				<img :src="imgName" v-if="visible" style="width: 100%">
				<div slot="footer"></div>
			</Modal>
			<Button @click="submit" class="btn" type="primary" long>提交</Button>
		</div>
	</div>
</template>

<script>
	import {
		feedbackSubmit
	} from '@/api/other'
	export default {
		data() {
			return {
				cityList: [{
						value: '1',
						label: '网站使用'
					},
					{
						value: '2',
						label: '协会发展'
					},
					{
						value: '3',
						label: '考试反馈'
					},
					{
						value: '4',
						label: '其他'
					},
				],
				model1: '',
				imgUrl: '',
				visible: false,
				uploadList: [],
				imgArr: [],
				imgName: '',
				file_name: '', //当前文件名
				imgOption: {
					maxSize: 2048,
					format: ['jpg', 'jpeg', 'png'],
					data: {
						type: 11
					}
				},
				formValidate: {
					name: '',
					company: '',
					phone: '',
					type: '',
					description: '',
					resources: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					company: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择问题类型',
						trigger: 'change'
					}],
					othertypedetail: [{
						required: true,
						message: '请输入其他问题类型',
						trigger: 'change'
					}],
					description: [{
						required: true,
						message: '请输入问题描述',
						trigger: 'blur'
					}],
					resources: [{
						required: true,
						message: '请上传资料',
						trigger: 'change'
					}],
				}
			}

		},
		mounted() {
			this.uploadList = this.$refs.upload.fileList;
		},
		methods: {
			submit() {
				if (this.uploadList.length > 0) {
					let arr = this.uploadList.map(v => {
						return v.response.result.file.filefullpath;
					})
					this.formValidate.resources = arr.join(',')
				}
				this.$refs['uform'].validate((valid) => {
					if (valid) {
						feedbackSubmit(this.formValidate).then(res => {
							if (res.code == 200 || res.code == 0) {
								this.$Modal.success({
									title: '提示',
									content: '提交成功！',
									onOk: () => {
										// this.$router.go(-1);
										this.formValidate = {
											name: '',
											company: '',
											phone: '',
											type: '',
											description: '',
											resources: ''
										}
									},
								});
							}else{
								this.$Modal.error({
									title: '提示',
									content: res.message,
									onOk: () => {},
								});
							}
						})
					}
				})
			},
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '超出文件大小限制',
					desc: '文件  ' + file.name + ' 太大, 不能超过 2M.'
				});
			},
			handleFormatError(file) {
				this.$Notice.warning({
					title: '文件格式不正确',
					desc: '文件的格式 ' + file.name + ' 不正确，请选择jpg或png.'
				});
			},
			handleSuccess(res, file) {
				let arr = this.uploadList.map(v => {
					return v.response.result.file.filefullpath;
				})
				this.imgArr = arr;
				// const fileList = this.$refs.upload.fileList;
				// console.log(111,fileList)
				// this.formValidate[this.file_name] = res.result.file.filefullpath;
			},
			handleView(name) {
				this.imgName = this.$previewImg + name;
				this.visible = true;
			},
			handleRemove(file) {
				const fileList = this.$refs.upload.fileList;
				this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box3 {
		color: #011243;
		padding: 50px;
		font-size: 14px;
		// box-shadow: 0px 4px 10px 0px #164EB9;

		.title {
			font-size: 25px;
			text-align: center;
			margin-bottom: 50px;
		}

		.cell_box {
			font-size: 14px;

			>div {
				display: flex;
				align-items: center;
				white-space: nowrap;
				margin: 20px;

				>div:first-child {
					width: 110px;
					text-align-last: justify;
					margin-right: 10px;
				}
			}

			.btn {
				height: 48px;
				font-size: 18px;
				letter-spacing: 60px;
				padding-left: 80px;
				margin-top: 50px;
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
			}
		}
	}

	.upload_img {
		display: flex;
		align-items: center;
		margin: 10px;

		>div {
			margin: 0 5px 5px 0;
		}

		.img_box {
			font-size: 50px;
			color: #888888;

			.iconfont {
				font-size: 60px;
				cursor: pointer;
				color: #666666;
			}
		}
	}

	/deep/ .ivu-upload-drag {
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.demo-upload-list {
		display: inline-block;
		width: 80px;
		height: 80px;
		text-align: center;
		line-height: 80px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
		margin-right: 4px;
	}

	.demo-upload-list img {
		width: 100%;
		height: 100%;
	}

	.demo-upload-list-cover {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .6);
	}

	.demo-upload-list:hover .demo-upload-list-cover {
		display: block;
	}

	.demo-upload-list-cover i {
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}

	/deep/.ivu-form .ivu-form-item-label {
		font-size: 14px;
	}

	/deep/.ivu-select-item {
		font-size: 14px !important
	}

	/deep/.ivu-input {
		font-size: 14px;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.box3 {
			padding: 20px 10px!important;
		}
	}
</style>
