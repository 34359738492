import request from '@/utils/request'
import Cookies from 'js-cookie'

// 菜单
export function getMenu(params) {
    return request({
        url: '/jeecg-boot/index/menu',
        method: 'GET',
        params: params
    })
}

// banner
export function getBanner(params) {
    return request({
        url: '/jeecg-boot/index/banner',
        method: 'GET',
        params: params
    })
}

// 获取首页信息
export function getInfo(params) {
    return request({
        url: '/jeecg-boot/index/info',
        method: 'GET',
        params: params
    })
}

// 首页信息新闻列表查询
export function getArticleDes(params) {
    return request({
        url: '/jeecg-boot/index/articleDes',
        method: 'GET',
        params: params
    })
}

// 文章类-分页列表查询（党建信息，行业要闻，工程交易）
export function getArticle(params) {
    return request({
        url: '/jeecg-boot/index/article',
        method: 'GET',
        params: {
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : '',
            ...params,
        }
    })
}

// 文章详情查询
export function getDetatilArticle(params) {
    return request({
        url: '/jeecg-boot/index/detatilArticle',
        method: 'GET',
        params: {
            ...params,
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : ''
        }
    })
}

// 首页会员单位
export function getVip(params) {
    return request({
        url: '/jeecg-boot/index/vip',
        method: 'GET',
        params: params
    })
}

// 首页会员单位
export function getVipInfo(params) {
    return request({
        url: '/jeecg-boot/index/vipInfo',
        method: 'GET',
        params: params
    })
}

// 首页外部链接
export function getLinks(params) {
    return request({
        url: '/jeecg-boot/index/links',
        method: 'GET',
        params: params
    })
}

// 首页飘窗
export function getPcList(params) {
    return request({
        url: '/jeecg-boot/index/getPcList',
        method: 'GET',
        params: params
    })
}

// 首页友情链接
export function getPartner(params) {
    return request({
        url: '/jeecg-boot/index/partner',
        method: 'GET',
        params: params
    })
}

// 首页政策法规关键词
export function getLawKw(params) {
    return request({
        url: '/jeecg-boot/index/lawKw',
        method: 'GET',
        params: params
    })
}

// 【从业人员】
export function getCyry(params) {
    return request({
        url: '/jeecg-boot/index/cyry',
        method: 'GET',
        params: {
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : '',
            ...params,
        }
    })
}


//中标地区获取
export function getZbdq(params) {
    return request({
        url: '/jeecg-boot/index/zbdq',
        method: 'GET',
        params: params
    })
}

// 会员名单
export function getVipList(params) {
    return request({
        url: '/jeecg-boot/management.student/tSocietyStudent/list',
        method: 'GET',
        params: params
    })
}

// 会员证书是否有效
export function checkVipPeriod(params) {
    return request({
        url: '/jeecg-boot/index/checkVipPeriod',
        method: 'GET',
        params: {
            ...params,
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : ''
        }
    })
}

// 会议
export function getMeeting(params) {
    return request({
        url: '/jeecg-boot/vip/meeting',
        method: 'GET',
        params: {
            ...params,
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : ''
        }
    })
}

// 会费
export function getJkxx(params) {
    return request({
        url: '/jeecg-boot/vip/jkxx',
        method: 'GET',
        params: {
            ...params,
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : ''
        }
    })
}

// 基本信息
export function queryVipInfo(params) {
    return request({
        url: '/jeecg-boot/vip/queryVipInfo',
        method: 'GET',
        params: {
            ...params,
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : ''
        }
    })
}

// 【从业人员】
export function getXmfzr(params) {
    return request({
        url: '/jeecg-boot/index/xmfzr',
        method: 'GET',
        params: {
            ...params,
        }
    })
}




