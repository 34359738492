<template>
	<div>
		<headerBox :current="8"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<left-tabs ref="tabsChild" :bgColor='1' @change="getMenu" :menu="menuList"></left-tabs>
			<div class="right_box">
				<item1 v-if="current==0"></item1>
				<item2 v-if="current==1" :locationList="locationList"></item2>
				<item3 v-if="current==2" :locationList="locationList"></item3>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import leftMenu from '@/components/left_menu.vue'
	import leftTabs from '@/components/left_tabs.vue'
	import item1 from './components/item1.vue'
	import item2 from './components/item2.vue'
	import item3 from './components/item3.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			leftMenu,
			leftTabs,
			item1,
			item2,
			item3
		},
		data() {
			return {
				locationList:[],
				current:0,
				menuList: [{
						title: '信用评价',
					},
					{
						title: '表彰信息',
					},
					{
						title: '曝光台',
					},
				]
			}

		},
		mounted() {
			let index = this.menuList.findIndex(v=>v.title==decodeURIComponent(this.$route.query.name))
			index = index==-1?0:index;
			this.setLocation(index);
		},
		methods: {
			getMenu() {
				this.setLocation();
			},
			setLocation(index){
				let current = index?index:this.$refs["tabsChild"].current;
				this.current = current;
				let locationList = ['会员中心',this.menuList[current].title];
				this.locationList = locationList;
				this.$refs["tabsChild"].current = current;
				this.$refs["bannerChild"].locationList=locationList;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 100px;
		display: flex;
		.right_box{
			width: 75%;
		}
	}
</style>
