<template>
	<div style="width: 1200px;margin: auto;">
		<!-- <div style="display: flex;justify-content: flex-end;width: 100%;">
			<div class="common_search_box">
				<Input v-model="kw" clearable @on-enter="toSearch" placeholder="请输入验证码查询">
				</Input>
				<div class="btn_box">
					<div @click="toSearch">查询</div>
				</div>
			</div>
		</div> -->
		<div class="table_box" :style="titStyle">
			<div class="cell1">
				<div class="cell_item">
					<div class="tit">单位名称</div>
					<div class="inp"><Input v-model="form.dwmc" placeholder="请输入单位名称"></Input></div>
				</div>
				<div class="cell_item">
					<div class="tit titX">统一社会信用代码</div>
					<div class="inp"><Input v-model="form.account" placeholder="请输入统一社会信用代码"></Input></div>
				</div>
			</div>
			<div class="cell2">
				<div class="tit"><span>单位</span><span>基本</span><span>情况</span></div>
				<!-- 大框 -->
				<div class="table_item">
					<!-- 一行 -->
					<div class="table_cell">
						<div class="td_item" style="width: 100%">
							<div class="td">企业注册地</div>
							<div class="inp"><Input v-model="form.zcdz" placeholder="请输入企业注册地"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item" :style="{width:width*2+'px'}">
							<div class="td">单位性质</div>
							<div>
								<RadioGroup class="radio_box" v-model="form.dwxz">
									<Radio v-for="(it,i) in radioList" :key="i" :label="it.label">
										<Icon type="social-apple"></Icon>
										<span>{{it.label}}</span>
									</Radio>
								</RadioGroup>
							</div>
						</div>
						<div class="td_item">
							<div class="td"><span>注册资金</span><span style="font-size: 14px;">(万元)</span></div>
							<div class="inp"><Input v-model="form.zczj" type="number" placeholder="请输入注册资金"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item">
							<div class="td">职工总人数</div>
							<div class="inp"><Input v-model="form.zgzrs" type="number" placeholder="请输入职工总人数"></Input></div>
						</div>
						<div class="td_item">
							<div class="td"><span>中级及以上</span><span>职称人数</span></div>
							<div class="inp"><Input v-model="form.zjyszcrs" type="number" placeholder="请输入人数"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">单位电话</div>
							<div class="inp"><Input v-model="form.dwdh" placeholder="请输入单位电话"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item">
							<div class="td">邮政编码</div>
							<div class="inp"><Input v-model="form.yb" placeholder="请输入邮政编码"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">企业邮箱</div>
							<div class="inp"><Input v-model="form.qyyx" placeholder="请输入企业邮箱"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">通讯地址</div>
							<div class="inp"><Input v-model="form.txdz" placeholder="请输入通讯地址"></Input></div>
						</div>
					</div>
				</div>
			</div>
			<div class="cell2">
				<div class="tit"><span>法定代</span><span>表人基</span><span>本情况</span></div>
				<!-- 大框 -->
				<div class="table_item">
					<!-- 一行 -->
					<div class="table_cell">
						<div class="td_item">
							<div class="td">姓名</div>
							<div class="inp"><Input v-model="form.frxm" placeholder="请输入姓名"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">性别</div>
							<RadioGroup class="radio_box" v-model="form.frxb">
								<Radio v-for="(it,i) in sexRadio" :key="i" :label="it.label">
									<Icon type="social-apple"></Icon>
									<span>{{it.label}}</span>
								</Radio>
							</RadioGroup>
						</div>
						<div class="td_item">
							<div class="td">出生年月</div>
							<div class="inp">
								<DatePicker :value="form.frcsny" @on-change="getLong" type="date" placeholder="请选择出生年月"
									format="yyyy-MM-dd" transfer :clearable='false'></DatePicker>
							</div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item" :style="{width:width*2+'px'}">
							<div class="td">身份证号</div>
							<div class="inp"><Input v-model="form.frsfzh" placeholder="请输入身份证号"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">政治面貌</div>
							<div class="inp"><Input v-model="form.frzzmm" placeholder="请输入政治面貌"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item">
							<div class="td"><span>其他社会</span><span>职务</span></div>
							<div class="inp"><Input v-model="form.frqtshzw" placeholder="请输入其他社会职务"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">职称</div>
							<div class="inp"><Input v-model="form.frzc" placeholder="请输入职称"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">手机号码</div>
							<div class="inp"><Input v-model="form.frsjhm" placeholder="请输入手机号码"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item">
							<div class="td">学历</div>
							<div class="inp"><Input v-model="form.frxl" placeholder="请输入学历"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">专业</div>
							<div class="inp"><Input v-model="form.frzy" placeholder="请输入专业"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">办公电话</div>
							<div class="inp"><Input v-model="form.frbgdh" placeholder="请输入办公电话"></Input></div>
						</div>
					</div>
				</div>
			</div>
			<div class="cell2">
				<div class="tit"><span>联系人</span></div>
				<!-- 大框 -->
				<div class="table_item">
					<!-- 一行 -->
					<div class="table_cell">
						<div class="td_item">
							<div class="td">姓名</div>
							<div class="inp"><Input v-model="form['kz.lxrName']" placeholder="请输入姓名"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">性别</div>
							<RadioGroup class="radio_box" v-model="form['kz.lxrSex']">
								<Radio v-for="(it,i) in sexRadio" :key="i" :label="it.label">
									<Icon type="social-apple"></Icon>
									<span>{{it.label}}</span>
								</Radio>
							</RadioGroup>
						</div>
						<div class="td_item">
							<div class="td">手机号码</div>
							<div class="inp"><Input v-model="form['kz.lxrSjhm']" placeholder="请输入手机号码"></Input></div>
						</div>
					</div>
					<div class="table_cell">
						<div class="td_item">
							<div class="td">职务</div>
							<div class="inp"><Input v-model="form['kz.lxrZw']" placeholder="请输入职务"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">办公电话</div>
							<div class="inp"><Input v-model="form['kz.lxrBgdh']" placeholder="请输入办公电话"></Input></div>
						</div>
						<div class="td_item">
							<div class="td">邮箱</div>
							<div class="inp"><Input v-model="form['kz.lxrYx']" placeholder="请输入邮箱"></Input></div>
						</div>
					</div>
				</div>
			</div>
			<div class="cell2">
				<div class="tit"><span>上传附件</span></div>
				<!-- 大框 -->
				<div class="table_item">
					<!-- 一行 -->
					<div class="img_box">
						<div v-for="(it,i) in imgZD" :key='i' @click="toUpload(it.label)">
							<div class="img">
								<div v-if="form[it.label]" class="demo-upload-list">
									<template>
										<img :src="$previewImg+form[it.label]">
										<div class="demo-upload-list-cover">
											<Icon type="ios-eye-outline" @click.native="handleView(it.label)"></Icon>
											<Icon type="ios-trash-outline" @click.native="handleRemove(it.label)"></Icon>
										</div>
									</template>
								</div>
								<Upload v-else ref="upload" :show-upload-list="false" :on-success="handleSuccess"
									:format="imgOption.format" :max-size="imgOption.maxSize"
									:on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" type="drag"
									:action="$imgHost" :data="imgOption.data">
									<div class="jia">+</div>
								</Upload>
							</div>
							<div>{{it.name}}</div>
						</div>
					</div>
				</div>
			</div>
			<Button @click="submit" class="btn" type="primary" long>提交</Button>
			<Modal title="" v-model="visible">
				<img :src="imgUrl" v-if="visible" style="width: 100%">
				<div slot="footer"></div>
			</Modal>
		</div>
	</div>
</template>

<script>
	import {
		applicationSubmit,
		applicationUpdateApply,
		getVipxx,
		infoQuery
	} from '@/api/other'
	import {
		yzPhone,
		yzAllCall,
		yzSfz,
		yzEmail
	} from '@/utils/util'
	export default {
		components: {},
		computed: {
			titStyle() {
				return {
					'--height': this.height + 'px', //输入框高度
					'--border': `${this.borderWidth}px solid #159FFF`, //边框
					'--gWidth': `${this.width}px` //边框
				}
			}
		},
		props: {
			// typeName: {
			// 	type: String,
			// 	default: ''//是否修改
			// },
		},
		data() {
			return {
				kw:'',
				height: '48',
				borderWidth: '1.4',
				width: 300,
				border: '',
				typeName:'',
				radioList: [{
						label: '事业',
						value: ''
					},
					{
						label: '国企',
						value: ''
					},
					{
						label: '民企',
						value: ''
					},
					{
						label: '其他',
						value: ''
					}
				],
				sexRadio: [{
						label: '男',
						value: ''
					},
					{
						label: '女',
						value: ''
					}
				],
				imgZD: [{
						name: '企业营业执照',
						label: 'yyzzpath'
					},
					{
						name: '法定代表人身份证正面',
						label: 'frsfzzmpath'
					},
					{
						name: '法定代表人身份证反面',
						label: 'frsfzfmpath'
					},
					{
						name: '自律承诺书',
						label: 'zlcnspath'
					},
				],
				imgOption: {
					maxSize: 2048,
					format: ['jpg', 'jpeg', 'png'],
					data: {
						type: 11
					}
				},
				form: {
					yyzzpath: '',
					frsfzzmpath: '',
					frsfzfmpath: '',
					zlcnspath: '',
					type: 1,
				},
				file_name: '', //当前文件名
				imgUrl: '',
				visible: false,
			}
		},
		mounted() {
			if (this.$Cookies.get('xh_userInfo')) {
				let userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				let userName = userInfo.user.ext1;
				this.getVipxx(userName)
			}
		},
		methods: {
			getVipxx(username) {
				getVipxx({
					username
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.vipvo;
						Object.keys(info.kz).map(v => {
							info[`kz.${v}`] = info.kz[v]
						})
						delete info.kz;
						info.uuid = res.result.applyuuid;
						console.log(1111111111,info)
						this.form = info;
					}
				})
			},
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '超出文件大小限制',
					desc: '文件  ' + file.name + ' 太大, 不能超过 2M.'
				});
			},
			handleFormatError(file) {
				this.$Notice.warning({
					title: '文件格式不正确',
					desc: '文件的格式 ' + file.name + ' 不正确，请选择jpg或png.'
				});
			},
			handleSuccess(res, file) {
				this.form[this.file_name] = res.result.file.filefullpath;
			},
			handleView(name) {
				this.imgUrl = this.$previewImg + this.form[name];
				this.visible = true;
			},
			handleRemove(name) {
				this.form[name] = ''
			},
			toUpload(name) {
				this.file_name = name;
			},
			getLong(e) {
				this.form.frcsny = e
			},
			submit() {
				if (!this.form.dwmc) {
					this.$Message.warning('请输入单位名称！');
					return
				}
				if (!this.form.account) {
					this.$Message.warning('请输入统一社会信用代码！');
					return
				}
				if (!this.form.zcdz) {
					this.$Message.warning('请输入企业注册地！');
					return
				}
				if (!this.form.dwxz) {
					this.$Message.warning('请选择单位性质！');
					return
				}
				if (!this.form.zczj) {
					this.$Message.warning('请输入注册资金！');
					return
				}
				if (!this.form.zgzrs) {
					this.$Message.warning('请输入职工总人数！');
					return
				}
				if (!this.form.zjyszcrs) {
					this.$Message.warning('请输入中级及以上职称人数！');
					return
				}
				if (!this.form.dwdh) {
					this.$Message.warning('请输入单位电话！');
					return
				}
				// else if (yzAllCall(this.form.dwdh)) {
				// 	this.$Message.warning('单位电话格式不正确！');
				// 	return
				// }
				
				if (!this.form.yb) {
					this.$Message.warning('请输入邮政编码！');
					return
				}
				if (!this.form.qyyx) {
					this.$Message.warning('请输入企业邮箱！');
					return
				}else if (yzEmail(this.form.qyyx)) {
					this.$Message.warning('企业邮箱格式不正确！');
					return
				}
				if (!this.form.txdz) {
					this.$Message.warning('请输入通讯地址！');
					return
				}
				if (!this.form.frxm) {
					this.$Message.warning('请输入法定代表人姓名！');
					return
				}
				if (!this.form.frxb) {
					this.$Message.warning('请选择法定代表人性别！');
					return
				}
				if (!this.form.frcsny) {
					this.$Message.warning('请选择法定代表人出生年月！');
					return
				}
				if (!this.form.frsfzh) {
					this.$Message.warning('请输入法定代表人身份证号！');
					return
				}else if (yzSfz(this.form.frsfzh)) {
					this.$Message.warning('法定代表人身份证号格式不正确！');
					return
				}
				
				if (!this.form.frzzmm) {
					this.$Message.warning('请输入法定代表人政治面貌！');
					return
				}
				if (!this.form.frqtshzw) {
					this.$Message.warning('请输入法定代表人其他社会职务！');
					return
				}
				if (!this.form.frzc) {
					this.$Message.warning('请输入法定代表人职称！');
					return
				}
				if (!this.form.frsjhm) {
					this.$Message.warning('请输入法定代表人手机号码！');
					return
				}else if (yzPhone(this.form.frsjhm)) {
					this.$Message.warning('法定代表人手机号码格式不正确！');
					return
				}
				
				if (!this.form.frxl) {
					this.$Message.warning('请输入法定代表人学历！');
					return
				}
				if (!this.form.frzy) {
					this.$Message.warning('请输入法定代表人专业！');
					return
				}

				if (!this.form.frbgdh) {
					this.$Message.warning('请输入法定代表人办公电话！');
					return
				} 
				// else if (yzAllCall(this.form.frbgdh)) {
				// 	this.$Message.warning('法定代表人办公电话格式不正确！');
				// 	return
				// }

				if (!this.form['kz.lxrName']) {
					this.$Message.warning('请输入联系人姓名！');
					return
				}
				if (!this.form['kz.lxrSex']) {
					this.$Message.warning('请选择联系人性别！');
					return
				}
				if (!this.form['kz.lxrSjhm']) {
					this.$Message.warning('请输入联系人手机号码！');
					return
				} else if (yzPhone(this.form['kz.lxrSjhm'])) {
					this.$Message.warning('联系人手机号码格式不正确！');
					return
				}

				if (!this.form['kz.lxrZw']) {
					this.$Message.warning('请输入联系人职务！');
					return
				}

				if (!this.form['kz.lxrBgdh']) {
					this.$Message.warning('请输入联系人办公电话！');
					return
				} 
				// else if (yzAllCall(this.form['kz.lxrBgdh'])) {
				// 	this.$Message.warning('联系人办公电话格式不正确！');
				// 	return
				// }

				if (!this.form['kz.lxrYx']) {
					this.$Message.warning('请输入联系人邮箱！');
					return
				} else if (yzEmail(this.form['kz.lxrYx'])) {
					this.$Message.warning('联系人邮箱格式不正确！');
					return
				}

				if (!this.form.yyzzpath) {
					this.$Message.warning('请上传企业营业执照！');
					return
				}
				if (!this.form.frsfzzmpath) {
					this.$Message.warning('请上传法定代表人身份证正面！');
					return
				}
				if (!this.form.frsfzfmpath) {
					this.$Message.warning('请上传法定代表人身份证反面！');
					return
				}
				if (!this.form.zlcnspath) {
					this.$Message.warning('请上传自律承诺书！');
					return
				}
				applicationUpdateApply({...this.form,dxyzm:this.kw}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.$Modal.success({
							title: '提示',
							content: '修改成功！',
							onOk: () => {
								this.$router.go(-1);
							},
						});
					}else{
						this.$Modal.error({
							title: '提示',
							content: res.message,
							onOk: () => {},
						});
					}
				})
			},
			toSearch(){
				if(this.kw==''){
					this.$Message.warning('请输入验证码查询！');
					return
				}
				// this.typeName = this.kw
				infoQuery({
					type:6,
					dxyzm:this.kw
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						if(res.result.code==0){
							let info = res.result.vipxx;
							info.kz = res.result.kz;
							Object.keys(info.kz).map(v => {
								info[`kz.${v}`] = info.kz[v]
							})
							delete info.kz;
							// info.uuid = res.result.applyuuid;
							this.form = info;
						}else{
							this.$Message.error(res.result.message);
						}
					}else{
						this.$Message.error(res.message);
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.inp {
		flex: 1;

		/deep/.ivu-input {
			height: var(--height);
			border-radius: 0;
			border: none;
			width: 100%;
		}

		/deep/.ivu-input:focus {
			box-shadow: 0 0 0 2px transparent;
		}
	}

	.table_box {
		font-size: 16px;
		letter-spacing: 5px;
		margin-top: 30px;

		>div {
			margin: 10px 0;
		}

		.cell1 {
			display: flex;
			border: var(--border);

			.cell_item {
				display: flex;
				align-items: center;
				width: 100%;

				>div {
					width: 50%;
					display: flex;
				}

				.tit {
					border-right: var(--border);
					background-color: #D2F3FF;
					padding: 0 30px;
					height: var(--height);
					display: flex;
					align-items: center;
					justify-content: center;
					white-space: nowrap;
					font-size: 16px;
				}

				.titX {
					border-left: var(--border);
				}

				.inp {
					width: 80%;
				}
			}
		}

		.cell2 {
			display: flex;

			.tit {
				flex: 1;
				border: var(--border);
				background-color: #D2F3FF;
				height: var(--height);
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin-right: 10px;
				width: 15%;
				height: auto;
				font-size: 16px;
			}

			.table_item {
				width: 85%;
				border: var(--border);
				border-bottom: none;

				.table_cell {
					display: flex;
					border-bottom: var(--border);

					.td_item {
						display: flex;
						align-items: center;
						width: var(--gWidth);

						.td {
							border-right: var(--border);
							border-left: var(--border);
							background-color: #F2F2F2;
							width: 140px;
							height: var(--height);
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							text-align: center;
							font-size: 16px;
						}

						.radio_box {
							padding: 0 20px;
							display: flex;
							justify-content: space-around;
							flex: 1;

							.ivu-radio-wrapper {
								font-size: 14px;
								margin-right: 15px;
								font-weight: normal;
							}

							.ivu-radio-wrapper:last-child {
								margin: 0;
							}

							/deep/.ivu-radio-inner {
								border-radius: 0;
							}

							/deep/.ivu-radio-checked {
								.ivu-radio-inner {
									background-color: #2D8CF0;
								}

								.ivu-radio-inner::after {
									display: none;
								}
							}
						}
					}

					.td_item:last-child {
						flex: 1;
					}

					.td_item:first-child {
						.td {
							border-left: none;
						}
					}
				}
			}

			.img_box {
				display: flex;
				justify-content: space-around;
				align-items: center;
				border-bottom: var(--border);

				>div {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: 20px;
				}

				.img {
					width: 100px;
					height: 100px;
					// border: var(--border);
					margin-bottom: 20px;

					.jia {
						font-size: 60px;
						font-weight: bold;
						color: #999;
					}
				}

				/deep/ .ivu-upload-drag {
					width: 100px;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.demo-upload-list {
					display: inline-block;
					width: 100px;
					height: 100px;
					text-align: center;
					line-height: 100px;
					border: 1px solid transparent;
					border-radius: 4px;
					overflow: hidden;
					background: #fff;
					position: relative;
					box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
					margin-right: 4px;
				}

				.demo-upload-list img {
					width: 100%;
					height: 100%;
				}

				.demo-upload-list-cover {
					display: none;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: rgba(0, 0, 0, .6);
				}

				.demo-upload-list:hover .demo-upload-list-cover {
					display: block;
				}

				.demo-upload-list-cover i {
					color: #fff;
					font-size: 20px;
					cursor: pointer;
					margin: 0 2px;
				}
			}
		}
	}

	.btn {
		font-size: 23px;
		letter-spacing: 60px;
		padding-left: 80px;
		margin-top: 50px;
		font-weight: bold;
		background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	}

	/deep/.ivu-input {
		color: #000;
		font-size: 16px;
	}

	/deep/.ivu-input-suffix {
		display: flex;
		align-items: center;
	}
	
	.common_search_box{
		width: 400px;
		margin-top: 20px;
		margin-bottom: -10px;
		/deep/.ivu-input{
			height: 40px;
		}
		.btn_box {
			height: 40px;
		}
	}
</style>
