<template>
	<div>
		<headerBox :current="999" ref="header_child"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div style="display: flex;justify-content: center;">
			<div class="content_box news_pc">
				<!-- <div class="common_search_box">
					<Input v-model="keywords" autofocus clearable @on-enter="tosearch" placeholder="请输入相关关键词">
					</Input>
					<div class="btn_box">
						<div @click="tosearch">搜索</div>
						<div @click="toReset">重置</div>
					</div>
				</div> -->
				<div style="width:100%">
					<listContent ref="listRef"></listContent>
				</div>
			</div>
		</div>
		<div class="news_sj">
			<div class="common_search_box">
				<Input v-model="keywords" autofocus clearable @on-enter="tosearch" placeholder="请输入相关关键词">
				</Input>
				<div class="btn_box">
					<div @click="tosearch">搜索</div>
					<div @click="toReset">重置</div>
				</div>
			</div>
			<searchSj ref="listRefSj" :menu="menuList"></searchSj>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import leftTabs from '@/components/left_tabs.vue'
	import listContent from './components/list.vue'
	import searchSj from './components/search_sj.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			leftTabs,
			listContent,
			searchSj
		},
		data() {
			return {
				locationList: [],
				infoList: [],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 20
				},
				catid: '',
				menuList: [],
				current: 0,
				keywords: '',
			}
		},
		mounted() {
			// this.setLocation();
			this.keywords = this.$route.query.queryText;
			console.log(66, this.keywords)
			this.$refs.header_child.search_value(this.keywords);
			this.tosearch();
			this.getArticle();
		},
		created() {
			window.addEventListener('popstate', this.popstate, false);
		},
		destroyed() {
			// this.$Cookies.remove('xh_queryText');
			// this.$destroy();
		},
		methods: {
			popstate() {
				location.reload()
			},
			/* ====================数据请求部分========================== */
			getArticle() {
				if(window.matchMedia("(max-width: 800px)").matches){
					   this.$refs['listRefSj'].init();
				}else {
					   this.$refs['listRef'].init();
				}
			},
			tosearch() {
				if(window.matchMedia("(max-width: 800px)").matches){
					   this.$refs['listRefSj'].keywords = this.keywords;
				}else {
					   this.$refs['listRef'].keywords = this.keywords;
				}
			},
			toReset() {
				this.keywords = '';
				if(window.matchMedia("(max-width: 800px)").matches){
					   this.$refs['listRefSj'].keywords = this.keywords;
				}else {
					   this.$refs['listRef'].keywords = this.keywords;
				}
			},
			/* ====================页面交互部分========================== */
			getMenu(e) {
				this.setLocation();
				this.getArticle()
			},
			getSjMenu(e) {
				this.setLocation(e);
				this.getArticle()
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			setLocation(index) {
				let current = index || index == 0 ? index : this.$refs["tabsChild"].current;
				this.current = current;
				let locationList = [];
				if (this.$route.query.catid == 306) {
					locationList = ['会员风采'];
				} else if (this.$route.query.catid == 305) {
					locationList = ['违法违规信息'];
				} else {
					locationList = ['行业动态', this.menuList[current].title];
				}
				this.locationList = locationList;
				this.$refs["tabsChild"].current = current;
				this.$refs["bannerChild"].locationList = locationList;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.common_search_box {
		margin-bottom: 20px;
	}

	.common_search_box /deep/.ivu-input {
		border-radius: 0 !important;
		height: 50px !important;
		line-height: 50px !important;
		width: 100% !important;
		margin-bottom: 10px;
		font-size: 14px !important;
		vertical-align: top;
	}

	.content_box {
		margin: 80px 100px;
		// display: flex;
		min-width: 1100px;
		max-width: 1400px;

		.right_box {
			width: 75%;

			>div {
				border-bottom: 1px solid #eee;
				padding: 10px;
				padding-bottom: 30px;
				margin-bottom: 30px;
				cursor: pointer;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
				-o-transition: all 0.2s ease-in;
				display: flex;

				img {
					width: 250px;
					height: 180px;
					margin-right: 30px;
				}

				.title {
					font-size: 20px;
					color: #000000;
					text-align: left;
				}

				.time {
					color: #575B66;
					font-size: 15px;
					padding: 10px 0;
				}

				.article {
					color: #9E9E9E;
					font-size: 13px;
				}
			}

			>div:hover {
				// background-color: #eee;
				// padding: 15px 10px;
				box-shadow: 5px 5px 20px #9E9E9E;
			}
		}
	}

	// 大屏幕
	@media only screen and (min-width: 800px) {
		.news_pc {
			// display: flex;
		}

		.news_sj {
			display: none;
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.news_pc {
			display: none !important;
		}

		.news_sj {
			padding-bottom: 100px;

			// display: flex!important;
			/deep/.van-tab {
				padding: 0 20px;
				font-size: 7;
			}

			/deep/.van-tabs__nav--line {
				padding-bottom: 10px;
			}

			.common_search_box {
				margin: 20px 20px 0;

				.btn_box {
					>div {
						padding: 0 20px;
					}
				}
			}
		}
	}
</style>
