<template>
	<div>
		<Modal v-model="showLogin" width="600" class="model_box" :mask-closable="false">
			<p slot="header" style="color:#2B4BFA;text-align:center;font-size: 16px;">
				<Icon type="information-circled"></Icon>
			</p>
			<div class="login_box">
				<img class="bg" src="../assets/images/login/gr_bg.png" />
				<div class="login_content">
					<div class="input_box">
						<!-- <Input class="input" v-model="form.loginName" placeholder="请输入姓名" key="xm">
						<span slot="prepend">姓名</span>
						</Input> -->
						<Input class="input" v-model="form.phone" placeholder="请输入手机号" key="sjh">
						<span slot="prepend">手机号</span>
						</Input>
						<Input class="input" v-model="form.phoneveriCode" placeholder="请输入验证码" key="yzm">
							<span slot="prepend">验证码</span>
							<!-- <img slot="append" :src="txYzm"/> -->
							<span class="yzm" slot="append" @click="getYzm"
								:style="{'pointer-events': isDisabled?'none':''}">{{sms_code}}</span>
						</Input>
						<Checkbox class="check_box" v-model="checked" @on-change="lookPop">我已阅读《隐私条款》
						</Checkbox>
						<Button class="btn" type="primary" :loading="loading" long @click="submit">登录</Button>
					</div>
				</div>
			</div>
		</Modal>
		<Modal v-model="showModal" title="隐私条款" :closable="false" :mask-closable="false" ok-text="我已阅读并同意" @on-ok="ok">
			<p class="xy_box" v-html="xyInfo.content"></p>
		</Modal>
	</div>
</template>

<script>
	import {
		getStuCode,
		getLogin,
		getHyLogin,
		// getRandomImage
	} from '@/api/login'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	import search from '@/components/search.vue'
	export default {
		name: 'vipLogin',
		components: {
			search
		},
		data() {
			return {
				showLogin: false,
				showModal: false,
				checked: false,
				form: {
					loginName:'',
					phone: '',
					phoneveriCode: ''
				},
				loading: false,
				glUrl: '',
				xyInfo: {
					content:''
				},
				sms_code: "获取验证码", //获取验证码按钮
				currentTime: 59,
				isDisabled: false,
				txYzm:''
			}
		},
		mounted() {
			
		},
		methods: {
			lookPop() {
				this.showModal = true;
				this.getSingleArticle(); //隐私条款
			},
			getSingleArticle() {
				getSingleArticle({
					catid: 210
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.xyInfo = res.result;
						this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi,
							`<span style="font-size:15px!important" `);
					}
				})
			},
			ok() {
				this.checked = true;
			},
			goRH(){
				this.$router.push('apply')
			},
			rePwd() {
				this.$Modal.info({
					title: '提示',
					content: '请联系协会重置密码，联系电话：0851-86587786',
					onOk: () => {

					},
				});
			},
			submit() {
				// if (this.form.loginName == '') {
				// 	this.$Message.info('姓名不能为空');
				// 	return
				// }
				if (this.form.phone == '') {
					this.$Message.info('手机号不能为空');
					return
				}
				if (this.form.phoneveriCode == '') {
					this.$Message.info('验证码不能为空');
					return
				}
				if (!this.checked) {
					this.$Message.info('请勾选同意考试须知');
					return
				}
				this.loading = true;
				getLogin({
					phone:this.form.phone,
					loginName:this.form.loginName,
					phoneveriCode:this.form.phoneveriCode
				}).then(res => {
					console.log(999999,res)
					this.loading = false;
					if (res.code == 200) {
						this.$Cookies.set('cyry_token', res.result.token);
						this.$Cookies.set('cyry_user', JSON.stringify(res.result));
						this.showLogin = false;
						location.reload()
					}else if(res.code == 201){
						this.$Modal.confirm({
							title: '温馨提示',
							content: '尊敬的用户，您还未注册成为从业人员，请您注册后使用相关功能！',
							okText:'前往注册',
							onOk: () => {
								let url =
									`${this.$ksHost}/#/exam-index?token=${res.result.token}`;
								window.open(url, '_blank')
								// let url =
								// 	`${this.$ksHost}/#/login?type=1&token=${res.result.token}`;
								// window.open(url, '_blank')
							},
						});
					}else if(res.code == 203){//审核未通过
						this.$Modal.confirm({
							title: '温馨提示',
							content: res.message,
							okText:'前往登录查看',
							onOk: () => {
								let url =
									`${this.$ksHost}/#/exam-home?token=${res.result.token}`;
								window.open(url, '_blank')
								// let url =
								// 	`${this.$ksHost}/#/login?type=1&token=${res.result.token}`;
								// window.open(url, '_blank')
							},
						});
					}
				})
			},
			getYzm() {
				// if (this.form.loginName == '') {
				// 	this.$Message.info('姓名不能为空');
				// 	return
				// }
				if (this.form.phone == '') {
					this.$Message.info('手机号不能为空');
					return
				}
				getStuCode({
					phone: this.form.phone
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.$Message.success('发送成功');
						this.countDown();
						this.isDisabled = true;
					}else{
						this.$Modal.error({
							title: '提示',
							content: res.message,
							onOk: () => {},
						});
						this.isDisabled = false;
					}
				})
			},
			//验证码倒计时
			countDown(options) {
				var that = this;
				var interval = setInterval(function() {
					that.currentTime--;
					if (that.currentTime <= 0) {
						clearInterval(interval)
						that.sms_code = '重新获取';
						that.currentTime = 59;
						that.isDisabled = false
					} else {
						that.sms_code = that.currentTime + 's'
					}
				}, 1000);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

		.login_box {
			position: relative;

			.bg {
				width: 100%;
				object-fit: fill;
				vertical-align: top;
				border-radius: 10px;
			}

			.login_content {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				padding-left: 40%;
				padding-right: 20px;
				display: flex;
				justify-content: center;
				flex-direction: column;

				img {
					height: 50px;
					margin-bottom: -5px;
				}
				
				.input_box {
					.input {
						width: 100%;
						margin-bottom: 25px;
							
						/deep/ .ivu-input-group-append {
							background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
							color: #fff;
							width: 100px;
							cursor: pointer;
							border: none;
							font-size: 15px;
						}
							
						/deep/ .ivu-input-group-prepend {
							background-color: #D0E8FF;
							width: 100px;
							font-size: 15px;
							text-align-last:justify;
							padding: 0 20px;
							color: #333;
						}
					}
							
					/deep/ .ivu-input-group .ivu-input,
					.ivu-input-group .ivu-input-inner-container {
						height: 40px;
						font-size: 14px;
					}
							
					.btn {
						height: 40px;
						font-size: 18px;
						letter-spacing: 30px;
						padding-left: 40px;
						background: #2B4BFA;
						box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
						border: none;
					}
				}
			}
		}

		.input {
			width: 100%;
			margin-bottom: 22px;

			::v-deep .ivu-input-group-append {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				width: 100px;
				cursor: pointer;
				border: none;
				font-size: 14px;
			}

			::v-deep .ivu-input-group-prepend {
				background-color: #D0E8FF;
				width: 160px;
				font-size: 12px;
				text-align-last: justify;
				padding: 0 20px;
				color: #333;
				font-weight: bold;
			}
		}

		.check_box {
			white-space: nowrap;
			/deep/ .ivu-checkbox-inner {
				margin-right: 5px;
			}
		}

		.flex_mm {
			margin-bottom: 20px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;

			.zhmm {
				cursor: pointer;
				font-size: 13px;
				display: flex;
				align-items: center;
				white-space: nowrap;

				.iconfont {
					margin-right: 3px;
				}
			}

			.zhmm:hover {
				color: #2B4BFA;
			}
		}
		.zcbtn{
			text-align: right;
			margin-top: 20px;
			font-size: 13px;
			span{
				color: #2B4BFA;
				cursor: pointer;
			}
		}

		.btn {
			height: 40px;
			font-size: 16px;
			letter-spacing: 30px;
			padding-left: 40px;
			background: #2B4BFA;
			box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
			border: none;
			margin-top: 30px;
		}
	}

	.xy_box {
		height: 400px;
		overflow: scroll;
		// transform: scale(0.9);
	}

	/deep/ .ivu-btn-text {
		display: none;
	}

	/deep/.ivu-input {
		height: 35px;
		font-size: 13px!important;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.login_content {
			padding-left: 38%!important;
			img {
				height: 40px !important;
				margin-bottom: -10px !important;
			}

			.input {
				width: 100%;
				margin-bottom: 15px!important;
			}
			/deep/.ivu-input {
				height: 30px!important;
				font-size: 13px!important;
			}
			/deep/ .ivu-input-group-prepend {
				font-size: 12px!important;
				padding: 0 5px!important;
				width: 60px!important;
			}
			/deep/ .ivu-input-group-append {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				width: 60px!important;
				cursor: pointer;
				border: none;
				font-size: 12px!important;
			}
			.btn {
				height: 30px!important;
				margin-top: 10px!important;
				font-size: 14px!important;
			}
		}
	}
</style>
