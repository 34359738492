<template>
  <!-- 飘窗效果 -->
  <div>
    <div id="thediv" ref="thediv" style="position: absolute; z-index: 111; left: 0; top: 0" v-show="thedivShow"
         @mouseover="clearFdAd" @mouseout="starFdAd" @click="goTz">
      <img class="fc" src="@/assets/images/fc3.png"/>
    </div>
    <Modal :fullscreen="fullscreen" v-model="showModal" width="60%" title="从业人员考试通知" :mask-closable="false"
           ok-text="已阅读"
           @on-ok="ok">
      <div slot="header" style="display: flex;align-items: center;justify-content: start">
        <p style="font-size: 18px">从业人员考试通知
          &nbsp;&nbsp;&nbsp;&nbsp;<Icon class="icon-zk" @click="zhCli" size="20" type="md-qr-scanner"/>
        </p>

      </div>
      <div>
        <iframe :style="styleObj" width="100%"
                src="http://36.138.114.21:8082/file_resources/public/20231012105000.pdf"></iframe>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getSingleArticle
} from '@/api/home/guild'
import {
  getLinks
} from '@/api/home/index'

var interval;
export default {
  data() {
    return {
      xPos: 800,
      yPos: 200,
      xin: true,
      yin: true,
      step: 2,
      delay: 18,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true,
      thedivShow: true,
      showModal: false,
      xyInfo: {},
      url: 'https://mp.weixin.qq.com/s/qzHhGiixxDUck7H1A9Vdpw',
      fullscreen: false,
      styleObj: {
        height: '400px'
      }
    };
  },

  mounted() {
    interval = setInterval(this.changePos, this.delay);
  },

  methods: {
    zhCli() {
      this.fullscreen = !this.fullscreen
      if (this.fullscreen) {
        this.styleObj.height = '900px'
      } else {
        this.styleObj.height = '400px'
      }
    },
    changePos() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (this.$refs.thediv) {
        this.Hoffset = this.$refs.thediv.offsetHeight; //获取元素高度
        this.Woffset = this.$refs.thediv.offsetWidth;
      }

      // 滚动部分跟随屏幕滚动
      // if (this.$refs.thediv) {
      // 	this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
      // 	this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";
      // }

      // 滚动部分不随屏幕滚动
      if (this.$refs.thediv) {
        this.$refs.thediv.style.left = this.xPos + document.body.scrollLeft + "px";
        this.$refs.thediv.style.top = this.yPos + 150 + "px";
      }

      if (this.yon) {
        this.yPos = this.yPos + this.step;
      } else {
        this.yPos = this.yPos - this.step;
      }
      if (this.yPos < 0) {
        this.yon = 1;
        this.yPos = 0;
      }
      if (this.yPos >= height - this.Hoffset) {
        this.yon = 0;
        this.yPos = height - this.Hoffset;
      }

      if (this.xon) {
        this.xPos = this.xPos + this.step;
      } else {
        this.xPos = this.xPos - this.step;
      }
      if (this.xPos < 0) {
        this.xon = 1;
        this.xPos = 0;
      }
      if (this.xPos >= width - this.Woffset) {
        this.xon = 0;
        this.xPos = width - this.Woffset;
      }
    },
    clearFdAd() {
      clearInterval(interval);
    },
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
    },
    goTz() {
      this.showModal = true;
      //this.getSingleArticle(); //隐私条款
      // this.getLinksKs(); //考试系统
    },
    getSingleArticle() {
      getSingleArticle({
        catid: 213
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.xyInfo = res.result;
          this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi,
              `<span style="font-size:15px!important" `);
        }
      })
    },
    ok() {
      // window.open(this.url, '_blank');
      // location.reload()
    },
    getLinksKs() {
      window.open(this.url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
#thediv {
  position: absolute;
  top: 43px;
  left: 2px;
  overflow: hidden;
}

.fc {
  z-index: 9999999;
  cursor: pointer;
  opacity: .9;
  height: 80px;
}

.xy_box {
  height: 400px;
  overflow: scroll;
  // transform: scale(0.9);
}

/deep/ .ivu-btn-text {
  display: none;
}

/deep/ .ivu-input {
  height: 35px;
  font-size: 13px;
}

/deep/ .ivu-modal-header-inner {
  font-size: 18px !important;
  text-align: center;
}

.icon-zk {
  cursor: pointer;
}

.icon-zk:hover {
  color: #1524fc;
}
</style>
