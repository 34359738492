<!-- 协会荣誉 -->
<template>
	<div class="recommendPage">
		<swiper v-if="infoList.length>0" :options="swiperOption" ref="mySwiper">
			<swiper-slide v-for="(it,i) in infoList" :key="i">
				<img :src="it.resource" />
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import {
		getResourcese
	} from '@/api/home/guild'
	export default {
		components: {
			swiper,
			swiperSlide,
		},
		data() {
			return {
				infoList:[],
				swiperOption: {
					loop: true,
					speed: 1000,
					autoHeight: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					},
					grabCursor: true,
					effect: 'coverflow',
					slidesPerView: 2,
					initialSlide: 1,
					centeredSlides: true,
					coverflowEffect: {
						rotate: 0, //30
						stretch: 30,
						depth: 80, //60
						modifier: 2, //2
						slideShadows: true
					},
					breakpoints: {
						800: {
							slidesPerView: 1.5,
						},
						600: {
							slidesPerView: 1.5,
						},
					}
				},
			}

		},
		mounted() {
			this.getResourcese()
		},
		methods: {
			getResourcese() {
				getResourcese({
					type: 4,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.recommendPage {
		padding: 40px 80px;
	
		.swiper-container {
			position: relative;
			width: 100vm;
			min-height: 220px;
	
			.swiper-slide {
				color: #000;
				font-size: 16px;
				text-align: center;
			}
	
			img {
				width: 100%;
				height: 100%;
				// object-fit: scale-down;
				// height: 299px;
				object-fit: cover;
			}
		}
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.recommendPage{
			padding: 40px 10px 0px!important;
			.swiper-container{
				width: 300px!important;
			}
		}
	}
</style>
