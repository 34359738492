<template>
	<div class="tz_box">
		
		<Tabs value="name1">
			<Tab-pane label="企业风采" name="name1">
				<div class="search_box">
					<div class="btn1" @click="toAdd">新增</div>
				</div>
				<div class="cell_box">
					<div class="cell th">
						<div v-for="(it,i) in th" :key="i">{{it}}</div>
					</div>
					<div v-if="ryList.length>0" class="cell td" v-for="(it,i) in ryList" :key="i">
						<div>{{params.pageSize*params.pageNo-params.pageSize+i+1}}</div>
						<div class="single">{{it.title}}</div>
						<!-- <div class="single">{{it.content}}</div> -->
						<div class="single">{{it.date}}</div>
						<div class="single"><a href="#" @click="showInfo(it)">{{ formatStatus(it) }}</a></div>
						<div class="single" style="cursor: pointer;" @click="goDetail(it)">查看</div>
					</div>
					<div v-if="ryList.length==0" class="common_excel_no_more">暂无内容~</div>
				</div>
				<Page v-if="ryList.length!=0" class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo" :page-size="params.pageSize"
					show-total></Page>
			</Tab-pane>
		</Tabs>
		<vipLogin ref="vipbox"></vipLogin>
		<detailModal ref="detailModal"></detailModal>

		<Modal v-model="showPop" width="420" class="model_box" >
			<p style="font-size: 16px;">{{showText}}</p>
		</Modal>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import vipLogin from '@/components/vip_login.vue'
	import detailModal from './detail.vue'
	import {
		getArticle
	} from '@/api/home/index'
	import {
		handleIdCard,
	} from '@/utils/util'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			vipLogin,
			detailModal
		},
		data() {
			return {
				th: ['序号', '标题', '创建日期', '状态', '详情'],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 10
				},
				form:{},
				ryList:[],
				userInfo: {},
				showPop: false,
				showText: ''
			}

		},
		mounted() {
			if (this.$Cookies.get('xh_userInfo')) {
				this.userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				console.log("===========userInfo=============", this.userInfo)
			}
			this.getArticle();
		},
		methods: {
			reflushTable() {
				this.getArticle()
			},

			showInfo(obj) {
				console.log("===========obj=============", obj)
				this.showText = this.formatStatus(obj)
				this.showPop =  true
			},
			formatStatus(obj) {
				//console.log("=======formatStatus========", obj, typeof(obj.status))
				if(obj.status == 1) return '审核通过'
				if(obj.status == 2) return '审核不通过' + (obj.otherinfo ? '|' + obj.otherinfo : '')
				return '待审核'
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle();
			},
			getArticle(){
				getArticle({...this.params,...this.form,catid: 306, typeid:this.userInfo.user.uuid}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						// if(info.length>0){
						// 	info.map(v=>{
						// 		v.sfz = handleIdCard(v.sfz);
						// 	})
						// }
						this.ryList = info;
						this.total = res.result.total;
					}else if(res.code == 401){
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			toAdd(){
				this.$refs['detailModal'].showPop = true;
				this.$refs['detailModal'].id = '';
			},
			toReset(){
				this.form = {};
				this.params.pageNo = 1;
				this.getArticle();
			},
			goDetail(it){
				this.$refs['detailModal'].showPop = true;
				this.$refs['detailModal'].id = it.id;
				//this.$refs['detailModal'].disabled = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .ivu-tabs-nav .ivu-tabs-tab {
		width: 200px;
		text-align: center;
		font-size: 18px;
	}

	.tz_box {
		margin: 10px 20px;
		// width: 100%;
		// max-width: 1000px;
		
		.search_box{
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			margin: 20px 0;
			>div{
				margin: 10px;
				white-space: nowrap;
				cursor: pointer;
			}
			.btn1{
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				padding: 5px 15px;
			}
			.btn2{
				background: #767676;
				color: #fff;
				padding: 5px 15px;
			}
		}

		.cell_box {
			margin-top: 20px;

			.cell {
				display: flex;
				width: 100%;
				white-space: nowrap;
				font-size: 15px;
				padding: 10px 0;
				text-align: center;
				border-left: 1px solid #eee;
				border-right: 1px solid #eee;

				>div:first-child {
					width: 10%;
				}
				
				>div:nth-child(2) {
					width: 40%;
				}
				
				>div:nth-child(3) {
					width: 20%;
				}
				>div:nth-child(4) {
					width: 15%;
				}
				>div:nth-child(5) {
					width: 15%;
				}
			}

			.th {
				background-color: #49B3FF;
			}
			.td{
				// cursor: pointer;
			}
			.td:hover{
				background-color: #eee;
			}
			.td:nth-child(odd){
				background-color: #D8D8D8;
			}
			.td:nth-child(even){
				border-bottom: 1px solid #eee;
			}
		}
	}
</style>
