import { render, staticRenderFns } from "./zx_pop.vue?vue&type=template&id=502143eb&scoped=true"
import script from "./zx_pop.vue?vue&type=script&lang=js"
export * from "./zx_pop.vue?vue&type=script&lang=js"
import style0 from "./zx_pop.vue?vue&type=style&index=0&id=502143eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502143eb",
  null
  
)

export default component.exports