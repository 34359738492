<template>
	<div>
		<headerBox :current="2"></headerBox>
		<bannerBox ref="bannerChild" :bgNum="1"></bannerBox>
		<div v-if="isPC" class="content_box party_pc">
			<left-tabs ref="tabsChild" :bgColor='2' @change="getMenu" :menu="menuList"></left-tabs>
			<div class="right_box">
				<item1 v-if="current==0"></item1>
				<item3 v-if="current==1" :locationList="locationList"></item3>
				<item2 v-if="current==2" :locationList="locationList"></item2>
				<item4 v-if="current==3" :locationList="locationList"></item4>
			</div>
		</div>
		<div v-if="!isPC" class="party_sj">
			<van-tabs v-model="current" :swipe-threshold="2" color="#2D85E2" @click="getSjMenu">
				<van-tab v-for="(it,i) in menuList" :key="i" :title="it.title">
					<div style="margin-top: 10px;">
						<item1 v-if="current==0"></item1>
						<item3 v-if="current==1" :locationList="locationList"></item3>
						<item2 v-if="current==2" :locationList="locationList"></item2>
						<item4 v-if="current==3" :locationList="locationList"></item4>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import leftMenu from '@/components/left_menu.vue'
	import leftTabs from '@/components/left_tabs.vue'
	import item1 from './components/item1.vue'
	import item2 from './components/item2.vue'
	import item3 from './components/item3.vue'
	import item4 from './components/item4.vue'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			leftMenu,
			leftTabs,
			item1,
			item2,
			item3,
			item4
		},
		data() {
			return {
				locationList: [],
				current: 0,
				menuList: [{
						title: '支部简介',
					},
					{
						title: '组织架构',
					},
					{
						title: '党建活动',
					},
					// {
					// 	title: '公益活动',
					// },
					{
						title: '党史学习教育',
					}
				],
				isPC:!window.matchMedia("(max-width: 800px)").matches
			}

		},
		mounted() {
			let index = this.menuList.findIndex(v => v.title == decodeURIComponent(this.$route.query.name))
			this.setLocation(index);
		},
		methods: {
			getMenu() {
				this.setLocation();
			},
			setLocation(index) {
				let current = index||index==0 ? index : this.$refs["tabsChild"].current;
				this.current = current;
				let locationList = ['党建专栏', this.menuList[current].title];
				this.locationList = locationList;
				this.$refs["tabsChild"].current = current;
				this.$refs["bannerChild"].locationList = locationList;
			},
			getSjMenu(e) {
				this.setLocation(e);
			}
		}
	}
</script>

<style lang="scss" scoped>
	// 大屏幕
	@media only screen and (min-width: 800px) {
		.content_box {
			margin: 80px 100px;
			min-width: 1100px;
			overflow: scroll;
			display: flex;

			.right_box {
				width: 75%;
			}
		}

		.party_pc {
			display: flex;
		}

		.party_sj {
			display: none;
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.party_pc {
			display: none !important;
		}

		.party_sj {
			padding: 10px;
			padding-bottom: 100px !important;
			display: flex !important;

			/deep/.van-tab {
				padding: 0 20px;
				font-size: 17px!important;
				width: auto !important;
			}

			/deep/.van-tabs__nav--line {
				padding-bottom: 10px;
			}
		}
	}
</style>
