<template>
	<div class="fixed_box" v-if="showModel&&subjectInfo.items.length>0">
		<div class="top_box">
			<div class="name">{{subjectInfo.items[subCurrent].title}}</div>
			<span class="iconfont icon-close" @click="toClose"></span>
		</div>
		<div>
			<div v-if="subjectInfo.items[subCurrent].attachType=='视频'" @contextmenu.prevent="menuPlayer()">
				<video-player class="video-player vjs-custom-skin" :playsinline="true" :options="playerOptions"
					@canplay="canplay($event)" @play="play($event)" @pause="pause($event)" @ended="ended"
					@timeupdate="timeupdate($event)" @loadedmetadata="loadEdmetadata($event)" @seeking="seeked"></video-player>
			</div>
			<div class="big_box"
				v-if="subjectInfo.items[subCurrent].attachType=='PPT'||subjectInfo.items[subCurrent].attachType=='WORD'||subjectInfo.items[subCurrent].attachType=='EXCEL'">
				<!-- <iframe ref="myIframe" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
					allowtransparency="yes"
					:src="`http://view.officeapps.live.com/op/view.aspx?src=${subjectInfo.items[subCurrent].attachFile}#toolbar=0`"
					style="width:100%;" :style="{height:boxHeight+'px'}">
				</iframe> -->
				<iframe marginwidth="0" marginheight="0" scrolling="yes" style="width:100%;"
					:style="{height:boxHeight+'px'}"
					:src="`http://www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=${subjectInfo.items[subCurrent].attachFile}#toolbar=0`">
				</iframe>

			</div>
			<div v-if="subjectInfo.items[subCurrent].attachType=='pdf'">
				<iframe :src="`${subjectInfo.items[subCurrent].attachFile}#toolbar=0`" style="width:100%;"
					:style="{height:boxHeight+'px'}">
				</iframe>
			</div>
			<div v-if="subjectInfo.items[subCurrent].attachType=='图片'">
				<img :src="`${subjectInfo.items[subCurrent].attachFile}`" style="width:100%;object-fit: contain"
					:style="{height:boxHeight+'px'}">
				</img>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'

  import {
    courseInfo,
    courseStudyRecord,
    queryCourseRecord, studyLock, studyUnLock,
  } from '@/api/home/guild'
	import {
		videoPlayer
	} from "vue-video-player";
	import 'video.js/dist/video-js.css'

	import {
		getNowDate,
		tosfm
	} from '@/utils/util'
	let timer;
	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
			videoPlayer,
		},
		props: {
			subCurrent: {
				type: Number,
				default: () => 0
			}
		},
		data() {
			return {
				spList: [],
				duration: 0, // 视频总长
				player: null,
				mediaUrl: null, // 播放视频的路径
				// 视频控制设置
				playerOptions: {
					playbackRates: [1.0], // 可选的播放速度
					autoplay: true, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: `${window.innerWidth}:${window.innerHeight-50}`, // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 类型
						src: "http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.mp4", // url地址
					}, ],
					poster: "http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.jpg", // 封面地址
					notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: true, // 是否显示剩余时间功能
						fullscreenToggle: true, // 是否显示全屏按钮
					},
					controlsList: 'nodownload',
				},
				subjectInfo: {
					items: []
				},
				ingId: '',
				timeTotal: 1,
				startTime: '',
				showModel: false,
				boxHeight: window.innerHeight - 50,
				isCanPlay:false,
				playObj:{},
				BoFangTime:0,
			}
		},
		mounted() {
			this.startTime = getNowDate();
      // console.log('页面初始化BoFangTime',this.BoFangTime)

/*      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL)
        window.addEventListener('popstate', this.back, false)
      }
      window.addEventListener('beforeunload', this.handleBeforeUnload)
      window.addEventListener('unload', this.handleBeforeUnload)*/
		},
/*    destroyed() {
      window.removeEventListener('popstate', this.back, false)
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
      window.removeEventListener('unload', this.handleBeforeUnload)
    },*/
		watch: {
			showModel(val) {
				if (val) {
					this.getResourceseSp();
				} else {
					// this.courseStudyRecordEnd();
				}
			},
			isCanPlay(val) {
				if(val){
					this.queryCourseRecord(this.playObj);
				}
			}
		},
		beforeDestroy() {
			//清除定时器
			this.clearTime();
		},
		methods: {
      // handleBeforeUnload(event) { // 监听浏览器关闭-刷新事件
      //   this.toClose()
      //   // 兼容火狐的做法
      //   event.preventDefault()
      //   event.returnValue = ''
      //   // 展示提示消息
      //   const message = '确定要离开吗？'
      //   event.returnValue = message
      //   return message
      // },
      back(){
        console.log('触发浏览器回退事件')
        this.toClose()
      },
			menuPlayer(){//禁止浏览器右键保存
				return false;
			},
			toClose() {
        console.log('结束学习，解锁！')
        studyUnLock({token: this.$Cookies.get('cyry_token')})
				this.courseStudyRecordEnd();
			},
			clearTime() {
				clearInterval(timer);
				timer = null;
				this.timeTotal = 1;
			},
			jishi() {
				this.clearTime();
				console.log('总时', this.timeTotal);
				timer = setInterval(() => {
					console.log('记时', this.timeTotal);
					this.timeTotal++;
					if (this.timeTotal % (60 * 1) == 0) {
						this.courseStudyRecordEnd(true);
					}
				}, 1000)
			},
			getResourceseSp() {
        console.log('开始学习，加锁！')
        studyLock({
          token: this.$Cookies.get('cyry_token')
        }).then(res => {
          if (res.code == 400){
            this.showModel = false
            return
          }
          if (res.code == 200 || res.code == 0) {
            courseInfo({
              id: this.$route.query.id,
              token: this.$Cookies.get('cyry_token')
            }).then(res => {
              if (res.code == 200 || res.code == 0) {
                let info = res.result;
                this.subjectInfo = info;
                info.items.map((v, i) => {
                  if (i == this.subCurrent) {
                    this.playerOptions['sources'][0]['src'] = v.attachFile;
                    this.playerOptions['poster'] = v.banner;
                    this.queryCourseRecord(v);
                    this.playObj = v;
                  }
                })
              }
            })
          }
        })
			},
			goVideo() {
				this.$router.push({
					name: 'video',
					query: {
						name: encodeURIComponent('视频文件')
					}
				})
			},
			courseStudyRecordEnd(isNoEnd) {
				let cyry_token = this.$Cookies.get('cyry_token');
				if (this.subjectInfo.charge == 0 && !cyry_token) {
					return
				}
				let it = this.subjectInfo.items[this.subCurrent];
				let spEnd = this.BoFangTime.toFixed(0) / (it.studyTimes * 60) > 0.6 ? 1 : 0;
				courseStudyRecord({
					courseId: it.courseId,
					itemId: it.id,
					studentId: JSON.parse(this.$Cookies.get('cyry_user')).studentId,
					studyTimes: it.attachType == '视频' ? this.BoFangTime.toFixed(0) : this.timeTotal,
					studyEnd: it.attachType == '视频' ? spEnd : 1,
					id: this.ingId,
					endTime: getNowDate(),
					startTime: this.startTime,
					token: this.$Cookies.get('cyry_token')
				}).then(res => {
					if(res.result.id){
						this.ingId = res.result.id;
					}
					if (!isNoEnd) {//关闭
						this.showModel = false;
						this.$emit('closeModal',res.result)
						this.clearTime();
					}else{
						// this.queryCourseRecord(this.playObj);
					}
					if (res.result.finish == 1 && it.examine == 1) {
						// this.goKS(1)
					}
				})
			},
			queryCourseRecord(it) {
				let cyry_token = this.$Cookies.get('cyry_token');
				if (this.subjectInfo.charge == 0 && !cyry_token) {
					return
				}

				queryCourseRecord({
					courseId: it.courseId,
					itemId: it.id,
					token: this.$Cookies.get('cyry_token')
				}).then(res => {
					if (res.result == null) {
						this.ingId = '';
						// 新课程学习无记录这里不做处理的话会沿用上一门点击课程的播放时间导致学习进度异常
            this.BoFangTime = 0
						// this.timeTotal = 0;
					} else {
						if(res.result.id){
							this.ingId = res.result.id;
						}
						if (it.attachType == '视频') {
							this.BoFangTime = res.result.studyTimes;
							this.continuePlay(res.result.studyTimes);
						}else{
							this.jishi();
						}
					}
				})
			},
			// 续播
			continuePlay(time) {
				let lastTime = time;
				if (lastTime > 0) {
					console.log(111111111, tosfm(time))
					this.$Modal.confirm({
						title: '提示',
						content: `上次观看到${tosfm(time)[0]=='00'?'':tosfm(time)[0]+'时'}${tosfm(time)[1]=='00'?'':tosfm(time)[1]+'分'}${tosfm(time)[2]=='00'?'':tosfm(time)[2]+'秒'},是否继续播放?`,
						onOk: () => {
							// 自动跳转到对应的位置并播放
							// this.timeTotal = time;
							this.player.currentTime(lastTime); //秒
							this.player.play();
						},
						onCancel() {
							// this.timeTotal = 0;
							this.player.currentTime(0);
							this.player.play();
						}
					});
				} else {
					this.player.currentTime(0);
					this.player.play();
				}
			},
			goKS(studyFinish) {
				if(this.subjectInfo.examine==0){//不考试
					return
				}
				let it = this.subjectInfo.items[this.subCurrent];
				if (studyFinish == 1) {
					this.$Modal.confirm({
						title: '温馨提示',
						okText: '前往考试',
						content: `温馨提示：您已完成“${this.subjectInfo.name}”课程的学习，可参加资格考试。`,
						onOk: () => {
							let url =
								`${this.$ksHost}/#/subject?type=1&courseId=${it.id}&studentId=${JSON.parse(this.$Cookies.get('cyry_user')).studentId}&token=${JSON.parse(this.$Cookies.get('cyry_user')).token}`;
							window.open(url, '_blank')
						},
					});
				} else {
					this.$Modal.confirm({
						title: '提示',
						content: '还未学习完成，请学习完成后参加考试！',
						onOk: () => {

						},
					});
				}
			},
			loop(currentTime) { // 视频轮询以计算播放时间
				var current = currentTime;
				//可以在这里加入请求，时刻向后端请求，通知服务器，目前视频播放的时长，以便下次播放视频，直接跳转
				this.last = current;
			},
			//默认加载之后，就会执行该函数
			canplay(player) {
				this.player = player;
				this.duration = player.duration();
				this.isCanPlay=true;
			},
			//点击视频播放
			play(player) {
				this.jishi()
				clearInterval(this.timeid);
				this.interval(this.player.duration);
			},
			//点击暂停时触发
			pause() {
				this.clearTime();
				clearInterval(this.timeid);
				this.loop(this.player.duration);
			},
			ended() {
				clearInterval(this.timeid);
			},
			timeupdate(player) { // 元素的currentTime发生变化时触发
				this.curr = player.currentTime;
				if (this.$Cookies.get('cyry_token')) {
					if (player.cache_.currentTime > this.BoFangTime + 1) {
						// 自动跳转到对应的位置并播放
						this.player.currentTime(this.BoFangTime); //秒
						this.player.play();
						return
					}
					// 保存最长进度位置
					if(player.cache_.currentTime>this.BoFangTime){
						this.BoFangTime = player.cache_.currentTime;
					}
				}
				// 限制快进
				if (this.curr - this.oldTime > 2) {
					this.player.currentTime(this.oldTime);
				} else {
					this.oldTime = this.curr;
				}
			},
			loadEdmetadata(e){
				console.log(888,e.target.duration)
			},
			seeked() { // 跳跃操作完成时触发
				clearInterval(this.timeid)
			},
			interval(currentTime) {
				this.timeid = setInterval(() => {
					this.loop(currentTime);
				}, 10000);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.fixed_box {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: #fff;

		.top_box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 30px;
			height: 50px;
			
			.name {
				font-size: 20px;
				color: #2d8cf0;
				font-weight: bold;
			}
			
			.icon-close {
				font-size: 30px;
				cursor: pointer;
			}
		}

	}



	/deep/ .video-js .vjs-tech {
		// object-fit: fill;
	}

	/deep/ .vjs-poster {
		background-size:100% 100%;

	}
	/deep/ .vjs-mouse-display .vjs-time-tooltip,/deep/ .video-js .vjs-slider{
		white-space: nowrap!important;
	}
</style>