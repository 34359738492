<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div class="layout">
				<div class="layout-content">
					<Row>
						<i-col span="5">
							<Menu :active-name="subCurrent" width="auto" v-if="subjectInfo.items.length>0"
								@on-select="chooseMenu">
								<div class="layout-logo-left"></div>
								<Menu-item v-for="(it,i) in subjectInfo.items" :key="i" :name="i">
									<span class="layout-text">{{it.title}}</span>
								</Menu-item>
							</Menu>
						</i-col>
						<i-col span="19">
							<div class="item_box">
								<div class="item">
									<div class="flex_item">
										<div class="tit_box" style="width: 80%;">
											<div style="align-items: center;">
												<!-- <image style="width: 100px;height: 100px;" :src="subjectInfo.items[subCurrent].banner"/> -->
												<span class="iconfont icon-word"></span>
												<div class="name"
													@click="courseStudyRecordEnd(subjectInfo.items[subCurrent])">
													{{subjectInfo.items[subCurrent].title}}
												</div>
											</div>
											<div>
												<span class="tit_blod">授课老师：</span>
												<span>{{subjectInfo.items[subCurrent].teacher}}</span>
												<span style="margin: 0 10px;">|</span>
												<span class="tit_blod">课程时长：</span>
												<span>{{subjectInfo.items[subCurrent].classHour}}小时</span>
											</div>
											<div>
												<span class="tit_blod">老师简介：</span>
												<span>
													杨萍，女，现年36岁，党员，大学本科，1997年7月参加工作，中小学二级教师，现任教于*县实验小学。从教17年来，她一直坚守在教学第一线，担任高段语文教学及班**工作。她忠诚*的教育事业，师德高尚，模范履行教师职责，热爱学生，团结同志，忘我工作，无私奉献，以校为家，教育教学成绩显著，是一位德才兼备的优秀教师。
												</span>
												<!-- <span>{{subjectInfo.items[subCurrent].teacherDesc}}</span> -->
											</div>
										</div>
										<div style="width: 15%;padding: 0 20px;display: flex;flex-direction: column;">
											<Progress style="width:130px" :percent="subjectInfo.items[subCurrent].percent" :stroke-width="5"
												:status="subjectInfo.items[subCurrent].percent>=60?'success':subjectInfo.items[subCurrent].study_status"></Progress>
											<div @click="goxx(subjectInfo.items[subCurrent])" class="xx_btn">去学习</div>
										</div>
									</div>
								</div>
							</div>
						</i-col>
					</Row>
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'

	import {
		courseInfo,
		courseStudyRecord,
		queryCourseRecord,
	} from '@/api/home/guild'
	import {
		videoPlayer
	} from "vue-video-player";
	import 'video.js/dist/video-js.css'

	import {
		getNowDate,
		tosfm
	} from '@/utils/util'
	let timer;
	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
			videoPlayer,
		},
		data() {
			return {
				spList: [],
				duration: 0, // 视频总长
				player: null,
				mediaUrl: null, // 播放视频的路径
				// 视频控制设置
				playerOptions: {
					playbackRates: [1.0], // 可选的播放速度
					autoplay: true, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 类型
						src: "http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.mp4", // url地址
					}, ],
					poster: "http://36.138.114.21:8082/file_resources/public/video/gyjz/20230908094822.jpg", // 封面地址
					notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: true, // 是否显示剩余时间功能
						fullscreenToggle: true, // 是否显示全屏按钮
					},
					controlsList: 'nodownload',
				},
				subjectInfo: {
					items: []
				},
				subCurrent: 0,
				ingId: '',
				timeTotal: 1,
				startTime: ''
			}
		},
		mounted() {
			this.startTime = getNowDate();
			this.getResourceseSp();
			this.$refs["bannerChild"].locationList = ['教育培训', '公益讲座'];
			this.setLocation();
		},
		beforeDestroy() {
			//清除定时器
			this.clearTime();
		},
		watch: {
			// duration() {
			// 	this.continuePlay();
			// }
		},
		methods: {
			goxx(){
				this.$router.push({
					name: 'subjectDetail',
					query:{
						id:it.id
					}
				});
			},
			clearTime() {
				clearInterval(timer);
				timer = null;
				// this.timeTotal = 1;
			},
			jishi() {
				this.clearTime();
				console.log('总时', this.timeTotal);
				timer = setInterval(() => {
					console.log('记时', this.timeTotal);
					this.timeTotal++;
				}, 1000)
			},
			getResourceseSp() {
				courseInfo({
					id: this.$route.query.id,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result;
						info.items.map((v, i) => {
							v.studyTimes == null ? 0 : v.studyTimes;
							v.percent = ((v.studyTimes / (v.classHour * 60 * 60)) * 100).toFixed(0);
							v.study_status = 'normal';
							if (i == 0) {
								v.study_status = 'active';
								this.queryCourseRecord(v);
							} else {
								v.study_status = 'normal'
							}
						})
						console.log(info)
						this.subjectInfo = info;
					}
				})
			},
			goVideo() {
				this.$router.push({
					name: 'video',
					query: {
						name: encodeURIComponent('视频文件')
					}
				})
			},
			setLocation(index) {
				this.$refs["bannerChild"].locationList = ['教育培训', '公益讲座'];
			},
			chooseMenu(e) {
				// 退出前
				this.subjectInfo.items.map((v, i) => {
					v.percent = ((this.timeTotal / (v.classHour * 60 * 60)) * 100).toFixed(0);
					if (this.subCurrent == i) {
						v.study_status = 'active';
						this.courseStudyRecordEnd(this.subjectInfo.items[i]);
					} else {
						v.study_status = 'normal';
					}
				})
				// 退出后，当前
				this.subCurrent = e;
				this.subjectInfo.items.map((v, i) => {
					v.percent = ((this.timeTotal / (v.classHour * 60 * 60)) * 100).toFixed(0);
					if (this.subCurrent == i) {
						v.study_status = 'active';
						this.queryCourseRecord(v);
					} else {
						v.study_status = 'normal';
					}
				})
			},
			courseStudyRecordEnd(it) {
				this.clearTime();
				courseStudyRecord({
					courseId: it.courseId,
					itemId: it.id,
					studentId: JSON.parse(this.$Cookies.get('cyry_user')).studentId,
					studyTimes: this.timeTotal,
					studyEnd: it.attachType == '视频' ? 0 : 1,
					id: this.ingId,
					endTime: getNowDate(),
					startTime: this.startTime,
					token: this.$Cookies.get('cyry_token')
				}).then(res => {
					it.percent = ((this.timeTotal / (it.classHour * 60 * 60)) * 100).toFixed(0);
				})
			},
			queryCourseRecord(it) {
				this.timeTotal = 1;
				queryCourseRecord({
					courseId: it.courseId,
					itemId: it.id,
					token: this.$Cookies.get('cyry_token')
				}).then(res => {
					if (res.result == null) {
						this.timeTotal = 0;
					} else {
						this.ingId = res.result.id;
						this.timeTotal = res.result.studyTimes;
						it.percent = ((this.timeTotal / (it.classHour * 60 * 60)) * 100).toFixed(0);
						if (it.attachType == '视频') {
							this.continuePlay(this.timeTotal);
						}
					}
					this.jishi();
					console.log(`${it.title}的`, res.result.studyTimes)
				})
			},
			// 续播
			continuePlay(time) {
				let lastTime = time;
				console.log(lastTime)
				console.log(2222, tosfm(time))
				if (lastTime > 1) {
					this.$Modal.confirm({
						title: '提示',
						content: `上次观看到${tosfm(time)[0]=='00'?'':tosfm(time)[0]+'时'}${tosfm(time)[1]=='00'?'':tosfm(time)[1]+'分'}${tosfm(time)[2]=='00'?'':tosfm(time)[2]+'秒'},是否继续播放?`,
						onOk: () => {
							// 自动跳转到对应的位置并播放
							this.player.currentTime(lastTime); //秒
							this.player.play();
						},
						onCancel() {
							this.player.currentTime(0);
						}
					});
				} else {
					this.player.currentTime(0);
				}
			},
			loop(currentTime) { // 视频轮询以计算播放时间
				var current = currentTime;
				//可以在这里加入请求，时刻向后端请求，通知服务器，目前视频播放的时长，以便下次播放视频，直接跳转
				this.last = current;
			},
			//默认加载之后，就会执行该函数
			canplay(player) {
				this.player = player;
				this.duration = player.duration();
				console.log(77777, this.duration)
			},
			//点击视频播放
			play(player) {
				clearInterval(this.timeid);
				this.interval(this.player.duration);
			},
			//点击暂停时触发
			pause() {
				clearInterval(this.timeid);
				this.loop(this.player.duration);
			},
			ended() {
				clearInterval(this.timeid);
			},
			timeupdate(player) { // 元素的currentTime发生变化时触发
				this.curr = player.currentTime;
				// 限制快进
				if (this.curr - this.oldTime > 2) {
					this.player.currentTime(this.oldTime);
				} else {
					this.oldTime = this.curr;
				}
			},
			seeked() { // 跳跃操作完成时触发
				clearInterval(this.timeid)
			},
			interval(currentTime) {
				this.timeid = setInterval(() => {
					this.loop(currentTime);
				}, 10000);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		// padding: 80px 100px;
		padding-bottom: 100px;
		// max-width: 1400px;
		// min-width: 1100px;
		margin: auto;

		.box3 {
			margin-top: 20px;

			.swiper-container {
				position: relative;
				width: 100vm;

				.swiper-slide {
					width: 100%;
					color: #000;
				}

				img {
					object-fit: fill;
					height: 300px;
					width: 100%;
				}

				.swiper_text {
					padding: 0px 20px 40px;

					.title {
						font-size: 20px;
						margin: 10px 0;
					}
				}
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #8971F1;
			}

		}
	}

	.item_box {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 1200px;
			margin: 20px;
			font-size: 14px;
			box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);

			.flex_item {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.xx_btn{
				color: #fff;
				background-color: #2179C8;
				border-radius: 3px;
				width: 80px;
				padding: 5px 0;
				text-align: center;
				font-size: 16px;
				cursor: pointer;
				margin: 10px 0;
			}

			.tit_box {
				padding: 15px 10px 8px;

				>div {
					display: flex;

					.icon-word {
						font-size: 30px;
						color: #41A5EE;
						margin-right: 5px;
					}

					.tit_blod {
						font-weight: bold;
						white-space: nowrap;
					}
				}
			}

			.name {
				// padding: 15px 10px;
				font-size: 20px;
			}

			.time {
				color: #575B66;
				font-size: 15px;
				padding: 10px 0;
				display: flex;
				align-items: center;
			}
		}
	}

	/deep/ .video-js .vjs-tech {
		// object-fit: fill;
	}

	/deep/ .vjs-poster {
		background-size: cover;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.content_box {
			padding: 20px 10px 80px;
			max-width: 100%;
			min-width: 100%;
			margin: auto;
		}

		.item_box {
			.item {
				width: 100%;
				margin: 0;
				font-size: 14px;
				box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);

				.tit_box {
					flex-direction: column;
				}

				.name {
					// padding: 15px 10px;
					font-size: 15px;
				}

				.time {
					color: #575B66;
					font-size: 15px;
					padding: 5px 0;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.layout {
		border: 1px solid #d7dde4;
		background: #f5f7f9;
	}

	.layout-logo {
		width: 100px;
		height: 30px;
		background: #5b6270;
		border-radius: 3px;
		float: left;
		position: relative;
		top: 15px;
		left: 20px;
	}

	.layout-nav {
		width: 420px;
		margin: 0 auto;
	}

	.layout-assistant {
		width: 300px;
		margin: 0 auto;
		height: inherit;
	}

	.layout-breadcrumb {
		padding: 10px 15px 0;
	}

	.layout-content {
		min-height: 200px;
		margin: 15px;
		overflow: hidden;
		background: #fff;
		border-radius: 4px;
	}

	.layout-content-main {
		padding: 10px;
	}

	.layout-copy {
		text-align: center;
		padding: 10px 0 20px;
		color: #9ea7b4;
	}

	.layout-text {
		font-size: 16px;
	}

	// 禁止拖到滚动条
	/deep/ .vjs-progress-control {
		pointer-events: none !important;
	}
</style>