<template>
  <div>
    <Modal v-model="showPop" :mask-closable="false" class="model_box" scrollable width="80%">
      <div :style="{height:height}" class="pop_content">
        <div v-if="newInfo.length>0" style="font-weight: bold;font-size: 16px;">{{ newInfo[0].courseName }}</div>
        <div class="table_box">
          <div class="cell_box">
            <div class="cell th">
              <div v-for="(it,i) in th" :key="i">{{ it }}</div>
            </div>
            <div v-for="(it,i) in newInfo" v-if="newInfo.length>0" :key="i" class="cell td">
              <div>{{ params.pageSize * params.pageNo - params.pageSize + i + 1 }}</div>
              <div class="single">{{ it.title }}</div>
              <div class="single">{{ it.classHour }}学时</div>
              <div class="single">{{ it.startTime }}</div>
              <div class="single">{{ it.endTime }}</div>
              <div class="single">{{ it.xxsj }}</div>
              <div class="single">{{ it.studyEnd == 1 ? '是' : '否' }}</div>
            </div>
            <div v-if="newInfo.length==0" class="common_excel_no_more">暂无内容~</div>
          </div>
          <Page v-if="newInfo.length!=0" :current="params.pageNo" :page-size="params.pageSize" :total="total"
                class="common_page_box" show-total @on-change="changePage"></Page>
          <!-- </Tab-pane> -->
          <!-- </Tabs> -->
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {recordInfo} from "@/api/home/guild";

export default {
  data() {
    return {
      th: ['序号', '课程名称', '课程学时', '学习开始时间', '学习结束时间', '已学习时间', '是否学习完成'],
      showPop: false,
      newInfo: [],
      height: '',
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10
      },
      courseId: '',
      studentId: ''
    }
  },
  mounted() {
    this.height = (window.innerHeight - 200) + 'px';
  },
  watch: {
    id(val) {
      if (val) {

      }
    }
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getInfo();
    },
    getInfo() {
      recordInfo({
        courseId: this.courseId,
        studentId: this.studentId,
        pageNo: this.params.pageNo,
        pageSize: this.params.pageSize,
        token: this.$Cookies.get('xh_userInfo') ? JSON.parse(this.$Cookies.get('xh_userInfo')).token : ''
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          if (info.length > 0) {
            info.map(v => {
              v.xxsj =
                  `${tosfm(v.studyTimes)[0] == '00' ? '00:' : tosfm(v.studyTimes)[0] + ':'}${tosfm(v.studyTimes)[1] == '00' ? '00:' : tosfm(v.studyTimes)[1] + ':'}${tosfm(v.studyTimes)[2] == '00' ? '00' : tosfm(v.studyTimes)[2] + ''}`
            })
          }
          this.newInfo = info;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: '错误',
            content: res.message,
            onOk: () => {
              this.$Cookies.remove('xh_userInfo');
            },
          });
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.model_box {
  /deep/ .ivu-modal-body {
    padding: 0;
  }

  /deep/ .ivu-modal-footer,
  /deep/ .ivu-modal-header {
    padding: 30px 50px;
    display: none;
  }

}

/deep/ .ivu-btn-text {
  display: none;
}


.pop_content {
  font-size: 15px;
  padding: 50px;
  overflow-y: scroll;
  overflow-x: scroll;

  .table_box {
    margin: 10px 0;
    // width: 100%;
    // max-width: 1000px;

    .search_box {
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;
      margin: 20px 0;

      > div {
        margin: 10px;
        white-space: nowrap;
        cursor: pointer;
      }

      .btn1 {
        background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
        color: #fff;
        padding: 5px 15px;
      }

      .btn2 {
        background: #767676;
        color: #fff;
        padding: 5px 15px;
      }
    }

    .cell_box {
      margin-top: 20px;

      .cell {
        display: flex;
        width: 100%;
        white-space: nowrap;
        font-size: 15px;
        padding: 10px 0;
        text-align: center;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;

        > div {
          width: 12%;
        }

        > div:first-child {
          width: 10%;
        }

        > div:nth-child(2) {
          width: 30%;
        }

        > div:nth-child(3) {
          width: 15%;
        }

        > div:nth-child(4) {
          width: 20%;
        }

        > div:nth-child(5) {
          width: 20%;
        }

        > div:nth-child(6) {
          width: 20%;
        }

        > div:nth-child(7) {
          width: 20%;
        }

      }

      .th {
        background-color: #49B3FF;
      }

      .td {
        // cursor: pointer;
      }

      .td:hover {
        background-color: #eee;
      }

      .td:nth-child(odd) {
        background-color: #D8D8D8;
      }

      .td:nth-child(even) {
        border-bottom: 1px solid #eee;
      }

      .detailBtn {
        cursor: pointer;
      }

      .detailBtn:hover {
        color: #2D85E2;
      }
    }
  }
}
</style>
