<!-- 联系我们 -->
<template>
	<!-- <Row :gutter="32">
		<Col class="demo-tabs-style1" style="background: #e3e8ee;padding:16px;">
		<Tabs type="card" :value="subName">
			<TabPane label="咨询分类" name="咨询分类">
				<div class="box2">
					<div class="title">联系我们</div>
					<div class="content" v-html="info.content"></div>
				</div>
			</TabPane>
			<TabPane label="意见反馈" name="意见反馈">
				<item6></item6>
			</TabPane>
		</Tabs>
		</Col>
	</Row> -->
	<div class="aa">
		<div class="box2">
			<div class="title">联系我们</div>
			<div class="a_man" v-for="(it,i) in people" :key="i">
				<img src="@/assets/images/lxwm.png"/>
				<div class="man_xx">
					<div>{{it[0]}}：{{it[1]}}</div>
					<div>{{it[2]}}</div>
				</div>
				<!-- <div v-for="(tt,ii) in it" :key="ii">{{tt}}</div> -->
			</div>
			<!-- <div class="content" v-html="info.content"></div> -->
		</div>
		<item6></item6>
	</div>
</template>

<script>
	import item6 from './item6.vue'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		components: {
			item6
		},
		data() {
			return {
				people: [],
				value: '',
				subName: ''
			}

		},
		mounted() {
			this.getSingleArticle()
		},
		methods: {
			getSingleArticle() {
				getSingleArticle({
					catid: 3,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let arr = res.result.title.trim().split(',');
						let newArr = [];
						arr.map(v => {
							v = v.split('|')
							newArr.push(v)
						})
						console.log(newArr)
						this.people = newArr;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.aa {
		box-shadow: 0px 4px 10px 0px #164EB9;
		padding-bottom: 50px;
		margin: 30px 100px;
		padding: 20px;
		border-radius: 10px;
	}

	.box2 {
		color: #011243;
		font-size: 14px;
		min-width: 600px;

		.title {
			font-size: 25px;
			text-align: center;
			margin-bottom: 10px;
			margin-top: 20px;
		}
		.a_man{
			position: relative;
			img{
				width: 100%;
			}
			.man_xx{
				position: absolute;
				top: 20%;
				left: 0;
				width: 100%;
				height: 60%;
				display: flex;
				align-items: center;
				>div{
					white-space: nowrap;
					color: #658CEC;
					font-family: YouYuan;
				}
				>div:first-child{
					text-align: center;
					font-size: 28px;
					padding-left: 40px;
					width: 55%;
					font-weight: bold;
				}
				>div:last-child{
					width: 40%;
					font-size: 35px;
				}
			}
		}
	}

	// .big_box{
	// 	.ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane{
	// 		background-color: #fff;
	// 		border: 1px solid #2d8cf0;
	// 		border-top: none;
	// 	}
	/deep/.ivu-tabs-bar {
		margin: 0;
	}

	// }
	.demo-tabs-style1>.ivu-tabs-card>.ivu-tabs-content {
		height: 120px;
		margin-top: -16px;
	}

	.demo-tabs-style1>.ivu-tabs-card>.ivu-tabs-content>.ivu-tabs-tabpane {
		background: #fff;
		padding: 16px;
	}

	.demo-tabs-style1>.ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab {
		border-color: transparent;
	}

	.demo-tabs-style1>.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab-active {
		border-color: #fff;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.aa {
			margin: 30px 0 !important;
			padding: 20px 0;
		}

		.box2 {
			min-width: 100%;
			width: 100%;
		}
	}
</style>