<template>
  <div>
    <div class="box header_pc">
      <div class="logo_box">
        <div class="logo">
          <img src="@/assets/logo.png" @click="goHome" />
          <span @click="goHome">贵州省建设工程招标投标协会</span>
        </div>
        <div class="search_box">
          <search ref="search_child"></search>
          <!-- <Poptip v-if="xh_userInfo.user.dwmc" trigger="hover">
              <div slot="content" class="userPop">
              <div @click="goUser">会员中心</div>
              <div @click="seeZs">会员证书</div>
              <div @click="updatePwd">修改密码</div>
              <div @click="updateVip">修改会员信息</div>
              <div @click="lookVipList">从业人员名单</div>
              <div class="outBtn" @click="goLogout">退出登录</div>
            </div>
          </Poptip> -->
          <div v-if="xh_userInfo.user.dwmc" class="user" @click="goUser">
            <span class="iconfont icon-renzhengyonghu"></span>
            <div style="color: orange">{{ xh_userInfo.user.dwmc }}</div>
          </div>
          <div v-else class="user" @click="goLogin">
            <span class="iconfont icon-chazhaoyonghu"></span>
            <div>会员登录</div>
          </div>

          <div v-if="cycy_userInfo.name" class="user" @click="goCyry">
            <span class="iconfont icon-gerendaiban"></span>
            <div style="color: orange">{{ cycy_userInfo.name }}</div>
          </div>
          <div v-else class="user" @click="goKs">
            <span class="iconfont icon-geren"></span>
            <div>从业人员登录</div>
          </div>
        </div>
      </div>
      <div class="menu_box">
        <div class="one_box">
          <div v-for="(it, i) in menuList" :key="i">
            <Tooltip :disabled="it.children.length == 0" placement="bottom">
              <div
                :class="{ selected: current == i }"
                class="one_box"
                @click="goUrlOne(it, i)"
              >
                <span class="menu_text">{{ it.menuName }}</span>
                <span
                  v-if="it.children.length > 0"
                  class="iconfont icon-xiajiantou"
                ></span>
              </div>
              <div v-if="it.children.length > 0" slot="content" class="tow_box">
                <div
                  v-for="(tt, ii) in it.children"
                  :key="ii"
                  :class="[tt.children.length == 0 ? 'tow_box_canHover' : '']"
                  @click="goUrl(tt.path, tt.menuName, i)"
                >
                  {{ tt.menuName }}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <vipLogin ref="vipbox"></vipLogin>
      <ksLogin ref="ksbox"></ksLogin>
      <upPwd ref="pwdbox"></upPwd>
    </div>
    <div
      :style="{
        background:
          scrollTop > 120
            ? scrollTop > 150
              ? 'rgba(0, 86, 195, 1)'
              : 'rgba(0, 86, 195, 0.5)'
            : 'rgba(0, 0, 0, .2)',
      }"
      class="header_sj"
    >
      <div class="logo_box">
        <div class="logo">
          <img src="@/assets/logo.png" @click="goHome" />
          <span @click="goHome">贵州省建设工程招标投标协会</span>
        </div>
        <div class="search_box">
          <div class="user_box">
            <div v-if="xh_userInfo.user.dwmc" class="user" @click="goUser">
              <span class="iconfont icon-renzhengyonghu"></span>
            </div>
            <div v-else class="user" @click="goLogin">
              <span class="iconfont icon-chazhaoyonghu"></span>
            </div>

            <div v-if="cycy_userInfo.name" class="user" @click="goCyry">
              <span class="iconfont icon-gerendaiban"></span>
              <div>{{ cycy_userInfo.name }}</div>
            </div>
            <div v-else class="user" @click="goKs">
              <span class="iconfont icon-geren"></span>
            </div>
          </div>
          <div class="iconfont icon-ego-menu" @click="showPop = true"></div>
        </div>
      </div>
      <van-popup
        v-model="showPop"
        :overlay-style="{ background: 'rgba(0, 0, 0, .5)' }"
        :style="{ height: '100%', width: '60%' }"
        closeable
        position="left"
      >
        <div style="margin-right: 40px; margin-top: 20px; padding-bottom: 50px">
          <Menu
            ref="side_menu"
            :active-name="activeName"
            :open-names="openNames"
            theme="light"
            width="auto"
            @on-select="chooseMenu"
          >
            <div v-for="(it, i) in menuList" :key="i">
              <Submenu
                v-if="it.children.length && it.children.length > 0"
                :name="it.menuName"
              >
                <template slot="title">
                  <!-- <span class="iconfont" :class="[it.icon]"></span> -->
                  {{ it.menuName }}
                </template>
                <MenuItem
                  v-for="(item, index) in it.children"
                  :key="index"
                  :name="item.menuName"
                >
                  {{ item.menuName }}
                </MenuItem>
              </Submenu>
              <MenuItem v-else :name="it.menuName">
                <!-- <span class="iconfont" :class="[it.icon]"></span> -->
                {{ it.menuName }}
              </MenuItem>
            </div>
          </Menu>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { getMenu, getLinks } from "@/api/home/index";
import { loginout } from "@/api/login";
import search from "@/components/search.vue";
import vipLogin from "@/components/vip_login.vue";
import upPwd from "@/components/upPwd.vue";
import ksLogin from "@/components/ks_login.vue";

export default {
  name: "Header",
  props: {
    isBlue: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  components: {
    search,
    vipLogin,
    upPwd,
    ksLogin,
  },
  data() {
    return {
      showPop: false,
      menuList: [],
      ksUrl: "",
      xh_userInfo: {
        user: {
          username: "",
        },
      },
      activeName: "首页",
      openNames: [],
      scrollTop: "",
      cycy_userInfo: { name: "" },
    };
  },
  mounted() {
    this.getLinks();
    this.getMenu();
    if (this.$Cookies.get("xh_userInfo")) {
      this.xh_userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
    }
    if (this.$Cookies.get("cyry_user")) {
      this.cycy_userInfo = this.$Cookies.get("cyry_user")
        ? JSON.parse(this.$Cookies.get("cyry_user"))
        : {};
      console.log(99, this.cycy_userInfo);
    }
    if (this.$Cookies.get("xh_activeName")) {
      this.activeName = this.$Cookies.get("xh_activeName");
    }
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    search_value(text) {
      this.$refs.search_child.queryText = text;
    },
    handleScroll(e) {
      var top =
        document.body.scrollTop ||
        document.documentElement.scrollTop ||
        window.pageYOffset;
      this.scrollTop = top;
    },
    getLinks() {
      getLinks({
        type: 10,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          this.ksUrl = res.result.url;
        }
      });
    },
    getMenu() {
      getMenu({}).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let menuList = res.result;
          menuList.map((it) => {
            if (it.menuName == "首页") {
              it.path = "/";
            }
            if (it.children.length > 0) {
              it.children.map((v) => {
                if (it.menuName == "行业动态") {
                  v.path = "news";
                }

                if (it.menuName == "党建专栏") {
                  v.path = "party";
                }

                if (it.menuName == "工程交易") {
                  v.path = "engineering";
                }
                if (it.menuName == "从业人员") {
                  if (v.menuName == "证书查询") {
                    v.path = "people";
                    // v.path = 'certificateLogin'
                  }
                  if (v.menuName == "从业人员库") {
                    v.path = "people";
                  }
                }
                if (it.menuName == "行业信用") {
                  v.path = "trade";
                }
                if (it.menuName == "学习培训") {
                  v.path = "subject";
                  // if (v.menuName == '公益讲座') {
                  // 	v.path = 'subject'
                  // }
                }
                if (it.menuName == "教育培训") {
                  v.path = "subject";
                  // if (v.menuName == '公益讲座') {
                  // 	v.path = 'subject'
                  // }
                }
                if (it.menuName == "关于协会") {
                  v.path = "guild";
                }
                if (it.menuName == "会员中心") {
                  v.path = "member";
                }
              });
            }
          });
          this.menuList = menuList;
          if (this.current < this.menuList.length) {
            this.openNames = [`${menuList[this.current].menuName}`];
          }
        }
      });
    },
    goHome() {
      this.$router.push("/");
    },
    goUrlOne(it, i) {
      if (it.menuName == "首页") {
        this.$router.push("/");
      } else if (it.menuName == "行业信用") {
        this.$Message.warning("此功能暂未开放！");
        return;
      }
      //  else if (it.menuName == '教育培训') {
      // 	this.$Message.warning('此功能暂未开放！');
      // 	return
      // }
      else if (it.children.length == 0) {
        this.$router.push(it.path);
      }
    },
    goUrl(e, menuName, i) {
      if (menuName == "报名考试") {
        window.open(this.ksUrl);
      } else if (menuName == "考试说明") {
        this.$router.push({
          name: "agreement",
          query: {
            catid: 214,
            name: "考试说明",
          },
        });
        location.reload();
      } else if (menuName == "从业人员库") {
        if (this.xh_userInfo.user.dwmc == "") {
          this.$refs["vipbox"].showLogin = true;
          this.$refs["vipbox"].goRyc = true;
        } else {
          this.$router.push({
            name: e,
            query: {
              name: encodeURIComponent(menuName),
            },
          });
          location.reload();
        }
      } else {
        this.$router.push({
          name: e,
          query: {
            name: encodeURIComponent(menuName),
          },
        });
        location.reload();
      }
    },
    goThreeUrl(name) {
      this.$router.push({
        name: "guild",
        query: {
          name: encodeURIComponent("联系我们"),
          subName: encodeURIComponent(name),
        },
      });
      location.reload();
    },
    goLogout() {
      let token = this.$Cookies.get("xh_userInfo")
        ? JSON.parse(this.$Cookies.get("xh_userInfo")).token
        : "";
      loginout({
        token,
      }).then((res) => {
        if (res.code == 200 || res.code == 0 || res.code == 401) {
          this.$Modal.confirm({
            title: "提示",
            content: "确定退出登录吗？",
            onOk: () => {
              this.$Message.success("退出成功！");
              this.$Cookies.remove("xh_userInfo");
              location.reload();
            },
          });
        }
      });
    },
    goLogin() {
      this.$refs["vipbox"].showLogin = true;
      this.$refs["vipbox"].userZX = true;
    },
    goUser() {
      this.$router.push("user");
      location.reload();
    },
    goCyry() {
      this.$router.push("cyry");
      location.reload();
    },
    goKs() {
      this.$refs["ksbox"].showLogin = true;
    },
    seeZs() {
      this.$router.push({
        name: "member",
        query: {
          name: encodeURIComponent("会员证书"),
        },
      });
      location.reload();
    },
    lookVipList() {
      this.$router.push("people");
      location.reload();
    },
    updatePwd() {
      this.$refs["pwdbox"].showLogin = true;
    },
    updateVip() {
      if (this.$Cookies.get("xh_userInfo")) {
        let userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
        let type = userInfo.user.ext5; //会员类型:1省内企业,2.省外企业,3.个人
        if (Number(type == 1)) {
          this.$router.push({
            name: "apply",
            query: {
              typeName: encodeURIComponent("省内"),
            },
          });
          location.reload();
        } else if (Number(type == 2)) {
          this.$router.push({
            name: "apply",
            query: {
              typeName: encodeURIComponent("省外"),
            },
          });
          location.reload();
        } else if (Number(type == 3)) {
          this.$router.push({
            name: "personal",
            query: {
              typeName: encodeURIComponent("个人"),
            },
          });
          location.reload();
        } else {
          this.$Message.warning("暂无会员信息！");
        }
      }
    },
    chooseMenu(menuName) {
      this.$Cookies.set("xh_activeName", menuName);
      let myarr = this.deepFind(
        this.menuList,
        (item, index, level) => item.menuName === menuName,
        "children"
      );
      if (menuName == "首页") {
        this.$router.push("/");
      } else if (menuName == "行业信用") {
        this.$Message.warning("此功能暂未开放！");
        return;
        // }
        // else if (menuName == '教育培训') {
        // this.$Message.warning('此功能暂未开放！');
        // return
      } else if (menuName == "报名考试") {
        window.open(this.ksUrl);
      } else if (menuName == "考试说明") {
        this.$router.push({
          name: "agreement",
          query: {
            catid: 214,
            name: "考试说明",
          },
        });
        location.reload();
      } else if (menuName == "从业人员库") {
        if (this.xh_userInfo.user.dwmc == "") {
          this.$refs["vipbox"].showLogin = true;
          this.$refs["vipbox"].goRyc = true;
        } else {
          this.$router.push({
            name: myarr[myarr.length - 1].path,
            query: {
              name: encodeURIComponent(menuName),
            },
          });
          location.reload();
        }
      } else {
        this.$router.push({
          name: myarr[myarr.length - 1].path,
          query: {
            name: encodeURIComponent(menuName),
          },
        });
        location.reload();
      }
    },
    deepFind(arr, condition, children) {
      // 即将返回的数组
      let main = [];

      // 用try方案方便直接中止所有递归的程序
      try {
        // 开始轮询
        (function poll(arr, level) {
          // 如果传入非数组
          if (!Array.isArray(arr)) return;

          // 遍历数组
          for (let i = 0; i < arr.length; i++) {
            // 获取当前项
            const item = arr[i];

            // 先占位预设值
            main[level] = item;

            // 检验是否已经找到了
            const isFind = (condition && condition(item, i, level)) || false;

            // 如果已经找到了
            if (isFind) {
              // 直接抛出错误中断所有轮询
              throw Error;

              // 如果存在children，那么深入递归
            } else if (children && item[children] && item[children].length) {
              poll(item[children], level + 1);

              // 如果是最后一个且没有找到值，那么通过修改数组长度来删除当前项
            } else if (i === arr.length - 1) {
              // 删除占位预设值
              main.length = main.length - 1;
            }
          }
        })(arr, 0);
        // 使用try/catch是为了中止所有轮询中的任务
      } catch (err) {}

      // 返回最终数组
      return main;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 30px 50px;
  color: #fff;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

  .logo_box {
    display: flex;
    justify-content: space-between;

    .logo {
      display: flex;
      align-items: center;
      font-size: 18px;

      span {
        white-space: nowrap;
        cursor: pointer;
      }

      img {
        width: 60px;
        margin-right: 30px;
        cursor: pointer;
      }
    }

    .search_box {
      display: flex;
      align-items: center;
      padding: 0 50px;

      .user {
        display: flex;
        align-items: center;
        margin-left: 30px;
        font-size: 15px;
        cursor: pointer;
        white-space: nowrap;

        .iconfont {
          margin-right: 10px;
        }
      }
    }
  }

  .menu_box {
    .one_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .iconfont {
        margin-left: 5px;
        font-size: 20px;
      }

      > div {
        padding: 10px 25px;
        font-size: 18px;
        cursor: pointer;

        .menu_text {
          white-space: nowrap;
        }
      }

      > div:hover {
        color: #f4dab4;
      }
    }

    .selected {
      color: #f4dab4;
      font-weight: bold;
    }

    .tow_box {
      text-align: center;

      > div {
        padding: 10px;
        font-size: 15px;
      }

      .tow_box_canHover:hover {
        color: #f4dab4;
      }
    }

    .three_box {
      text-align: right;

      > div {
        font-size: 14px;
        margin: 5px 0;
      }

      > div:hover {
        color: #f4dab4;
      }
    }

    ::v-deep .ivu-tooltip-inner {
      background-color: #fff;
      color: #011243;
    }

    ::v-deep .ivu-tooltip-arrow {
      border-bottom-color: #fff;
    }
  }
}

.userPop {
  font-size: 15px;
  text-align: center;

  > div {
    padding: 8px 0;
    cursor: pointer;
    color: #666;
  }

  > div:hover {
    color: #2d85e2;
  }

  .outBtn {
    border-top: 1px solid #eee;
  }
}

.icon-renzhengyonghu,
.icon-gerendaiban {
  color: orange;
}

// 大屏幕
@media only screen and (min-width: 800px) {
  .header_pc {
    display: inline-block;
  }

  .header_sj {
    display: none;
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .header_pc {
    display: none;
  }

  .header_sj {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    color: #fff;
    // background: rgba(0, 0, 0, .2);
    display: inline-block;
    padding: 5px 0 5px 20px;

    .logo_box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        font-size: 14px;

        span {
          white-space: nowrap;
          cursor: pointer;
        }

        img {
          width: 40px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .icon-ego-menu {
        font-size: 24px;
        text-align: center;
      }

      .search_box {
        padding: 0px !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        .user_box {
          display: flex;
        }

        .user {
          margin: 0 10px;

          .iconfont {
            margin-right: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .ivu-menu-vertical.ivu-menu-light:after {
    background-color: transparent;
  }

  .ivu-menu,
  .ivu-menu-item {
    font-size: 16px;
  }

  // /deep/.ivu-menu-vertical .ivu-menu-item{
  // 	border-top: 1px solid red!important;
  // }
  // /deep/.ivu-menu-vertical .ivu-menu-submenu-title{
  // 	border-top: 1px solid red!important;
  // 	.ivu-menu-submenu-title .ivu-menu-item{
  // 		border: none!important;
  // 	}
  // }
}
</style>
