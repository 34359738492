<!-- 违法违规信息 -->
<template>
	<div class="box3">
		<div class="item" v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
			<img v-if="it.thumb" style="width: 26%;" :src="it.thumb" />
			<div :style="{width: it.thumb?'70%':'100%'}">humb?'70%':'100%'}">
				<div class="title single">{{it.title}}</div>
				<div class="time">{{it.date}}</div>
				<div class="article">
					{{it.description}}
				</div>
			</div>
		</div>
		<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
		<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo" :page-size="params.pageSize" show-total></Page>
	</div>
</template>

<script>
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		components: {

		},
		props:{
			locationList:{
				type:Array,
				default:()=>[]
			}
		},
		data() {
			return {
				infoList: [],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 6
				},
				catid: '',
			}

		},
		mounted() {
			this.getArticle();
		},
		methods: {
			getArticle() {
				getArticle({
					catid: 305,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params:{
						locationList:encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box3 {
		.item {
			border-bottom: 1px solid #eee;
			padding: 10px;
			padding-bottom: 30px;
			margin-bottom: 30px;
			cursor: pointer;
			transition: all 0.2s ease-in;
			-webkit-transition: all 0.2s ease-in;
			-moz-transition: all 0.2s ease-in;
			-o-transition: all 0.2s ease-in;
			display: flex;
	
			img {
				width: 250px;
				height: 180px;
				margin-right: 30px;
			}
	
			.title {
				font-size: 20px;
				color: #000000;
				text-align: left;
			}
	
			.time {
				color: #575B66;
				font-size: 15px;
				padding: 10px 0;
			}
	
			.article {
				color: #9E9E9E;
				font-size: 13px;
			}
	
		}
	
		.item:hover {
			box-shadow: 5px 5px 20px #9E9E9E;
		}
	}
</style>
