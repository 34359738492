<template>
	<div>
		<Modal v-model="showPop" width="60%" class="model_box" scrollable :mask-closable="false">
			<div class="pop_content" :style="{height:height}">
				<Spin size="large" fix v-if="isLoading"></Spin>
				<div v-else>
					<div class="title">{{newInfo.title}}</div>
					<div class="line_box">
						<div class="time">发布时间：{{newInfo.date}}</div>
						<div class="ly">来源：{{newInfo.source||'暂无'}}</div>
						<div class="share">
							<Tooltip content="Top Center text" placement="top">
								<img src="@/assets/images/wx.png" />
								<img slot="content" class="qrcode" :src="newInfo.qrcode" />
							</Tooltip>
							<img @click="goWb" src="@/assets/images/wb.png" />
						</div>
					</div>
					<div class="content" v-html="newInfo.content"></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		changePwd,
	} from '@/api/login'
	import {
		getDetatilArticle
	} from '@/api/home/index'
	export default {
		data() {
			return {
				showPop: false,
				form: {},
				loading: false,
				id: '',
				newInfo: {},
				height: '',
				isLoading:false
			}
		},
		mounted() {
			this.height = (window.innerHeight - 200) + 'px';
		},
		watch: {
			id(val) {
				if (val) {
					this.getDetatilArticle(val)
				}
			}
		},
		methods: {
			getDetatilArticle(id) {
				this.isLoading = true
				getDetatilArticle({
					id: id,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let newInfo = res.result;
						this.newInfo = newInfo
						let height = document.documentElement.clientHeight - 50;
						this.newInfo.content = this.newInfo.content.replace(/\<iframe/gi,
							`<iframe style="height:${height}px" `);
					}
					this.isLoading = false
				})
			},
			goWb() {
				window.open(this.newInfo.weibo, '_blank')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

	}

	/deep/ .ivu-btn-text {
		display: none;
	}


	.pop_content {
		font-size: 15px;
		padding: 50px;
		overflow-y: scroll;
		overflow-x: scroll;

		.title {
			font-size: 22px;
			text-align: center;
		}

		.ly {
			font-size: 13px;
		}

		.line_box {
			padding: 50px 0 5px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.time {
				font-size: 13px;
			}

			.share {
				display: flex;

				img {
					height: 25px;
					margin: 0 10px;
					cursor: pointer;
				}

				.qrcode {
					height: 100px;
				}
			}
		}

	}
</style>
