<template>
	<div class="login_content">
		<div class="login_box">
			<img class="bg" src="@/assets/images/login/bg5.png" />
			<div class="hh_box">
				<div class="box">
					<div class="input_box">
						<Input class="input" v-model="form.loginName" placeholder="请输入姓名" key="xm">
						<span slot="prepend">姓名</span>
						</Input>
						<Input class="input" v-model="form.phone" placeholder="请输入手机号" key="sjh">
						<span slot="prepend">手机号</span>
						</Input>
						<Input class="input" v-model="form.phoneveriCode" placeholder="请输入验证码" key="yzm">
							<span slot="prepend">验证码</span>
							<!-- <img slot="append" :src="txYzm"/> -->
							<span class="yzm" slot="append" @click="getYzm"
								:style="{'pointer-events': isDisabled?'none':''}">{{sms_code}}</span>
						</Input>
						<Checkbox class="check_box" v-model="checked" @on-change="lookPop">我已阅读《隐私条款》
						</Checkbox>
						<Button class="btn" type="primary" :loading="loading" long @click="submit">登录</Button>
					</div>
				</div>
			</div>
			<Modal v-model="showModal" title="隐私条款" :closable="false" :mask-closable="false" ok-text="我已阅读并同意"
				@on-ok="ok">
				<p class="xy_box" v-html="xyInfo.content"></p>
			</Modal>
		</div>
	</div>
</template>
<script>
	import {
		getStuCode,
		getLogin,
		getHyLogin,
		// getRandomImage
	} from '@/api/login'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		data() {
			return {
				showModal: false,
				checked: false,
				form: {
					loginName:'',
					phone: '',
					phoneveriCode: ''
				},
				loading: false,
				glUrl: '',
				xyInfo: {
					content:''
				},
				sms_code: "获取验证码", //获取验证码按钮
				currentTime: 59,
				isDisabled: false,
				txYzm:''
			}
		},
		mounted() {
			// this.getRandomImage();
		},
		methods: {
			// getRandomImage(){
			// 	getRandomImage({}).then(res => {
			// 		if (res.code == 200 || res.code == 0) {
			// 			this.txYzm = res.result;
			// 		}
			// 	})
			// },
			getSingleArticle() {
				getSingleArticle({
					catid: 210
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.xyInfo = res.result;
						this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi, `<span style="font-size:15px!important" ` );
					}
				})
			},
			submit() {
				if (this.form.loginName == '') {
					this.$Message.info('姓名不能为空');
					return
				}
				if (this.form.phone == '') {
					this.$Message.info('手机号不能为空');
					return
				}
				if (this.form.phoneveriCode == '') {
					this.$Message.info('验证码不能为空');
					return
				}
				if (!this.checked) {
					this.$Message.info('请勾选同意考试须知');
					return
				}
				this.loading = true;
				getLogin({
					phone:this.form.phone,
					loginName:this.form.loginName,
					phoneveriCode:this.form.phoneveriCode
				}).then(res => {
					this.loading = false;
					if (res.code == 200 || res.code == 0) {
						let query ={
							sfz: res.result.sfz,
							token: res.result.token
						}
						this.$router.push({
							name: 'people_detail',
							query: query
						})
					}
				})
			},
			getYzm() {
				if (this.form.loginName == '') {
					this.$Message.info('姓名不能为空');
					return
				}
				if (this.form.phone == '') {
					this.$Message.info('手机号不能为空');
					return
				}
				getStuCode({
					phone: this.form.phone
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						// this.form.phoneveriCode = res.result;
						this.$Message.success('发送成功');
						this.countDown();
						this.isDisabled = true;
					}else{
						this.$Modal.error({
							title: '提示',
							content: res.message,
							onOk: () => {},
						});
						this.isDisabled = false;
					}
				})
			},
			//验证码倒计时
			countDown(options) {
				var that = this;
				var interval = setInterval(function() {
					that.currentTime--;
					if (that.currentTime <= 0) {
						clearInterval(interval)
						that.sms_code = '重新获取';
						that.currentTime = 59;
						that.isDisabled = false
					} else {
						that.sms_code = that.currentTime + 's'
					}
				}, 1000);
			},
			lookPop(){
				this.showModal=true;
				this.getSingleArticle();//隐私条款
			},
			ok() {
				this.checked = true;
			},
			getTab(i) {
				this.current = i;
			},
			goGl() {
				window.open(this.glUrl)
			},
			goHome() {
				this.$router.push('/')
			},
		}
	}
</script>
<style lang="scss" scoped>
	.login_content{
		overflow-y: scroll;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.login_box {
		min-width: 1100px;
		overflow: scroll;
		.bg {
			min-width: 1100px;
			width: 100%;
			height: 100vh;
			position: absolute;
			top: 0;
			// object-fit: cover;
		}

		.hh_box {
			min-width: 1100px;
			position: absolute;
			top: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.box {
				width: 900px;
				display: flex;
				justify-content: center;
			
				.input_box {
					width: 400px;
					.input {
						width: 100%;
						margin-bottom: 25px;
			
						/deep/ .ivu-input-group-append {
							background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
							color: #fff;
							width: 100px;
							cursor: pointer;
							border: none;
							font-size: 15px;
						}
			
						/deep/ .ivu-input-group-prepend {
							background-color: #D0E8FF;
							width: 100px;
							font-size: 15px;
							text-align-last:justify;
							padding: 0 20px;
							color: #333;
						}
					}
			
					/deep/ .ivu-input-group .ivu-input,
					.ivu-input-group .ivu-input-inner-container {
						height: 40px;
						font-size: 14px;
					}
			
					.check_box {
						margin-bottom: 20px;
						color: #fff;
						/deep/ .ivu-checkbox-inner{
							margin-right: 5px;
						}
					}
			
					.btn {
						height: 40px;
						font-size: 18px;
						letter-spacing: 30px;
						padding-left: 40px;
						background: #2B4BFA;
						box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
						border: none;
					}
				}
			}
		}
	}
	.xy_box {
		height: 400px;
		overflow: scroll;
	}
	
	/deep/ .ivu-btn-text {
		display: none;
	}
</style>
