<template>
	<div>
		<headerBox :current="1"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box news_pc">
			<left-tabs ref="tabsChild" :bgColor='1' @change="getMenu" :menu="menuList"></left-tabs>
			<div :style="{width:menuList.length>0?'75%':'85%'}">
				<listContent ref="listRef"></listContent>
			</div>
		</div>
		<div class="news_sj">
			<listSj ref="listRefSj" :menu="menuList"></listSj>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import leftTabs from '@/components/left_tabs.vue'
	import listContent from './components/list.vue'
	import listSj from './components/listSj.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			leftTabs,
			listContent,
			listSj
		},
		data() {
			return {
				locationList: [],
				infoList: [],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 20
				},
				catid: '',
				menuList: [],
				current: 0,
			}

		},
		mounted() {
			this.catid = this.$route.query.catid;
			if (this.$route.query.catid != 306 && this.$route.query.catid != 305) {
				this.menuList = [{
						title: '行业要闻',
						catid: 6
					},
					{
						title: '协会动态',
						catid: 91
					},
					{
						title: '通知公告',
						catid: 10
					},
					// {
					// 	title: '招采信息',
					// 	catid: 301,
					// }
				]
				let index = this.menuList.findIndex(v => v.title == decodeURIComponent(this.$route.query.name))
				this.setLocation(index);
			} else {
				this.setLocation();
			}
			this.getArticle();
		},
		methods: {
			/* ====================数据请求部分========================== */
			getArticle() {
				let catid = '';
				if (this.$route.query.catid == 306) {
					catid = 306;
				} else if (this.$route.query.catid == 305) {
					catid = 305;
				} else {
					catid = this.menuList[this.current].catid;
				}
				if(window.matchMedia("(max-width: 800px)").matches){
					   this.$refs['listRefSj'].catid = catid;
				}else {
					   this.$refs['listRef'].catid = catid;
				}
			},
			/* ====================页面交互部分========================== */
			getMenu(e) {
				this.setLocation();
				this.getArticle()
			},
			getSjMenu(e){
				this.setLocation(e);
				this.getArticle()
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			setLocation(index) {
				let current = index||index==0 ? index : this.$refs["tabsChild"].current;
				this.current = current;
				let locationList = [];
				if (this.$route.query.catid == 306) {
					locationList = ['会员风采'];
				} else if (this.$route.query.catid == 305) {
					locationList = ['违法违规信息'];
				} else {
					locationList = ['行业动态', this.menuList[current].title];
				}
				this.locationList = locationList;
				this.$refs["tabsChild"].current = current;
				this.$refs["bannerChild"].locationList = locationList;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 100px;
		display: flex;
		min-width: 1100px;

		.right_box {
			width: 75%;

			>div {
				border-bottom: 1px solid #eee;
				padding: 10px;
				padding-bottom: 30px;
				margin-bottom: 30px;
				cursor: pointer;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
				-o-transition: all 0.2s ease-in;
				display: flex;

				img {
					width: 250px;
					height: 180px;
					margin-right: 30px;
				}

				.title {
					font-size: 20px;
					color: #000000;
					text-align: left;
				}

				.time {
					color: #575B66;
					font-size: 15px;
					padding: 10px 0;
				}

				.article {
					color: #9E9E9E;
					font-size: 13px;
				}
			}

			>div:hover {
				// background-color: #eee;
				// padding: 15px 10px;
				box-shadow: 5px 5px 20px #9E9E9E;
			}
		}
	}

	// 大屏幕
	@media only screen and (min-width: 800px) {
		.news_pc {
			display: flex;
		}

		.news_sj {
			display: none;
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.news_pc {
			display: none!important;
		}

		.news_sj {
			padding-bottom: 100px;
			display: flex!important;
			/deep/.van-tab{
				padding: 0 20px;
				font-size: 7;
			}
			/deep/.van-tabs__nav--line {
				padding-bottom:10px;
			}

		}
	}
</style>
