<template>
	<div class="tabs">
		<div :style="{width:boxWidth+'px'}" v-for="(it,i) in menu" :key="i" @click="getTab(i)" :class="[bgColor==1?current==i?'selected1':'tabs_item1':current==i?'selected2':'tabs_item2']">
			<span>{{it.title}}</span>
			<span class="iconfont icon-gengduo"></span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'left_menu',
		props: {
			bgColor: {
				type: Number,
				default: 1//1蓝色，2红色
			},
			menu: {
				type: Array,
				default: []
			},
			boxWidth: {
				type: Number,
				default: 150
			}
		},
		data() {
			return {
				current: 0,
			}
		},
		mounted() {
			
		},
		methods: {
			getTab(i) {
				this.current = i;
				this.$emit('change',i)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.tabs{
		margin-right: 80px;
		display: flex;
		flex-direction: column;
		>div {
			width: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 15px;
			font-size: 15px;
			margin: 5px 0;
			color: #FF3700;
			cursor: pointer;
			text-align: center;
			position: relative;
			span{
				white-space: nowrap;
			}
		
			.icon-gengduo {
				margin-left: 15px;
				position: absolute;
				right: 10px;
			}
		}
		.tabs_item1{
			color: #164EB9;
		}
		.selected1 {
			font-size: 15px;
			color: #fff;
			background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		}
		.selected2 {
			font-size: 15px;
			color: #fff;
			background: linear-gradient(270deg, #FF8400 2%, #E22D2D 100%);
		}
		.tabs_item2{
			color: #FF3700;
		}
	}
</style>
