<template>
	<div>
		<Modal v-model="showPop" width="1020" class="model_box" title="新增企业风采"  scrollable :mask-closable="false">
			<div class="pop_content" :style="{height:height}">
				<div class="form_content">
					<Form ref="uform" :model="formValidate" :rules="ruleValidate" :label-width="80">
							<div style="width: 70%; float:left;">
								<FormItem label="注销人员" prop="title">
									<Input v-model="formValidate.title" placeholder="请输入注销人员" readonly></Input>
								</FormItem>
								<FormItem label="备注">
									<Input v-model="formValidate.remark" placeholder="请输入备注" :disabled="disabled"></Input>
								</FormItem>
							</div>

							<div style="width: 25%; float:right;">
								<FormItem label="注销材料" prop="material">
									<div class="upload_img" @click="toUpload('material')">
										<div v-if="formValidate['material']" class="demo-upload-list">
											<template>
												<img :src="$previewImg+formValidate['material']">
												<div class="demo-upload-list-cover">
													<Icon type="ios-eye-outline" @click.native="handleView('material')"></Icon>
													<Icon type="ios-trash-outline" @click.native="handleRemove('material')"></Icon>
												</div>
											</template>
										</div>
										<Upload
											v-else
											ref="upload"
											:disabled="disabled"
											:show-upload-list="false"
											:on-success="handleSuccess"
											:format="imgOption.format" 
											:max-size="imgOption.maxSize" 
											:on-format-error="handleFormatError"
											:on-exceeded-size="handleMaxSize" 
											type="drag"
											:action="$imgCommonHost" :data="imgOption.data">
											<div class="img_box">+</div>
											</Upload>
									</div>
								</FormItem>
							</div>
							
						</Form>

						<div class="div_btn" v-if="!disabled">
							<Button @click="submit" class="btn" type="primary" long>提交</Button>	
						</div>
				</div>

				<Modal title="" v-model="visible">
					<img :src="imgUrl" v-if="visible" style="width: 100%">
					<div slot="footer"></div>
				</Modal>

			</div>
		</Modal>
	</div>
</template>

<script>
	import axios from 'axios'
	import {
		cyryZxsq
	} from '@/api/other'
	export default {
		data() {
			return {
				showPop: false,
				form: {},
				loading: false,
				formData: '',
				newInfo: {},
				height: '',
				isLoading:false,
				formValidate: {
					title:'',
					stuids: '',
					material: '',
					remark: ''
				},
				ruleValidate: {
					title: [{
						required: true,
						message: '注销人员不能为空',
						trigger: 'change'
					}],
					material: [{
						required: true,
						message: '请上传注销材料',
						trigger: 'change'
					}]
				},
				userInfo: {},
				publicUploadUrl: this.$previewImg,
				imgUrl:'',
				visible:false,
				file_name:'',//当前文件名
				imgOption:{
					maxSize:2048,
					format:['jpg','jpeg','png'],
					data:{type:11}
				}, 
				disabled: false,
				
			}
		},
		mounted() {
			//this.height = (window.innerHeight - 100) + 'px';
			//tinymce.init(this.editorInit)
			if (this.$Cookies.get('xh_userInfo')) {
				this.userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
			}
			
		},
		watch: {
			formData(val) {
				this.formValidate.material = ''
				this.formValidate.remark = ''

				let data = JSON.parse(val)
				console.log("=======formData========", data)
				this.formValidate.title = data.names
				this.formValidate.stuids = data.stuids
			}
		},
		methods: {
			submit() {
				this.$refs['uform'].validate((valid) => {
					if (valid) {
						let param = {...this.formValidate }
						//console.log("========param========", param)
						cyryZxsq(param).then(res => {
							if (res.code == 200 || res.code == 0) {
								this.$Modal.success({
									title: '提示',
									content: '提交成功！',
									onOk: () => {
										this.showPop = false
									},
								});
							} else {
								this.$Modal.error({
									title: '提示',
									content: '提交失败！' + res.message,
									onOk: () => {
										
									},
								});
							}
						})
					}
				})
			},
			
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '超出文件大小限制',
					desc: '文件  ' + file.name + ' 太大, 不能超过 2M.'
				});
			},
			handleFormatError (file) {
				this.$Notice.warning({
					title: '文件格式不正确',
					desc: '文件的格式 ' + file.name + ' 不正确，请选择jpg或png.'
				});
			},
			handleSuccess (res, file) {
				this.formValidate[this.file_name] = this.$previewImg+res.result.file.filefullpath;
			},
			handleView (name) {
				this.imgUrl = this.$previewImg+this.formValidate[name];
				this.visible = true;
			},
			handleRemove (name) {
				this.formValidate[name] = ''
			},
			toUpload(name){
				this.file_name = name;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

	}

	/deep/ .ivu-btn-text {
		display: none;
	}


	.pop_content {
		font-size: 15px;
		padding: 20px 20px 0 20px;
		overflow-y: scroll;
		overflow-x: scroll;

		.title {
			font-size: 22px;
			text-align: center;
		}

		.ly {
			font-size: 13px;
		}

		.line_box {
			padding: 50px 0 5px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.time {
				font-size: 13px;
			}

			.share {
				display: flex;

				img {
					height: 25px;
					margin: 0 10px;
					cursor: pointer;
				}

				.qrcode {
					height: 100px;
				}
			}
		}

	}

	.btn {
		font-size: 18px;
		letter-spacing: 40px;
		padding-left: 60px;
		background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	}
	/deep/ .ivu-upload-drag{
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.demo-upload-list {
		display: inline-block;
		width: 60px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0,0,0,.2);
		margin-right: 4px;
	}
	.demo-upload-list img{
		width: 100%;
		height: 100%;
	}
	.demo-upload-list-cover{
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0,0,0,.6);
	}
	.demo-upload-list:hover .demo-upload-list-cover{
		display: block;
	}
	.demo-upload-list-cover i{
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}
	/deep/.ivu-form .ivu-form-item-label{
		font-size: 14px;
		line-height: none;
	}
	/deep/.ivu-input{
		font-size: 14px;
	}

	.upload_img {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 100px;
		margin: 0 10px;
		background-color: #D8D8D8;
		padding: 20px 10px;
		border-radius: 5px;

		.img_box {
			font-size: 50px;
			color: #888888;
			.iconfont {
				font-size: 60px;
				cursor: pointer;
				color: #666666;
			}
		}
	}

	.form_content {
		margin: 30px 20px 10px 20px;
		// margin: 80px 200px;

		.div_btn {
			text-align: center;
			margin-top: 20px;
			.btn {
				width: 200px;
				margin-left: 260px;
				margin-top: 30px;
			}
		}

	}
</style>
