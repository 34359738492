<template>
  <div class="ryxx_box">
    <Tabs v-if="!showXx" value="name1">
      <Tab-pane label="项目负责人信息" name="name1">
        <div class="search_box">
          <div>
            人员姓名：<Input
              v-model="form.certName"
              placeholder=""
              style="width: 180px"
            ></Input>
          </div>
          <div>
            证书编号：<Input
              v-model="form.certNo"
              placeholder=""
              style="width: 180px"
            ></Input>
          </div>
          <div class="btn1" @click="toSearch">查询</div>
          <div class="btn2" @click="toReset">重置</div>
          <!--          <div class="btn3" @click="toLogoff">人员注销申请</div>-->
        </div>
        <div class="cell_box">
          <div class="cell th">
            <div v-for="(it, i) in th" :key="i">{{ it }}</div>
          </div>
          <div
            v-for="(it, i) in ryList"
            v-if="ryList.length > 0"
            :key="i"
            class="cell td"
          >
            <div>
              {{ params.pageSize * params.pageNo - params.pageSize + i + 1 }}
            </div>
            <div>{{ it.certName }}</div>
            <div>{{ it.certIdcard }}</div>
            <div>{{ it.certCompany }}</div>
            <div>{{ it.certNo }}</div>
            <div>{{ it.yxqz }}</div>
            <div style="cursor: pointer" @click="goDetail(it)">查看</div>
          </div>
          <div v-if="ryList.length == 0" class="common_excel_no_more">
            暂无内容~
          </div>
        </div>
        <Page
          v-if="ryList.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <detailModal ref="detailModal"></detailModal>
    <vipLogin ref="vipbox"></vipLogin>

    <Modal
      v-model="showAlert"
      :mask-closable="true"
      class="model_box"
      scrollable
      width="300px"
    >
      <div style="font-size: 18px; padding: 40px">请选择要注销的从业人员！</div>
    </Modal>

    <logoffModal ref="logoffModal"></logoffModal>
    <div v-if="showXx">
      <el-button @click="backXx">返回</el-button>
      <xx :studentData="{ studentId: studentId }"></xx>
    </div>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import vipLogin from "@/components/vip_login.vue";
import xx from "./xx/index.vue";
import detailModal from "./detail.vue";
import logoffModal from "./logoff.vue";
import { getXmfzr } from "@/api/home/index";

export default {
  name: "home",
  components: {
    bannerBox,
    headerBox,
    footerBox,
    vipLogin,
    detailModal,
    logoffModal,
    xx,
  },
  data() {
    return {
      th: [
        "序号",
        "姓名",
        "身份证号",
        "从业单位",
        "证书编号",
        "有效期限",
        "查看",
      ],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        certName: "",
        certNo: "",
        certCompany: "",
      },
      ryList: [],
      xh_userInfo: {
        user: {
          username: "",
        },
      },
      showAlert: false,
      showXx: false,
      studentId: "",
    };
  },
  mounted() {
    if (this.$Cookies.get("xh_userInfo")) {
      this.xh_userInfo = JSON.parse(this.$Cookies.get("xh_userInfo"));
    }
    this.getCyry();
  },
  methods: {
    changePage(e) {
      this.params.pageNo = e;
      this.getCyry();
    },
    getCyry() {
      getXmfzr({
        ...this.params,
        ...this.form,
        certCompany: this.xh_userInfo.user.dwmc,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
        }
      });
    },
    toSearch() {
      this.params.pageNo = 1;
      this.getCyry();
    },
    toReset() {
      this.form = {};
      this.params.pageNo = 1;
      this.getCyry();
    },
    toLogoff() {
      let names = [];
      let stuids = [];
      this.ryList.forEach((item) => {
        console.log("========item======", item);
        if (item.isCheck) {
          names.push(item.name);
          stuids.push(item.stuUUID);
        }
      });
      if (stuids.length == 0) {
        this.showAlert = true;
        return false;
      }

      let formData = { names: names.join(","), stuids: stuids.join(",") };
      this.$refs["logoffModal"].showPop = true;
      this.$refs["logoffModal"].formData = JSON.stringify(formData);
    },
    goDetail(it) {
      this.$refs["detailModal"].showPop = true;
      this.$refs["detailModal"].certno = it.certNo;
    },
    goStudy(it) {
      this.showXx = true;
      this.studentId = it.stuUUID;
      console.log("tokens", this.tokens);
      // this.$router.push({name: 'cyry', params: {studentId: it.stuUUID, token: tokens}})
    },
    backXx() {
      this.showXx = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.ryxx_box {
  margin: 10px 20px;
  // width: 100%;
  // max-width: 1000px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 20px 0;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }

    .btn3 {
      background: red;
      color: #fff;
      padding: 5px 15px;
      float: right;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div:first-child {
        width: 8%;
      }

      > div:nth-child(2) {
        width: 10%;
      }

      > div:nth-child(3) {
        width: 15%;
      }

      > div:nth-child(4) {
        width: 27%;
      }

      > div:nth-child(5) {
        width: 15%;
      }

      > div:nth-child(6) {
        width: 15%;
      }
    }

    .th {
      background-color: #49b3ff;
    }

    .td {
      cursor: pointer;
    }

    .td:hover {
      background-color: #eee;
    }

    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }

    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}

.model_box {
  /deep/ .ivu-modal-body {
    padding: 0;
  }

  /deep/ .ivu-modal-footer,
  /deep/ .ivu-modal-header {
    padding: 30px 50px;
    display: none;
  }
}
</style>
