<template>
  <div class="index">
    <bayWindow v-for="item in sypcList" :key="item.id" :info="item" random></bayWindow>
    <Modal :fullscreen="fullscreen" v-model="showModal" width="60%" v-for="item in sypcList"
           :title = "modalTiele" :mask-closable="false" ok-text="我已阅读"
           @on-ok="ok">
      <div slot="header" style="display: flex;align-items: center;justify-content: start;position: relative">
        <p style="font-size: 18px">{{modalTiele}}
          &nbsp;&nbsp;&nbsp;&nbsp;<Icon class="icon-zk" @click="zhCli" size="20" type="md-qr-scanner"/>
        </p>

      </div>
      <div>
        <embed :style="styleObj" width="100%"
               :src="modelPdfUrl">
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  getSingleArticle
} from '@/api/home/guild'
import {
  getLinks,getPcList
} from '@/api/home/index'

import bayWindow from '../components/bayWindow.vue'

var interval;
export default {
  components: {
    bayWindow
  },
  data() {
    return {
      xPos: 100,
      yPos: 200,
      xin: true,
      yin: true,
      step: 1,
      delay: 18,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true,
      thedivShow: true,
      showModal: false,
      xyInfo: {},
      sypcList: [],
      url: '',
      fullscreen: false,
      modalTiele: '',
      modelPdfUrl:'',
      styleObj: {
        height: '400px'
      }
    };
  },

  mounted() {
    this.pcList()
    interval = setInterval(this.changePos, this.delay);
  },

  methods: {
    pcList(){
      getPcList({
        catid: '213',
        indexshow: '1'
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.sypcList = res.result
          this.sypcList.forEach(item => {
                item.imgUrl = this.$sypcHost+item.imgUrl
                item.pdfUrl = this.$sypcHost+item.pdfUrl
          }
          )
        }
      })
    },
    zhCli() {
      this.fullscreen = !this.fullscreen
      if (this.fullscreen) {
        this.styleObj.height = '900px'
      } else {
        this.styleObj.height = '400px'
      }
    },
    changePos() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (this.$refs.thediv) {
        this.Hoffset = this.$refs.thediv.offsetHeight; //获取元素高度
        this.Woffset = this.$refs.thediv.offsetWidth;
      }

      // 滚动部分跟随屏幕滚动
      // if (this.$refs.thediv) {
      // 	this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
      // 	this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";
      // }

      // 滚动部分不随屏幕滚动
      if (this.$refs.thediv) {
        this.$refs.thediv.style.left = this.xPos + document.body.scrollLeft + "px";
        this.$refs.thediv.style.top = this.yPos + 150 + "px";
      }

      if (this.yon) {
        this.yPos = this.yPos + this.step;
      } else {
        this.yPos = this.yPos - this.step;
      }
      if (this.yPos < 0) {
        this.yon = 1;
        this.yPos = 0;
      }
      if (this.yPos >= height - this.Hoffset) {
        this.yon = 0;
        this.yPos = height - this.Hoffset;
      }

      if (this.xon) {
        this.xPos = this.xPos + this.step;
      } else {
        this.xPos = this.xPos - this.step;
      }
      if (this.xPos < 0) {
        this.xon = 1;
        this.xPos = 0;
      }
      if (this.xPos >= width - this.Woffset) {
        this.xon = 0;
        this.xPos = width - this.Woffset;
      }
    },
    clearFdAd() {
      clearInterval(interval);
    },
    starFdAd() {
      interval = setInterval(this.changePos, this.delay);
    },
    goTz(item) {
      this.modalTiele = item.title
      this.modelPdfUrl = item.pdfUrl
      this.showModal = true;
      this.getSingleArticle(); //隐私条款
      this.getLinksKs(); //考试系统
    },
    getSingleArticle() {
      getSingleArticle({
        catid: 214
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.xyInfo = res.result;
          this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi,
              `<span style="font-size:15px!important" `);
        }
      })
    },
    ok() {
      // window.open(this.url, '_blank');
      // location.reload()
    },
    getLinksKs() {
      getLinks({
        type: 10
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.url = res.result.url;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
#thediv {
  position: absolute;
  top: 43px;
  left: 2px;
  overflow: hidden;
}

.fc {
  z-index: 9999999;
  cursor: pointer;
  opacity: .9;
  height: 80px;
}

.xy_box {
  height: 400px;
  overflow: scroll;
  // transform: scale(0.9);
}

/deep/ .ivu-btn-text {
  display: none;
}

/deep/ .ivu-input {
  height: 35px;
  font-size: 13px;
}

/deep/ .ivu-modal-header-inner {
  font-size: 18px !important;
  text-align: center;
}

.icon-zk {
  cursor: pointer;
  position: absolute;
  right: 30px;
}

.icon-zk:hover {
  color: #1524fc;
}
</style>
