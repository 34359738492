<template>
	<div>
		<Modal v-model="showLogin" width="600" class="model_box" :mask-closable="false">
			<p slot="header" style="color:#2B4BFA;text-align:center;font-size: 16px;">
				<Icon type="information-circled"></Icon>
			</p>
			<div class="login_box">
				<img class="bg" src="../assets/images/login/bg4.png" />
				<div class="login_content">
					<div>
						<img src="../assets/images/login/4.png">
						<Input class="input" v-model="form.username" placeholder="请输入统一社会信用代码" key="xm" type="text">
						</Input>
					</div>
					<div>
						<img src="../assets/images/login/5.png">
						<Input class="input" v-model="form.password" type="password" autocomplete="new-password"
							placeholder="请输入登录密码" key="mm">
						</Input>
					</div>
					<div class="flex_mm">
						<Checkbox class="check_box" v-model="checked" @on-change="lookPop">我已阅读《隐私条款》
						</Checkbox>
						<div class="zhmm" @click="rePwd">
							<span class="iconfont icon-wangjimima"></span>
							<span>忘记密码</span>
						</div>
					</div>
					<Button class="btn" type="primary" :loading="loading" long @click="submit">登录</Button>
					<div class="zcbtn">还不是会员？<span @click="goRH">立即申请入会</span></div>
				</div>
			</div>
		</Modal>
		<Modal v-model="showModal" title="隐私条款" :closable="false" :mask-closable="false" ok-text="我已阅读并同意" @on-ok="ok">
			<p class="xy_box" v-html="xyInfo.content"></p>
		</Modal>
	</div>
</template>

<script>
	import {
		getHyLogin,
	} from '@/api/login'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	import search from '@/components/search.vue'
	export default {
		name: 'vipLogin',
		components: {
			search
		},
		data() {
			return {
				menuList: [],
				ksUrl: '',

				showLogin: false,
				showModal: false,
				checked: false,
				form: {
					username: '',
					password: '',
				},
				loading: false,
				xyInfo: {
					content: ''
				},
				keyWord: '',
				xh_userInfo: {
					user: {
						username: ''
					}
				},
				goRyc: false,
				isZCFG: false,
				zcfgQuery: {},
				isHYZS: false,
				hasLoad: false,
				userZX: false
			}
		},
		mounted() {

		},
		methods: {
			lookPop() {
				this.showModal = true;
				this.getSingleArticle(); //隐私条款
			},
			getSingleArticle() {
				getSingleArticle({
					catid: 210
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.xyInfo = res.result;
						this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi,
							`<span style="font-size:15px!important" `);
					}
				})
			},
			ok() {
				this.checked = true;
			},
			goRH(){
				this.$router.push('apply')
			},
			rePwd() {
				this.$Modal.info({
					title: '提示',
					content: '请联系协会重置密码，联系电话：0851-86587786',
					onOk: () => {

					},
				});
			},
			submit() {
				if (this.form.username == '') {
					this.$Message.info('统一社会信用代码不能为空');
					return
				}
				if (this.form.password == '') {
					this.$Message.info('密码不能为空');
					return
				}
				if (!this.checked) {
					this.$Message.info('请勾选同意考试须知');
					return
				}
				this.loading = true;
				getHyLogin({
					username: this.form.username,
					password: this.form.password
				}).then(res => {
					this.loading = false;
					if (res.code == 200 || res.code == 0) {
						this.$Message.success('登录成功！');
						this.$Cookies.set('xh_userInfo', JSON.stringify(res.result));
						this.showLogin = false;
						if (this.goRyc) {
							this.$router.push({
								name: 'people',
								query: {
									name: encodeURIComponent('从业人员库')
								}
							})
							location.reload()
						} else if (this.isZCFG) {
							this.$router.push({
								name: 'policy',
								query: this.zcfgQuery
							})
							location.reload()
						} else if (this.userZX) {
							this.$router.push('user')
							location.reload()
						} else if (this.isHYZS) {
							location.reload()
						} else if (this.hasLoad) {
							location.reload()
						} else {
							this.$router.push('/');
							location.reload()
						}
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

		.login_box {
			position: relative;

			.bg {
				width: 100%;
				object-fit: fill;
				vertical-align: top;
			}

			.login_content {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				padding-left: 40%;
				padding-right: 20px;
				display: flex;
				justify-content: center;
				flex-direction: column;

				img {
					height: 50px;
					margin-bottom: -5px;
				}
			}
		}

		.input {
			width: 100%;
			margin-bottom: 22px;

			::v-deep .ivu-input-group-append {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				width: 100px;
				cursor: pointer;
				border: none;
				font-size: 14px;
			}

			::v-deep .ivu-input-group-prepend {
				background-color: #D0E8FF;
				width: 160px;
				font-size: 12px;
				text-align-last: justify;
				padding: 0 20px;
				color: #333;
				font-weight: bold;
			}
		}

		.check_box {
			white-space: nowrap;
			/deep/ .ivu-checkbox-inner {
				margin-right: 5px;
			}
		}

		.flex_mm {
			margin-bottom: 20px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;

			.zhmm {
				cursor: pointer;
				font-size: 13px;
				display: flex;
				align-items: center;
				white-space: nowrap;

				.iconfont {
					margin-right: 3px;
				}
			}

			.zhmm:hover {
				color: #2B4BFA;
			}
		}
		.zcbtn{
			text-align: right;
			margin-top: 20px;
			font-size: 13px;
			span{
				color: #2B4BFA;
				cursor: pointer;
			}
		}

		.btn {
			height: 40px;
			font-size: 16px;
			letter-spacing: 30px;
			padding-left: 40px;
			background: #2B4BFA;
			box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
			border: none;
		}
	}

	.xy_box {
		height: 400px;
		overflow: scroll;
		// transform: scale(0.9);
	}

	/deep/ .ivu-btn-text {
		display: none;
	}

	/deep/.ivu-input {
		height: 35px;
		font-size: 13px!important;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.login_content {
			padding-left: 38%!important;
			img {
				height: 40px !important;
				margin-bottom: -10px !important;
			}

			.input {
				width: 100%;
				margin-bottom: 5px!important;
			}
			/deep/.ivu-input {
				height: 30px!important;
				font-size: 13px;
			}
		}
	}
</style>
