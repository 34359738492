<template>
	<div>
		<headerBox :current="111"></headerBox>
		<bannerBox ref="bannerChild" :bgNum="1"></bannerBox>
		<div class="content_box">
			<!-- <div class="title">{{newInfo.title}}</div> -->
			<!-- <div class="time">发布时间：{{newInfo.date}}</div> -->
			<div class="content" v-html="newInfo.content"></div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getSingleArticle
	} from '@/api/home/party'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				newInfo:{}
			}
		},
		mounted() {
			this.getSingleArticle();
			this.$refs["bannerChild"].locationList = ['党建专栏','学习园地','党章'];
		},
		methods: {
			// 党章
			getSingleArticle(){
				getSingleArticle({
					catid: this.$route.query.catid,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.newInfo = res.result;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 200px;

		.title {
			font-size: 22px;
			text-align: center;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			padding-bottom: 30px;
		}

		.time {
			font-size: 12px;
			padding: 50px 0 20px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
		}

	}
</style>
