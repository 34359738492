<template>
	<div>
		<div class="hybm_box">
			<Tabs value="name1">
				<Tab-pane label="会议报名" name="name1">
					<div class="cell_box">
						<div v-if="hyList.length>0" class="ry_box">
							<div class="cell cellHY th">
								<div>序号</div>
								<div>会议主题</div>
								<div>会议内容</div>
							</div>
							<div class="cell cellHY td" v-for="(it,i) in hyList" :key="i">
								<div>{{i+1}}</div>
								<div class="single">{{it.title}}</div>
								<div class="single">{{it.content}}</div>
							</div>
						</div>
						<div v-else class="common_excel_no_more">暂无内容~</div>
					</div>
					<Page v-if="hyList.length!=0" class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo" :page-size="params.pageSize"
						show-total></Page>
				</Tab-pane>
			</Tabs>
		</div>
		<vipLogin ref="vipbox"></vipLogin>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import vipLogin from '@/components/vip_login.vue'
	import {
		getMeeting
	} from '@/api/home/index'
	import {
		handleIdCard,
	} from '@/utils/util'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			vipLogin
		},
		data() {
			return {
				th: ['序号', '姓名', '证书编号', '所在单位'],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 10
				},
				form:{},
				hyList:[],
				jfType:[
					{ label: '现金交费', value: 1 },
					{ label: '对工帐户', value: 2 },
					{ label: '微信转帐', value: 3 },
					{ label: '支付宝转帐', value: 4 },
					{ label: '其它方式', value: 5 },
				]
			}

		},
		mounted() {
			this.getMeeting();
		},
		methods: {
			changePage(e) {
				this.params.pageNo = e;
				this.getMeeting();
			},
			getMeeting(){
				let xh_userInfo = {};
				if (this.$Cookies.get('xh_userInfo')) {
					xh_userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				}
				getMeeting({
					vipid: xh_userInfo.user.uuid,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						this.hyList = info;
						this.total = res.result.total;
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			toSearch(){
				this.params.pageNo = 1;
				this.getCyry();
			},
			toReset(){
				this.form = {};
				this.params.pageNo = 1;
				this.getCyry();
			},
			goDetail(it){
				this.$router.push({
					name:'people_detail',
					query:{
						certno:it.certno
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .ivu-tabs-nav .ivu-tabs-tab {
		width: 200px;
		text-align: center;
		font-size: 18px;
	}

	.hybm_box {
		margin: 10px 20px;
		// width: 100%;
		// max-width: 1000px;
		
		.search_box{
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			margin: 20px 0;
			>div{
				margin: 10px;
				white-space: nowrap;
				cursor: pointer;
			}
			.btn1{
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				padding: 5px 15px;
			}
			.btn2{
				background: #767676;
				color: #fff;
				padding: 5px 15px;
			}
		}

	}
	.ry_box {
		margin-top: 10px;
	
		.cell {
			display: flex;
			width: 100%;
			white-space: nowrap;
			font-size: 15px;
			padding: 10px 0;
			text-align: center;
			border-left: 1px solid #eee;
			border-right: 1px solid #eee;
	
			>div:first-child {
				width: 10%;
			}
	
			>div:nth-child(2) {
				width: 15%;
			}
	
			>div:nth-child(3) {
				width: 25%;
			}
	
			>div:nth-child(4) {
				width: 40%;
			}
		}
	
		.cellHY {
			>div:first-child {
				width: 10%;
			}
	
			>div:nth-child(2) {
				width: 30%;
			}
	
			>div:nth-child(3) {
				width: 60%;
			}
		}
	
		.th {
			background-color: #49B3FF;
			color: #fff;
		}
	
		.td {
			cursor: pointer;
		}
	
		.td:hover {
			background-color: #eee;
		}
	
		.td:nth-child(odd) {
			background-color: #D8D8D8;
		}
	
		.td:nth-child(even) {
			border-bottom: 1px solid #eee;
		}
	}
</style>
