<template>
	<div>
		<headerBox></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="policy_box">
			<div style="overflow-x: scroll;">
				<div style="min-width: 1100px;">
					<div class="content_box">
						<left-tabs ref="tabsChild" :bgColor='1' :boxWidth="250" @change="getMenu" :menu="tabList">
						</left-tabs>
						<div class="right_box">
							<item1 ref="item1" @change="chooseTab"></item1>
							<!-- <item2 v-if="current==1" ref="item2"></item2> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import leftTabs from '@/components/left_tabs.vue'
	import item1 from './components/item1.vue'
	import item2 from './components/item2.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			leftTabs,
			item1,
			item2,
		},
		data() {
			return {
				current: 0,
				menuList: [{
						title: '政策查询',
					},
					{
						title: '法律查找',
					},
				],
				tabList: [{
						title: '全部',
						type: 0
					},
					{
						title: '国家法律',
						type: 19
					},
					{
						title: '国家行政法规',
						type: 20
					},
					{
						title: '国务院规范性',
						type: 97
					},
					{
						title: '住建部规章及规范性文件',
						type: 136
					},
					{
						title: '其他部门规章及规范性文件',
						type: 155
					},
					// {
					// 	title: '贵州省有关规性文件',
					// 	type: 156
					// }
				],
			}

		},
		mounted() {
			let index = this.tabList.findIndex(v => v.type == decodeURIComponent(this.$route.query.type))
			if (this.$route.query.name) {
				index = this.tabList.findIndex(v => v.title == decodeURIComponent(this.$route.query.name))
			}
			index = index == -1 ? 0 : index;
			this.setLocation(index);
		},
		methods: {
			getMenu() {
				this.setLocation();
			},
			setLocation(index) {
				let current = index ? index : this.$refs["tabsChild"].current;
				this.current = current;
				let locationList = ['政策法规', this.tabList[current].title]; //
				this.locationList = locationList;
				this.$refs["tabsChild"].current = current;
				this.$refs["item1"].current = current;
				this.$refs["bannerChild"].locationList = locationList;
			},
			chooseTab(i) {
				this.setLocation(i)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 100px;
		display: flex;

		.right_box {
			width: 75%;
		}
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.policy_box{
			padding-bottom: 100px;
		}
	}
</style>
