import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state ={
    username: 'ABCD',
    userState:0
}
const mutations ={
    setUser (state, name) {
        state.username = name
    },
    setUserState (state, data) {
        state.userState +=data
    },
}
export default new Vuex.Store({
    state,
    mutations
})

