<template>
  <div class="details">
    <el-dialog
        :before-close="handleClose"
        :title="details.title"
        :visible.sync="dialogVisible"
        width="70%">
      <div>
        <el-card>
          <el-form label-position="right" label-width="120px">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="姓名：">
                  {{ details.data.name }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证：">
                  {{ details.data.idCard }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电话：">
                  {{ details.data.phone }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公司：">
                  {{ details.data.company }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="报名时间：">
                  {{ details.data.lrrq }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="作废状态：">
                  {{ details.data.zfrq ? '作废' : '正常' }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证正面：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.idCardZm + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证反面：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.idCardFm + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="毕业证图片：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.byz + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社保证明图片：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.sbzm + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="一寸照图片：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.photo + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="在职证明图片：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.zzzm + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <!--          <el-form label-position="right" label-width="120px">-->
          <!--            <el-form-item label="姓名：">-->
          <!--              {{ details.data.name }}-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="身份证：">-->
          <!--              {{ details.data.idCard }}-->

          <!--            </el-form-item>-->
          <!--            <el-form-item label="电话：">-->
          <!--              {{ details.data.phone }}-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="身份证正面：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.idCardZm + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="身份证反面：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.idCardFm + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="公司：">-->
          <!--              {{ details.data.company }}-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="毕业证图片：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.byz + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="社保证明图片：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.sbzm + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="一寸照图片：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.photo + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="在职证明图片：">-->
          <!--              <el-image-->
          <!--                  :preview-src-list="srcList"-->
          <!--                  :src="$previewImg + details.data.zzzm + `?token=${$Cookies.get('cyry_token')}`"-->
          <!--                  style="width: 100px; height: 100px">-->
          <!--                <div slot="error" class="image-slot">-->
          <!--                  <i class="el-icon-picture-outline" style="font-size: 24px"></i>-->
          <!--                </div>-->
          <!--              </el-image>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="报名时间：">-->
          <!--              {{ details.data.lrrq }}-->
          <!--            </el-form-item>-->
          <!--            &lt;!&ndash;            <el-form-item label="是否审核通过：">&ndash;&gt;-->
          <!--            &lt;!&ndash;              {{ details.data.status }}&ndash;&gt;-->
          <!--            &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
          <!--            &lt;!&ndash;            <el-form-item label="审核日期：">&ndash;&gt;-->
          <!--            &lt;!&ndash;              {{ details.data.reviewDate }}&ndash;&gt;-->
          <!--            &lt;!&ndash;            </el-form-item>&ndash;&gt;-->
          <!--            <el-form-item label="作废状态：">-->
          <!--              {{ details.data.zfrq ? '作废' : '正常' }}-->
          <!--            </el-form-item>-->
          <!--          </el-form>-->
        </el-card>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "EnrollsDetails",
  props: ['details'],
  data() {
    return {
      srcList: [],
      dialogVisible: false
    }
  },
  watch: {
    details: {
      handler(newValue, oldValue) {
        // 这里不仅可以监听到对象引用的变化，还能监听到对象内部属性的变化
        this.dialogVisible = newValue.dialogVisible
        if (newValue.dialogVisible) {
          let imgArr = []
          imgArr.push(this.getImgToken(newValue.data.idCardZm))
          imgArr.push(this.getImgToken(newValue.data.idCardFm))
          imgArr.push(this.getImgToken(newValue.data.byz))
          imgArr.push(this.getImgToken(newValue.data.sbzm))
          imgArr.push(this.getImgToken(newValue.data.photo))
          imgArr.push(this.getImgToken(newValue.data.zzzm))
          this.srcList = imgArr
          console.log(this.srcList)
        }
        console.log('details:', newValue);
      },
      deep: true, // 开启深度监听
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    getImgToken(imgUrl) {
      let token = this.$Cookies.get('cyry_token')
      if (!imgUrl) {
        return imgUrl
      }
      let urlStr = this.$previewImg + imgUrl + '?token=' + token
      return urlStr
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  /deep/ .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .el-col {
      margin-bottom: 10px;
    }
  }

  /deep/ .el-image {
    .image-slot {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f7fa;
      color: #909399;

    }
  }
}

</style>