<template>
	<div>
		<headerBox :current="111"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div v-if="isPdf">
				<iframe style="height:800px" width="100%" :src="xyInfo.content"></iframe>
			</div>
			<div v-else class="content" v-html="xyInfo.content"></div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				xyInfo:{},
				isPdf:false
			}

		},
		mounted() {
			this.getSingleArticle();//隐私条款
			if(decodeURIComponent(this.$route.query.name)=='考试说明'){
				this.$refs["bannerChild"].locationList = ['从业人员',decodeURIComponent(this.$route.query.name)];
			}else{
				this.$refs["bannerChild"].locationList = [decodeURIComponent(this.$route.query.name)];
			}
		},
		methods: {
			/* ====================数据请求部分========================== */
			getSingleArticle() {
				getSingleArticle({
					catid: this.$route.query.catid
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.xyInfo = res.result;
						this.isPdf = this.xyInfo.content.search("pdf")!=-1;
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.content_box {
		font-size: 15px;
		margin: 80px 200px;

		.title {
			font-size: 22px;
			text-align: center;
		}
		
		.line_box{
			padding: 50px 0 5px;
			border-bottom: 1px solid #eee;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.time {
				font-size: 12px;
			}
			.share{
				display: flex;
				img{
					height: 25px;
					margin: 0 10px;
					cursor: pointer;
				}
				.qrcode{
					height: 100px;
				}
			}
		}

	}
</style>
