<template>
	<div class="box1">
		<div class="img" v-if="newList.length>0" @click="goDetail(leftInfo.id)">
			<img v-if="leftInfo.thumb" :src="leftInfo.thumb" alt="/" />
			<img v-else src="@/assets/images/defaultImg.jpg" alt="/" />
			<div class="img_text">
				<div class="title single">{{leftInfo.title}}</div>
				<div class="content">
					{{leftInfo.description}}
				</div>
			</div>
		</div>
		<div class="tab_box">
			<div class="tab_tit">
				<div v-for="(it,i) in tabList" :key="i" @mouseover="getTab(i)" @click="getTab(i)" :class="current==i?'selected':'noSelected'">
					{{it.name}}</div>
			</div>
			<div class="box1_height">
				<div v-if="newList.length>0">
					<div class="box1_content" v-for="(tt,ii) in newList" :key="ii" @click="goDetail(tt.id)">
						<div class="tag_line"></div>
						<div class="text single">{{tt.title}}</div>
						<div>{{tt.date}}</div>
					</div>
					<div @click="goDetail()" class="common_more" style="float: right;margin-top: 10px;">
						<span>更多</span>
						<span class="iconfont icon-gengduo"></span>
					</div>
				</div>
				<div class="common_no_more" style="height: 248px" v-if="newList.length==0">暂无内容~</div>
			</div>
		</div>
		<!-- <div class="tab_box" v-if="tabList.length>0">
			<Tabs :value="tabList[current].catid" @on-click="getTab">
				<Tab-pane v-for="(it,i) in tabList" :key="i" :label="it.name" :name="it.catid">
					<div class="box1_height">
						<div v-if="newList.length>0">
							<div class="box1_content" v-for="(tt,ii) in newList" :key="ii" @click="goDetail(it,tt.id)">
								<div class="tag_line"></div>
								<div class="text single">{{tt.title}}</div>
								<div>{{tt.date}}</div>
							</div>
							<div @click="goDetail(tabList[current])" class="common_more"
								style="float: right;margin-top: 10px;">
								<span>更多</span>
								<span class="iconfont icon-gengduo"></span>
							</div>
						</div>
						<div class="common_no_more" style="height: 248px" v-if="newList.length==0">暂无内容~</div>
					</div>
				</Tab-pane>
			</Tabs>
		</div> -->
	</div>
</template>
<script>
	import {
		getArticleDes
	} from '@/api/home/index'
	export default {
		props: {
			isBlue: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				current: 2,
				tabList: [{
						name: '行业要闻',
						catid: '6',
						url: 'news'
					},
					{
						name: '协会动态',
						catid: '91',
						url: 'news'
					},
					{
						name: '通知公告',
						catid: '10',
						url: 'news'
					},
					{
						name: '党建活动',
						catid: '153',
						url: 'party'
					},
				],
				newList: [],
				leftInfo: {}
			}
		},
		mounted() {
			let catid = this.tabList[this.current].catid;
			this.getArticleDes(catid);
		},
		methods: {
			getArticleDes(catid) {
				getArticleDes({
					catid,
					pageNo: 1,
					pageSize: 4
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.newList = res.result.records;
						// if(catid==10){//通知公告
						this.leftInfo = res.result.records[0];
						// }
					}
				})
			},
			// getTab(e) {
			// 	this.current = this.tabList.findIndex(v => v.catid == e)
			// 	this.getArticleDes(e);
			// },
			getTab(e) {
				this.current = e
				this.getArticleDes(this.tabList[e].catid);
			},
			goDetail(e) {
				let url = ''
				let query = {};
				let params = {};
				if (e) {
					url = 'news_detail'
					query = {
						id: e
					}
					params = {
						locationList: encodeURIComponent(JSON.stringify([this.tabList[this.current].name]))
					}
				} else {
					url = this.tabList[this.current].url
					query = {
						name: this.tabList[this.current].name
					}
				}
				this.$router.push({
					name: url,
					query: query,
					params: params
				});
			},
		}
	}
</script>
<style lang="scss" scoped>
	.box1 {
		display: flex;
		height: 300px;

		.img {
			width: 55%;
			margin-right: 70px;
			position: relative;
			cursor: pointer;
			overflow: hidden;

			img {
				width: 100%;
				// height: 100%;
				// object-fit: contain;
			}

			.img_text {
				width: 100%;
				position: absolute;
				bottom: 0;
				color: #fff;
				background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%);

				.title {
					margin: 10px 15px;
					font-size: 16px;
				}

				.content {
					margin: 10px 15px;
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					-moz-box-orient: vertical;
					-o-box-orient: vertical;
				}
			}
		}

		.tab_box {
			width: 45%;

			.tab_tit {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 17px;
				white-space: nowrap;

				div {
					// margin: 0 20px;
					cursor: pointer;
					padding: 5px 10px;
				}

				.selected {
					border: none;
					color: #fff;
					background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				}

				.noSelected:hover {
					color: #2D85E2;
				}
			}

			/deep/ .ivu-tabs {
				.ivu-tabs-nav-scroll {
					display: flex;
				}

				.ivu-tabs-nav-container {
					font-size: 15px !important;
					padding: 0;
				}

				.ivu-tabs-bar {
					border: none;
					margin: 0;
				}

				.ivu-tabs-nav .ivu-tabs-tab-active {
					border: none;
					color: #fff;
					background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				}

				.ivu-tabs-ink-bar {
					height: 0;
				}
			}

			.box1_height {

				// height: 248px;
				// overflow-y: scroll;
				.box1_content {
					font-size: 14px;
					border-bottom: 1px solid #eee;
					display: flex;
					align-items: center;
					white-space: nowrap;
					padding: 10px 5px;
					margin-top: 20px;
					cursor: pointer;

					.text {
						margin: 0 20px;
						font-size: 15px;
					}

					.tag_line {
						width: 4px;
						height: 19px;
						background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
					}
				}

				.box1_content:hover {
					background-color: #eee;
				}
			}
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.box1 {
			display: inline-block;
			width: 100%;

			.img {
				width: 100%;
				margin: 0;
				margin-bottom: 30px;
				height: 150px;
			}

			.tab_box {
				width: 100%;
			}
			.box1_content{
				margin-top: 0!important;
				padding: 15px 5px!important;
			}
		}
	}
</style>
