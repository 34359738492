<template>
	<div class="login_content">
		<div class="login_box">
			<div class="nav_box">
				<span @click="goHome" class="iconfont icon-fanhui"></span>
				<span @click="goHome">首页</span>
			</div>
			<img class="bg" src="@/assets/images/login/bg1.png" />
			<div class="hh_box">
				<div class="box">
					<img @click="goLogin(1)" src="@/assets/images/login/6.png"/>
					<img @click="goLogin(2)" src="@/assets/images/login/7.png"/>
					<img @click="goGl" src="@/assets/images/login/3.png"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getLinks
	} from '@/api/home/index'
	export default {
		data() {
			return {
				glUrl: '',
			}
		},
		mounted() {
			this.getLinks();
		},
		methods: {
			// 管理员登录
			getLinks() {
				getLinks({
					type: 9
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.glUrl = res.result.url;
					}
				})
			},
			goGl() {
				window.open(this.glUrl)
			},
			goLogin(e){
				if(e==1){
					this.$router.push('personalLogin')
				}else{
					this.$router.push('memberLogin')
				}
			},
			goHome(){
				this.$router.push('/');
			}
		}
	}
</script>
<style lang="scss" scoped>
	.login_content{
		overflow-y: scroll;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.nav_box{
		position: absolute;
		padding: 20px 50px;
		font-size: 20px;
		display: flex;
		align-items: center;
		z-index: 9999;
		span{
			cursor: pointer;
		}
		.icon-fanhui{
			font-size: 30px;
			margin-right: 10px;
		}
	}
	.login_box {
		position: relative;
		.bg {
			width: 100%;
			height: 100vh;
			object-fit: fill;
			vertical-align: top;
			min-width: 1100px;
		}

		.hh_box {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			min-width: 1100px;

			.box {
				width: 900px;
				display: flex;
				justify-content: center;
				margin-left: 100px;
				img{
					height: 100px;
					margin: 0 20px;
					cursor: pointer;
					box-shadow: 8px 8px 10px 0 rgba(153, 153, 153, 0.5);
					transition: all 0.22s ease-in;
					-webkit-transition: all 0.22s ease-in;
					-moz-transition: all 0.22s ease-in;
					-o-transition: all 0.22s ease-in;
					border-radius: 10px;
				}
				img:hover{
					transform: scale(1.08);
				}
			}
		}
	}
	/* 手机 */
	@media only screen and (max-width: 800px) {
		
	}
</style>
