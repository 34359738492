import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App'
import router from './router'
import './style/index.scss'; //公共样式
import "swiper/dist/css/swiper.css";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

import store from './store/store.js';
import Cookies from 'js-cookie'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import Vant from 'vant';
import 'vant/lib/index.css';
// 引入Viewer插件
import VueViewer, {directive as viewerDirective} from 'v-viewer';
// 引入Viewer插件的图片预览器的样式
import 'viewerjs/dist/viewer.css';

Vue.prototype.$Cookies = Cookies;

Vue.config.productionTip = false

Vue.use(iView);
Vue.use(ElementUI);

Vue.use(Vant);
// 使用Viewer图片预览器
Vue.use(VueViewer)
// 用于图片预览的指令方式调用 在元素上加上会处理元素下所有的图片,为图片添加点击事件,点击即可预览
Vue.directive('viewer', viewerDirective({
    debug: true
}));
NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: true, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from, next) => {
    // 每次切换页面时，调用进度条
    NProgress.start();
    next();
});

router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount("#app")
