<template>
	<div>
		<headerBox :current="111"></headerBox>
		<bannerBox ref="bannerChild" :bgNum="1"></bannerBox>
		<div class="content_box">
			<div class="right_box">
				<div class="item" v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
					<!-- <img v-if="it.thumb" style="width: 26%;" :src="it.thumb" /> -->
					<div>
						<div class="title single">{{it.title}}</div>
						<div class="time">{{it.date}}</div>
						<div class="article">
							{{it.description}}
						</div>
					</div>
				</div>
				<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
				<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo" :page-size="params.pageSize" show-total></Page>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				infoList: [],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 6
				},
				catid: '',
			}

		},
		mounted() {
			this.getArticle();
			this.$refs["bannerChild"].locationList = ['党建专栏','学习园地','学党史'];
		},
		methods: {
			getArticle(){
				getArticle({
					catid: 309,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			goDetail(it) {
				let url = 'news_detail';
				let query = {
					id:it.id,
				};
				let params = {
					locationList: encodeURIComponent(JSON.stringify(['党建专栏', '学习园地', '学党史'])),
					bgNum:1
				}
				this.$router.push({
					name: url,
					query: query,
					params: params
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 200px;

		.right_box {
			.item {
				border-bottom: 1px solid #eee;
				padding: 10px;
				padding-bottom: 30px;
				margin-bottom: 30px;
				cursor: pointer;
				transition: all 0.2s ease-in;
				-webkit-transition: all 0.2s ease-in;
				-moz-transition: all 0.2s ease-in;
				-o-transition: all 0.2s ease-in;
		
				img {
					width: 250px;
					height: 180px;
					margin-right: 30px;
				}
		
				.title {
					font-size: 20px;
					color: #000000;
					text-align: left;
				}
		
				.time {
					color: #575B66;
					font-size: 15px;
					padding: 10px 0;
				}
		
				.article {
					color: #9E9E9E;
					font-size: 13px;
				}
		
			}
		
			.item:hover {
				box-shadow: 5px 5px 20px #9E9E9E;
			}
		}
	}
</style>
