<template>
	<div class="container">
		<headerBox :current="4"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<Tabs value="name1">
				<Tab-pane label="会费管理" name="name1">
					<div class="cell_box">
						<div class="cell th">
							<div v-for="(it,i) in th" :key="i">{{it}}</div>
						</div>
						<div v-if="ryList.length>0" class="cell td" v-for="(it,i) in ryList" :key="i">
							<div>{{params.pageSize*params.pageNo-params.pageSize+i+1}}</div>
							<div>{{it.jksj}}</div>
							<div>{{it.jkje}}</div>
							<div>{{it.sfffzs==1?'已发放':'未发放'}}</div>
							<div>{{it.zsffrq}}</div>
							<div>{{it.jfnd}}</div>
							<div>{{it.yxksrq}}</div>
							<div>{{it.yxjzrq}}</div>
							<div>{{jfType.find(v=>v.value==it.jffs)?jfType.find(v=>v.value==it.jffs).label:it.jffs}}</div>
							<div>{{it.jffph}}</div>
							<div>{{it.jfjbr}}</div>
							<div>{{it.beizhu}}</div>
						</div>
						<div v-else class="common_excel_no_more">暂无内容~</div>
					</div>
					<Page v-if="ryList.length!=0" class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo" :page-size="params.pageSize"
						show-total></Page>
				</Tab-pane>
			</Tabs>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
		<vipLogin ref="vipbox"></vipLogin>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import vipLogin from '@/components/vip_login.vue'
	import {
		getJkxx
	} from '@/api/home/index'
	import {
		handleIdCard,
	} from '@/utils/util'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			vipLogin
		},
		data() {
			return {
				th: ['序号', '交款时间', '交款金额', '是否发放证书', '证书发放日期', '缴费年度', '会员生效日期','会员失效日期','缴费方式','缴费发票号','缴费经办人','备注'],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 10
				},
				form:{},
				ryList:[],
				jfType:[
					{ label: '现金交费', value: 1 },
					{ label: '对工帐户', value: 2 },
					{ label: '微信转帐', value: 3 },
					{ label: '支付宝转帐', value: 4 },
					{ label: '其它方式', value: 5 },
				]
			}

		},
		mounted() {
			this.getJkxx();
			this.$refs["bannerChild"].locationList=['会费管理'];
		},
		methods: {
			changePage(e) {
				this.params.pageNo = e;
				this.getJkxx();
			},
			getJkxx(){
				let xh_userInfo = {};
				if (this.$Cookies.get('xh_userInfo')) {
					xh_userInfo = JSON.parse(this.$Cookies.get('xh_userInfo'))
				}
				getJkxx({
					refid:xh_userInfo.user.applyuuid
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let info = res.result.records;
						this.ryList = info;
					} else if (res.code == 401) {
						this.$refs['vipbox'].showLogin = true;
					}
				})
			},
			toSearch(){
				this.params.pageNo = 1;
				this.getCyry();
			},
			toReset(){
				this.form = {};
				this.params.pageNo = 1;
				this.getCyry();
			},
			goDetail(it){
				this.$router.push({
					name:'people_detail',
					query:{
						certno:it.certno
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .ivu-tabs-nav .ivu-tabs-tab {
		width: 200px;
		text-align: center;
		font-size: 18px;
	}

	.content_box {
		margin: 80px auto;
		max-width: 1000px;
		
		.search_box{
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			>div{
				margin: 10px;
				white-space: nowrap;
				cursor: pointer;
			}
			.btn1{
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				padding: 5px 15px;
			}
			.btn2{
				background: #767676;
				color: #fff;
				padding: 5px 15px;
			}
		}

		.cell_box {
			margin-top: 20px;

			.cell {
				display: flex;
				width: 100%;
				white-space: nowrap;
				font-size: 14px;
				padding: 10px 0;
				text-align: center;
				border-left: 1px solid #eee;
				border-right: 1px solid #eee;

				>div:first-child {
					width: 8%;
				}
				
				>div:nth-child(2) {
					width: 10%;
				}
				
				>div:nth-child(3) {
					width: 10%;
				}

				>div:nth-child(4) {
					width: 10%;
				}

				>div:nth-child(5) {
					width: 10%;
				}

				>div:nth-child(6) {
					width: 10%;
				}

				>div:nth-child(7) {
					width: 10%;
				}
				>div:nth-child(8) {
					width: 10%;
				}
				>div:nth-child(9) {
					width: 10%;
				}
				>div:nth-child(10) {
					width: 10%;
				}
				>div:nth-child(11) {
					width: 10%;
				}
				>div:nth-child(12) {
					width: 10%;
				}
			}

			.th {
				background-color: #49B3FF;
			}
			.td{
				// cursor: pointer;
			}
			.td:hover{
				background-color: #eee;
			}
			.td:nth-child(odd){
				background-color: #D8D8D8;
			}
			.td:nth-child(even){
				border-bottom: 1px solid #eee;
			}
		}
	}
</style>
