<template>
	<div class="item1_box">
		<div class="common_search_box">
			<Input v-model="keywords" clearable @on-enter="init(current)" placeholder="请输入相关关键词">
			</Input>
			<div class="btn_box">
				<div @click="init(current)">搜索</div>
				<div @click="toReset">重置</div>
			</div>
		</div>
		<div v-if="current==0">
			<div v-for="(item,index) in tabList" :key="index" v-if="index!=0">
				<div class="flex_tit">
					<div class="common_box_tit">
						<div>{{item.title}}</div>
						<div class="bom_line"></div>
					</div>
					<div class="common_more" @click="goMore(index)">
						<span>更多</span>
						<span class="iconfont icon-gengduo"></span>
					</div>
				</div>
				<div class="fg_content" v-if="item.infoList.length>0">
					<div v-for="(it,i) in item.infoList" :key="i" @click="goDetail(it)">
						<div class="single name">{{it.title}}</div>
						<div>{{it.date}}</div>
					</div>
				</div>
				<div class="common_no_more" style="height: 248px" v-if="item.infoList.length==0">暂无内容~</div>
			</div>
		</div>
		<div v-else>
			<div class="flex_tit">
				<div class="common_box_tit">
					<div>{{tabList[current].title}}</div>
					<div class="bom_line"></div>
				</div>
				<!-- <div class="common_more">
					<span>更多</span>
					<span class="iconfont icon-gengduo"></span>
				</div> -->
			</div>
			<div class="fg_content" v-if="infoList.length>0">
				<div v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
					<div class="single name">{{it.title}}</div>
					<div>{{it.date}}</div>
				</div>
				<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total"
					:current="params.pageNo" :page-size="params.pageSize" show-total></Page>
			</div>
			<div class="common_no_more" style="height: 248px" v-if="infoList.length==0">暂无内容~</div>
		</div>
		<!-- <div class="box1" v-if="infoList.length>0">
			<div v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
				<img style="width: 25%;" v-if="it.thumb" :src="it.thumb" />
				<div :style="{width: it.thumb?'70%':'100%'}">
					<div class="title single">{{it.title}}</div>
					<div class="time">{{it.date}}</div>
					<div class="article">
						{{it.description}}
					</div>
				</div>
			</div>
			
		</div>
		<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div> -->
		<vipLogin ref="vipbox"></vipLogin>
	</div>
</template>

<script>
	import vipLogin from '@/components/vip_login.vue'
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		components: {
			vipLogin
		},
		props: {
			
		},
		data() {
			return {
				current:0,
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 10
				},
				keywords:'',
				infoList: [],
				locationList:[],
				tabList:[
					{
						title: '全部',
						type: 0,
						infoList:[]
					},
					{
						title: '国家法律',
						type: 19,
						infoList:[]
					},
					{
						title: '国家行政法规',
						type: 20,
						infoList:[]
					},
					{
						title: '国务院规范性',
						type: 97,
						infoList:[]
					},
					{
						title: '住建部规章及规范性文件',
						type: 136,
						infoList:[]
					},
					{
						title: '其他部门规章及规范性文件',
						type: 155,
						infoList:[]
					},
					// {
					// 	title: '贵州省有关规性文件',
					// 	type: 156
					// }
				],
			}

		},
		watch: {
			current(val){
				this.init(val)
			},
		},
		mounted() {
			this.keywords = this.$route.query.keywords;
			this.init(this.current)
			// this.getArticle()
			// console.log(this.current)
		},
		methods: {
			init(val){
				if(val!=0){
					this.params.pageNo = 1;
					this.getArticle();
				}else{
					this.tabList.map(v=>{
						getArticle({
							catid: v.type,
							pageNo: 1,
							pageSize: 6,
							keywords:this.keywords
						}).then(res => {
							if (res.code == 200 || res.code == 0) {
								v.infoList = res.result.records;
							}else if(res.code == 401){
								this.$refs['vipbox'].showLogin = true;
								this.$refs['vipbox'].hasLoad = true;
							} else {
								v.infoList = [];
							}
						})
					})
				}
			},
			getArticle() {
				let type = this.tabList[this.current].type;
				getArticle({
					catid: type,
					...this.params,
					keywords:this.keywords
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					}else if(res.code == 401){
						this.$refs['vipbox'].showLogin = true;
						this.$refs['vipbox'].hasLoad = true;
					} else {
						this.infoList = [];
					}
				})
			},
			goDetail(it){
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(['政策法规',this.tabList[this.current].title]))
					}
				});
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			toReset(){
				this.keywords ='';
				this.params.pageNo = 1;
				this.init(this.current)
			},
			goMore(i){
				this.current = i;
				this.$emit('change',i)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item1_box{
		display: flex;
		flex-direction: column;
	}
	.common_search_box{
		margin-bottom: 20px;
	}
	.common_search_box /deep/.ivu-input{
		border-radius: 0 !important;
		height: 50px !important;
		line-height: 50px !important;
		width: 100%!important;
		margin-bottom: 10px;
		font-size: 14px !important;
		vertical-align: top;
	}
	
	// .box1 {
	// 	margin-top: 20px;
	// 	>div {
	// 		border-bottom: 1px solid #eee;
	// 		padding: 10px;
	// 		padding-bottom: 30px;
	// 		margin-bottom: 30px;
	// 		cursor: pointer;
	// 		transition: all 0.2s ease-in;
	// 		-webkit-transition: all 0.2s ease-in;
	// 		-moz-transition: all 0.2s ease-in;
	// 		-o-transition: all 0.2s ease-in;
	// 		display: flex;
		
	// 		img {
	// 			width: 250px;
	// 			height: 180px;
	// 			margin-right: 30px;
	// 		}
		
	// 		.title {
	// 			font-size: 20px;
	// 			color: #000000;
	// 			text-align: left;
	// 		}
		
	// 		.time {
	// 			color: #575B66;
	// 			font-size: 15px;
	// 			padding: 10px 0;
	// 		}
		
	// 		.article {
	// 			color: #9E9E9E;
	// 			font-size: 13px;
	// 		}
	// 	}
		
	// 	>div:hover {
	// 		// background-color: #eee;
	// 		// padding: 15px 10px;
	// 		box-shadow: 5px 5px 20px #9E9E9E;
	// 	}
	// }
	.flex_tit{
		display: flex;
		justify-content: space-between;
	}
	.common_box_tit{
		font-size: 18px!important;
		.bom_line{
			width: 40px;
			height: 4px;
			margin-top: 5px;
		}
	}
	.fg_content{
		margin: 10px 0;
		>div{
			font-size: 14px;
			border-bottom: 1px solid #eee;
			display: flex;
			align-items: center;
			white-space: nowrap;
			padding: 10px 5px;
			cursor: pointer;
			.name{
				padding-right: 20px;
			}
		}
		>div:hover{
			background-color: #eee;
		}
	}
	.common_no_more{
		margin: 10px 0;
	}
	
</style>
