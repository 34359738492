import request from '@/utils/request'
import Cookies from 'js-cookie'

// 关于协会、会员中心
export function getSingleArticle(params) {
    return request({
        url: '/jeecg-boot/index/singleArticle',
        method: 'GET',
        params: params
    })
}

// 协会荣誉，学习资料
export function getResourcese(params) {
    return request({
        url: '/jeecg-boot/index/resources',
        method: 'GET',
        params: params
    })
}

// 个人中心：通知
export function getV9News(params) {
    return request({
        url: '/jeecg-boot/vip/v9News',
        method: 'GET',
        params: {
            token: Cookies.get('xh_userInfo') ? JSON.parse(Cookies.get('xh_userInfo')).token : '',
            ...params,
        }
    })
}

// 培训课程列表
export function courseList(params) {
    return request({
        url: '/jeecg-boot/index/courseList',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 个人信息 课程中心
export function cyryCourseList(params) {
    return request({
        url: '/jeecg-boot/web/cyryCourseList',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

export function courseInfo(params) {
    return request({
        url: '/jeecg-boot/index/courseInfo',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

export function studyLock(params) {
    return request({
        url: '/jeecg-boot/index/studyLock',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

export function studyUnLock(params) {
    return request({
        url: '/jeecg-boot/index/studyUnlock',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 报名
export function courseEnroll(params) {
    return request({
        url: '/jeecg-boot/index/courseEnroll',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 课程学习记录
export function courseStudyRecord(params) {
    return request({
        url: '/jeecg-boot/index/courseStudyRecord',
        method: 'POST',
        data: {
            ...params,
        }
    })
}

// 课程进度
export function queryCourseRecord(params) {
    return request({
        url: '/jeecg-boot/index/queryCourseRecord',
        method: 'POST',
        data: {
            ...params,
        }
    })
}

// 报名记录分页查询
export function enrollInfo(params) {
    return request({
        url: '/jeecg-boot/web/course/enroll',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 学习记录详情
export function studyInfo(params) {
    return request({
        url: '/jeecg-boot/web/course/total',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 下载学习记录
export function studyDownload(params) {
    return request({
        url: '/jeecg-boot/web/course/total/export',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 学习记录分页查询
export function recordInfo(params) {
    return request({
        url: '/jeecg-boot/web/course/record',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 检测人员的单位是否是会员
export function checkMember(params) {
    return request({
        url: '/jeecg-boot/index/checkMember',
        method: 'GET',
        params: {
            ...params,
        }
    })
}

// 退出登录
export function loginoutCyry(params) {
    return request({
        url: '/jeecg-boot/index/loginout',
        method: 'GET',
        params: params,
    })
}


export var getClientSize = function () {
    var width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    var height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
    return {width: width, height: height};
};

let scrollBarWidth;
export var getScrollBarWidth = function () {
    if (scrollBarWidth !== undefined)
        return scrollBarWidth;
    var outer = document.createElement('div');
    outer.className = 'el-scrollbar__wrap';
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.position = 'absolute';
    outer.style.top = '-9999px';
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    var inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);
    scrollBarWidth = widthNoScroll - widthWithScroll;
    return scrollBarWidth;
};
