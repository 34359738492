<template>
	<div>
		<Modal v-model="showLogin" width="500" class="model_box" :mask-closable="false">
			<p slot="header" style="color:#2B4BFA;text-align:center;font-size: 16px;">
				<Icon type="information-circled"></Icon>
			</p>
			<div class="login_box">
				<div class="login_content">
					<div>
						<div class="tit_name">证书编号:</div>
						<Input class="input" v-model="form.certno" disabled placeholder="请输入证书编号" key="xm">
						</Input>
					</div>
					<div>
						<div class="tit_name">注销原因:</div>
						<RadioGroup class="input" v-model="form.type">
							<Radio v-for="(it,i) in typeList" :key="i" :label="it.value">
								<span>{{it.label}}</span>
							</Radio>
						</RadioGroup>
					</div>
					<div v-if="form.type==2">
						<div class="tit_name">其他原因:</div>
						<Input class="input" v-model="form.otherReason" type="textarea"
							:autosize="{minRows: 3,maxRows: 5}" placeholder="请输入其他原因内容"></Input>
					</div>

					<Button class="btn" type="primary" :loading="loading" long @click="submit">提交</Button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		applyCertCancel,
	} from '@/api/other'
	export default {
		data() {
			return {
				typeList: [{
						value: 1,
						label: '单位变更'
					},
					{
						value: 2,
						label: '其他原因'
					}
				],
				showLogin: false,
				form: {},
				loading: false,
			}
		},
		mounted() {

		},
		methods: {
			submit() {
				if (!this.form.type) {
					this.$Message.info('请选择注销原因');
					return
				}
				
				if (this.form.type==2&&!this.form.otherReason) {
					this.$Message.info('请输入其他原因内容');
					return
				}
				
				this.loading = true;
				applyCertCancel({
					...this.form,
					token: this.$route.query.token
				}).then(res => {
					this.loading = false;
					if (res.code == 200 || res.code == 0) {
						this.$Modal.success({
							title: '提示',
							content: '注销成功！',
							onOk: () => {
								this.showLogin = false;
								this.$router.push('certificateLogin')
							},
						});
					}else{
						this.$Modal.error({
							title: '提示',
							content: res.message,
							onOk: () => {},
						});
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.model_box {
		/deep/.ivu-modal-body {
			padding: 0;
		}

		/deep/.ivu-modal-footer,
		/deep/.ivu-modal-header {
			padding: 30px 50px;
			display: none;
		}

		.login_box {
			position: relative;

			.bg {
				width: 100%;
				object-fit: fill;
				vertical-align: top;
			}

			.login_content {
				// position: absolute;
				// top: 0;
				padding: 50px 50px;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;

				>div {
					display: flex;
					align-items: center;
					margin-bottom: 25px;
				}

				img {
					height: 50px;
					margin-bottom: -5px;
				}

				.tit_name {
					font-size: 16px;
					white-space: nowrap;
					margin-right: 10px;
				}
			}
		}

		.input {
			width: 100%;
			// margin-bottom: 22px;

			::v-deep .ivu-input-group-append {
				background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
				color: #fff;
				width: 100px;
				cursor: pointer;
				border: none;
				font-size: 14px;
			}

			::v-deep .ivu-input-group-prepend {
				background-color: #D0E8FF;
				width: 160px;
				font-size: 12px;
				text-align-last: justify;
				padding: 0 20px;
				color: #333;
				font-weight: bold;
			}

			/deep/.ivu-radio-wrapper {
				font-weight: normal;
				font-size: 14px;
				margin-right: 20px;
			}
		}

		.check_box {
			/deep/ .ivu-checkbox-inner {
				margin-right: 5px;
			}
		}

		.flex_mm {
			margin-bottom: 20px;
			color: #666;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;

			.zhmm {
				cursor: pointer;
				font-size: 13px;
				display: flex;
				align-items: center;

				.iconfont {
					margin-right: 3px;
				}
			}

			.zhmm:hover {
				color: #2B4BFA;
			}
		}

		.btn {
			height: 40px;
			font-size: 16px;
			letter-spacing: 30px;
			padding-left: 40px;
			background: #2B4BFA;
			box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
			border: none;
			margin-top: 10px;
		}
	}

	.xy_box {
		height: 400px;
		overflow: scroll;
		// transform: scale(0.9);
	}

	/deep/ .ivu-btn-text {
		display: none;
	}

	/deep/.ivu-input {
		height: 35px;
		font-size: 13px;
	}
</style>
