<template>
  <div class="details">
    <el-dialog
        :before-close="handleClose"
        :title="details.title"
        :visible.sync="dialogVisible"
        width="70%">
      <div>
        <el-card>
          <el-table :cell-style="{padding: 5}" :data="tableData">
            <el-table-column align="center" label="序号" show-overflow-tooltip type="index"
                             width="60px"></el-table-column>
            <el-table-column align="center" label="课程名称" prop="courseName" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="课程学时" prop="classHour" show-overflow-tooltip width="80px">
              <template #default="scope">
                {{ scope.row.classHour ? scope.row.classHour + '学时' : '' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="学习开始时间" prop="startTime" show-overflow-tooltip
                             width="120px"></el-table-column>
            <el-table-column align="center" label="学习结束时间" prop="endTime" show-overflow-tooltip
                             width="120px"></el-table-column>
            <el-table-column align="center" label="已学习时间" prop="studyTimes" show-overflow-tooltip>
              <template #default="scope">
                {{ tosfm(scope.row.studyTimes) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否学习完成" prop="studyFinish" show-overflow-tooltip width="150px">
              <template #default="scope">
                {{ scope.row.studyEnd == '0' ? '是' : scope.row.studyEnd == '1' ? '否' : '' }}
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
                :page-size="10"
                :page-sizes="[10, 50, 100, 200]"
                :total="total"
                layout="total, sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </el-card>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {recordInfo} from "@/api/home/guild";

export default {
  name: "EnrollsDetails",
  props: ['details'],
  data() {
    return {
      srcList: [],
      dialogVisible: false,
      tableData: [],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10
      },
    }
  },
  watch: {
    details: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue.dialogVisible
        if (newValue.dialogVisible) {
          this.getStudyInfo()
        }
        console.log('details:', newValue);
      },
      deep: true, // 开启深度监听
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pageNo = 1
      this.params.pageSize = val
      this.getStudyInfo()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.pageNo = val
      this.getStudyInfo()
    },
    getStudyInfo() {
      recordInfo({
        courseId: this.details.data.courseId,
        ...this.params,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        console.log('res', res)
        if (res.code == 200) {
          console.log('res.result', res.result)
          this.tableData = res.result.records || []
          this.total = res.result.total
        }
      })
    },
    tosfm(time) {
      // 转换为式分秒
      let h = parseInt(time / 60 / 60 % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt(time / 60 % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(time % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      let timeStr = ''
      let timeArr = [h, m, s]
      if (Array.isArray(timeArr) && timeArr.length == 3) {
        timeStr = timeArr[0] + ':' + timeArr[1] + ':' + timeArr[2]
      }
      return timeStr
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  /deep/ .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .el-col {
      margin-bottom: 10px;
    }
  }

  .pagination {
    margin-top: 30px;
    text-align: right;
  }
}

</style>