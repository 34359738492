<!-- 协会简介 -->
<template>
	<div class="box1">
		<div v-if="info" v-html="info.content"></div>
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		components: {

		},
		data() {
			return {
				info:{content:''}
			}

		},
		mounted() {
			this.getSingleArticle()
		},
		methods: {
			getSingleArticle(){
				getSingleArticle({
					catid: 204,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box1{
		font-size: 14px;
	}
</style>
