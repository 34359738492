import Vue from 'vue'

import VueRouter from 'vue-router'
import index from '../views/home/index.vue'
import login from '../views/login/login.vue'
import memberLogin from '../views/login/member_login.vue'
import certificateLogin from '../views/login/certificate_login.vue'
import personalLogin from '../views/login/personal_login.vue'
import news from '../views/news/index.vue'
import newsDetail from '../views/news/detail.vue'
import search from '../views/news/search.vue'
import agreement from '../views/news/agreement.vue'
import vipList from '../views/vip/vipList.vue'
import vipInfo from '../views/vip/vipInfo.vue'
import vip from '../views/vip/index.vue'
import party from '../views/party/index.vue'
import partyDetail from '../views/party/detail.vue'
import partyList from '../views/party/list.vue'
import engineering from '../views/engineering/index.vue'
import people from '../views/people/index.vue'
import peopleDetail from '../views/people/detail.vue'

import tax from '../views/people/tax.vue'
import basic from '../views/people/basic.vue'
import trade from '../views/trade/index.vue'
// import subject from '../views/subject/index.vue'
import gyjz from '../views/subject/gyjz.vue'
import subject from '../views/subject/subject.vue'
import video from '../views/subject/video.vue'
import subjectDetail from '../views/subject/subjectDetail.vue'
import ppt from '../views/subject/ppt.vue'
import pdf from '../views/subject/pdf.vue'
import QandA from '../views/subject/QandA.vue'
import policy from '../views/policy/index.vue'
import member from '../views/member/index.vue'
import apply from '../views/member/apply.vue'
import personal from '../views/member/personal.vue'
import guild from '../views/guild/index.vue'
import user from '../views/user/index.vue'
import cyry from '../views/cyry/index.vue'
import xmfzrDetail from "@/views/people/xmfzrDetail.vue";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '/home/index',
    component: index
},
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/memberLogin',
        name: 'memberLogin',
        component: memberLogin
    },
    {
        path: '/certificateLogin',
        name: 'certificateLogin',
        component: certificateLogin
    },
    {
        path: '/personalLogin',
        name: 'personalLogin',
        component: personalLogin
    },
    {
        path: '/news',
        name: 'news',
        component: news
    },
    {
        path: '/news_detail',
        name: 'news_detail',
        component: newsDetail
    },
    {
        path: '/search',
        name: 'search',
        component: search
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: agreement
    },
    {
        path: '/vip',
        name: 'vip',
        component: vip
    },
    {
        path: '/vipList',
        name: 'vipList',
        component: vipList
    },
    {
        path: '/vipInfo',
        name: 'vipInfo',
        component: vipInfo
    },
    {
        path: '/party',
        name: 'party',
        component: party
    },
    {
        path: '/party_detail',
        name: 'party_detail',
        component: partyDetail
    },
    {
        path: '/party_list',
        name: 'party_list',
        component: partyList
    },
    {
        path: '/engineering',
        name: 'engineering',
        component: engineering
    },
    {
        path: '/people',
        name: 'people',
        component: people
    },
    {
        path: '/people_detail',
        name: 'people_detail',
        component: peopleDetail
    },
    {
        path: '/xmfzrDetail',
        name: 'xmfzrDetail',
        component: xmfzrDetail
    },
    {
        path: '/tax',
        name: 'tax',
        component: tax
    },
    {
        path: '/basic',
        name: 'basic',
        component: basic
    },
    {
        path: '/trade',
        name: 'trade',
        component: trade
    },
    {
        path: '/subject',
        name: 'subject',
        component: subject
    },
    {
        path: '/gyjz',
        name: 'gyjz',
        component: gyjz
    },
    {
        path: '/video',
        name: 'video',
        component: video
    },
    {
        path: '/subjectDetail',
        name: 'subjectDetail',
        component: subjectDetail
    },
    {
        path: '/ppt',
        name: 'ppt',
        component: ppt
    },
    {
        path: '/pdf',
        name: 'pdf',
        component: pdf
    },
    {
        path: '/QandA',
        name: 'QandA',
        component: QandA
    },
    {
        path: '/policy',
        name: 'policy',
        component: policy
    },
    {
        path: '/member',
        name: 'member',
        component: member
    },
    {
        path: '/apply',
        name: 'apply',
        component: apply
    },
    {
        path: '/personal',
        name: 'personal',
        component: personal
    },
    {
        path: '/guild',
        name: 'guild',
        component: guild
    },
    {
        path: '/user',
        name: 'user',
        component: user
    },
    {
        path: '/cyry',
        name: 'cyry',
        component: cyry
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
export default router
