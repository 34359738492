<template>
  <div class="layout">
    <Layout>
      <Header>
        <Menu mode="horizontal" theme="dark">
          <div class="layout-logo">
            <div class="nav_box">
              <span class="iconfont icon-fanhui" @click="goBack"></span>
              <span @click="goBack">返回</span>
            </div>
            <img src="@/assets/logo.png" @click="goHome">

          </div>

          <div class="layout-nav">
            <div class="goStudy">
              <a @click="goStudy">考试系统</a>
            </div>
            <Poptip v-if="cyry_user.name" trigger="hover">
              <div slot="content" class="userPop">
                <div class="outBtn" @click="goLogout">退出登录</div>
              </div>
              <div class="user">
                <span class="iconfont icon-chazhaoyonghu"></span>
                <div>{{ cyry_user.name }}</div>
              </div>
            </Poptip>
            <div v-else class="user" @click="$refs['vipbox'].showLogin = true;">
              <span class="iconfont icon-chazhaoyonghu"></span>
              <div>用户登录</div>
            </div>
          </div>
        </Menu>
      </Header>
      <Layout>
        <Sider :style="{background: '#fff'}" hide-trigger>
          <Menu ref="side_menu" :active-name="activeName" :open-names="openNames" theme="light" width="auto"
                @on-select="chooseMenu">
            <div v-for="(it,i) in menuList" :key="i">
              <Submenu v-if="it.option" :name="it.name">
                <template slot="title">
                  <span :class="[it.icon]" class="iconfont"></span>
                  {{ it.name }}
                </template>
                <MenuItem v-for="(item,index) in it.option" :key="index" :name="item.name">{{ item.name }}
                </MenuItem>
              </Submenu>
              <MenuItem v-else :name="it.name">
                <span :class="[it.icon]" class="iconfont"></span>
                {{ it.name }}
              </MenuItem>
            </div>
          </Menu>
        </Sider>
        <div :style="{height:contentHeight}" style="overflow-y: scroll;width: 85%;">
          <Layout v-if="menuObj.name == '基础信息'" :style="{padding:'10px'}">
            <basicInfo></basicInfo>
          </Layout>
          <Layout v-if="menuObj.name == '课程中心'" :style="{padding:'10px'}">
            <course></course>
          </Layout>
          <Layout v-if="menuObj.name=='报名记录'" :style="{padding: '10px'}">
            <Card>
              <bm :params="{pageNo: 1,pageSize: 5}"></bm>
            </Card>
          </Layout>
          <Layout v-if="menuObj.name=='培训记录'" :style="{padding: '10px'}">
            <Card>
              <xx :params="{pageNo: 1,pageSize: 5}"></xx>
            </Card>
          </Layout>
          <Layout v-if="menuObj.name=='证书情况'" :style="{padding: '10px'}">
            <Card>
              <certificate :params="{pageNo: 1,pageSize: 5}"></certificate>
            </Card>
          </Layout>
        </div>
      </Layout>
    </Layout>
    <upPwd ref="pwdbox"></upPwd>
    <vipLogin ref="vipbox"></vipLogin>
    <!-- <footerBox ref="footerBox"></footerBox> -->
    <Back-top :bottom="120" :height="100">
      <div class="iconfont icon-shangjiantou"></div>
    </Back-top>
  </div>
</template>
<script>
import footerBox from '@/components/footer.vue'
import upPwd from '@/components/upPwd.vue'
import vipLogin from '@/components/vip_login.vue'

import bm from './components/bm/index.vue'
import xx from './components/xx/index.vue'
import basicInfo from './components/basicInfo/index.vue'
import course from './components/course/index.vue'
import certificate from './components/certificate/index.vue'
import {loginoutCyry} from '@/api/home/guild'
import {toKsxt} from "@/api/home/basicInfo";

export default {
  components: {
    footerBox,
    upPwd,
    vipLogin,
    bm,
    xx,
    basicInfo,
    course,
    certificate
  },
  data() {
    return {
      activeName: '基础信息',
      contentHeight: '',
      menuList: [{
        name: '基础信息',
        icon: 'icon-shouye',
        url: '/'
      }, {
        name: '课程中心',
        icon: 'icon-shouye',
        url: '/'
      }, {
        name: '报名记录',
        icon: 'icon-shouye',
        url: '/'
      },
        {
          name: '培训记录',
          icon: 'icon-tongzhi',
          url: 'news',
          params: {
            name: encodeURIComponent('通知公告')
          }
        },
        {
          name: '证书情况',
          icon: 'icon-shouye',
          url: '/'
        }
      ],
      cyry_user: {
        name: ''
      },
      newList: [],
      ryList: [],
      hyList: [],
      menuObj: {
        name: '基础信息',
      },
      openNames: []
    }
  },
  watch: {
    openNames() {
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      })
    }
  },
  mounted() {
    if (this.$Cookies.get('cyry_user')) {
      this.cyry_user = JSON.parse(this.$Cookies.get('cyry_user'))
    }
    // const height = window.innerHeight;
    this.contentHeight = `calc(${100}vh - 64px - 0px)`;
  },
  methods: {
    /* ====================数据请求部分========================== */

    /* ====================页面交互部分========================== */
    goHome() {
      this.$router.push('/')
    },
    goBack() {
      this.$router.back()
    },
    // 跳转到学习考试系统
    goStudy() {
      toKsxt({token: this.$Cookies.get('cyry_token')}).then(res => {
        if (res) {
          if (res.code == 200) {
            let token = res.result.token || ''
            let url =
                `${this.$ksHost}/#/home?token=${token}`;
            window.open(url, '_blank')
          }
        } else {
          this.$message(res.message);
        }
      }).catch(err => {
        this.$message(err);
      })

    },
    updatePwd() {
      // console.log(this.$refs['pwdbox'])
      this.$refs['pwdbox'].showLogin = true;
    },
    changeMenu(name) {
      this.activeName = name;
      this.menuList.map(v => {
        if (v.name == name) {
          this.openNames = [v.name]
        } else {
          if (v.option) {
            v.option.map(k => {
              if (k.name == name) {
                this.openNames = [v.name];
              }
            })
          }
        }
      })
      this.menuObj = {
        name: name
      };
    },
    chooseMenu(e) {
      let obj = {};
      this.menuList.map(v => {
        if (v.name == e) {
          obj = v
        } else {
          if (v.option) {
            v.option.map(k => {
              if (k.name == e) {
                obj = k
              }
            })
          }
        }
      })
      console.log("=======obj========", obj)
      this.menuObj = obj;
      if (this.menuObj.url && this.menuObj.url == 'zcfg') {
        this.$nextTick(() => {
          console.log("===========zcfgBox=============", this.$refs['zcfgBox'])
          this.$refs['zcfgBox'].typeName = this.menuObj.name
        })
      }

      if (obj.name == '网站首页') {
        this.$router.push('/')
      }
    },
    goLogout() {
      loginoutCyry({
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200 || res.code == 0 || res.code == 401) {
          this.$Modal.confirm({
            title: '提示',
            content: '确定退出登录吗？',
            onOk: () => {
              this.$Message.success('退出成功！');
              this.$Cookies.remove('cyry_user');
              this.$Cookies.remove('cyry_token');
              this.goHome();
              location.reload()
            },
          });
        }
      })
    },
  }

}
</script>
<style lang="scss" scoped>
/deep/ .ivu-menu-horizontal {
  height: 64px;
  line-height: 64px;
}

/deep/ .ivu-layout-header {
  background-color: #4381E6;

  .ivu-menu-dark {
    background-color: #4381E6;
  }
}

/deep/ .ivu-card {
  margin: 8px;
}

.layout {
  border: 1px solid #d7dde4;
  border-radius: 4px;
  background: #f5f7f9;
  min-width: 1400px;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.layout-logo {
  border-radius: 3px;
  float: left;
  position: relative;
  top: 0;
  left: 0px;
  display: flex;
  align-items: center;
  height: 100%;

  img {
    height: 50px;
    cursor: pointer;
    vertical-align: top;
  }

  .nav_box {
    color: #fff;
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      cursor: pointer;
    }

    .icon-fanhui {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

/deep/ .ivu-tooltip-inner {
  background-color: #fff;
  color: #011243;
}

/deep/ .ivu-tooltip-arrow {
  border-bottom-color: #fff
}

.layout-nav {
  float: right;
  margin: 0 auto;
  margin-right: 50px;
  color: #fff;
  font-size: 15px;
  display: flex;

  .goStudy {
    a {
      color: #ffffff;
    }
  }
}

.userPop {
  font-size: 15px;
  text-align: center;

  > div {
    padding: 8px 0;
    cursor: pointer;
    color: #666;
  }

  > div:hover {
    // color: #2D85E2;
  }

  .outBtn {
    border-top: 1px solid #eee;
  }
}

.user {
  display: flex;
  align-items: center;
  margin-left: 30px;
  font-size: 15px;
  cursor: pointer;
  white-space: nowrap;

  .icon-chazhaoyonghu {
    margin-right: 10px;
  }
}

.layout-footer-center {
  text-align: center;
}

.user_box {
  display: flex;
  align-items: center;
  font-size: 16px;

  > div {
    display: flex;
    align-items: center;
    width: 33.33%;
    height: 100%;

    .avater {
      height: 80px;

      img {
        height: 100%;
        vertical-align: top;
      }
    }

    .user_info {
      margin-left: 20px;
    }

    .xgmm {
      font-size: 16px;
      background-color: #4381E6;
      color: #fff;
      padding: 8px 20px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

/deep/ .ivu-col {
  height: 100%;
}

/deep/ .ivu-menu {
  font-size: 16px;
}

/deep/ .ivu-menu-item {
  font-size: 16px;
}

.box1_height {
  // margin-top: -15px;

  .box1_content {
    font-size: 14px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 15px 5px;
    // margin-top: 20px;
    cursor: pointer;

    .text {
      margin: 0 20px;
      font-size: 15px;
    }

    .tag_line {
      width: 4px;
      height: 19px;
      background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
    }
  }

  .box1_content:hover {
    background-color: #eee;
  }
}

.ry_box {
  margin-top: 10px;

  .cell {
    display: flex;
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    > div:first-child {
      width: 10%;
    }

    > div:nth-child(2) {
      width: 15%;
    }

    > div:nth-child(3) {
      width: 25%;
    }

    > div:nth-child(4) {
      width: 40%;
    }
  }

  .cellHY {
    > div:first-child {
      width: 10%;
    }

    > div:nth-child(2) {
      width: 30%;
    }

    > div:nth-child(3) {
      width: 60%;
    }
  }

  .th {
    background-color: #49B3FF;
    color: #fff;
  }

  .td {
    cursor: pointer;
  }

  .td:hover {
    background-color: #eee;
  }

  .td:nth-child(odd) {
    background-color: #D8D8D8;
  }

  .td:nth-child(even) {
    border-bottom: 1px solid #eee;
  }
}
</style>
