<template>
  <div class="details">
    <el-dialog
        :before-close="handleClose"
        :title="details.title"
        :visible.sync="dialogVisible"
        width="70%">
      <div>
        <el-card>
          <el-form label-position="right" label-width="220px">

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="姓名：">
                  {{ details.data.certName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="性别：">
                  {{ details.data.certSex }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公司：">
                  {{ details.data.certCompany }}
                </el-form-item>
              </el-col>

              <el-col :span="12">

                <el-form-item label="证书编号：">
                  {{ details.data.certNo }}
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item label="生效日期：">
                  {{ details.data.yxqq }}
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item label="失效日期：">
                  {{ details.data.yxqz }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否取消：">
                  {{ details.data.iscancel == '1' ? '是' : details.data.iscancel == '0' ? '否' : '' }}
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item label="违规取消日期：">
                  {{ details.data.cancelDate }}
                </el-form-item>
              </el-col>
              <!--              <el-col :span="12">-->

              <!--                <el-form-item label="取消人：">-->
              <!--                  {{ details.data.cancelPerson }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="12">-->

              <!--                <el-form-item label="录入日期：">-->
              <!--                  {{ details.data.reviewDate }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="12">-->

              <!--                <el-form-item label="录入人员：">-->
              <!--                  {{ details.data.lrry }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="12">-->

              <!--                <el-form-item label="修改日期：">-->
              <!--                  {{ details.data.updatetime }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="12">-->
              <!--                <el-form-item label="是否因违规取消：">-->
              <!--                  {{ details.data.ifviolation == '1' ? '是' : details.data.ifviolation == '0' ? '否' : '' }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="12">-->
              <!--                <el-form-item v-if="details.data.ifviolation == '1'" label="违规取消原因：">-->
              <!--                  {{ details.data.violationreasondm }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :span="24">-->
              <!--                <el-form-item v-if="details.data.ifviolation == '1'" label="其它违规取消原因：">-->
              <!--                  {{ details.data.othviolationreason }}-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :span="12">
                <el-form-item label="证书图片：">
                  <el-image
                      ref="toast"
                      :preview-src-list="srcList"
                      :src="$previewImg + details.data.resourceUrl + `?token=${$Cookies.get('cyry_token')}`"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                  <div>
                    <el-button type="text" @click="downloadImage(details.data.resourceUrl)">下载</el-button>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="证书二维码：">
                  <el-image
                      :preview-src-list="srcList"
                      :src="details.data.qrCodePath"
                      style="width: 100px; height: 100px">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" style="font-size: 24px"></i>
                    </div>
                  </el-image>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "EnrollsDetails",
  props: ['details'],
  data() {
    return {
      srcList: [],
      dialogVisible: false
    }
  },
  watch: {
    details: {
      handler(newValue, oldValue) {
        // 这里不仅可以监听到对象引用的变化，还能监听到对象内部属性的变化
        this.dialogVisible = newValue.dialogVisible
        if (newValue.dialogVisible) {
          let imgArr = []
          imgArr.push(this.getImgToken(newValue.data.resourceUrl))
          imgArr.push(newValue.data.qrCodePath)
          this.srcList = imgArr
        }
        console.log('details:', newValue);
      },
      deep: true, // 开启深度监听
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    downloadImage(imgUrl) {
      if (!imgUrl) {
        return
      }
      let urlString = this.getImgToken(imgUrl)
      window.open(urlString)
      // const x = new XMLHttpRequest()
      // x.open('GET', urlString, true)
      // x.setRequestHeader('Access-Control-Allow-Origin', '*');
      // x.responseType = 'blob'
      // x.onload = function () {
      //   const url = window.URL.createObjectURL(x.response)
      //   const a = document.createElement('a')
      //   a.href = url
      //   a.download = '培训记录.pdf'
      //   a.click()
      //   // document.body.removeChild(a)
      //   // 然后移除
      // }
      // x.send()

    },
    download(href, name) {

      let eleLink = document.createElement('a')

      eleLink.download = name

      eleLink.href = href

      eleLink.click()

      eleLink.remove()

    },
    getImgToken(imgUrl) {
      let token = this.$Cookies.get('cyry_token')
      if (!imgUrl) {
        return imgUrl
      }
      let urlStr = this.$previewImg + imgUrl + '?token=' + token
      return urlStr
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  /deep/ .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .el-col {
      margin-bottom: 10px;
    }
  }

  /deep/ .el-image {
    .image-slot {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f7fa;
      color: #909399;

    }
  }
}

</style>