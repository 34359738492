<template>
	<div class="left_menu">
		<Menu theme="light" :active-name="menu[current].catid" @on-select="getMenu">
			<label v-for="(it,i) in menu" :key="i">
				<Menu-item v-if="it.subMenu.length==0" :name="it.catid">
					{{it.title}}
				</Menu-item>
				<Submenu v-else :name="it.catid">
					<template slot="title">
						{{it.title}}
					</template>
					<Menu-group v-for="(tt,ii) in it.subMenu" :key="ii">
						<Menu-item :name="tt.catid">{{tt.title}}</Menu-item>
					</Menu-group>
				</Submenu>
			</label>
		</Menu>
	</div>
</template>
<script>
	export default {
		name: 'left_menu',
		props: {
			bgColor: {
				type: Boolean,
				default: false
			},
			menu: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				current: 0,
			}
		},
		mounted() {

		},
		methods: {
			getMenu(e) {
				this.$emit("change",e)
			},
		}
	}
</script>
<style lang="scss" scoped>
	.left_menu {
		margin-right: 80px;

		/deep/ .ivu-menu {
			.ivu-menu-item {
				text-align: center;
			}
		}


	}
</style>
