<template>
  <div>
    <div v-if="infoList.length>0">
      <div v-for="(it,i) in infoList" :key="it.id" class="item pc_item">
        <img :src="it.banner" style="width: 25%;"/>
        <div class="kc_box" style="width: 60%;">
          <div class="title single">{{ it.name }}</div>
          <div>
            <span class="tit_bold">课程学时：</span>
            <span>{{ it.classHour }}学时</span>
            <span style="margin: 0 10px;">|</span>
            <span class="tit_bold">学习方式：</span>
            <span>{{ it.study == 0 ? '线下' : it.study == 1 ? '线上' : '' }}</span>
            <span style="margin: 0 10px;">|</span>
            <span class="tit_bold">培训人数：</span>
            <span>{{ it.classNum }}</span>
          </div>
          <div>
            <span class="tit_bold">课程简介：</span>
            <span>{{ it.description }}</span>
          </div>
          <div v-if="it.study==1" class="price_box">
            <div>
              <span class="tit_bold">课程价格：</span>
              <span v-if="it.charge==0" class="price">免费</span>
              <div v-else>
								<span :style="{'text-decoration': ishy?'line-through':'','color':ishy?'#999':'limegreen'}"
                      class="price">￥{{ it.classPrice }}</span>元
              </div>
            </div>
            <span v-if="it.classPricemember" style="margin: 0 10px;">|</span>
            <div v-if="it.classPricemember">
              <span class="tit_bold">会员价格：</span>
              <span class="price" style="color: orange;">￥{{ it.classPricemember }}</span>元
            </div>
          </div>
          <div v-if="it.study==0">
            <span class="tit_bold">培训地址：</span>
            <span>{{ it.pxAddr }}</span>
          </div>
          <div v-if="it.study==0">
            <span class="tit_bold">培训时间：</span>
            <span>{{ it.pxTime }}</span>
            <span style="margin: 0 10px;">|</span>
            <span class="tit_bold">测试时间：</span>
            <span>{{ it.csTime }}</span>
          </div>
        </div>
        <div v-if="it.study==1" class="kc_btn" style="width: 15%;">
          <div v-if="it.charge==0" class="btn" @click="goDetail(it)">点击学习</div>
          <div v-if="it.charge!=0&&it.isEnroll">
            <div class="btn" @click="goXX(it)">点击学习</div>
            <!-- <div v-if="it.examine==1" class="btn btnks" @click="goKS(it)"
              :style="{opacity:it.studyFinish==1?'1':'.5'}">参加考试</div> -->
          </div>
          <div v-if="it.charge!=0&&it.isEnroll==null" class="btn btnks" @click="toBm(it)">报名学习</div>
        </div>
      </div>

      <div v-for="(it,i) in infoList" :key="i" class="sj_item">
        <div class="title">{{ it.name }}</div>
        <div>
          <img :src="it.banner" style="width: 26%;"/>
          <div class="kc_box" style="width: 70%;">
            <div>
              <span class="tit_bold">课程学时：</span>
              <span>{{ it.classHour }}学时</span>
              <span style="margin: 0 10px;">|</span>
              <span class="tit_bold">学习方式：</span>
              <span>{{ it.study == 0 ? '线下' : it.study == 1 ? '线上' : '' }}</span>
              <span style="margin: 0 10px;">|</span>
              <span class="tit_bold">培训人数：</span>
              <span>{{ it.classNum }}</span>
            </div>
            <div>
              <span class="tit_bold">课程简介：</span>
              <span>{{ it.description }}</span>
            </div>
            <div v-if="it.study==1" class="price_box">
              <div>
                <span class="tit_bold">课程价格：</span>
                <span v-if="it.charge==0" class="price">免费</span>
                <div v-else>
									<span :style="{'text-decoration': ishy?'line-through':'','color':ishy?'#999':'limegreen'}"
                        class="price">￥{{ it.classPrice }}</span>元
                </div>
              </div>
              <span v-if="it.classPricemember" style="margin: 0 10px;">|</span>
              <div v-if="it.classPricemember">
                <span class="tit_bold">会员价格：</span>
                <span class="price" style="color: orange;">￥{{ it.classPricemember }}</span>元
              </div>
            </div>
            <div v-if="it.study==0">
              <span class="tit_bold">培训地址：</span>
              <span>{{ it.pxAddr }}</span>
            </div>
            <div v-if="it.study==0">
              <span class="tit_bold">培训时间：</span>
              <span>{{ it.pxTime }}</span>
              <span style="margin: 0 10px;">|</span>
              <span class="tit_bold">测试时间：</span>
              <span>{{ it.csTime }}</span>
            </div>
          </div>
        </div>
        <div v-if="it.study==1" class="kc_btn">
          <div v-if="it.charge==0" class="btn" @click="goDetail(it)">点击学习</div>
          <div v-if="it.charge!=0&&it.isEnroll">
            <div class="btn" @click="goXX(it)">点击学习</div>
            <!-- <div v-if="it.examine==1" class="btn btnks" @click="goKS(it)"
              :style="{opacity:it.studyFinish==1?'1':'.5'}">参加考试</div> -->
          </div>
          <div v-if="it.charge!=0&&it.isEnroll==null" class="btn btnks" @click="toBm(it)">报名学习</div>
        </div>
      </div>
    </div>
    <div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
    <Page v-if="infoList.length!=0" :current="params.pageNo" :page-size="params.pageSize" :total="total"
          class="common_page_box" show-total @on-change="changePage"></Page>
    <ksLogin ref="ksbox"></ksLogin>
  </div>
</template>

<script>
import bannerBox from '@/components/banner.vue'
import headerBox from '@/components/header.vue'
import footerBox from '@/components/footer.vue'
import ksLogin from '@/components/ks_login.vue'

import {checkMember, courseEnroll, courseList} from '@/api/home/guild'

export default {
  name: 'home',
  components: {
    bannerBox,
    headerBox,
    footerBox,
    ksLogin
  },
  data() {
    return {
      infoList: [],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 6
      },
      catid: '',
      ishy: false,
      cyry_user: {}
    }

  },
  mounted() {
    if (this.$Cookies.get('cyry_user')) {
      this.cyry_user = JSON.parse(this.$Cookies.get('cyry_user'))
      checkMember({
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        this.ishy = res.result // true 会员  false 非会员
      })
    }
    this.getInfo();
  },
  watch: {},
  methods: {
    getInfo() {
      courseList({
        ...this.params,
        categary: 1,
        token: this.$Cookies.get('cyry_token')
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.infoList = res.result.records;
          this.total = res.result.total;
        } else if (res.code == 500) {
          this.$Modal.error({
            title: '错误',
            content: res.message,
            onOk: () => {
              this.$Cookies.remove('cyry_user');
              this.$Cookies.remove('cyry_token');
              this.$refs['ksbox'].showLogin = true;
            },
          });
        } else {
          this.infoList = [];
        }
      })
    },
    changePage(e) {
      this.params.pageNo = e;
      this.getInfo()
    },
    goDetail(it) {
      // if(it.charge==1){//不免费
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '提示',
          content: '未登录，请登录从业人员信息后开始学习。',
          okText: '去登录',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      if (it.isEnroll == 0) {
        this.$Modal.info({
          title: '提示',
          content: '已报名缴费未确认,请在缴费确认后参加学习。',
        });
        return
      }
      // }
      let url = 'subjectDetail';
      this.$router.push({
        name: url,
        query: {
          id: it.id,
          name: encodeURIComponent('公益讲座')
        }
      });
    },
    goXX(it) {
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '提示',
          content: '未登录，请登录从业人员信息后开始学习。',
          okText: '去登录',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      if (it.isEnroll == 0) {
        this.$Modal.info({
          title: '提示',
          content: '已报名缴费未确认,请在缴费确认后参加学习。',
        });
        return
      }
      let url = 'subjectDetail';
      this.$router.push({
        name: url,
        query: {
          id: it.id,
          name: encodeURIComponent('公益讲座')
        }
      });
    },
    toBm(it) {
      let that = this;
      let cyry_token = this.$Cookies.get('cyry_token');
      if (!cyry_token) {
        this.$Modal.confirm({
          title: '温馨提示',
          okText: '登录',
          content: '未登录，请您登录从业人员信息后报名学习',
          onOk: () => {
            this.$refs['ksbox'].showLogin = true;
          },
        });
        return
      }
      that.$Modal.confirm({
        title: '温馨提示',
        width: 600,
        okText: '报名',
        content: `<div style="font-size:16px">
					尊敬的${that.cyry_user.name}用户，该课程为收费课程，请您先缴费后学习！
					缴费金额：<span style="color:red">${that.ishy ? '会员价' : '非会员价'}${this.ishy ? it.classPricemember : it.classPrice}元</span>，
					缴费完成后系统将为您开通学习入口，祝您取得佳绩~
					</div>
					<br>
					帐号：52001613600052521882<br>
					收款单位名称：贵州省建设工程招标投标协会<br>
					开 户 行：中国建设银行股份有限公司贵阳朝阳支行<br>
					<br>
					如您已缴费，系统仍未开通，请拨打0851-86587786咨询。
					`,
        onOk: () => {
          setTimeout(() => {
            courseEnroll({
              id: it.id,
              token: that.$Cookies.get('cyry_token')
            }).then(res => {
              if (res.code == 200 || res.code == 0) {
                that.$Modal.info({
                  title: '提示',
                  content: res.message,
                  onOk: () => {
                    that.getInfo();
                  },
                });
              }
            })
          }, 500)
        },
      });
    },
    goKS(it) {
      if (it.studyFinish == 1) {
        let url = `${this.$ksHost}/#/subject?type=1&courseId=${it.id}&studentId=${JSON.parse(this.$Cookies.get('cyry_user')).studentId}&token=${JSON.parse(this.$Cookies.get('cyry_user')).token}`;
        window.open(url, '_blank')
      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '还未学习完成，请学习完成后参加考试！',
          onOk: () => {

          },
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pc_item {
  // border: 1px solid #eee;
  padding: 10px;
  padding-bottom: 30px;
  margin: 20px 0;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  box-shadow: 5px 5px 20px #9E9E9E;
  display: flex;
  align-items: center;
  width: 100%;

  img {
    width: 250px;
    height: 180px;
    margin-right: 50px;
  }

  .kc_box {
    > div {
      color: #575B66;
      font-size: 15px;
      padding: 5px 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .tit_bold {
      color: #000;
      white-space: nowrap;
    }

    .title {
      font-size: 20px;
      color: #333;
      text-align: left;
      font-weight: bold;
      color: #2d8cf0;
    }

    .price_box {
      display: flex;
      align-items: flex-end;

      div {
        display: flex;
        align-items: flex-end;
      }

      .price {
        font-size: 18px;
        color: limegreen;
      }
    }
  }

  .kc_btn {
    display: flex;
    justify-content: center;

    .btn {
      color: #fff;
      background-color: #2179C8;
      border-radius: 3px;
      margin-top: 10px;
      width: 80px;
      padding: 5px 0;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      white-space: nowrap;
    }

    .btn:hover {
      color: gold;
    }

    .btnks {
      background-color: seagreen;
    }
  }

}

.item:hover {
  // box-shadow: 5px 5px 20px #9E9E9E;
}

// 大屏幕
@media only screen and (min-width: 800px) {
  .sj_item {
    display: none !important;
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .pc_item {
    display: none !important;
  }

  .sj_item {
    overflow: hidden;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    box-shadow: 5px 5px 20px #9E9E9E;
    width: 98%;
    padding: 2%;
    margin: 0 auto 30px;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 100%;
      height: 60px;
      margin-right: 15px;
    }

    .title {
      font-size: 16px !important;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      font-weight: bold;
      color: #2d8cf0;
    }

    .kc_box {
      > div {
        color: #575B66;
        font-size: 13px;
        padding: 2px 0;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      .tit_bold {
        color: #000;
        white-space: nowrap;
      }

      .title {
        font-size: 20px;
        color: #333;
        text-align: left;
        font-weight: bold;
        color: #2d8cf0;
      }

      .price_box {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        div {
          display: flex;
          align-items: flex-end;
        }

        .price {
          // font-size: 14px;
          color: limegreen;
        }
      }
    }

    .kc_btn {
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background-color: #2179C8;
        border-radius: 3px;
        margin-top: 5px;
        width: 80px;
        padding: 5px 0;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;
      }

      .btn:hover {
        color: gold;
      }

      .btnks {
        background-color: seagreen;
      }
    }

  }

  .item:hover {
    // box-shadow: 5px 5px 20px #9E9E9E;
  }

}
</style>