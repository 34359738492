<template>
	<div class="item1_box">
		<div class="common_search_box">
			<Input v-model="keywords" clearable @on-enter="getArticle('search')" placeholder="请输入相关关键词">
			</Input>
			<div class="btn_box">
				<div @click="getArticle('search')">搜索</div>
				<div @click="toReset">重置</div>
			</div>
		</div>
		<div class="box1" v-if="infoList.length>0">
			<div v-for="(it,i) in infoList" :key="i" @click="goDetail(it)">
				<img style="width: 25%;" v-if="it.thumb" :src="it.thumb" />
				<div :style="{width: it.thumb?'70%':'100%'}">
					<div class="title single">{{it.title}}</div>
					<div class="time">{{it.date}}</div>
					<div class="article">
						{{it.description}}
					</div>
				</div>
			</div>
			<Page v-if="infoList.length!=0" class="common_page_box" @on-change="changePage" :total="total"
				:current="params.pageNo" :page-size="params.pageSize" show-total></Page>
		</div>
		<div v-if="infoList.length==0" class="common_excel_no_more">暂无内容~</div>
	</div>
</template>

<script>
	import {
		getArticle
	} from '@/api/home/index'
	export default {
		components: {

		},
		data() {
			return {
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 6
				},
				keywords:'',
				infoList: [],
				locationList:['政策法规','法律查找']
			}

		},
		mounted() {
			this.keywords = this.$route.query.keywords
			this.getArticle()
		},
		methods: {
			getArticle(search) {
				if(search){
					this.params.pageNo = 1;
				}
				getArticle({
					catid: 20,
					...this.params,
					keywords:this.keywords
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.infoList = res.result.records;
						this.total = res.result.total;
					} else {
						this.infoList = [];
					}
				})
			},
			goDetail(it){
				this.$router.push({
					name: 'news_detail',
					query: {
						id: it.id,
					},
					params: {
						locationList: encodeURIComponent(JSON.stringify(this.locationList))
					}
				});
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getArticle()
			},
			toReset(){
				this.getArticle()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item1_box{
		display: flex;
		flex-direction: column;
	}
	.box1 {
		margin-top: 20px;
		>div {
			border-bottom: 1px solid #eee;
			padding: 10px;
			padding-bottom: 30px;
			margin-bottom: 30px;
			cursor: pointer;
			transition: all 0.2s ease-in;
			-webkit-transition: all 0.2s ease-in;
			-moz-transition: all 0.2s ease-in;
			-o-transition: all 0.2s ease-in;
			display: flex;
		
			img {
				width: 250px;
				height: 180px;
				margin-right: 30px;
			}
		
			.title {
				font-size: 20px;
				color: #000000;
				text-align: left;
			}
		
			.time {
				color: #575B66;
				font-size: 15px;
				padding: 10px 0;
			}
		
			.article {
				color: #9E9E9E;
				font-size: 13px;
			}
		}
		
		>div:hover {
			// background-color: #eee;
			// padding: 15px 10px;
			box-shadow: 5px 5px 20px #9E9E9E;
		}
	}
</style>
