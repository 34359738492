<template>
	<div class="box1">
		<Tabs value="name1">
			<Tab-pane label="信用评价" name="name1">
				<div class="cell_box">
					<div class="cell th">
						<div v-for="(it,i) in th" :key="i">{{it}}</div>
					</div>
					<div class="cell td" v-for="(it,i) in 10" :key="i">
						<div>{{i+1}}</div>
						<div>贵州国诚工程管理有限公司</div>
						<div>AAA</div>
						<div>张大侠</div>
						<div>2023年12月</div>
						<div>贵州省建设工程招标投标协会</div>
					</div>
				</div>
				<Page class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo"
					:page-size="params.pageSize" show-total></Page>
			</Tab-pane>
			<Tab-pane label="未参与信用评价" name="name2">
				<div class="cell_box">
					<div class="cell th">
						<div v-for="(it,i) in th" :key="i">{{it}}</div>
					</div>
					<div class="cell td" v-for="(it,i) in 10" :key="i">
						<div>{{i+1}}</div>
						<div>贵州国诚工程管理有限公司</div>
						<div>AAA</div>
						<div>张大侠</div>
						<div>2023年12月</div>
						<div>贵州省建设工程招标投标协会</div>
					</div>
				</div>
				<Page class="common_page_box" @on-change="changePage" :total="total" :current="params.pageNo"
					:page-size="params.pageSize" show-total></Page>
			</Tab-pane>
		</Tabs>
	</div>
</template>

<script>
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		components: {

		},
		data() {
			return {
				th: ['序号', '企业名称', '等级', '法定代表人', '有效期', '评选机构'],
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 6
				},
			}

		},
		mounted() {
			this.getSingleArticle()
		},
		methods: {
			getSingleArticle() {
				getSingleArticle({
					catid: 201,
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.info = res.result;
					}
				})
			},
			changePage(e) {
				this.params.pageNo = e;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.cell_box {
		margin-top: 20px;

		.cell {
			display: flex;
			width: 100%;
			white-space: nowrap;
			font-size: 14px;
			padding: 10px 0;
			text-align: center;
			border-left: 1px solid #eee;
			border-right: 1px solid #eee;

			>div:first-child {
				width: 15%;
			}

			>div:nth-child(2) {
				width: 40%;
				text-align: left;
			}

			>div:nth-child(3) {
				width: 15%;
			}

			>div:nth-child(4) {
				width: 15%;
			}

			>div:nth-child(5) {
				width: 20%;
			}

			>div:nth-child(6) {
				width: 35%;
			}
		}

		.th {
			background-color: #49B3FF;
		}

		.td:nth-child(odd) {
			background-color: #D8D8D8;
		}
	}
</style>
