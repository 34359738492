<template>
  <div>
    <div class="box2">
      <div class="gcjy">
        <div class="on_flex">
          <div class="common_box_tit">
            <div>工程交易</div>
            <div class="bom_line"></div>
          </div>
          <div class="tab_tit">
            <div v-for="(it,i) in tabList" :key="i" @mouseover="getTab(i)" @click="getTab(i)"
                 :class="current==i?'selected':'noSelected'">{{ it.name }}
            </div>
          </div>
          <div></div>
        </div>
        <div class="cell_box">
          <div v-if="newList.length>0">
            <div class="cell_list" v-for="(it,i) in newList" :key="i" @click="goDetail(it.id)">
              <div class="tag_line"></div>
              <div class="cell_con">
                <div class="title">{{ it.title }}</div>
                <!-- <div>{{it.description}}</div> -->
              </div>
            </div>
            <div class="common_more" @click="goDetail()" style="float: right;margin-top: 10px;">
              <span>更多</span>
              <span class="iconfont icon-gengduo"></span>
            </div>
          </div>
          <div class="common_no_more" style="height: 280px" v-if="newList.length==0">暂无内容~</div>
        </div>
      </div>
      <div class="kjrk">
        <div class="common_box_tit">
          <div>快捷入口</div>
          <div class="bom_line"></div>
        </div>
        <div class="kjrk_cell">
          <div v-for="(it,i) in kjList" :key="i" :class="[it.bg]" @click="goUrl(it)">
            <span class="iconfont" :class="[it.icon]"></span>
            <span>{{ it.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bom_box">
      <div v-for="(it,i) in linkList" :key="i" @click="goLink(it.url)">
        <span class="iconfont" :class="[it.icon]"></span>
        <span>{{ it.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getArticleDes,
  getLinks
} from '@/api/home/index'

export default {
  props: {
    isBlue: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      current: 0,
      tabList: [
        {name: '招标公告', catid: '302'},
        {name: '澄清与修改公告', catid: '304'},
        {name: '中标结果公示', catid: '303'},
        // {name: '中标候选人公公示', catid: '322'},
        // {name: '资审公告', catid: '323'},
      ],
      kjList: [
        {
          name: '从业人员专业技术能力评价考试',
          icon: 'icon-icon-kaoshi',
          bg: 'item1',
          url: '',
          isOut: true
        },
        {
          name: '申请入会',
          icon: 'icon-shenqing',
          bg: 'item2',
          url: 'member',
          isOut: false,
          subName: '在线申请会员'
        },
        {
          name: '登录入口',
          icon: 'icon-login',
          bg: 'item3',
          url: 'login',
          isOut: false
        },
        {
          name: '代理机构登记',
          icon: 'icon-dengji',
          bg: 'item4',
          url: '',
          isOut: true
        },
        {
          name: '信用评价',
          icon: 'icon-pingjia',
          bg: 'item5',
          url: '',
          isOut: true
        },
      ],
      newList: [],
      linkList: [
        {
          name: '贵州省综合评标专家库',
          icon: 'icon-fasong',
          type: '1',
          url: ''
        },
        {
          name: '贵州省建筑业监管和公共服务系统',
          icon: 'icon-kefu',
          type: '2',
          url: ''
        },
        {
          name: '建筑云在线',
          icon: 'icon-yunduan',
          type: '3',
          url: ''
        }
      ]
    }
  },
  mounted() {
    let catid = this.tabList[this.current].catid;
    this.getArticleDes(catid);
    this.getLinks1();
    this.getLinks2();
    this.getLinks3();
    this.getLinksKs();
  },
  methods: {
    getArticleDes(catid) {
      getArticleDes({catid, pageNo: 1, pageSize: 4}).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.newList = res.result.records;
        }
      })
    },
    getLinks1() {
      getLinks({type: 1}).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.linkList[0].url = res.result.url;
        }
      })
    },
    getLinks2() {
      getLinks({type: 2}).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.linkList[1].url = res.result.url;
        }
      })
    },
    getLinks3() {
      getLinks({type: 3}).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.linkList[2].url = res.result.url;
        }
      })
    },
    getLinksKs() {
      getLinks({type: 10}).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.kjList[0].url = res.result.url;
        }
      })
    },
    getTab(e) {
      this.current = e;
      let catid = this.tabList[this.current].catid;
      this.getArticleDes(catid);
    },
    goUrl(it) {
      if (it.url) {
        if (it.isOut) {
          window.open(it.url, '_blank')
        } else {
          let query = {};
          if (it.subName) {
            query.name = encodeURIComponent(it.subName);
          }
          this.$router.push({
            name: it.url,
            query: query
          })
        }
      } else {
        this.$Message.warning('此功能暂未开放！');
      }
    },
    goDetail(id) {
      // if(this.current==2){

      // 	if(id){
      // 		this.$router.push({
      // 			name: 'news_detail',
      // 			query: {
      // 				id:id
      // 			},
      // 			params:{
      // 				locationList:encodeURIComponent(JSON.stringify(['首页','违法违规信息']))
      // 			}
      // 		});
      // 	}else{
      // 		this.$router.push({
      // 			name: 'news',
      // 			query: {
      // 				catid: 305
      // 			}
      // 		});
      // 	}
      // }else{
      let url = '';
      let catid = this.tabList[this.current].catid;
      let query = {};
      let params = {};
      if (id) {
        url = 'news_detail';
        query = {
          id
        };
        params = {
          locationList: encodeURIComponent(JSON.stringify(['工程交易', this.tabList[this.current].name]))
        }
      } else {
        url = 'engineering'
        query = {
          name: this.tabList[this.current].name
        }
      }
      this.$router.push({
        name: url,
        query: query,
        params: params
      });
      // }
    },
    goLink(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.box2 {
  display: flex;

  .gcjy {
    flex: 1;
    width: 55%;
    padding-right: 70px;

    .tab_tit {
      display: flex;
      justify-content: space-between;
      font-size: 17px;
      white-space: nowrap;
      width: 60%;

      div {
        padding: 5px 10px;
        cursor: pointer;
      }

      .selected {
        border: none;
        color: #fff;
        background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
      }

      .noSelected:hover {
        color: #2D85E2;
      }
    }

    .cell_box {
      width: 100%;
      // height: 280px;
      // overflow-y: scroll;
      margin-top: 15px;

      .cell_list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
        // padding-bottom: 10px;
        // margin-top: 30px;
        padding: 10px 5px;
        margin-top: 20px;
        cursor: pointer;

        .tag_line {
          margin-right: 20px;
          width: 3px;
          height: 19px;
          background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
        }

        .cell_con {
          width: 100%;
        }

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }

        .title {
          font-size: 15px;
        }
      }

      .cell_list:hover {
        background-color: #eee;
      }
    }
  }

  .kjrk {
    width: 45%;

    .kjrk_cell {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5px;

      > div {
        display: flex;
        padding-left: 15px;
        align-items: center;
        width: 47%;
        min-width: 165px;
        height: 80px;
        border-radius: 8px;
        font-size: 16px;
        color: #fff;
        margin: 10px 0;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.22s ease-in;
        -webkit-transition: all 0.22s ease-in;
        -moz-transition: all 0.22s ease-in;
        -o-transition: all 0.22s ease-in;

        .iconfont {
          margin-right: 10px;
          font-size: 25px;
        }
      }

      > div:hover {
        transform: scale(1.08);
      }

      .item1 {
        background: linear-gradient(180deg, #6D8BE7 2%, #4160C1 100%);
        width: 100%;
        justify-content: center;
      }

      .item2 {
        background: linear-gradient(180deg, #FFC868 0%, #F68915 100%);
      }

      .item3 {
        background: linear-gradient(180deg, #E24069 5%, #AE2626 100%);
      }

      .item4 {
        background: linear-gradient(180deg, #8CE0FC 3%, #316FE7 99%);
      }

      .item5 {
        background: linear-gradient(180deg, #8CE0FC 0%, #26AF74 98%);
      }
    }
  }
}

.bom_box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 16px;

  > div {
    white-space: nowrap;
    color: #fff;
    padding: 20px 20px;
    border-radius: 8px;
    background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.22s ease-in;
    -webkit-transition: all 0.22s ease-in;
    -moz-transition: all 0.22s ease-in;
    -o-transition: all 0.22s ease-in;

    .iconfont {
      margin-right: 10px;
      font-size: 25px;
    }
  }

  > div:hover {
    box-shadow: 8px 8px 10px 0 rgba(153, 153, 153, 0.5);
  }

  > div:first-child {
    width: 30%;
  }

  > div:nth-child(2) {
    width: 40%;
  }

  > div:last-child {
    width: 25%;
  }
}

/* 手机 */
@media only screen and (max-width: 800px) {
  .box2 {
    display: inline-block;
    width: 100%;

    .on_flex {
      // display: inline-block;
    }

    .gcjy {
      width: 100%;
      padding-right: 0;
      margin-bottom: 70px;

      .tab_tit {
        width: auto;
        margin-top: 10px;

        > div {
          padding: 5px 8px;
        }
      }
    }

    .kjrk {
      width: 100%;
    }

    .cell_list {
      margin-top: 0 !important;
      padding: 15px 5px !important;
    }
  }
  .bom_box {
    flex-direction: column;

    > div:first-child {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }

    > div:last-child {
      width: 100%;
    }
  }
}
</style>
