import request from '@/utils/request'
import Cookies from 'js-cookie'

// 意见反馈提交
export function feedbackSubmit(params) {
	return request({
		url: '/jeecg-boot/index/feedbackSubmit',
		method: 'GET',
		params: params
	})
}

// 个人入会申请提交
export function grapplicationSubmit(params) {
	return request({
		url: '/jeecg-boot/index/grapplicationSubmit',
		method: 'GET',
		params: params
	})
}

// 个人入会申请修改
export function grapplicationUpdate(params) {
	return request({
		url: '/jeecg-boot/index/grapplicationUpdate',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}

// 企业会员审核修改
export function updateVipxxChangeApply(params) {
	return request({
		url: '/jeecg-boot/index/updateVipxxChangeApply',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}

// 获取企业会员信息
export function getVipxx(params) {
	return request({
		url: '/jeecg-boot/index/getVipxx',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}

// 企业入会
export function applicationSubmit(params) {
	return request({
		url: '/jeecg-boot/index/applicationSubmit',
		method: 'GET',
		params: params
	})
}

// 修改企业会员
export function applicationUpdateApply(params) {
	return request({
		url: '/jeecg-boot/index/applicationUpdateApply',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}
// 修改验证码的企业会员
export function applicationUpdate(params) {
	return request({
		url: '/jeecg-boot/index/applicationUpdate',
		method: 'GET',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:'',
			...params,
		}
	})
}

//从业人员证书申请注销
export function applyCertCancel(params) {
	return request({
		url: '/jeecg-boot/index/applyCertCancel',
		method: 'GET',
		params: {
			...params,
		}
	})
}


//根据验证码查询入会信息
export function infoQuery(params) {
	return request({
		url: '/jeecg-boot/index/infoQuery',
		method: 'GET',
		params: {
			...params,
		}
	})
}


// 企业风采添加
export function qyfcApply(params) {
	return request({
		url: '/jeecg-boot/index/hyfcApply',
		method: 'POST',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:''
		},
		data: JSON.stringify(params)
	})
}

// 从业人员注销申请
export function cyryZxsq(params) {
	return request({
		url: '/jeecg-boot/index/cyryZxsq',
		method: 'POST',
		params: {
			token:Cookies.get('xh_userInfo')?JSON.parse(Cookies.get('xh_userInfo')).token:''
		},
		data: JSON.stringify(params)
	})
}

