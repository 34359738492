<template>
	<div>
		<headerBox :current="6"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box_kc">
			<div class="layout">
				<div class="layout-content">
					<Row>
						<i-col span="5">
							<Menu :active-name="subCurrent" width="auto" v-if="subjectInfo.items.length>0"
								@on-select="chooseMenu">
								<div class="layout-logo-left"></div>
								<Menu-item v-for="(it,i) in subjectInfo.items" :key="i" :name="i">
									<span class="layout-text">{{it.title}}</span>
								</Menu-item>
							</Menu>
						</i-col>
						<i-col span="19">
							<div class="item_box" v-if="subjectInfo.items.length>0">
								<div class="item">
									<div class="flex_item">
										<div class="tit_box">
											<div style="align-items: center;">
												<img style="height: 100px;object-fit: cover;margin-right: 15px;" :src="subjectInfo.items[subCurrent].banner"/>
												<div>
													<span v-if="subjectInfo.items[subCurrent].attachType=='PPT'"
														class="iconfont icon-ppt" style="color: #D24625"></span>
													<span v-if="subjectInfo.items[subCurrent].attachType=='PDF'"
														class="iconfont icon-PDF" style="color: #E45252"></span>
													<span v-if="subjectInfo.items[subCurrent].attachType=='WORD'"
														class="iconfont icon-word" style="color: #41A5EE"></span>
													<span v-if="subjectInfo.items[subCurrent].attachType=='EXCEL'"
														class="iconfont icon-excel-full" style="color: #207346"></span>
													<span v-if="subjectInfo.items[subCurrent].attachType=='图片'"
														class="iconfont icon-tupian" style="color: #148BD5"></span>
													<span v-if="subjectInfo.items[subCurrent].attachType=='视频'"
														class="iconfont icon-shipin" style="color: #6467F0"></span>
													<div class="name">
														{{subjectInfo.items[subCurrent].title}}
													</div>
												</div>
											</div>
											<div class="kc_info">
												<span class="tit_bold">授课老师：</span>
												<span>{{subjectInfo.items[subCurrent].teacher}}</span>
												<span style="margin: 0 10px;">|</span>
												<span class="tit_bold">课程学时：</span>
												<span>{{subjectInfo.items[subCurrent].classHour}}学时</span>
												<span style="margin: 0 10px;">|</span>
												<span class="tit_bold">学习时长：</span>
												<span>{{subjectInfo.items[subCurrent].studyTimes}}分钟</span>
											</div>
											<div>
												<span class="tit_bold">老师简介：</span>
												<span>{{subjectInfo.items[subCurrent].teacherDesc}}</span>
											</div>
										</div>
										<div class="xx_box">
											<Progress style="width:130px"
												:percent="subjectInfo.items[subCurrent].percent" :stroke-width="5"
												:status="subjectInfo.items[subCurrent].percent>=60?'success':subjectInfo.items[subCurrent].study_status"></Progress>
											<div @click="goxx(subjectInfo.items[subCurrent])" class="xx_btn">去学习</div>
										</div>
									</div>
								</div>
							</div>
							<div class="btn_box">
								<div @click="goBack">返回课程</div>
								<div style="background-color: seagreen;" v-if="subjectInfo.examine==1"
									:style="{opacity:subjectInfo.studyFinish==1?'1':'.5'}"
									@click="goKS(subjectInfo,subjectInfo.studyFinish)">参加考试</div>
							</div>
						</i-col>
					</Row>
				</div>
			</div>
		</div>
		<fullScreen ref="fullScreen" :subCurrent="subCurrent" @closeModal="closeModal"></fullScreen>
		<ksLogin ref="ksbox"></ksLogin>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/dist/css/swiper.css";
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import fullScreen from './components/full-screen.vue'
	import ksLogin from '@/components/ks_login.vue'
	import {
		courseInfo,
		queryCourseRecord,
	} from '@/api/home/guild'

	export default {
		name: 'home',
		components: {
			swiper,
			swiperSlide,
			bannerBox,
			headerBox,
			footerBox,
			fullScreen,
			ksLogin
		},
		data() {
			return {
				subjectInfo: {
					items: []
				},
				subCurrent: 0,
			}
		},
		mounted() {
			this.getResourceseSp();
			this.$refs["bannerChild"].locationList = ['教育培训', decodeURIComponent(this.$route.query.name)];
			// this.setLocation();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
			goKS(info,studyFinish) {
				if(info.examine==0){//不考试
					return
				}
				console.log(33,info)
				if (studyFinish == 1) {
					this.$Modal.confirm({
						title: '温馨提示',
						okText: '前往考试',
						cancelText:'暂不考试',
						content: `温馨提示：您已完成“${info.name}”课程的学习，可参加资格考试。`,
						onOk: () => {
							let url =
								`${this.$ksHost}/#/subject?type=1&courseId=${info.id}&studentId=${JSON.parse(this.$Cookies.get('cyry_user')).studentId}&token=${JSON.parse(this.$Cookies.get('cyry_user')).token}`;
							window.open(url, '_blank')
						},
					});
				} else {
					this.$Modal.confirm({
						title: '提示',
						content: '还未学习完成，请学习完成后参加考试！',
						onOk: () => {

						},
					});
				}
			},
			goxx() {
				console.log(this.subjectInfo)
				this.$refs["fullScreen"].showModel = true;
			},
			getResourceseSp() {
				courseInfo({
					id: this.$route.query.id,
					token: this.$Cookies.get('cyry_token')
				}).then(res => {
					if (res.code == 200) {
						let info = res.result;
						info.items.map((v, i) => {
							v.study_status = 'normal';
							if (i == this.subCurrent) {
								v.study_status = 'active';
							} else {
								v.study_status = 'normal'
							}
						})
						this.$refs["bannerChild"].locationList = ['教育培训', decodeURIComponent(this.$route.query
							.name), info.name];
						this.subjectInfo = info;
						this.subjectInfo.items.map((v, i) => {
							if (i == this.subCurrent) {
								this.queryCourseRecord(v);
							}
						})
					} else if (res.code == 500) {
						this.$Modal.error({
							title: '错误',
							content: res.message,
							onOk: () => {
								this.$Cookies.remove('cyry_user');
								this.$Cookies.remove('cyry_token');
								this.$refs['ksbox'].showLogin = true;
							},
						});
					}
				})
			},
			goVideo() {
				this.$router.push({
					name: 'video',
					query: {
						name: encodeURIComponent('视频文件')
					}
				})
			},
			setLocation(index) {
				this.$refs["bannerChild"].locationList = ['教育培训', '公益讲座'];
			},
			chooseMenu(e) {
				this.subCurrent = e;
				this.subjectInfo.items.map((v, i) => {
					if (this.subCurrent == i) {
						v.study_status = 'active';
						this.queryCourseRecord(v);
					} else {
						v.study_status = 'normal';
					}
				})
			},
			closeModal(it) {
				let info = this.subjectInfo;
				this.subjectInfo = {};
				if(it.studyTimes){
					let timeTotal = it.studyTimes;
					info.items.map((v, i) => {
						if (i == this.subCurrent) {
							v.percent = it.studyEnd == 1 ? 100 : Number(((timeTotal / (v
								.studyTimes * 60)) * 100).toFixed(0));
							if (info.studyFinish == 1 && info.examine == 1) {
								this.goKS(info,1)
							}
						}
					})
					console.log(`${it.title}的`, it.studyTimes)
				}
				this.subjectInfo = info;
			},
			queryCourseRecord(it) {
				let cyry_token = this.$Cookies.get('cyry_token');
				if (this.subjectInfo.charge == 0 && !cyry_token) {
					return
				}
				queryCourseRecord({
					courseId: it.courseId,
					itemId: it.id,
					token: cyry_token
				}).then(res => {
					if (res.result != null) {
						this.closeModal(res.result)
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content_box_kc {
		// padding: 80px 100px;
		padding-bottom: 100px;
		// max-width: 1400px;
		// min-width: 1100px;
		margin: auto;

		.box3 {
			margin-top: 20px;

			.swiper-container {
				position: relative;
				width: 100vm;

				.swiper-slide {
					width: 100%;
					color: #000;
				}

				img {
					object-fit: fill;
					height: 300px;
					width: 100%;
				}

				.swiper_text {
					padding: 0px 20px 40px;

					.title {
						font-size: 20px;
						margin: 10px 0;
					}
				}
			}

			/deep/ .swiper-pagination-bullet-active {
				background-color: #8971F1;
			}

		}
	}

	.item_box {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 1200px;
			margin: 20px;
			font-size: 14px;
			box-shadow: 0px 6px 40px 0px rgba(31, 44, 69, 0.1);

			.flex_item {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.tit_box {
				padding: 15px 10px;
				font-size: 16px;
				width: 82%;

				>div {
					display: flex;
					// flex-wrap: wrap;

					.iconfont {
						font-size: 30px;
						color: #41A5EE;
						margin-right: 5px;
					}

					.tit_bold {
						color: #000;
						// font-weight: bold;
						white-space: nowrap;
					}
				}

				.name {
					// padding: 15px 10px;
					font-size: 20px;
					color: #2d8cf0;
					font-weight: bold;
				}

				.kc_info {
					margin: 10px 0;
				}
			}

			.xx_box {
				width: 15%;
				padding: 0 20px;
				display: flex;
				flex-direction: column;

				.xx_btn {
					color: #fff;
					background-color: #2179C8;
					border-radius: 3px;
					width: 80px;
					padding: 5px 0;
					text-align: center;
					font-size: 16px;
					cursor: pointer;
					margin: 10px 0;
				}

				.xx_btn:hover {
					color: gold;
				}
			}
		}
	}

	.layout {
		border: 1px solid #d7dde4;
		background: #f5f7f9;
	}

	.layout-logo {
		width: 100px;
		height: 30px;
		background: #5b6270;
		border-radius: 3px;
		float: left;
		position: relative;
		top: 15px;
		left: 20px;
	}

	.layout-nav {
		width: 420px;
		margin: 0 auto;
	}

	.layout-assistant {
		width: 300px;
		margin: 0 auto;
		height: inherit;
	}

	.layout-breadcrumb {
		padding: 10px 15px 0;
	}

	.layout-content {
		min-height: 200px;
		margin: 15px;
		overflow: hidden;
		background: #fff;
		border-radius: 4px;
	}

	.layout-content-main {
		padding: 10px;
	}

	.layout-copy {
		text-align: center;
		padding: 10px 0 20px;
		color: #9ea7b4;
	}

	.layout-text {
		font-size: 16px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		font-weight: bold;
	}

	.btn_box {
		display: flex;
		justify-content: center;
		width: 500px;
		margin: 20px 0;

		>div {
			color: #fff;
			background-color: #2179C8;
			border-radius: 3px;
			margin: 0 20px;
			margin-top: 10px;
			width: 80px;
			padding: 5px 0;
			text-align: center;
			font-size: 16px;
			cursor: pointer;
			white-space: nowrap;
		}
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.layout-content {
			margin: 0;
		}

		.ivu-menu-vertical .ivu-menu-item,
		.ivu-menu-vertical .ivu-menu-submenu-title {
			padding: 5px;
		}

		.layout-text {
			font-size: 14px;
		}

		.tit_box {
			padding: 15px 10px;
			font-size: 14px !important;
			width: 100%!important;
		}
		
		.item{
			margin: 0;
			.flex_item {
				flex-direction: column;
				align-items: center;
			}
		}

		.xx_box {
			padding: 0!important;
			width: 100% !important;
			display: flex;
			flex-direction: row !important;
			align-items: center;
			justify-content: space-around;

			.xx_btn {
				width: 60px !important;
				font-size: 14px !important;
			}
		}
		.btn_box{
			width: 80%;
		}
	}
</style>