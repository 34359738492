<template>
	<div>
		<headerBox :current="111"></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div class="box2">
				<Tabs :value="typeName">
					<TabPane :label="`省内企业在线申请会员资料${typeName?'修改':''}`" name="省内"
						v-if="!typeName||(typeName&&typeName=='省内')">
						<div class="big_table">
							<provinceIn></provinceIn>
						</div>
					</TabPane>
					<TabPane :label="`省外企业在线申请会员资料${typeName?'修改':''}`" name="省外"
						v-if="!typeName||(typeName&&typeName=='省外')">
						<div class="big_table">
							<provinceOut></provinceOut>
						</div>
					</TabPane>
				</Tabs>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import provinceIn from './components/apply_form/province_in.vue'
	import provinceOut from './components/apply_form/province_out.vue'
	export default {
		name: 'home',
		components: {
			bannerBox,
			headerBox,
			footerBox,
			provinceIn,
			provinceOut
		},
		data() {
			return {
				typeName: ''
			}
		},
		mounted() {
			if (this.$route.query.typeName) {
				this.typeName = decodeURIComponent(this.$route.query.typeName)
				this.$refs["bannerChild"].locationList = ['修改在线会员信息'];
			} else {
				this.$refs["bannerChild"].locationList = ['会员中心', '在线申请会员'];
			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 50px auto 80px;
		width: 1200px;

		.box2 {
			color: #011243;
			font-size: 14px;
			font-family: YouYuan;

			.big_table {
				border-radius: 10px;
				box-shadow: 0px 4px 10px 0px #ddd;
				padding: 10px 50px 70px;
				margin: 30px 10px;
			}

			.title {
				font-size: 30px;
				text-align: center;
				margin-bottom: 50px;
			}

			/deep/.ivu-tabs-nav-scroll {
				padding: 0 80px;
			}

			/deep/.ivu-tabs-nav .ivu-tabs-tab {
				font-size: 24px;
			}

			/deep/.ivu-tabs-tab-active {
				font-size: 26px !important;
				font-weight: bold;
			}
		}

	}


	.btn {
		height: 48px;
		font-size: 18px;
		letter-spacing: 60px;
		padding-left: 80px;
		margin-top: 50px;
		background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
		box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	}

	/deep/.ivu-form .ivu-form-item-label {
		font-size: 14px;
	}

	/deep/.ivu-input {
		font-size: 14px;
	}

	/* 手机 */
	@media only screen and (max-width: 800px) {
		.content_box {
			margin: 50px 0 0px!important;
			padding: 0;
			width: 100%;
			.box2{
				/deep/.ivu-tabs-nav-scroll {
					padding: 0 0px;
				}
				
				/deep/.ivu-tabs-nav .ivu-tabs-tab {
					font-size: 16px!important;
				}
				
				/deep/.ivu-tabs-tab-active {
					font-size: 20px !important;
					font-weight: bold;
				}
			}
			.big_table {
				border-radius: 10px;
				box-shadow: 0px 4px 10px 0px #ddd;
				padding: 10px 10px 70px!important;
				margin: 30px auto!important;
				overflow-x: scroll;
				width: 92%;
			}
		}
	}
</style>
