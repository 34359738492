export function getDate() {
	var date = new Date() // 获取时间
	var year = date.getFullYear() // 获取年
	var month = date.getMonth() + 1 // 获取月
	month = (month > 9) ? month : ('0' + month);
	var strDate = date.getDate() // 获取日
	strDate = (strDate < 10) ? ('0' + strDate) : strDate;
	return year + '-' + month + '-' + strDate
}

export function getOtherDate(num) {
	var date = new Date() // 获取时间
	date.setDate((date.getDate() + num))
	var year = date.getFullYear() // 获取年
	var month = date.getMonth() + 1 // 获取月
	month = (month > 9) ? month : ('0' + month);
	var strDate = date.getDate() // 获取日
	strDate = (strDate < 10) ? ('0' + strDate) : strDate;
	return year + '-' + month + '-' + strDate
}

export function getNowDate() {
	var date = new Date();
	var sign2 = ":";
	var year = date.getFullYear() // 年
	var month = date.getMonth() + 1; // 月
	var day = date.getDate(); // 日
	var hour = date.getHours(); // 时
	var minutes = date.getMinutes(); // 分
	var seconds = date.getSeconds() //秒
	var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
	var week = weekArr[date.getDay()];
	// 给一位数的数据前面加 “0”
	if (month >= 1 && month <= 9) {
		month = "0" + month;
	}
	if (day >= 0 && day <= 9) {
		day = "0" + day;
	}
	if (hour >= 0 && hour <= 9) {
		hour = "0" + hour;
	}
	if (minutes >= 0 && minutes <= 9) {
		minutes = "0" + minutes;
	}
	if (seconds >= 0 && seconds <= 9) {
		seconds = "0" + seconds;
	}
	return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
}

// 秒转换为时分秒
export function tosfm(time) {
	// 转换为式分秒
	let h = parseInt(time / 60 / 60 % 24)
	h = h < 10 ? '0' + h : h
	let m = parseInt(time / 60 % 60)
	m = m < 10 ? '0' + m : m
	let s = parseInt(time % 60)
	s = s < 10 ? '0' + s : s
	// 作为返回值返回
	return [h, m, s]
}

/**
 * 验证手机号
 */
export function yzPhone(value) {
	return !/^1[34578]\d{9}$/.test(value)
}

/**
 * 验证手机号和座机
 */
export function yzAllCall(value) {
	return !/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(value)
}

/**
 * 验证身份证号码
 */
export function yzSfz(value) {
	return !/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)
}

/**
 * 验证邮箱
 */
export function yzEmail(value) {
	return !/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)
}

// 身份证脱敏
export function handleIdCard(id) {
	return id.replace(/^(.{4})(?:\d+)(.{4})$/, "$1**********$2");
}

// 手机号脱敏
export function handlePhone(phone) {
	return phone.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
}