<template>
	<div class="container" style="background-color: #fff;">
		<headerBox></headerBox>
		<bannerBox ref="bannerChild"></bannerBox>
		<div class="content_box">
			<div style="overflow-x: scroll;">
				<div style="min-width: 1080px;">
					<div class="common_search_box">
						<Input class="gzdwInput" v-model="params.dwmc" @on-enter="toSearch" placeholder="请输入工作单位搜索">
						</Input>
						<Select v-model="params.type" style="width:50%" @on-change="getType" placeholder="请选择会员类型">
							<Option v-for="item in tabList" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
						<div class="btn_box">
							<div @click="toSearch">搜索</div>
							<div @click="toReset">重置</div>
						</div>
					</div>
					<div class="title">{{typeName}}</div>
					<div class="cell">
						<img src="../../assets/images/vip1.png" />
						<div class="cell_list th">
							<div>序号</div>
							<div>工作单位</div>
							<div>会员类型</div>
						</div>
					</div>
					<div class="cell" v-for="(it,i) in vipList" :key="i">
						<img src="../../assets/images/vip2.png" />
						<div class="cell_list">
							<div>{{params.pageSize*params.pageNo-params.pageSize+i+1}}</div>
							<div @click="goToVipPage(it.uuid)" style="cursor:pointer;">{{it.dwmc}}</div>
							<div>{{it.vipType}}</div>
						</div>
					</div>
					<div v-if="vipList.length!=0" class="common_page_box">
						<Page @on-change="changePage" :total="total" :current="params.pageNo"
							:page-size="params.pageSize" show-total></Page>
					</div>
					<div v-if="vipList.length==0" class="common_excel_no_more">暂无内容~</div>
				</div>
			</div>
		</div>
		<footerBox></footerBox>
		<Back-top :height="100" :bottom="120">
			<div class="iconfont icon-shangjiantou"></div>
		</Back-top>
	</div>
</template>

<script>
	import bannerBox from '@/components/banner.vue'
	import headerBox from '@/components/header.vue'
	import footerBox from '@/components/footer.vue'
	import {
		getDetatilArticle
	} from '@/api/home/index'
	import {
		getVip
	} from '@/api/home/index'
	export default {
		name: 'vipIndex',
		components: {
			bannerBox,
			headerBox,
			footerBox,
		},
		data() {
			return {
				newInfo: {},
				total: 0,
				params: {
					pageNo: 1,
					pageSize: 20,
					dwmc: '',
					type: ''
				},
				catid: '',
				vipList: [],
				typeName: '',
				tabList: [{
						label: '全部',
						value: 0,
					},
					{
						label: '会长单位',
						value: 2
					},
					// {
					// 	label: '副会长单位',
					// 	value:2
					// },
					{
						label: '监事单位',
						value: 3
					},
					{
						label: '常务理事单位',
						value: 4
					},
					{
						label: '理事单位',
						value: 6
					},
					{
						label: '会员单位',
						value: 7
					}
				],
				tabList1: [{
						label: '全部',
						value: 0,
					},
					{
						label: '会长单位',
						value: 1
					},
					{
						label: '副会长单位',
						value: 2
					},
					{
						label: '监事单位',
						value: 3
					},
					{
						label: '常务理事单位',
						value: 4
					},
					{
						label: '副秘书长',
						value: 5
					},
					{
						label: '理事单位',
						value: 6
					},
					{
						label: '会员单位',
						value: 7
					},
					{
						label: '监事长',
						value: 30
					}
				],
			}

		},
		mounted() {
			this.typeName = this.tabList.find(v => v.value == this.$route.query.type).label;
			this.params.type = Number(this.$route.query.type);
			this.$refs["bannerChild"].locationList = ['会员名录'];
			this.getVip()
		},
		methods: {
			/* ====================数据请求部分========================== */
			getVip() {
				getVip({
					// type: this.$route.query.type,
					...this.params
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						let arr = res.result.records;
						if (arr.length > 0) {
							arr.map(v => {
								v.vipType = this.tabList1.find(k => k.value == v.type) ? this.tabList1
									.find(k => k.value == v.type).label : v.type
							})
						}
						this.vipList = arr;
						this.total = res.result.total;
					}
				})
			},
			/* ====================页面交互部分========================== */
			getType(e) {
				this.typeName = this.tabList.find(v => v.value == e).label;
				this.getVip()
			},
			changePage(e) {
				this.params.pageNo = e;
				this.getVip()
			},
			goDetail(it) {
				this.$router.push({
					name: '/news_detail'
				})
			},
			toSearch() {
				this.params.pageNo = 1;
				this.getVip();
			},
			toReset() {
				this.params.pageNo = 1;
				this.params.dwmc = '';
				this.getVip()
			},
			goToVipPage(uuid) {
				this.$router.push({
					name: 'vipInfo',
					query: {
						id: uuid
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content_box {
		margin: 80px 200px;

		.cell {
			position: relative;

			img {
				width: 100%;
				margin: 0;
				padding: 0;
				vertical-align: top;
				// position: absolute;
			}

			.cell_list {
				position: absolute;
				bottom: 0;
				z-index: 99;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				text-align: center;
				font-size: 14px;

				>div:first-child {
					width: 20%;
				}

				>div:nth-child(2) {
					width: 57%;
				}

				>div:last-child {
					width: 28%;
				}
			}

			.th {
				font-size: 15px;
			}
		}

		.page_box {
			padding-top: 40px;
			text-align: center;
		}
	}

	.title {
		font-size: 25px;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 20px;
	}
	
	/deep/.ivu-select-selection,
	/deep/.ivu-select-placeholder,
	/deep/.ivu-select-selected-value {
		border-radius: 0 !important;
		height: 50px !important;
		line-height: 50px !important;
		width: 100%;
		margin-bottom: 10px;
		font-size: 14px !important;
		border-left: none !important;
		vertical-align: top;
	}
	.common_search_box /deep/.ivu-input{
		border-radius: 0 !important;
		height: 50px !important;
		line-height: 50px !important;
		width: 100%;
		margin-bottom: 10px;
		font-size: 14px !important;
		vertical-align: top;
	}
	
	.gzdwInput{
		height: 50px !important;
		line-height: 50px !important;
		// background-color: red;
		input{
			height: 50px !important;
			line-height: 50px !important;
		}
	}
	
	/* 手机 */
	@media only screen and (max-width: 800px) {
		.content_box{
			padding-bottom: 100px;
			padding-left: 0;
			padding-right: 0;
			>div{
				box-shadow: 0 0 5px rgba(0, 0, 0, .2);
				border-radius: 10px;
				padding: 20px;
				margin: 0;
			}
		}
	}
</style>
