<template>
	<div class="login_content">
		<div class="login_box">
			<div class="nav_box">
				<span @click="goHome" class="iconfont icon-fanhui"></span>
				<span @click="goHome">首页</span>
			</div>
			<img class="bg" src="@/assets/images/login/bg2.png" />
			<div class="hh_box">
				<div class="box">
					<div class="input_box">
						<Input class="input" v-model="form.username" placeholder="请输入身份证号" key="xm">
						<span slot="prepend">身份证号</span>
						</Input>
						<Input class="input" v-model="form.password" type="password" autocomplete="new-password" placeholder="请输入登录密码" key="mm">
						<span slot="prepend">登录密码</span>
						</Input>
						<div class="flex_mm">
							<Checkbox class="check_box" v-model="checked" @on-change="lookPop">我已阅读《隐私条款》
							</Checkbox>
							<div class="zhmm" @click="rePwd">
								<span class="iconfont icon-wangjimima"></span>
								<span>忘记密码</span>
							</div>
						</div>
						<Button class="btn" type="primary" :loading="loading" long @click="submit">登录</Button>
					</div>
				</div>
			</div>
			<Modal v-model="showModal" title="隐私条款" :closable="false" :mask-closable="false" ok-text="我已阅读并同意"
				@on-ok="ok">
				<p class="xy_box" v-html="xyInfo.content"></p>
			</Modal>
		</div>
	</div>
</template>
<script>
	import {
		getStuCode,
		getLogin,
		getHyLogin,
	} from '@/api/login'
	import {
		getSingleArticle
	} from '@/api/home/guild'
	export default {
		data() {
			return {
				showModal: false,
				checked: false,
				form: {
					username:'',
					password: '',
				},
				loading: false,
				xyInfo: {
					content:''
				},
			}
		},
		mounted() {
			
		},
		methods: {
			getSingleArticle() {
				getSingleArticle({
					catid: 210
				}).then(res => {
					if (res.code == 200 || res.code == 0) {
						this.xyInfo = res.result;
						this.xyInfo.content = this.xyInfo.content.replace(/\<span/gi, `<span style="font-size:15px!important" ` );
					}
				})
			},
			submit() {
				if (this.form.username == '') {
					this.$Message.info('身份证号码不能为空');
					return
				}
				if (this.form.password == '') {
					this.$Message.info('密码不能为空');
					return
				}
				if (!this.checked) {
					this.$Message.info('请勾选同意考试须知');
					return
				}
				this.loading = true;
				getHyLogin({
					username:this.form.username,
					password:this.form.password
				}).then(res => {
					this.loading = false;
					if (res.code == 200 || res.code == 0) {
						this.$Message.success('登录成功！');
						this.$Cookies.set('xh_userInfo', JSON.stringify(res.result));
						this.showLogin = false;
						this.$router.push('/')
					}else{
						this.$Modal.error({
							title: '提示',
							content: res.message,
							onOk: () => {},
						});
					}
				})
			},
			lookPop(){
				this.showModal=true;
				this.getSingleArticle();//隐私条款
			},
			ok() {
				this.checked = true;
			},
			goHome() {
				this.$router.push('/')
			},
			rePwd(){
				this.$Modal.info({
					title: '提示',
					content: '请联系协会重置密码，联系电话：0851-86587786',
					onOk: () => {
						
					},
				});
			}
		}
	}
</script>
<style lang="scss" scoped>
	.login_content{
		overflow-y: scroll;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.login_box {
		min-width: 1100px;
		overflow: scroll;
		.bg {
			min-width: 1100px;
			width: 100%;
			height: 100vh;
			position: absolute;
			top: 0;
			// object-fit: cover;
		}
		.nav_box{
			position: absolute;
			padding: 20px 50px;
			font-size: 20px;
			display: flex;
			align-items: center;
			z-index: 9999;
			span{
				cursor: pointer;
			}
			.icon-fanhui{
				font-size: 30px;
				margin-right: 10px;
			}
		}
		.hh_box {
			min-width: 1100px;
			position: absolute;
			top: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.box {
				width: 900px;
				display: flex;
				justify-content: center;
			
				.input_box {
					width: 450px;
					.input {
						width: 100%;
						margin-bottom: 25px;
			
						/deep/ .ivu-input-group-append {
							background: linear-gradient(270deg, #2D85E2 2%, #5B43DF 100%);
							color: #fff;
							width: 100px;
							cursor: pointer;
							border: none;
							font-size: 15px;
						}
			
						/deep/ .ivu-input-group-prepend {
							background-color: #D0E8FF;
							width: 165px;
							font-size: 15px;
							text-align-last:justify;
							padding: 0 20px;
							color: #333;
						}
					}
			
					/deep/ .ivu-input-group .ivu-input,
					.ivu-input-group .ivu-input-inner-container {
						height: 40px;
						font-size: 14px;
					}
			
					.flex_mm{
						margin-bottom: 20px;
						color: #fff;
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 14px;
						.check_box {
							/deep/ .ivu-checkbox-inner{
								margin-right: 5px;
							}
						}
						.zhmm{
							cursor: pointer;
							font-size: 13px;
							display: flex;
							align-items: center;
							.iconfont{
								margin-right: 3px;
							}
						}
						.zhmm:hover{
							// color: #2B4BFA;
						}
					}
			
					.btn {
						height: 40px;
						font-size: 18px;
						letter-spacing: 30px;
						padding-left: 40px;
						background: #2B4BFA;
						box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.4);
						border: none;
					}
				}
			}
		}
	}
	.xy_box {
		height: 400px;
		overflow: scroll;
	}
	
	/deep/ .ivu-btn-text {
		display: none;
	}
</style>
