<template>
  <div class="content_box">
    <div class="search_box">
      <div>
        姓名：<Input
          v-model="form.name"
          placeholder=""
          style="width: 180px"
        ></Input>
      </div>
      <div>
        证书编号：<Input
          v-model="form.certno"
          placeholder=""
          style="width: 180px"
        ></Input>
      </div>
      <div>
        从业单位：<Input
          v-model="form.dw"
          placeholder=""
          style="width: 180px"
        ></Input>
      </div>
      <div class="btn1" @click="toSearch">查询</div>
      <div class="btn2" @click="toReset">重置</div>
    </div>
    <Tabs value="name1" @on-click="handleTabClick">
      <Tab-pane label="从业人员库" name="name1">
        <div class="cell_box">
          <div class="cell th">
            <div v-for="(it, i) in th" :key="i">{{ it }}</div>
          </div>
          <div
            v-for="(it, i) in ryList"
            v-if="ryList.length > 0"
            :key="i"
            class="cell td"
            @click="goDetail(it)"
          >
            <div>
              {{ params.pageSize * params.pageNo - params.pageSize + i + 1 }}
            </div>
            <div>{{ it.name }}</div>
            <div>{{ it.dw }}</div>
            <div>{{ it.certno }}</div>
            <div>{{ it.yxqz }}</div>
          </div>
          <div v-if="ryList.length == 0" class="common_excel_no_more">
            暂无内容~
          </div>
        </div>
        <Page
          v-if="ryList.length != 0"
          :current="params.pageNo"
          :page-size="params.pageSize"
          :total="total"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
      <Tab-pane label="项目负责人库" name="name2">
        <div class="cell_box">
          <div class="cell th">
            <div v-for="(it, i) in thXmfzr" :key="i">{{ it }}</div>
          </div>
          <div
            v-for="(it, i) in xmfzrList"
            v-if="xmfzrList.length > 0"
            :key="i"
            class="cell td"
            @click="goXmfzrDetail(it)"
          >
            <div>
              {{
                paramsXfmzr.pageSize * paramsXfmzr.pageNo -
                paramsXfmzr.pageSize +
                i +
                1
              }}
            </div>
            <div>{{ it.certName }}</div>
            <div>{{ it.certCompany }}</div>
            <div>{{ it.certNo }}</div>
            <div>{{ it.yxqz }}</div>
          </div>
          <div v-if="xmfzrList.length == 0" class="common_excel_no_more">
            暂无内容~
          </div>
        </div>
        <Page
          v-if="xmfzrList.length != 0"
          :current="paramsXfmzr.pageNo"
          :page-size="paramsXfmzr.pageSize"
          :total="xfmzrTotal"
          class="common_page_box"
          show-total
          @on-change="changePage"
        ></Page>
      </Tab-pane>
    </Tabs>
    <vipLogin ref="vipbox"></vipLogin>
  </div>
</template>

<script>
import bannerBox from "@/components/banner.vue";
import headerBox from "@/components/header.vue";
import footerBox from "@/components/footer.vue";
import vipLogin from "@/components/vip_login.vue";
import { getCyry, getXmfzr } from "@/api/home/index";

export default {
  name: "home",
  components: {
    bannerBox,
    headerBox,
    footerBox,
    vipLogin,
  },
  data() {
    return {
      th: ["序号", "姓名", "从业单位", "证书编号", "有效期限"],
      total: 0,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        name: "",
        certno: "",
        dw: "",
      },
      ryList: [],
      thXmfzr: ["序号", "姓名", "从业单位", "证书编号", "有效期限"],
      xmfzrList: [],
      xfmzrTotal: 0,
      paramsXfmzr: {
        pageNo: 1,
        pageSize: 10,
      },
      formXfmzr: {
        certName: "",
        certNo: "",
        certCompany: "",
      },
      tabName: "name1",
    };
  },
  mounted() {
    this.getCyry();
  },
  methods: {
    handleTabClick(name) {
      this.tabName = name;
      this.form = {};
      console.log("name", name);
      this.toReset();
    },
    changePage(e) {
      if (this.tabName == "name1") {
        this.params.pageNo = e;
        this.getCyry();
      } else if (this.tabName == "name2") {
        this.paramsXfmzr.pageNo = e;
        this.getXmfzr();
      }
    },
    getCyry() {
      getCyry({ ...this.params, ...this.form }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          // if(info.length>0){
          // 	info.map(v=>{
          // 		v.sfz = handleIdCard(v.sfz);
          // 	})
          // }
          this.ryList = info;
          this.total = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
          this.$refs["vipbox"].goRyc = true;
        }
      });
    },
    toSearch() {
      if (this.tabName == "name1") {
        this.params.pageNo = 1;
        this.getCyry();
      } else if (this.tabName == "name2") {
        this.paramsXfmzr.pageNo = 1;
        this.getXmfzr();
      }
    },
    toReset() {
      this.form = {};
      if (this.tabName == "name1") {
        this.params.pageNo = 1;
        this.getCyry();
      } else if (this.tabName == "name2") {
        this.paramsXfmzr.pageNo = 1;
        this.getXmfzr();
      }
    },
    goDetail(it) {
      this.$router.push({
        name: "people_detail",
        query: {
          certno: it.certno,
        },
      });
    },
    // 项目负责人 查询列表
    getXmfzr() {
      getXmfzr({
        ...this.paramsXfmzr,
        certName: this.form.name,
        certNo: this.form.certno,
        certCompany: this.form.dw,
      }).then((res) => {
        if (res.code == 200 || res.code == 0) {
          let info = res.result.records;
          this.xmfzrList = info;
          this.xfmzrTotal = res.result.total;
        } else if (res.code == 401) {
          this.$refs["vipbox"].showLogin = true;
          this.$refs["vipbox"].goRyc = true;
        }
      });
    },
    // 项目负责人 详情
    goXmfzrDetail(it) {
      this.$router.push({
        name: "xmfzrDetail",
        query: {
          certno: it.certNo,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ivu-tabs-nav .ivu-tabs-tab {
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.content_box {
  margin: 80px auto;
  width: 80%;
  max-width: 1400px;

  .search_box {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;

    > div {
      margin: 10px;
      white-space: nowrap;
      cursor: pointer;
    }

    .btn1 {
      background: linear-gradient(270deg, #2d85e2 2%, #5b43df 100%);
      color: #fff;
      padding: 5px 15px;
    }

    .btn2 {
      background: #767676;
      color: #fff;
      padding: 5px 15px;
    }
  }

  .cell_box {
    margin-top: 20px;

    .cell {
      display: flex;
      width: 100%;
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0;
      text-align: center;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;

      > div:first-child {
        width: 8%;
      }

      > div:nth-child(2) {
        width: 12%;
      }

      > div:nth-child(3) {
        width: 30%;
      }

      > div:nth-child(4) {
        width: 25%;
      }

      > div:nth-child(5) {
        width: 30%;
      }
    }

    .th {
      background-color: #49b3ff;
    }

    .td {
      cursor: pointer;
    }

    .td:hover {
      background-color: #eee;
    }

    .td:nth-child(odd) {
      background-color: #d8d8d8;
    }

    .td:nth-child(even) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
